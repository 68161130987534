import axios, { AxiosResponse, CancelToken } from "axios";
import { Attachment } from "../modules/attachment";

const attachment = {
  getOrgAttachments: (orgId: string): Promise<AxiosResponse<Attachment[]>> =>
    axios.get(`/api/v1/organization/${orgId}/file`),
  postAttachment: (
    orgId: string,
    data: FormData,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<Attachment>> =>
    axios.post(`/api/v1/organization/${orgId}/file`, data, { cancelToken }),
  getPartAttachments: (
    requestId: number,
    partKey: string
  ): Promise<AxiosResponse<{ [fieldName: string]: Attachment[] }>> =>
    axios.get(`/api/v1/audit-request/${requestId}/part/${partKey}/files`),
  linkPartAttachments: (
    requestId: number,
    partKey: string,
    fieldName: string,
    fileIds: number[]
  ): Promise<AxiosResponse> =>
    axios.post(
      `/api/v1/audit-request/${requestId}/part/${partKey}/field/${fieldName}/files`,
      { fileIds }
    ),
  deleteAttachment: (
    orgId: string,
    fileId: string | number
  ): Promise<AxiosResponse> =>
    axios.delete(`/api/v1/organization/${orgId}/file/${fileId}`),
  putAttachment: (
    orgId: string,
    fileId: string | number,
    fileName: string
  ): Promise<AxiosResponse> =>
    axios.put(`/api/v1/organization/${orgId}/file/${fileId}/metadata`, {
      fileName,
    }),
};

export default attachment;
