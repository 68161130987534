import React, { PropsWithChildren } from "react";
import { DevelopmentItem } from "../../modules/development";
import { DevelopmentItemStatus } from "../../model";
import "./DevelopmentItemCardFrame.scss";

interface DevelopmentItemCardFrameProps {
  itemProps: Partial<DevelopmentItem>;
  className?: string;
  height?: number;
  opacity?: number;
  innerRef?: any;
}

export function DevelopmentItemCardFrame(
  props: PropsWithChildren<DevelopmentItemCardFrameProps>
) {
  return (
    <div
      className={`development-item-card-frame ${props.className || ""}`}
      style={{
        height: props.height,
        opacity: props.opacity,
      }}
      ref={props.innerRef}
    >
      <div
        className={`colorband ${
          props.itemProps.locked || !props.itemProps.status
            ? "colorband-passive"
            : props.itemProps.status === DevelopmentItemStatus.NOT_STARTED
            ? "colorband-notstarted"
            : props.itemProps.status === DevelopmentItemStatus.ONGOING
            ? "colorband-ongoing"
            : props.itemProps.status === DevelopmentItemStatus.READY
            ? "colorband-ready"
            : ""
        }`}
      />
      {props.children}
    </div>
  );
}
