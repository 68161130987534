export const translatedGradeOptions = (t) => [
  { value: "INSUFFICIENT", label: t("gradeOptions.insufficient") },
  { value: "SUFFICIENT", label: t("gradeOptions.sufficient") },
  { value: "GOOD", label: t("gradeOptions.good") },
  { value: "EXCELLENT", label: t("gradeOptions.excellent") },
  { value: "NOTAPPLICABLE", label: t("gradeOptions.notapplicable") },
];

export const translatedGradeRatingMap = (t) => {
  return {
    INSUFFICIENT: t("gradeRatingMap.insufficient"),
    SUFFICIENT: t("gradeRatingMap.sufficient"),
    GOOD: t("gradeRatingMap.good"),
    EXCELLENT: t("gradeRatingMap.excellent"),
    NOTAPPLICABLE: t("gradeRatingMap.notapplicable"),
  };
};

export const translatedYesNoOptions = (t) => [
  { value: "YES", label: t("yesNoOptions.yes") },
  { value: "NO", label: t("yesNoOptions.no") },
];

export const translatedYesNoMap = (t) => {
  return {
    YES: t("yesNoMap.yes"),
    NO: t("yesNoMap.no"),
  };
};
