import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./ClubDashboard.scss";
import { SET_CURRENT_ORG } from "../../modules/account";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { ReduxState } from "../../reducers";
import MainRouteViewWrapper from "../MainRouteViewWrapper";
import homebg from "../../images/landings/landing1.jpg";
import SubNav from "../../components/Navigation/SubNav";
import { getClubDashboardUrl } from "../../routePaths";
import HighlightNavItem from "../../components/Navigation/HighlightNavItem";
import { ClubDashboardRoutes } from "../../router";
import { fetchOrganizationCapabilities } from "../../modules/organization";

type ClubDashboardProps = WithTranslation &
  RouteComponentProps<{ orgId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface ClubDashboardState {}

class ClubDashboard extends Component<ClubDashboardProps, ClubDashboardState> {
  componentDidMount() {
    const {
      currentOrg,
      match: {
        params: { orgId },
      },
    } = this.props;

    const organizationId = parseInt(orgId, 10);
    if (!currentOrg) {
      this.props.setCurrentOrg(organizationId);
    }

    this.props.fetchOrganizationCapabilities(organizationId);
  }

  render() {
    const {
      t,
      match: {
        params: { orgId },
      },
    } = this.props;
    const getCurrentDashboard = getClubDashboardUrl(orgId);

    return (
      <MainRouteViewWrapper
        picture={homebg}
        bannerplus={true}
        bannersponsor={true}
        footer={true}
        pictureText={t("clubdashboard.title")}
      >
        <div className="clubdashboard">
          <SubNav>
            <HighlightNavItem
              target={getCurrentDashboard}
              text={t("clubdashboard.news")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/path"}
              text={t("clubdashboard.path")}
              subnav={false}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/development"}
              text={t("clubdashboard.development")}
              subnav={false}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/attachments"}
              text={t("clubdashboard.attachments")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/tools"}
              text={t("clubdashboard.tools")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/help"}
              text={t("clubdashboard.help")}
              subnav={true}
            />
          </SubNav>
          <ClubDashboardRoutes />
        </div>
      </MainRouteViewWrapper>
    );
  }
}

const homeTranslated = withTranslation("common")(ClubDashboard);

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{ orgId: string }>
) => {
  return {
    currentOrg: state.account.currentOrg,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrg: (orgId) => dispatch(SET_CURRENT_ORG(orgId)),
  fetchOrganizationCapabilities: (orgId: number) =>
    dispatch(fetchOrganizationCapabilities(orgId)),
});

const HomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(homeTranslated);

export default withRouter(HomeConnected);
