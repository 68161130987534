import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AuditResponse, OrgType } from "../../model";
import { ReduxState } from "../../reducers";
import { RouteComponentProps, withRouter } from "react-router";
import {
  fetchAuditRequestAddInfo,
  fetchSingleAuditRequestAudit,
} from "../../modules/auditRequest";
import { reduxForm, getFormValues } from "redux-form";
import { getOrganizationDashboardUrl } from "../../routePaths";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { isEmpty } from "lodash";
import {
  fetchSingleResponse,
  fetchPreviousResponseByAuditRequest,
  saveResponse,
  submitResponse,
  SET_RESPONSE_SUBMIT_TYPE,
} from "../../modules/auditor";
import Alert from "react-s-alert";
import AuditSummaryV1 from "../../components/Forms/V1/AuditSummaryV1";
import AuditSummaryV2 from "../../components/Forms/V2/AuditSummaryV2";
import AuditSummaryV3 from "../../components/Forms/V3/AuditSummaryV3";
import AuditSummaryV4 from "../../components/Forms/V4/AuditSummaryV4";
import AuditSummaryV5 from "../../components/Forms/V5/AuditSummaryV5";
import { Modal } from "react-bootstrap";

interface ComponentProps {
  initialize(formData: any): void;
  handleSubmit(values?: any): void;
}

type AuditSummaryProps = ComponentProps &
  WithTranslation &
  RouteComponentProps<{
    orgId: string;
    requestId: string;
    responseId: string;
  }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class AuditSummary extends Component<AuditSummaryProps, any> {
  constructor(props: AuditSummaryProps) {
    super(props);
    this.state = {
      showSubmitConfirmModal: false,
    };
  }

  fetchCurrentPrevious = async () => {
    const {
      currentResponse,
      match: { params },
      fetchPreviousResponse,
      initialize,
    } = this.props;
    // if response is already fetched, use currentResponse
    let response: any = null;
    if (
      currentResponse &&
      currentResponse.id.toString() === params.responseId
    ) {
      response = currentResponse;
    } else {
      response = await this.props.fetchSingleResponse(params.responseId);
    }
    // always fetch the previous response
    const previousResponse: AuditResponse = await fetchPreviousResponse(
      params.requestId
    );
    // initialize the form with either previousResponse or currentResponse (if currentResponse is empty)
    if (!response || (isEmpty(response.values) && previousResponse)) {
      const { decision, ...rest } = previousResponse.values;
      initialize(rest);
    } else if (response) {
      initialize(response.values);
    }
  };

  componentDidMount() {
    const {
      match: {
        params: { requestId, responseId },
      },
      currentResponse,
      previousResponse,
      auditRequest,
      initialize,
    } = this.props;
    if (
      requestId &&
      (!auditRequest || auditRequest.id.toString() !== requestId)
    ) {
      this.props.fetchSingleAuditRequestAudit(requestId);
    }
    // always fetch additional info because it might change between route changes
    if (requestId) {
      this.props.fetchAuditRequestAddInfo(requestId);
    }
    // always check if current and previous responses are fetch
    // otherwise, initialize the form with either previousResponse or currentResponse (if currentResponse is empty)
    if (requestId && responseId) {
      this.fetchCurrentPrevious();
    } else if (
      !currentResponse ||
      (isEmpty(currentResponse.values) && previousResponse)
    ) {
      const { decision, ...rest } = previousResponse!.values;
      initialize(rest);
    } else {
      initialize(currentResponse.values);
    }
  }

  saveResponseDraft = async () => {
    await this.props.setSubmitType("DRAFT");
    this.props.submitThisForm();
  };

  navigateToAuditPart = () => {
    const { match, auditRequest, history } = this.props;
    history.push(
      `${getOrganizationDashboardUrl(match.params.orgId)}/path/${
        auditRequest ? auditRequest.id : null
      }`
    );
  };

  saveResponseAndSubmit = async () => {
    const { formData, t } = this.props;
    if (!formData.decision) {
      Alert.error(t("auditSummary.decisionMissing"));
    } else {
      if (
        formData.decision === "REJECTED_PRELIMINARY" &&
        !formData.agreedCorrectionDate
      ) {
        Alert.error(t("auditSummary.correctionDateMissing"));
      } else {
        await this.props.setSubmitType("SUBMIT");
        this.props.submitThisForm();
      }
    }
  };

  render() {
    const {
      auditRequest,
      handleSubmit,
      currentResponse,
      t,
      currentOrgData,
      formData,
      auditRequestAddInfo,
    } = this.props;
    const { showSubmitConfirmModal } = this.state;
    if (!auditRequest || !currentResponse) {
      return null;
    }
    const currentOrgType = currentOrgData ? currentOrgData.type : null;
    const options: any = [
      {
        key: "ACCEPTED",
        value: (
          <div>
            <b>{t("auditSummary.congratulations")}</b>
            {t("auditSummary.congratulations2")}
          </div>
        ),
      },
      { key: "REJECTED", value: <div>{t("auditSummary.reject")}</div> },
    ];
    const commentsAdded =
      auditRequest &&
      auditRequest.parts &&
      auditRequest.parts.filter((x) => x.state === "AWAITING_REVISION").length >
        0;
    if (commentsAdded) {
      options.splice(1, 0, {
        key: "REJECTED_PRELIMINARY",
        value: (
          <div>
            <div className="text">{t("auditSummary.rejectPreliminary")}</div>
          </div>
        ),
      });
    }

    const summaryContent =
      auditRequest.formVersion === 1 ? (
        <AuditSummaryV1
          formData={formData}
          readOnly={currentResponse.submitted}
          auditor={true}
          commentsAdded={commentsAdded}
          options={options}
          currentOrgType={currentOrgType}
          handleSubmit={handleSubmit}
          saveResponseDraft={this.saveResponseDraft}
          saveResponseAndSubmit={() =>
            this.setState({ showSubmitConfirmModal: true })
          }
          navigateBack={this.navigateToAuditPart}
        />
      ) : auditRequest.formVersion === 2 ? (
        <AuditSummaryV2
          formData={formData}
          readOnly={currentResponse.submitted}
          auditor={true}
          commentsAdded={commentsAdded}
          options={options}
          currentOrgType={currentOrgType}
          handleSubmit={handleSubmit}
          auditRequest={auditRequest}
          auditRequestAddInfo={auditRequestAddInfo}
          saveResponseDraft={this.saveResponseDraft}
          saveResponseAndSubmit={() =>
            this.setState({ showSubmitConfirmModal: true })
          }
          navigateBack={this.navigateToAuditPart}
        />
      ) : auditRequest.formVersion === 3 ? (
        <AuditSummaryV3
          formData={formData}
          readOnly={currentResponse.submitted}
          auditor={true}
          commentsAdded={commentsAdded}
          options={options}
          currentOrgType={currentOrgType}
          handleSubmit={handleSubmit}
          auditRequest={auditRequest}
          auditRequestAddInfo={auditRequestAddInfo}
          saveResponseDraft={this.saveResponseDraft}
          saveResponseAndSubmit={() =>
            this.setState({ showSubmitConfirmModal: true })
          }
          navigateBack={this.navigateToAuditPart}
        />
      ) : auditRequest.formVersion === 4 ? (
        <AuditSummaryV4
          formData={formData}
          readOnly={currentResponse.submitted}
          auditor={true}
          commentsAdded={commentsAdded}
          options={options}
          currentOrgType={currentOrgType}
          handleSubmit={handleSubmit}
          auditRequest={auditRequest}
          auditRequestAddInfo={auditRequestAddInfo}
          saveResponseDraft={this.saveResponseDraft}
          saveResponseAndSubmit={() =>
            this.setState({ showSubmitConfirmModal: true })
          }
          navigateBack={this.navigateToAuditPart}
        />
      ) : (
        <AuditSummaryV5
          formData={formData}
          readOnly={currentResponse.submitted}
          auditor={true}
          commentsAdded={commentsAdded}
          options={options}
          currentOrgType={currentOrgType}
          handleSubmit={handleSubmit}
          auditRequest={auditRequest}
          auditRequestAddInfo={auditRequestAddInfo}
          saveResponseDraft={this.saveResponseDraft}
          saveResponseAndSubmit={() =>
            this.setState({ showSubmitConfirmModal: true })
          }
          navigateBack={this.navigateToAuditPart}
        />
      );

    return (
      <>
        {summaryContent}
        <Modal
          show={showSubmitConfirmModal}
          onHide={() => this.setState({ showSubmitConfirmModal: false })}
          dialogClassName="audit-submit-modal"
          className="centered-modal"
        >
          <Modal.Body>
            <h1>{t("summaryConfirmModal.title")}</h1>
            <p>{t("summaryConfirmModal.subtitle")}</p>
            <p>{t("summaryConfirmModal.subtitle2")}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => {
                  this.saveResponseAndSubmit();
                }}
              >
                {t("buttons.send")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => this.setState({ showSubmitConfirmModal: false })}
              >
                {t("buttons.cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const translated = withTranslation("forms")(AuditSummary);

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  return {
    formData: getFormValues("summaryForm")(state),
    auditRequest: state.auditRequest.auditRequest,
    auditRequestAddInfo: state.auditRequest.auditRequestAddInfo,
    currentResponse: state.auditor.currentResponse,
    previousResponse: state.auditor.previousResponse,
    currentOrgData: state.account.user
      ? state.account.user.writeAccessOrgs.find(
          (x) =>
            x.orgId === parseInt(ownProps.match.params.orgId, 10) &&
            (x.type === OrgType.SPORTS_FEDERATION ||
              x.type === OrgType.COLLECTION_AREA_ASSOCIATION)
        )
      : null,
    submitType: state.auditor.submitType,
  };
};
const mapDispatchToProps = (dispatch) => ({
  // This function is ran when user presses submit button
  onSubmit: (formValues: any, dispatch, props) =>
    dispatch(saveResponse(props.match.params.responseId, formValues)).then(
      (saveSucceeded) => {
        if (saveSucceeded && props.submitType === "SUBMIT") {
          dispatch(submitResponse(props.match.params.responseId)).then(
            (submitSucceeded: boolean) => {
              if (submitSucceeded) {
                props.history.push(
                  `${getOrganizationDashboardUrl(
                    props.match.params.orgId
                  )}/auditing`
                );
              }
            }
          );
        }
      }
    ),
  fetchSingleAuditRequestAudit: (requestId: string) =>
    dispatch(fetchSingleAuditRequestAudit(requestId)),
  fetchAuditRequestAddInfo: (requestId: string) =>
    dispatch(fetchAuditRequestAddInfo(requestId)),
  fetchPreviousResponse: (requestId: string) =>
    dispatch(fetchPreviousResponseByAuditRequest(requestId)),
  fetchSingleResponse: (responseId: string) =>
    dispatch(fetchSingleResponse(responseId)),
  submitThisForm: () => dispatch(submit("summaryForm")),
  setSubmitType: (type: string) => dispatch(SET_RESPONSE_SUBMIT_TYPE(type)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "summaryForm" })(translated as any))
);
