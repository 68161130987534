import React, { useState } from "react";
import paperclipIcon from "../../../images/paperclip.svg";
import closeIcon from "../../../images/closeicon.svg";
import "./AttachmentField.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  orgId: string;
  readOnly?: boolean;
  edit?: boolean;
  placeholder?: string;
  forceShowContent?: boolean;
  linkedAttachments?: any[];
  openAttachmentModal(): void;
  unlinkAttachment(id: number): void;
}

export function AttachmentField({
  readOnly,
  edit,
  placeholder,
  linkedAttachments,
  openAttachmentModal,
  unlinkAttachment,
  forceShowContent,
}: Props) {
  const { t } = useTranslation("forms");
  const [deleteId, setDeleteId] = useState<number | null>(null);

  let content: any = null;

  const addElement = (
    <div
      className="add-attachments hoverborder"
      onClick={() => openAttachmentModal()}
    >
      <span className="text">{t("forms:addAttachment")}</span>
      <img src={paperclipIcon} alt="Attachments" />
    </div>
  );

  if (linkedAttachments && linkedAttachments.length > 0) {
    content = (
      <div
        className={
          readOnly ? "attachment-container noleftpad" : "attachment-container"
        }
      >
        <div
          className={readOnly ? "header" : "header hoverborder"}
          onClick={() => (readOnly ? null : openAttachmentModal())}
        >
          <div className="title">{t("forms:attachments")}</div>
          {readOnly ? null : <img src={paperclipIcon} alt="Attachments" />}
        </div>
        <div className="attachment-list">
          {linkedAttachments.map((one) => {
            return (
              <div className="attachment" key={one.id}>
                <a
                  className="name"
                  href={one.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {one.name}
                </a>
                {(!readOnly || edit) && (
                  <span
                    className="delete"
                    role="button"
                    onClick={() => setDeleteId(one.id)}
                  >
                    <img src={closeIcon} alt="" />
                  </span>
                )}
              </div>
            );
          })}
        </div>
        {forceShowContent && !readOnly && addElement}
      </div>
    );
  } else if (
    (!linkedAttachments || linkedAttachments.length === 0) &&
    (!readOnly || forceShowContent)
  ) {
    content = (
      <div
        className={
          readOnly ? "attachment-container noleftpad" : "attachment-container"
        }
      >
        {!!placeholder && <div className="placeholder">{placeholder}</div>}
        {!readOnly && addElement}
      </div>
    );
  }
  return (
    <div className="attachment-field">
      {content}
      {deleteId && (
        <Modal
          show={!!deleteId}
          onHide={() => setDeleteId(null)}
          dialogClassName="attachment-modal unlink-modal"
          className="centered-modal"
        >
          <Modal.Body>
            <h1>{t("deleteModal.title")}</h1>
            <span>{t("deleteModal.subtitle")}</span>
          </Modal.Body>
          <Modal.Footer>
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => {
                  unlinkAttachment(deleteId);
                  setDeleteId(null);
                }}
              >
                {t("deleteModal.delete")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => setDeleteId(null)}
              >
                {t("common:modal.cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
