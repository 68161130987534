import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PartStatus, AuditRequest } from "../../../model";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReduxState } from "../../../reducers";
import { connect } from "react-redux";

export interface ClubFormButtonsProps extends WithTranslation {
  partState: PartStatus;
  submitted: boolean;
  auditRequest?: AuditRequest | null;
  isNotesOpen?: boolean;
  onSubmitClick(): any;
  onDraftClick(): any;
  onBackClick(): any;
}

class ClubFormButtons extends Component<ClubFormButtonsProps, any> {
  private windowHeight = 0;
  constructor(props: ClubFormButtonsProps) {
    super(props);
    this.state = {
      float: true,
    };
  }

  componentDidMount() {
    this.windowHeight = window.innerHeight;
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.updateWindowHeight);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowHeight);
  }

  updateWindowHeight = () => {
    this.windowHeight = window.innerHeight;

    // In some cases, we have to adjust the float state after resizing
    this.handleScroll();
  };

  handleScroll = () => {
    const buttons = document.getElementById("clubformbuttons");
    const buttonsBottom = buttons ? buttons.getBoundingClientRect().bottom : 0;
    const float = buttonsBottom > this.windowHeight;

    if (this.state.float !== float) {
      this.setState({
        float,
      });
    }
  };

  // PartFormWrapper decides whether to use AuditFormButtons or ClubFormButtons
  // onClick handlers are also passed from there
  render() {
    const {
      onBackClick,
      onDraftClick,
      onSubmitClick,
      t,
      submitted,
      auditRequest,
    } = this.props;
    const popoverSend = (
      <Popover id="popover-positioned-bottom">{t("popovers.clubSend")}</Popover>
    );
    const popoverDraft = (
      <Popover id="popover-positioned-bottom">
        {t("popovers.clubDraft")}
      </Popover>
    );
    return (
      <React.Fragment>
        {this.state.float && (
          <div
            className={
              this.props.isNotesOpen
                ? "buttons floatbuttons floatwidth"
                : "buttons floatbuttons"
            }
          >
            <div className="centergroup">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={popoverSend}
              >
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={onSubmitClick}
                  disabled={!auditRequest || submitted}
                >
                  {t("clubFormButtons.send")}
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={popoverDraft}
              >
                <button
                  type="button"
                  disabled={!auditRequest || submitted}
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={onDraftClick}
                >
                  {t("clubFormButtons.draft")}
                </button>
              </OverlayTrigger>
              <button
                type="button"
                className="btn btn-secondary btn-secondary-blue"
                onClick={onBackClick}
              >
                {t("clubFormButtons.back")}
              </button>
            </div>
          </div>
        )}
        <div id="clubformbuttons" className="buttons">
          <div className="centergroup">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="bottom"
              overlay={popoverSend}
            >
              <button
                type="button"
                className="btn btn-secondary btn-secondary-blue"
                onClick={onSubmitClick}
                disabled={!auditRequest || submitted}
              >
                {t("clubFormButtons.send")}
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="bottom"
              overlay={popoverDraft}
            >
              <button
                type="button"
                disabled={!auditRequest || submitted}
                className="btn btn-secondary btn-secondary-blue"
                onClick={onDraftClick}
              >
                {t("clubFormButtons.draft")}
              </button>
            </OverlayTrigger>
            <button
              type="button"
              className="btn btn-secondary btn-secondary-blue"
              onClick={onBackClick}
            >
              {t("clubFormButtons.back")}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const linksTranslated = withTranslation("common")(ClubFormButtons);

const mapStateToProps = (state: ReduxState) => {
  return {
    isNotesOpen: state.path.isNotesOpen,
  };
};

export default connect(mapStateToProps)(linksTranslated);
