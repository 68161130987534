import axios, { AxiosResponse } from "axios";
import { DevelopmentItem } from "../modules/development";

const development = {
  getDevelopmentItems: (orgId: number): Promise<AxiosResponse> =>
    axios.get(`/api/v1/club-development/org/${orgId}`),
  createDevelopmentItem: (
    orgId: number,
    item: DevelopmentItem
  ): Promise<AxiosResponse> =>
    axios.post(`/api/v1/club-development/org/${orgId}/item`, item),
  putDevelopmentItem: (
    itemId: number,
    item: Partial<DevelopmentItem>
  ): Promise<AxiosResponse> =>
    axios.put(`/api/v1/club-development/item/${itemId}`, item),
  deleteDevelopmentItem: (itemId: number): Promise<AxiosResponse> =>
    axios.delete(`/api/v1/club-development/item/${itemId}`),
};

export default development;
