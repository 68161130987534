import axios from "axios";

const config = {
  getConfig: (locale: string) =>
    locale !== undefined
      ? axios.get(`/api/v1/config?locale=${locale}`)
      : axios.get(`/api/v1/config`),
};

export default config;
