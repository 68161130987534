import React from "react";
import { Component } from "react";

import "./AuditSettingsModal.scss";
import { Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ReduxState } from "../../reducers";
import { CLOSE_AUDIT_SETTINGS_MODAL } from "../../modules/auditSettings";
import pencilSvg from "../../images/pencil-icon.svg";
import closeIcon from "../../images/closeicon.svg";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format as fnsFormat } from "date-fns";
import DayPicker from "react-day-picker";
import { ClubEnrollmentUpdate, PendingAuditState } from "../../model";
import { reopenRequest, updateEnrollment } from "../../modules/auditor";
import { RouteComponentProps, withRouter } from "react-router";
import moment from "moment";
import { InfoBox } from "../Infobox";

type AuditSettingsModalProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const dateFormat = "DD.MM.YYYY";

class AuditSettingsModal extends Component<AuditSettingsModalProps, any> {
  constructor(props: AuditSettingsModalProps) {
    super(props);
    this.state = {
      dateInput: null,
      confirmationOpen: false,
    };
  }

  dayPicker = React.createRef<DayPickerInput>();

  componentWillReceiveProps(newProps: AuditSettingsModalProps) {
    if (this.state.dateInput) {
      // Do not touch our the date if we already have a state
      return;
    }

    const { auditInfo } = newProps.modalData;
    if (!auditInfo) {
      return;
    }

    this.setState({
      dateInput: auditInfo.nextAuditTimestamp
        ? new Date(auditInfo.nextAuditTimestamp)
        : null,
    });
  }

  handleAuditDateChange = () => {
    const { modalData, updateEnrollment } = this.props;
    const auditInfo = modalData.auditInfo ? modalData.auditInfo : undefined;
    if (!auditInfo) {
      return null;
    }

    updateEnrollment(auditInfo.clubId, auditInfo.qualityPath, {
      nextAuditTimestamp: this.state.dateInput,
    });
  };

  showDayPicker = () => {
    if (this.dayPicker && this.dayPicker.current) {
      this.dayPicker.current.getInput().select();
    }
  };

  closeModal = () => {
    this.setState(
      { dateInput: null, confirmationOpen: false },
      this.props.hideModal
    );
  };

  closeConfirmationModal = () => {
    this.setState({ confirmationOpen: false });
  };

  parseDate = (str, format, locale) => {
    const parsed = moment(str, format).toDate();
    if ((DayPicker.DateUtils as any).isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  handleDateInput = (date: any) => {
    this.setState({
      dateInput: date,
    });
  };

  formatDate = (date, format, locale) => {
    return fnsFormat(date, format, { locale });
  };

  handleReopenRequest = (auditRequestId: number) => {
    this.closeConfirmationModal();
    this.props.reopenRequest(auditRequestId);
  };

  render() {
    const { modalData, t } = this.props;
    // take modal data from state and show commentbox and/or audit dropdown, depending on options.
    // controlled from AuditField and the props it gets.
    const auditInfo = modalData.auditInfo ? modalData.auditInfo : undefined;
    if (!auditInfo) {
      return null;
    }

    const auditState = auditInfo.state;
    const canOpenAudit = auditState === PendingAuditState.RESPONSE_DUE;

    return (
      <div>
        <Modal
          show={modalData.modalIsOpen}
          onHide={this.closeModal}
          dialogClassName="settings-modal"
        >
          <div className="settings-header">
            <span className="leftspans">{t("auditsettings.modify")}</span>

            <button className="btn-invisible" onClick={this.closeModal}>
              <img className="closeicon" src={closeIcon} alt="Cross" />
            </button>
          </div>
          <Modal.Body>
            <React.Fragment>
              <div className="clubinfocontainer">
                <h4>{auditInfo.federationName}</h4>
                <h1>{auditInfo.clubName}</h1>
                <h3>{t(`auditsettings.${auditInfo.qualityPath}`)}</h3>
              </div>
              {auditInfo &&
                auditInfo.auditRequestId &&
                !modalData.deadlineOnly && (
                  <div className="unlockcontainer">
                    {auditState && (
                      <div className="title">
                        {t("auditsettings.state")}:{" "}
                        <span className="status">
                          {t("state." + auditState)}
                        </span>
                      </div>
                    )}
                    <button
                      disabled={!canOpenAudit}
                      className="btn btn-lg btn-secondary-blue"
                      onClick={() => {
                        this.setState({ confirmationOpen: true });
                      }}
                    >
                      {t("auditsettings.unlock")}
                    </button>
                    {!canOpenAudit && (
                      <InfoBox text={t("auditsettings.info")} />
                    )}
                  </div>
                )}
              <div className="deadlinecontainer">
                <h2>{t("auditsettings.timetable")}</h2>
                <div className="deadlinedate">
                  <div className="title">{t("auditsettings.next-audit")}</div>
                  <DayPickerInput
                    ref={this.dayPicker}
                    formatDate={this.formatDate}
                    format={dateFormat}
                    parseDate={this.parseDate}
                    value={
                      auditInfo.nextAuditTimestamp
                        ? fnsFormat(auditInfo.nextAuditTimestamp, dateFormat)
                        : undefined
                    }
                    placeholder={
                      auditInfo.nextAuditTimestamp
                        ? null
                        : fnsFormat(new Date(), dateFormat)
                    }
                    onDayChange={this.handleDateInput}
                    className="daypicker"
                  />
                  <img src={pencilSvg} alt="" onClick={this.showDayPicker} />
                  <button
                    className="btn btn-lg btn-secondary-blue"
                    onClick={this.handleAuditDateChange}
                    disabled={!this.state.dateInput}
                  >
                    {t("modal.save")}
                  </button>
                </div>
              </div>
              <div className="buttoncontainer">
                <button
                  className="btn btn-lg btn-secondary-blue"
                  onClick={this.closeModal}
                >
                  {t("modal.close")}
                </button>
              </div>
            </React.Fragment>
          </Modal.Body>

          <Modal
            show={this.state.confirmationOpen}
            onHide={this.closeConfirmationModal}
            dialogClassName="submit-modal confirmation-modal"
            className="centered-modal"
          >
            <Modal.Body>
              <h1>{t("auditsettings.confirm")}</h1>
            </Modal.Body>
            <Modal.Footer>
              <div className="modalbuttons">
                <button
                  className="btn btn-lg btn-secondary-blue"
                  onClick={() => {
                    this.handleReopenRequest(auditInfo.auditRequestId);
                  }}
                >
                  {t("auditsettings.send")}
                </button>
                <button
                  className="btn btn-lg btn-secondary-blue"
                  onClick={this.closeConfirmationModal}
                >
                  {t("modal.cancel")}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </Modal>
      </div>
    );
  }
}
const commentTranslated = withTranslation("common")(AuditSettingsModal);

const mapStateToProps = (state: ReduxState) => {
  return {
    modalData: state.auditSettings,
  };
};

const mapDispatchToProps = (dispatch, ownProps: any) => ({
  hideModal: () => dispatch(CLOSE_AUDIT_SETTINGS_MODAL()),
  reopenRequest: (requestId: number) =>
    dispatch(reopenRequest(requestId, ownProps.match.params.orgId)),
  updateEnrollment: (
    clubId: number,
    qualityPath: string,
    data: ClubEnrollmentUpdate
  ) =>
    dispatch(
      updateEnrollment(clubId, qualityPath, data, ownProps.match.params.orgId)
    ),
});

export default withRouter<RouteComponentProps<{}>, any>(
  connect(mapStateToProps, mapDispatchToProps)(commentTranslated)
);
