import { createAction } from "redux-actions";
import Api from "../api";
import i18next from "i18next";
import Logger from "js-logger";

export const SET_LOCALE_SUCCESS = createAction<string>("SET_LOCALE_SUCCESS");
export const SET_LOCALE_FAILURE = createAction("SET_LOCALE_FAILURE");

export function setLocale(locale: string) {
  return async (dispatch): Promise<boolean> => {
    try {
      i18next.changeLanguage(locale);

      const refreshEvent = new CustomEvent("refreshEntries");
      window.dispatchEvent(refreshEvent);

      // Dispatch success before persisting, since persisting is only secondary. The main thing is that the user has
      // changed the language and is able to use the application using the newly selected language.
      dispatch(SET_LOCALE_SUCCESS(locale));

      try {
        await Api.i18n.setLocale(locale);
      } catch (err) {
        Logger.warn("Persisting locale change failed");
      }
      return true;
    } catch (err) {
      dispatch(SET_LOCALE_FAILURE());
      return false;
    }
  };
}
