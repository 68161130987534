import React from "react";
import "./ExtraInfoTextBox.scss";
import infoIcon from "../../../images/info-icon2.svg";
import { useTranslation } from "react-i18next";

export interface IExtraInfoTextBoxProps {
  text: string;
}

export const ExtraInfoTextBox = (props: IExtraInfoTextBoxProps) => {
  const { text } = props;

  const { t } = useTranslation("forms");
  return (
    <div className="extra-info-text-container">
      <div className="extra-info-text-header">
        <img className="extra-info-text-icon" src={infoIcon} alt="" />
        <div>
          <b className="extra-info-text-heading">
            {t("sectionExtraInfo.extraInfoHeading")}
          </b>
        </div>
      </div>
      <div className="extra-info-text-content">{text}</div>
    </div>
  );
};
