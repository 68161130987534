import React from "react";
import { getFormValues } from "redux-form";
import { Component } from "react";
import {
  AuditRequest,
  ClubEnrollment,
  PartStatus,
  PathType,
} from "../../model";
import {
  getComments,
  saveCommentsDraft,
  saveSingleCommentDraft,
} from "../../modules/comment";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { withTranslation, WithTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { isEmpty } from "lodash";
import { fetchPartTools } from "../../modules/contentful";
import {
  getAuditRequestPartValues,
  SET_SUBMIT_TYPE,
  updateAuditRequestPart,
  updateAuditRequestPartAudit,
  updateAuditRequestPartValues,
  fetchAuditRequestsByEnrollment,
  updateAuditRequestPartSingleValue,
  copyClubContentFromOtherQualityPath,
} from "../../modules/auditRequest";
import ClubFormButtons from "./Components/ClubFormButtons";
import AuditFormButtons from "./Components/AuditFormButtons";
import {
  getClubDashboardUrl,
  getOrganizationDashboardUrl,
} from "../../routePaths";
import * as classNames from "classnames";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import { SET_CURRENT_FORM, SET_NAVIGATE_TO_MODAL } from "../../modules/path";
import {
  fetchPartAttachments,
  fetchOrgAttachments,
} from "../../modules/attachment";
import { TFunction } from "i18next";
import { SportsFederationTranslationKeyMapType } from "../../modules/config";
import { AuditFormMetaInfo } from "../../modules/formMetaInfo";

/*
 * This wrapper performs datafetching and button operations for different PathForms
 * Used in PathForm.tsx in club dashboard / organization dashboard
 * Props given are passed to the actual form structure component (children)
 * */

interface ReduxForm {
  initialize(formData: any): void;
  handleSubmit(values?: any): void;
}
export interface ComponentProps {
  // required, PathForm sets this depending on selected pathType
  form: string;
  commentEdit?: boolean;
  orgId?: any;
  status?: any;
  readOnlyMode?: boolean;
  auditRequest?: AuditRequest | null;
  enrollment?: ClubEnrollment | null;
  clubMode: boolean;
  partKey: string;
  pathType: string;
  toolsKey?: string;
  translationNs?: string;
  children?: any;
  formMetaInfo?: AuditFormMetaInfo;
  formVersion?: number;
  sportsFederationTranslationKeyMap?:
    | SportsFederationTranslationKeyMapType
    | {};
}

type PathFormWrapperFormProps = ComponentProps &
  ReduxForm &
  RouteComponentProps<{}> &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class PathFormWrapper extends Component<PathFormWrapperFormProps> {
  async componentDidMount() {
    if (this.props.auditRequest && this.props.auditRequest.id) {
      this.props.fetchFormPartValues(this.props.auditRequest);
      this.props.getComments(this.props.auditRequest);
      this.props.getAttachments(this.props.auditRequest);
      this.props.getOrgAttachments();
      this.props.setCurrentForm();
    }
    window.addEventListener("refreshEntries", this.props.fetchTools);
    window.addEventListener("updateSingleField", this.updateSingleField);
    window.addEventListener("updateComments", this.saveSingleCommentDraft);
    this.props.fetchTools();
  }

  async componentDidUpdate(prevProps: PathFormWrapperFormProps) {
    if (
      !prevProps.auditRequest &&
      this.props.auditRequest &&
      this.props.auditRequest.id
    ) {
      this.props.fetchFormPartValues(this.props.auditRequest);
      this.props.getComments(this.props.auditRequest);
      this.props.getAttachments(this.props.auditRequest);
      this.props.getOrgAttachments();
      if (!this.props.formCurrentOrgId) {
        this.props.setCurrentForm();
      }
    }

    if (prevProps.formPartValues !== this.props.formPartValues) {
      this.props.initialize(this.props.formPartValues);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.removeEventListener("refreshEntries", this.props.fetchTools);
    window.removeEventListener("updateSingleField", this.updateSingleField);
    window.removeEventListener("updateComments", this.saveSingleCommentDraft);
    dispatch(SET_NAVIGATE_TO_MODAL(false));
  }

  // save a single field value when changing the value in the form
  updateSingleField = async (event) => {
    const { auditRequest } = this.props;
    const part = auditRequest
      ? auditRequest.parts.find((x) => x.key === this.props.partKey)
      : null;
    if (part && part.state !== "DRAFT") {
      await this.props.updatePartState(PartStatus.DRAFT, true);
    }
    await this.props.updateAuditRequestPartSingleValue({
      [event.detail.fieldName]: event.detail.value,
    });
  };

  updateOnly = async () => {
    const { auditRequest } = this.props;
    const part = auditRequest
      ? auditRequest.parts.find((x) => x.key === this.props.partKey)
      : null;
    if (part && part.state !== "DRAFT") {
      await this.props.updatePartState(PartStatus.DRAFT, true);
    }
    // DRAFT submittype omits updating the part to READY
    await this.props.setSubmitType("DRAFT");
    await this.props.submitThisForm();
  };

  updateAndMarkAsReady = async () => {
    const { auditRequest, history } = this.props;
    const part = auditRequest
      ? auditRequest.parts.find((x) => x.key === this.props.partKey)
      : null;
    if (part && part.state !== "DRAFT") {
      await this.props.updatePartState(PartStatus.DRAFT, true);
    }
    // READY submittype updates the part state to READY after values have been updated
    await this.props.setSubmitType("READY");
    await this.props.submitThisForm();
    history.push(getClubDashboardUrl(this.props.orgId) + "/path");
  };

  navigateToClubPart = () => {
    const { history } = this.props;
    history.push(getClubDashboardUrl(this.props.orgId) + "/path");
  };

  saveCommentsDraft = () => {
    this.props.saveCommentsDraft();
  };

  // save a single audit comment when changing the field value in the comment modal
  saveSingleCommentDraft = (event) => {
    this.props.saveSingleCommentDraft(event.detail);
  };

  navigateToAuditPart = () => {
    const { history } = this.props;
    history.push(
      getOrganizationDashboardUrl(this.props.orgId) +
        "/path/" +
        this.props.auditRequest!.id
    );
  };

  saveCommentAndMarkReady = async () => {
    const { history } = this.props;
    await this.props.saveCommentsDraft(true);
    await this.props.updatePartStateAudit(PartStatus.AWAITING_REVISION);
    history.push(
      getOrganizationDashboardUrl(this.props.orgId) +
        "/path/" +
        this.props.auditRequest!.id
    );
  };

  initializeWithOtherEnrollment = async () => {
    const { enrollments, enrollment, formData, t } = this.props;
    let confirmValue = false;
    if (!isEmpty(formData)) {
      confirmValue = confirm(t("formCopy.confirm"));
    } else {
      confirmValue = true;
    }
    if (!confirmValue) {
      return;
    }
    // find out what the other enrollment is
    if (!enrollments || !enrollment) {
      return null;
    }

    let otherPath: PathType | undefined;
    if (enrollment.qualityPath === PathType.YOUTH) {
      otherPath = PathType.ADULTS;
    } else if (enrollment.qualityPath === PathType.ADULTS) {
      otherPath = PathType.YOUTH;
    } else if (enrollment.qualityPath === PathType.TOP_SPORTS) {
      /** TOP_SPORTS always gets content from YOUTH form */
      otherPath = PathType.YOUTH;
    } else {
      return null;
    }

    this.props.copyClubContentFromOtherQualityPath(otherPath);
  };

  tFuncWithNamespace = (t: TFunction, ns: string) => (key: string) => {
    return t(key.includes(":") ? key : ns + ":" + key);
  };

  render() {
    const {
      handleSubmit,
      auditRequest,
      enrollment,
      enrollments,
      navigateToSummary,
      history,
      translationNs,
      partKey,
    } = this.props;
    const { t, ...propsWithoutTFunction } = this.props;
    const part = auditRequest
      ? auditRequest.parts.find((x) => x.key === this.props.partKey)
      : null;

    const showCopyContentsButton = partKey !== "basiccriteria";

    const partState = part ? PartStatus[part.state] : PartStatus.NOT_STARTED;
    const submitted = auditRequest ? auditRequest.submitted : false;
    const stateboxClasses = classNames("stateindicator", {
      stateReady: partState === PartStatus.READY,
      stateAwaiting: partState === PartStatus.AWAITING_REVISION,
      stateDraft: partState === PartStatus.DRAFT,
      stateNot: partState === PartStatus.NOT_STARTED,
    });
    const childTranslation = translationNs
      ? this.tFuncWithNamespace(t, translationNs)
      : t;

    return (
      <React.Fragment>
        {showCopyContentsButton &&
          enrollment &&
          enrollments &&
          enrollments.length > 1 &&
          !submitted && (
            <div className="formcopycontainer">
              <div className="formcopyinnercontainer">
                <div className="formcopybuttonnote">{t("formCopy.note")}</div>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={this.initializeWithOtherEnrollment}
                >
                  {t("formCopy.copy", {
                    from:
                      enrollment.qualityPath === PathType.ADULTS ||
                      enrollment.qualityPath === PathType.TOP_SPORTS
                        ? t("formCopy.youth")
                        : t("formCopy.adults"),
                  })}
                </button>
              </div>
            </div>
          )}
        <form onSubmit={handleSubmit} className="pathpart">
          {React.cloneElement(this.props.children as any, {
            ...propsWithoutTFunction,
            t: childTranslation,
          })}
          {this.props.clubMode && !submitted && (
            <div className="partstatecontainer">
              <div className="statebox">
                <div className={stateboxClasses} />
                <div className="stateboxstatus">
                  {t(`path.state.${partState}`)}
                </div>
              </div>
            </div>
          )}
          {this.props.clubMode ? (
            <ClubFormButtons
              partState={PartStatus[partState] as PartStatus}
              onBackClick={
                navigateToSummary ? history.goBack : this.navigateToClubPart
              }
              onDraftClick={this.updateOnly}
              onSubmitClick={this.updateAndMarkAsReady}
              auditRequest={auditRequest}
              submitted={submitted}
            />
          ) : (
            <AuditFormButtons
              partState={PartStatus[partState] as PartStatus}
              enrollment={enrollment}
              onBackClick={
                navigateToSummary ? history.goBack : this.navigateToAuditPart
              }
              onDraftClick={this.saveCommentsDraft}
              onSubmitClick={this.saveCommentAndMarkReady}
              auditRequest={auditRequest}
            />
          )}
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: ComponentProps) => {
  return {
    user: state.account.user,
    formData: getFormValues(ownProps.form)(state),
    tools: state.contentful.entries.partTools,
    formPartValues: state.auditRequest.formPartValues,
    enrollments: state.path.clubEnrollments,
    formCurrentOrgId: state.path.currentOrgId,
    navigateToSummary: state.path.navigateToSummary,
  };
};

const mapDispatchToProps = (dispatch, ownProps: ComponentProps) => {
  return {
    // This function is ran when user presses submit button
    onSubmit: (formValues: any) => {
      dispatch(
        updateAuditRequestPartValues(
          ownProps.enrollment!.id!,
          ownProps.auditRequest!.id.toString(),
          ownProps.partKey,
          formValues
        )
      );
    },
    updateAuditRequestPartSingleValue: (values: any) =>
      dispatch(
        updateAuditRequestPartSingleValue(
          ownProps.enrollment!.id!,
          ownProps.auditRequest!.id.toString(),
          ownProps.partKey,
          values
        )
      ),
    fetchTools: () => dispatch(fetchPartTools(ownProps.toolsKey!)),
    fetchRequests: (enrollmentId: number) =>
      dispatch(fetchAuditRequestsByEnrollment(enrollmentId)),
    fetchFormPartValues: (request: AuditRequest) =>
      dispatch(getAuditRequestPartValues(request.id, ownProps.partKey)),
    submitThisForm: () => dispatch(submit(ownProps.form)),
    updatePartState: (state: PartStatus, quiet: boolean) =>
      dispatch(
        updateAuditRequestPart(
          ownProps.enrollment!.id!,
          ownProps.auditRequest!.id.toString(),
          ownProps.partKey,
          state,
          quiet
        )
      ),
    setSubmitType: (type: string) => dispatch(SET_SUBMIT_TYPE(type)),
    saveCommentsDraft: (quiet?: boolean) =>
      dispatch(
        saveCommentsDraft(ownProps.auditRequest!.id, ownProps.partKey, quiet)
      ),
    saveSingleCommentDraft: (data: { [fieldName: string]: string }) =>
      dispatch(
        saveSingleCommentDraft(
          ownProps.auditRequest!.id,
          ownProps.partKey,
          data
        )
      ),
    getComments: (request: AuditRequest) =>
      dispatch(getComments(request.id, ownProps.partKey)),
    getAttachments: (request: AuditRequest) =>
      dispatch(fetchPartAttachments(request.id, ownProps.partKey)),
    getOrgAttachments: () => dispatch(fetchOrgAttachments(ownProps.orgId)),
    updatePartStateAudit: (state: PartStatus, quiet?: boolean) =>
      dispatch(
        updateAuditRequestPartAudit(
          ownProps.auditRequest!.id.toString(),
          ownProps.partKey,
          state,
          quiet
        )
      ),
    setCurrentForm: () => {
      dispatch(
        SET_CURRENT_FORM({
          currentOrgId: ownProps.auditRequest!.clubId.toString(),
          currentPart: ownProps.partKey,
          currentType: ownProps.pathType,
          currentRequest: ownProps.auditRequest
            ? ownProps.auditRequest.id
            : undefined,
        })
      );
    },
    copyClubContentFromOtherQualityPath: (otherQualityPath: string) =>
      dispatch(
        copyClubContentFromOtherQualityPath(
          ownProps.enrollment!.id!,
          ownProps.auditRequest!.id,
          ownProps.partKey,
          otherQualityPath
        )
      ),
    dispatch,
  };
};

const enhance = compose(withTranslation("forms"), reduxForm({}), withRouter);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(PathFormWrapper));
