import { createAction, handleActions } from "redux-actions";
import * as Logger from "js-logger";
import Alert from "react-s-alert";
import { ReduxState } from "../reducers";
import i18next from "i18next";
import Api from "../api";

export const SET_COMMENT_MODAL = createAction<CommentModalState>(
  "SET_COMMENT_MODAL"
);
export const CLOSE_COMMENT_MODAL = createAction("CLOSE_COMMENT_MODAL");
export const UPDATE_COMMENT = createAction<CommentModalState>("UPDATE_COMMENT");
export const RECEIVE_COMMENTS = createAction<any>("RECEIVE_COMMENTS");
export const SET_DECISION_MODAL = createAction<DecisionModalState>(
  "SET_DECISION_MODAL"
);
export const CLOSE_DECISION_MODAL = createAction("CLOSE_DECISION_MODAL");

export function getComments(requestId: number, partKey: string) {
  return async (dispatch): Promise<void> => {
    try {
      const response = await Api.club.getAuditRequestPartComments(
        requestId.toString(),
        partKey
      );
      dispatch(RECEIVE_COMMENTS(response.data));
    } catch (err) {
      Logger.warn("Get proposal comments failed");
      Alert.error(i18next.t("common:alerts.comment.getFailed"));
    }
  };
}

export function saveCommentsDraft(
  requestId: number,
  partKey: string,
  quiet?: boolean
) {
  return async (dispatch, getState): Promise<void> => {
    try {
      const state: ReduxState = getState();
      await Api.club.setAuditRequestPartComments(
        requestId.toString(),
        partKey,
        state.comment.comments
      );
      const response2 = await Api.club.getAuditRequestPartComments(
        requestId.toString(),
        partKey
      );
      dispatch(RECEIVE_COMMENTS(response2.data));
      if (!quiet) {
        Alert.info(i18next.t("common:alerts.comment.draftSuccess"));
      }
    } catch (err) {
      Logger.warn("submit proposal comments draft failed");
      Alert.error(i18next.t("common:alerts.comment.draftFailed"));
    }
  };
}

export function saveSingleCommentDraft(
  requestId: number,
  partKey: string,
  comments: { [fieldName: string]: string }
) {
  return async (dispatch): Promise<void> => {
    try {
      await Api.club.setAuditRequestPartComments(
        requestId.toString(),
        partKey,
        comments
      );
      const response2 = await Api.club.getAuditRequestPartComments(
        requestId.toString(),
        partKey
      );
      dispatch(RECEIVE_COMMENTS(response2.data));
    } catch (err) {
      Logger.warn("submit proposal comments draft failed");
      Alert.error(i18next.t("common:alerts.comment.draftFailed"));
    }
  };
}

export enum CommentMode {
  Field = "Field",
  General = "General",
  Main = "Main",
}

export interface CommentModalState {
  show?: boolean;
  showAudit?: boolean;
  showComment?: boolean;
  fieldValue?: any;
  // is the field in the organizer block or the activity program block
  type?: string | null;
  fieldName: string;
  orgId?: string | null;
  comment?: string;
  audit?: any;
  fieldTranslated?: string;
  commentMode?: CommentMode;
}

export interface DecisionModalState {
  show: boolean;
  proposalId: string | null;
  amount: string | null;
  title: string | null;
}

export interface ProposalComments {
  youth: { [fieldName: string]: string | {} };
  adults: { [fieldName: string]: string | {} };
  generalComment?: string;
}

export interface CommentState {
  comments: {};
  commentModal: CommentModalState;
  decisionModal: DecisionModalState;
}

const initialState: CommentState = {
  comments: {},
  commentModal: {
    show: false,
    type: null,
    fieldName: "",
    orgId: null,
    comment: "",
    commentMode: CommentMode.Field,
  },
  decisionModal: {
    show: false,
    proposalId: null,
    amount: null,
    title: null,
  },
};

const createNewComment = (data: CommentModalState) => {
  return {
    [data.fieldName]: data.comment,
  };
};

const updateComment = (comment: {}, data: CommentModalState) => {
  return {
    ...comment,
    [data.fieldName]: data.comment,
  };
};

const commentReducer = handleActions<CommentState, any>(
  {
    [RECEIVE_COMMENTS as any]: (state, action) => {
      return {
        ...state,
        comments: action.payload,
      };
    },
    [UPDATE_COMMENT as any]: (state, action) => {
      let data: any = null;
      const commentOld = state.comments;
      if (commentOld) {
        data = updateComment(commentOld, action.payload);
      } else {
        data = createNewComment(action.payload);
      }
      return {
        ...state,
        comments: data,
      };
    },
    [SET_COMMENT_MODAL as any]: (state, action) => {
      return {
        ...state,
        commentModal: action.payload,
      };
    },
    [CLOSE_COMMENT_MODAL as any]: (state, action) => {
      return {
        ...state,
        commentModal: initialState.commentModal,
      };
    },
    [SET_DECISION_MODAL as any]: (state, action) => {
      return {
        ...state,
        decisionModal: action.payload,
      };
    },
    [CLOSE_DECISION_MODAL as any]: (state, action) => {
      return {
        ...state,
        decisionModal: initialState.commentModal,
      };
    },
  },
  initialState
);

export default commentReducer;
