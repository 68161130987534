import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { render } from "react-dom";
import Root from "./Root";
import "./index.scss";
import createStore from "./store";
import { SetupJSLogger } from "./util/logger";
import Alert from "react-s-alert";
import { siclSetAppElement } from "@suomisport/ui-components/utilities/siclSetAppElement";

import axios from "axios";
import { authenticateUser, sleep } from "./modules/account";
import { paths } from "./routePaths";
import "./i18n";
import i18next from "i18next";

async function translatedAlert(
  alert: (string) => void,
  key: string,
  alt: string
) {
  const translated = i18next && i18next.isInitialized ? i18next.t(key) : alt;

  alert(translated);
}

axios.interceptors.response.use(undefined, async (err) => {
  const res = err.response;

  if (
    res &&
    (res.status === 403 || res.status === 401) &&
    window.location.pathname !== "/"
  ) {
    // if fetching the account information failed with 403 / 401, the user is / has been logged out
    if (res.config && res.config.url === "/api/v1/account/active") {
      if (window.location.pathname !== paths.signout) {
        translatedAlert(
          Alert.error,
          "common:alerts.common.session",
          "Not logged in or session timed out"
        );
      }
      await sleep(2000);

      window.location.assign("/");
    } else {
      // if another request failed with 403 / 401, we first have to assume the user simply does not have access
      translatedAlert(
        Alert.error,
        "common:alerts.common.accessDenied",
        "Access denied"
      );

      // let's anyhow check if the user has been logged out
      // in case the user has been logged out, this will take us to the "if" branch above
      await store.dispatch(authenticateUser());

      await sleep(2000); // keep the alert visible for some time

      // if we're not in the above "if" branch by now, the user simply doesn't have access. Let's her back to
      // her login page (which should usually take her to a club / organization dashboard)
      window.location.assign(paths.login);
    }
  } else {
    return Promise.reject(err);
  }
});

SetupJSLogger();

const store = createStore();

const rootElement = document.getElementById("root") as HTMLElement;
siclSetAppElement(rootElement);
render(<Root store={store} />, rootElement);
