import { createAction, handleActions } from "redux-actions";
import { combineReducers } from "redux";
import * as Logger from "js-logger";
import { DevelopmentItemStatus, PathType } from "../model";
import Alert from "react-s-alert";
import Api from "../api";
import i18next from "i18next";
import {
  clubDevelopmentIntroModalReducer,
  ClubDevelopmentIntroModalState,
  developmentItemDeletionModalReducer,
  DevelopmentItemDeletionModalState,
  developmentItemPropsModalReducer,
  DevelopmentItemPropsModalState,
} from "./developmentModals";
import { v4 } from "uuid";

export interface DevelopmentItem {
  id?: number;
  locked?: boolean;
  description: string;
  priority?: number;
  pathType: PathType;
  theme?: string;
  year?: number;
  assignees?: string[];
  status?: DevelopmentItemStatus;
  results?: string;
  comment?: string;
  attachmentFileIds?: number[];
}

export const MAX_YEARS = 5;

export const SET_DEVELOPMENT_ITEMS_STATE = createAction<{
  items: DevelopmentItem[];
}>("SET_DEVELOPMENT_ITEMS_STATE");

export function getDevelopmentItems(orgId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      const items = (await Api.development.getDevelopmentItems(orgId)).data;
      dispatch(SET_DEVELOPMENT_ITEMS_STATE({ items }));
      return true;
    } catch (err) {
      Logger.warn("fetching development items failed");
      Alert.error(i18next.t("clubDevelopment:errors.itemsFetchingError"));
      return false;
    }
  };
}

export const CREATE_DEVELOPMENT_ITEM_STATE = createAction<{
  item: DevelopmentItem;
}>("CREATE_DEVELOPMENT_ITEM_STATE");

export function createDevelopmentItem(orgId: number, item: DevelopmentItem) {
  return async (dispatch): Promise<boolean> => {
    try {
      const created = (await Api.development.createDevelopmentItem(orgId, item))
        .data;
      dispatch(CREATE_DEVELOPMENT_ITEM_STATE({ item: created }));
      return true;
    } catch (err) {
      Logger.warn("creating development item failed");
      Alert.error(i18next.t("clubDevelopment:errors.itemCreationError"));
      return false;
    }
  };
}

export const UPDATE_DEVELOPMENT_ITEM_STATE = createAction<{
  id: number;
  item: Partial<DevelopmentItem>;
}>("UPDATE_DEVELOPMENT_ITEM_STATE");

export function updateDevelopmentItem(
  id: number,
  item: Partial<DevelopmentItem>
) {
  return async (dispatch, getState): Promise<boolean> => {
    const backup = Object.assign(
      {},
      getState().development.view.developmentItems.find(
        (item) => item.id === id
      )
    );

    try {
      const { id: unusedId, locked: unusedLocked, ...itemWithoutId } = item;
      dispatch(UPDATE_DEVELOPMENT_ITEM_STATE({ id, item }));
      await Api.development.putDevelopmentItem(id, itemWithoutId);
      return true;
    } catch (err) {
      Logger.warn("updating development item failed");
      Alert.error(i18next.t("clubDevelopment:errors.itemUpdateError"));
      if (backup) {
        dispatch(UPDATE_DEVELOPMENT_ITEM_STATE({ id, item: backup }));
      }
      return false;
    }
  };
}

export const DELETE_DEVELOPMENT_ITEM_STATE = createAction<{
  id: number;
}>("DELETE_DEVELOPMENT_ITEM_STATE");

export function deleteDevelopmentItem(id: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.development.deleteDevelopmentItem(id);
      dispatch(DELETE_DEVELOPMENT_ITEM_STATE({ id }));
      return true;
    } catch (err) {
      Logger.warn("deleting development item failed");
      Alert.error(i18next.t("clubDevelopment:errors.itemDeletionError"));
      return false;
    }
  };
}

type DevelopmentItemFilterValue = any | any[];

interface DevelopmentItemFilter {
  itemProperty: keyof DevelopmentItem;
  value: DevelopmentItemFilterValue;
  transform?(propertyValue: any): any;
}

export const SET_DEVELOPMENT_ITEM_FILTER_STATE = createAction<
  DevelopmentItemFilter
>("SET_DEVELOPMENT_ITEM_FILTER_STATE");
export const CLEAR_DEVELOPMENT_ITEM_FILTERS_STATE = createAction(
  "CLEAR_DEVELOPMENT_ITEM_FILTERS_STATE"
);

function sortDevelopmentItems(item1: DevelopmentItem, item2: DevelopmentItem) {
  const statusToScore = {
    [DevelopmentItemStatus.NOT_STARTED]: 1,
    [DevelopmentItemStatus.ONGOING]: 2,
    [DevelopmentItemStatus.READY]: 3,
  };

  const itemScore = (item: DevelopmentItem) =>
    (item.locked ? -10000 : 0) +
    (item.year ? -1000 : 0) +
    (item.priority ? item.priority * 10 : 0) +
    (item.status && statusToScore[item.status]
      ? statusToScore[item.status]
      : 0);
  const item1Score = itemScore(item1);
  const item2Score = itemScore(item2);

  return item1Score > item2Score ? -1 : item1Score < item2Score ? 1 : 0;
}

function filterDevelopmentItems(
  items: DevelopmentItem[] | null,
  filters: DevelopmentItemFilter[]
) {
  if (!items) {
    return null;
  }
  return items
    .filter(
      (item) =>
        !filters.find((filter) => {
          let propertyValue = item[filter.itemProperty];
          if (filter.transform) {
            propertyValue = filter.transform(propertyValue);
          }

          if (
            filter.itemProperty === "pathType" &&
            propertyValue === PathType.ALL
          ) {
            return false;
          }

          if (Array.isArray(filter.value)) {
            return !filter.value.includes(propertyValue);
          }

          return propertyValue !== filter.value;
        })
    )
    .sort(sortDevelopmentItems);
}

export interface DevelopmentViewState {
  developmentItems: DevelopmentItem[] | null;
  itemViewStateKey: string;
  itemFilters: DevelopmentItemFilter[];
  filteredDevelopmentItems: DevelopmentItem[] | null;
}

const developmentViewInitialState: DevelopmentViewState = {
  developmentItems: null,
  itemViewStateKey: v4(),
  itemFilters: [],
  filteredDevelopmentItems: null,
};

const developmentViewReducer = handleActions<DevelopmentViewState, any>(
  {
    [SET_DEVELOPMENT_ITEMS_STATE as any]: (state, action) => {
      return {
        ...state,
        developmentItems: action.payload.items,
        itemViewStateKey: v4(),
        filteredDevelopmentItems: filterDevelopmentItems(
          action.payload.items,
          state.itemFilters
        ),
      };
    },
    [CREATE_DEVELOPMENT_ITEM_STATE as any]: (state, action) => {
      const newDevelopmentItems = [
        ...(state.developmentItems || []),
        action.payload.item,
      ];
      return {
        ...state,
        developmentItems: newDevelopmentItems,
        itemViewStateKey: v4(),
        filteredDevelopmentItems: filterDevelopmentItems(
          newDevelopmentItems,
          state.itemFilters
        ),
      };
    },
    [UPDATE_DEVELOPMENT_ITEM_STATE as any]: (state, action) => {
      const newDevelopmentItems = (state.developmentItems || []).map((item) =>
        item.id === action.payload.id
          ? Object.assign(item, action.payload.item)
          : item
      );
      return {
        ...state,
        developmentItems: newDevelopmentItems,
        itemViewStateKey: v4(),
        filteredDevelopmentItems: filterDevelopmentItems(
          newDevelopmentItems,
          state.itemFilters
        ),
      };
    },
    [DELETE_DEVELOPMENT_ITEM_STATE as any]: (state, action) => {
      if (!action.payload.id) {
        return state;
      }
      const newDevelopmentItems = (state.developmentItems || []).filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        developmentItems: newDevelopmentItems,
        itemViewStateKey: v4(),
        filteredDevelopmentItems: filterDevelopmentItems(
          newDevelopmentItems,
          state.itemFilters
        ),
      };
    },
    [SET_DEVELOPMENT_ITEM_FILTER_STATE as any]: (state, action) => {
      let newItemFilters = state.itemFilters;
      const { value } = action.payload;

      if (value && !(Array.isArray(value) && value.length === 0)) {
        const filter = state.itemFilters.find(
          (filter) => filter.itemProperty === action.payload.itemProperty
        );
        if (filter) {
          // update the filter value
          filter.value = value;
        } else {
          // add a new filter
          newItemFilters = [...newItemFilters, action.payload];
        }
      } else {
        // remove the filter
        newItemFilters = newItemFilters.filter(
          (filter) => filter.itemProperty !== action.payload.itemProperty
        );
      }

      return {
        ...state,
        itemViewStateKey: v4(),
        itemFilters: newItemFilters,
        filteredDevelopmentItems: filterDevelopmentItems(
          state.developmentItems,
          newItemFilters
        ),
      };
    },
    [CLEAR_DEVELOPMENT_ITEM_FILTERS_STATE as any]: (state, action) => {
      return {
        ...state,
        itemViewStateKey: v4(),
        itemFilters: [],
        filteredDevelopmentItems: filterDevelopmentItems(
          state.developmentItems,
          []
        ),
      };
    },
  },
  developmentViewInitialState
);

export interface DevelopmentState {
  itemPropsModal: DevelopmentItemPropsModalState;
  itemDeletionModal: DevelopmentItemDeletionModalState;
  clubDevelopmentIntroModal: ClubDevelopmentIntroModalState;
  view: DevelopmentViewState;
}

const developmentReducer = combineReducers<DevelopmentState>({
  itemPropsModal: developmentItemPropsModalReducer,
  itemDeletionModal: developmentItemDeletionModalReducer,
  clubDevelopmentIntroModal: clubDevelopmentIntroModalReducer,
  view: developmentViewReducer,
});

export default developmentReducer;
