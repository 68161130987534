import React from "react";
import { TFunction } from "i18next";
import { ExpandingContentRowHeader } from "../Headers/ExpandingContentRowHeader";
import { TahtiseuraField } from "./Fields/TahtiseuraField";
import { find } from "lodash";
import ToolLinks from "./Components/ToolLinks";
import { PathType } from "../../model";
import { TahtiseuraListField } from "./Fields/TahtiseuraListField";
import { AuditFormMetaInfo } from "../../modules/formMetaInfo";
import { SportsFederationTranslationKeyMapType } from "../../modules/config";
import { ExtraInfoTextBox } from "./Components/ExtraInfoTextBox";

export interface TahtiseuraListFieldProps {
  t: TFunction;
  sectionKey: string;
  section: string;
  formData: any;
  ratingMap: any;
  type: string;
  orgId: string | undefined;
  positive: boolean;
  readOnly?: boolean;
  commentEdit?: boolean;
}

export interface TahtiseuraFormSectionWithContentsProps {
  orgId: string | undefined;
  partKey: string;
  sectionKey: string;
  formVersion: number;
  formMetaInfo: AuditFormMetaInfo;
  sportsFederationTranslationKeyMap: SportsFederationTranslationKeyMapType;
  t: TFunction;
  formData: any;
  options: { value: string; label: any }[];
  ratingMap: any;
  type: string;
  partThemes: any;
  readOnly?: boolean;
  commentEdit?: boolean;
  noLabel?: boolean;
}

const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const TahtiseuraListFieldComponent = (props: TahtiseuraListFieldProps) => {
  const {
    t,
    sectionKey,
    section,
    formData,
    ratingMap,
    type,
    orgId,
    positive,
    readOnly,
    commentEdit,
  } = props;

  const feedbackType = positive ? "positive" : "negative";
  return (
    <>
      <hr />
      <div className="widefieldlabel">{t(`${sectionKey}.${feedbackType}`)}</div>
      <TahtiseuraListField
        t={t}
        name={`${section}${capitalize(feedbackType)}`}
        placeholder={`${sectionKey}.placeholder`}
        fieldTranslated={`${sectionKey}.${feedbackType}`}
        formData={formData}
        readOnly={readOnly}
        commentEdit={commentEdit}
        ratingMap={ratingMap}
        type={type}
        orgId={orgId}
      />
    </>
  );
};

export function FormSectionWithContents(
  props: TahtiseuraFormSectionWithContentsProps
) {
  const {
    orgId,
    partKey,
    sectionKey,
    formVersion,
    formMetaInfo,
    sportsFederationTranslationKeyMap,
    t,
    formData,
    options,
    ratingMap,
    type,
    partThemes,
    readOnly,
    commentEdit,
  } = props;

  if (!formMetaInfo || !sportsFederationTranslationKeyMap) {
    return null;
  }
  const sportsFederationId = formMetaInfo.federationId;
  const federationTKey =
    sportsFederationId && sportsFederationTranslationKeyMap[sportsFederationId];
  const pathType = PathType[type];
  const partMetaInfo = formMetaInfo?.parts.find((part) => {
    return (
      part.formVersions?.includes(formVersion) &&
      part.qualityPaths?.includes(pathType) &&
      part.key === partKey
    );
  });
  if (!partMetaInfo) {
    return null;
  }

  const sectionMetaInfo = partMetaInfo.sections.find(
    (section) =>
      section.formVersions?.includes(formVersion) &&
      section.qualityPaths?.includes(pathType) &&
      section.key === sectionKey
  );
  if (!sectionMetaInfo) {
    return null;
  }

  const fieldsMetaInfo = sectionMetaInfo.fields
    .sort((a, b) => a.index - b.index)
    .filter(
      (field) =>
        field.formVersions?.includes(formVersion) &&
        field.qualityPaths?.includes(pathType)
    );
  if (!fieldsMetaInfo || fieldsMetaInfo.length === 0) {
    return null;
  }

  const sectionSplitted = sectionKey.split(".");
  const section = sectionSplitted[sectionSplitted.length - 1];
  const includeTahtiseuraListFields = fieldsMetaInfo.some((field) => {
    return field.key.endsWith("Negative0");
  });

  const extraSectionInfoTextTranslationKey = `${federationTKey}.${sectionKey}.extraInfoText`;

  // Translation function t returns the given translation key if it doesn't find a defined translation string
  // for it. This way we can check if an optional extraInfo text has been defined or not (some sports federations
  // wanted to provide extra info about basic criteria fields).
  const sectionHasExtraInfoText =
    t(extraSectionInfoTextTranslationKey) !==
    extraSectionInfoTextTranslationKey;

  return (
    <ExpandingContentRowHeader
      title={t(`${sectionKey}.formHeader`)}
      subTitle={
        partKey !== "basiccriteria" ? t(`topsports.subFormHeader`) : undefined
      }
      isTopSports={type === PathType.TOP_SPORTS}
    >
      <div className="form-section">
        {fieldsMetaInfo.map((field, index) => {
          if (field.key.endsWith("_audit")) {
            const key = field.key.replace(/_audit$/, "");
            const isLastItem = index === fieldsMetaInfo.length - 1;
            return (
              <React.Fragment key={key}>
                <TahtiseuraField
                  t={t}
                  options={options}
                  name={key}
                  tkey={`${federationTKey}.${sectionKey}.${key
                    .replace(section, "")
                    .toLowerCase()}`}
                  formData={formData}
                  readOnly={readOnly}
                  commentEdit={commentEdit}
                  ratingMap={ratingMap}
                  type={type}
                  orgId={orgId}
                />
                {!isLastItem && <hr />}
              </React.Fragment>
            );
          }
          return null;
        })}
        {includeTahtiseuraListFields && (
          <TahtiseuraListFieldComponent
            t={t}
            sectionKey={sectionKey}
            section={section}
            formData={formData}
            ratingMap={ratingMap}
            type={type}
            orgId={orgId}
            positive={true}
            readOnly={readOnly}
            commentEdit={commentEdit}
          />
        )}
        {includeTahtiseuraListFields && (
          <TahtiseuraListFieldComponent
            t={t}
            sectionKey={sectionKey}
            section={section}
            formData={formData}
            ratingMap={ratingMap}
            type={type}
            orgId={orgId}
            positive={false}
            readOnly={readOnly}
            commentEdit={commentEdit}
          />
        )}
      </div>
      {sectionHasExtraInfoText && (
        <ExtraInfoTextBox text={t(extraSectionInfoTextTranslationKey)} />
      )}
      <ToolLinks
        tools={find(
          partThemes,
          (x) => x.fields.type === `topsport${capitalize(section)}`
        )}
      />
    </ExpandingContentRowHeader>
  );
}
