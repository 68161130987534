import { handleActions, createAction } from "redux-actions";
import * as Logger from "js-logger";
import { PathType } from "../model";
import Alert from "react-s-alert";
import Api from "../api";

export interface RequestFieldMetaInfo {
  key: string;
  index: number;
  formVersions?: number[];
  qualityPaths?: PathType[];
}

export interface RequestSectionMetaInfo {
  key: string;
  fields: RequestFieldMetaInfo[];
  formVersions?: number[];
  qualityPaths?: PathType[];
}

export interface RequestPartMetaInfo {
  key: string;
  sections: RequestSectionMetaInfo[];
  formVersions?: number[];
  qualityPaths?: PathType[];
}

export interface AuditMetaInfo {
  parts: RequestPartMetaInfo[];
  federationId: number;
}

export function findPartKeyOfSection(
  auditMetaInfo: AuditMetaInfo,
  sectionKey: string
) {
  const part = auditMetaInfo.parts.find((part) =>
    part.sections.some((section) => section.key === sectionKey)
  );

  return part ? part.key : null;
}

export const SET_CLUB_AUDIT_METAINFO_STATE = createAction<{
  clubId: number;
  auditMetaInfo: AuditMetaInfo;
}>("SET_CLUB_AUDIT_METAINFO_STATE");

export function getAuditMetaInfo(clubId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      const auditMetaInfo = (
        await Api.auditMetaInfo.getAuditRequestMetaInfo(clubId)
      ).data;
      dispatch(
        SET_CLUB_AUDIT_METAINFO_STATE({
          clubId,
          auditMetaInfo,
        })
      );
      return true;
    } catch (err) {
      Logger.warn("fetching audit metainfo failed");
      Alert.error("common:auditMetaInfo.fetchingError");
      return false;
    }
  };
}

export interface AuditMetaInfoState {
  clubAuditMetaInfo: Map<number, AuditMetaInfo>;
}

const auditMetaInfoInitialState: AuditMetaInfoState = {
  clubAuditMetaInfo: new Map<number, AuditMetaInfo>(),
};

const auditMetaInfoReducer = handleActions<AuditMetaInfoState, any>(
  {
    [SET_CLUB_AUDIT_METAINFO_STATE as any]: (state, action) => {
      return {
        ...state,
        clubAuditMetaInfo: {
          ...state.clubAuditMetaInfo,
          [action.payload.clubId]: action.payload.auditMetaInfo,
        },
      };
    },
  },
  auditMetaInfoInitialState
);

export default auditMetaInfoReducer;
