import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { fetchEnrollments } from "../../modules/path";
import PathInfo from "../../components/PathInfo/PathInfo";
import { format } from "date-fns";
import { RouteComponentProps, withRouter } from "react-router";
import {
  CLEAR_AUDIT_REQUEST_ADD_INFO,
  fetchAuditRequestsByEnrollment,
} from "../../modules/auditRequest";
import { AuditRequest, ClubEnrollment, PathType } from "../../model";
import { first, orderBy } from "lodash";
import { ExpandingContentRowHeader } from "../../components/Headers/ExpandingContentRowHeader";
import "./ClubDashboard.scss";
import "./Path.scss";
import { OrganizationCapabilityType } from "../../modules/organization";

interface PathProps
  extends WithTranslation,
    RouteComponentProps<{ orgId: string }>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  fetchRequests(
    enrollmentId: number
  ): Promise<{ enrollmentId: number; response: any } | null>;
}

class Path extends Component<PathProps, any> {
  constructor(props: PathProps) {
    super(props);
    this.state = {
      enrollmentsFetched: false,
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { orgId },
      },
      fetchEnrollments,
      fetchRequests,
    } = this.props;

    this.props.clearAuditRequestAddInfo();

    const enrollments = await fetchEnrollments(orgId);

    enrollments.forEach((enrollment) => {
      fetchRequests(enrollment.id);
    });

    this.setState({ enrollmentsFetched: true });
  }

  getEnrollmentBasedOnPathType = (pathType: PathType) => {
    const { enrollments } = this.props;

    return enrollments.find(
      (enrollment) => enrollment.qualityPath === pathType
    );
  };

  getActiveRequestBasedOnEnrollment = (
    enrollment: ClubEnrollment
  ): AuditRequest => {
    const { auditRequests } = this.props;
    const requests = enrollment && auditRequests[enrollment.qualityPath!];

    return requests
      ? first(orderBy(requests, ["lastModifiedTimestamp"], ["desc"]))
      : null;
  };

  getNonActiveRequestsBasedOnEnrollment = (
    enrollment: ClubEnrollment
  ): AuditRequest[] => {
    const { auditRequests } = this.props;
    const activeRequest = this.getActiveRequestBasedOnEnrollment(enrollment);
    const activeRequestId = activeRequest ? activeRequest.id : null;

    return activeRequest && auditRequests
      ? auditRequests[enrollment.qualityPath!].filter(
          (request: AuditRequest) => request.id !== activeRequestId
        )
      : [];
  };

  render() {
    const { paths, t, clubCapabilities } = this.props;

    const clubHasTopSportsCapability = clubCapabilities?.some((capability) => {
      return (
        capability.capabilityType ===
          OrganizationCapabilityType.CLUB_TOP_SPORTS_ENABLED &&
        capability.value === "true"
      );
    });

    const hasArchivedAudits = (() => {
      if (this.state.enrollmentsFetched && paths) {
        return paths
          .map((path) => {
            const enrollment = this.getEnrollmentBasedOnPathType(path.type);
            let nonActiveRequests = this.getNonActiveRequestsBasedOnEnrollment(
              enrollment!
            );

            return nonActiveRequests.length;
          })
          .some((nonActiveRequest) => {
            return nonActiveRequest > 0;
          });
      } else {
        return false;
      }
    })();

    return (
      <div className="pathspage">
        <h1>{t("clubpath")}</h1>
        {this.state.enrollmentsFetched &&
          paths &&
          paths.map((path) => {
            /** Don't show top sports path for clubs that don't have the capability set */
            if (
              path.type === PathType.TOP_SPORTS &&
              !clubHasTopSportsCapability
            ) {
              return null;
            }
            const enrollment = this.getEnrollmentBasedOnPathType(path.type);
            return (
              <div key={path.pathId} className="pathinfocontainer">
                <PathInfo
                  data={path}
                  enrollment={enrollment!}
                  request={this.getActiveRequestBasedOnEnrollment(enrollment!)}
                  isArchived={false}
                />
              </div>
            );
          })}
        <h3>{t("clubPathArchive.archiveHeading")}</h3>
        {hasArchivedAudits ? (
          this.state.enrollmentsFetched &&
          paths &&
          paths.map((path) => {
            const enrollment = this.getEnrollmentBasedOnPathType(path.type);
            let nonActiveRequests = this.getNonActiveRequestsBasedOnEnrollment(
              enrollment!
            );

            nonActiveRequests = orderBy(
              nonActiveRequests,
              ["lastModifiedTimestamp"],
              ["desc"]
            );

            return nonActiveRequests.map((request) => {
              /**
               TODO: fetchEnrollments only fetches ACTIVE enrollments, not previous enrollments.
               This means that audit archive won't show audits from previous enrollments.
               When club loses its tähtiseura status, that enrollment is deactivated and if they
               ever resume to the same path, a completely new enrollment is created and all audits
               are tied to that.*/
              return (
                <ExpandingContentRowHeader
                  title={t(`forms:path.type.${path.type}`)}
                  subTitle={[
                    t(`clubPathArchive.auditAcceptedSubTitle`),
                    `${format(request.lastModifiedTimestamp, "DD.MM.YYYY")}`,
                  ].join(" ")}
                  key={request.id}
                  className="archived"
                >
                  <PathInfo
                    key={request.id}
                    data={path}
                    enrollment={enrollment!}
                    request={request}
                    isArchived={true}
                  />
                </ExpandingContentRowHeader>
              );
            });
          })
        ) : (
          <div className="no-archived-audits-text">
            {t("clubPathArchive.noArchivedAudits")}
          </div>
        )}
      </div>
    );
  }
}

const translated = withTranslation(["common", "forms"])(Path);

const mapStateToProps = (state: ReduxState, props) => {
  const orgId = props.match.params.orgId;
  const enrollments = state.path.clubEnrollments || [];
  const auditRequests = {};
  enrollments.forEach((enrollment) => {
    auditRequests[enrollment.qualityPath!] =
      state.auditRequest.requestLists[enrollment.id!];
  });
  return {
    paths: state.path.paths,
    enrollments: enrollments,
    auditRequests: auditRequests, // audit requests per path type here: auditRequests['YOUTH'] => [{req1},{req2}]
    clubCapabilities: orgId ? state.organization.capabilities[orgId] : [],
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchEnrollments: (orgId) => dispatch(fetchEnrollments(orgId)),
  fetchRequests: (enrollmentId: number) =>
    dispatch(fetchAuditRequestsByEnrollment(enrollmentId)), //state.auditRequest.requestLists[enrollment.id!]
  clearAuditRequestAddInfo: () => dispatch(CLEAR_AUDIT_REQUEST_ADD_INFO()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translated)
);
