import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PathData, PartStatus, AuditResponse, AuditRequest } from "../../model";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import * as classNames from "classnames";
import { PartIcon } from "./PartIcon";
import { getPathAudit, getPathPartAudit } from "../../routePaths";
import {
  createResponse,
  fetchResponseByAuditRequest,
} from "../../modules/auditor";
import { ReduxState } from "../../reducers";
import "./PathInfo.scss";
import "./PathInfoAudit.scss";

interface ComponentProps
  extends RouteComponentProps<{
    superOrgId?: string;
    orgId: string;
    requestId: string;
  }> {
  data?: PathData;
  auditRequest: AuditRequest;
  isArchived: boolean;
}

type PathInfoProps = ComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class PathInfo extends Component<PathInfoProps, any> {
  navigateToPathPart = async (requestId, orgId, type, pathType) => {
    this.props.history.push(getPathPartAudit(orgId, type, pathType, requestId));
  };

  componentDidMount = () => {
    const { fetchResponseByAuditRequest, auditRequest } = this.props;
    fetchResponseByAuditRequest(auditRequest.id);
  };

  createResponseAndNavigate = async () => {
    const { match, auditRequest, requestResponse, history } = this.props;
    if (auditRequest) {
      // Due to unfortunate naming federation/area id is pointed by superOrgId when url path is
      // under /organization/<superOrgId>/club/<orgId> (like in clubs audit archive),
      // but when the url is /organization/<orgId>/path/870 then orgId points to the federation instead of
      // the club
      const orgIdForUrl = match.params.superOrgId
        ? match.params.superOrgId
        : match.params.orgId;
      if (!requestResponse) {
        const response: AuditResponse = await this.props.createResponse(
          auditRequest.id
        );
        history.push(
          getPathAudit(
            orgIdForUrl,
            auditRequest.id.toString(),
            response.id.toString()
          )
        );
      } else {
        history.push(
          getPathAudit(
            orgIdForUrl,
            auditRequest.id.toString(),
            requestResponse.id.toString()
          )
        );
      }
    }
  };

  createPathParts = (showNewComments: boolean) => {
    const { t, data, auditRequest, isArchived, match } = this.props;
    const activeRequest = auditRequest;
    // Combine path part data from pathState and auditRequestState to form PartIcons
    // the parts that form a path are defined in the frontend but we need to get the saved parts from the backend
    // to display path progress
    if (!data || !data.parts) {
      return null;
    }
    let partsComponents: any = [];
    const partsLength = data.parts.length;
    data.parts.forEach((value, index) => {
      let icon = value.icon.not;
      // display dots connecting PartIcons if not last part
      let connector = index < partsLength - 1;
      let requestPartStatus = PartStatus.NOT_STARTED;
      let showOrangeCircleText = false;
      // use activeRequest to show real path status if the user has already saved a path before.
      if (activeRequest) {
        const requestPart = activeRequest.parts
          ? activeRequest.parts.find((x) => x.key === value.type)
          : null;
        requestPartStatus = requestPart
          ? PartStatus[requestPart.state]
          : PartStatus.NOT_STARTED;
        switch (requestPartStatus) {
          case PartStatus.DRAFT:
            icon = value.icon.draft;
            break;
          case PartStatus.READY:
          case PartStatus.AWAITING_REVISION:
            icon = isArchived ? value.icon.archived : value.icon.ready;
            break;
          default:
            icon = value.icon.not;
        }
      }
      showOrangeCircleText =
        requestPartStatus === "AWAITING_REVISION" && showNewComments;

      // Due to unfortunate naming federation/area id is pointed by superOrgId when url path is
      // under /organization/<superOrgId>/club/<orgId> (like in clubs audit archive),
      // but when the url is /organization/<orgId>/path/870 then orgId points to the federation instead of
      // the club
      const superOrgId = match.params.superOrgId
        ? match.params.superOrgId
        : match.params.orgId;

      // finally give data to PartIcon constructor and push into an array
      partsComponents.push(
        PartIcon(
          t(`${value.type}.title`),
          icon,
          requestPartStatus as PartStatus,
          connector,
          false,
          value.type,
          superOrgId,
          data.type,
          this.navigateToPathPart,
          activeRequest ? activeRequest.id : null,
          false,
          showOrangeCircleText,
          "",
          isArchived
        )
      );
    });
    return partsComponents;
  };

  render() {
    const { data, t, auditRequest, requestResponse, isArchived } = this.props;

    if (!auditRequest) {
      return null;
    }
    const awardGranted =
      requestResponse && requestResponse.decision === "ACCEPTED";

    const sidestatusClassnames = classNames("sidestatus", {
      pathStarted: auditRequest,
      pathAccepted: awardGranted,
      pathArchived: isArchived,
    });
    const showOrangePartText =
      !requestResponse ||
      !requestResponse.submitted ||
      requestResponse.decision !== "ACCEPTED";
    const partsComponents = this.createPathParts(showOrangePartText);

    return (
      <div className="pathstatuscontainer">
        <div className="pathstatus">
          {!isArchived && <div className={sidestatusClassnames} />}
          {!isArchived && data && (
            <div className="statustitle">{t(`path.type.${data.type}`)}</div>
          )}
          <div className="partscontainer">{partsComponents}</div>
          {(auditRequest.submitted || requestResponse) && (
            <div
              className="auditsummarycontainer"
              onClick={this.createResponseAndNavigate}
            >
              <div
                className={
                  !requestResponse || !requestResponse.submitted
                    ? "summaryicon"
                    : requestResponse && requestResponse.decision === "ACCEPTED"
                    ? "summaryicon greenicon"
                    : "summaryicon yellowicon"
                }
              >
                !
              </div>
              <div
                className={
                  !requestResponse || !requestResponse.submitted || isArchived
                    ? "summarytext bluetext"
                    : requestResponse && requestResponse.decision === "ACCEPTED"
                    ? "summarytext greentext"
                    : "summarytext yellowtext"
                }
              >
                {t("path.summary")}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const PathInfoTranslated = withTranslation("forms")(PathInfo);

const mapStateToProps = (state: ReduxState, props: ComponentProps) => {
  return {
    requestResponse: state.auditor.requestResponses[props.auditRequest.id],
  };
};

const mapDispatchToProps = (dispatch) => ({
  createResponse: (requestId: number) =>
    dispatch(createResponse(requestId.toString())),
  fetchResponseByAuditRequest: (requestId: number) =>
    dispatch(fetchResponseByAuditRequest(requestId.toString())),
});

const PathInfoConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PathInfoTranslated as any);

export default withRouter<ComponentProps, any>(PathInfoConnected);
