import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./MainRouteViewWrapper.scss";
import { Footer } from "../components/Footer";
// import accentimg from '../images/banneraccent.svg';
import bannerlogo from "../images/homebannerlogo.svg";
import bannerpluslogo from "../images/bannerplus.svg";
import bannersponsorlogo from "../images/Cloetta-Logo-White.png";
import { ServiceNotification } from "../components/ServiceNotification";
import { ReduxState } from "../reducers";
import { connect } from "react-redux";
import {
  PathNotes,
  PathNotesPosition,
} from "../components/PathNotes/PathNotes";
import { SET_NOTES_MODAL } from "../modules/path";
import notesbutton from "../images/Note-icon.svg";
// tslint:disable

interface ViewWrapperProps extends WithTranslation {
  picture?: any;
  pictureText?: string;
  footer?: boolean;
  accent?: boolean;
  bannerplus?: boolean;
  bannersponsor?: boolean;
  children: any;
  serviceNotifications?: Array<string> | null;
  isNotesOpen?: boolean;
  currentRequest?: number;
  openNotesModal(value: boolean): void;
}

interface PathNotesParams {
  viewcontainerHeight: number | null;
  headerHeight: number;
  footerHeight: number;
  pageY: number;
}

/*
  Wraps an image header with text and displays children below it. Use this only for the main navigation routes
  defined in routes.tsx. Check HomePublic.tsx to see an example.
*/
class MainRouteViewWrapper extends Component<ViewWrapperProps, any> {
  private prevPathNotesParams: PathNotesParams | null = null;

  constructor(props: ViewWrapperProps) {
    super(props);
    this.state = {
      notesButtonFloat: false,
      notesPosition: PathNotesPosition.Top,
      pathNotesMaxHeight: null,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes("path/")) {
      // scroll event is also dispatched by the ExpandingContentRowHeader to re-layout elements, let's use it
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleScroll);
      window.addEventListener(
        "pathNotesSizeChanged",
        this.handlePathNotesSizeChanged
      );
    }
  }

  componentWillUnmount() {
    if (window.location.pathname.includes("path/")) {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleScroll);
      window.removeEventListener(
        "pathNotesSizeChanged",
        this.handlePathNotesSizeChanged
      );
    }
    this.props.openNotesModal(false);
  }

  getHeaderHeight = () => {
    const notificationsElement = document.getElementById("notifications");
    const notificationsElementHeight = notificationsElement
      ? notificationsElement.clientHeight
      : 0;

    const headerElement = document.getElementById("header");
    const headerElementHeight = headerElement ? headerElement.clientHeight : 0;

    return notificationsElementHeight + headerElementHeight;
  };

  getFooterHeight = () => {
    const footer = document.getElementById("footer");
    return footer ? footer.clientHeight : 0;
  };

  handlePathNotesSizeChanged = () => {
    // Make handleScroll re-evaluate the layout, even though the notespath element's size if the only parameter that
    // changed.
    this.prevPathNotesParams = null;

    this.handleScroll();
  };

  handleScroll = () => {
    const viewcontainer = document.getElementById("viewcontainer");
    const pathnotes = document.getElementById("pathnotes");

    const headerHeight = this.getHeaderHeight();
    const footerHeight = this.getFooterHeight();
    const pageY = window.pageYOffset;

    /* Changes of the path notes position will lead to layouts being updated. The layout updates in turn can lead to
       changes of the pathnotes element's height of a few pixels. (Even though the height is not adjusted anywhere
       explicitly, this is how it seems to behave.) This in turn can lead to infinite loops of calling this function.

       Therefore, we must not update the layout (or state for this matter) if the only change in parameters since the
       last call is a change in the path notes element's height, with the exception of the user herself resizing the
       path notes. This user-action will cause the prevPathNotesParams to be cleared thus allowing for re-evaluation
       of the layout.
     */

    const pathNotesParams: PathNotesParams = {
      viewcontainerHeight: viewcontainer ? viewcontainer.clientHeight : null,
      headerHeight,
      footerHeight,
      pageY,
    };

    if (
      this.prevPathNotesParams !== null &&
      this.prevPathNotesParams.viewcontainerHeight ===
        pathNotesParams.viewcontainerHeight &&
      this.prevPathNotesParams.headerHeight === pathNotesParams.headerHeight &&
      this.prevPathNotesParams.footerHeight === pathNotesParams.footerHeight &&
      this.prevPathNotesParams.pageY === pathNotesParams.pageY
    ) {
      // do nothing to prevent infinite loops, see above.
      return;
    }

    this.prevPathNotesParams = pathNotesParams;

    const notesButtonFloat = pageY > headerHeight;

    let notesPosition: PathNotesPosition | null = null;

    if (viewcontainer && pathnotes) {
      if (pageY <= headerHeight) {
        notesPosition = PathNotesPosition.Top;
      } else if (
        viewcontainer.clientHeight - pageY <
        footerHeight + pathnotes.clientHeight
      ) {
        notesPosition = PathNotesPosition.Bottom;
      } else {
        notesPosition = PathNotesPosition.Floating;
      }
    } else {
      // in case pathnotes element is not yet available, make a best guess based on the button's float state
      notesPosition = notesButtonFloat
        ? PathNotesPosition.Floating
        : PathNotesPosition.Top;
    }

    const pathNotesMaxHeight = viewcontainer
      ? viewcontainer.clientHeight - headerHeight - footerHeight
      : null;

    if (
      this.state.notesButtonFloat !== notesButtonFloat ||
      this.state.notesPosition !== notesPosition ||
      this.state.pathNotesMaxHeight !== pathNotesMaxHeight
    ) {
      this.setState({
        notesButtonFloat,
        notesPosition,
        pathNotesMaxHeight,
      });
    }
  };

  render() {
    const {
      t,
      picture,
      children,
      pictureText,
      footer,
      accent,
      bannerplus,
      bannersponsor,
      serviceNotifications,
      currentRequest,
    } = this.props;
    const pathname = window.location.pathname;
    const showPathNotes =
      pathname.includes("YOUTH") ||
      pathname.includes("ADULTS") ||
      pathname.includes("TOP_SPORTS");

    return (
      <div className="viewcontainer" id="viewcontainer">
        {serviceNotifications && serviceNotifications.length > 0 && (
          <ServiceNotification notifications={serviceNotifications} />
        )}
        <div
          className={accent ? "header homepagebanner" : "header"}
          style={{ backgroundImage: `url(${picture})` }}
        >
          {accent && <img src={bannerlogo} className="bannerlogo" alt="" />}
          {accent && (
            <svg className="banneraccent" height="100%" width="100%">
              <defs>
                <clipPath id="clip-path">
                  <rect className="cls-1" width="100%" height="100%" />
                </clipPath>
              </defs>
              <title>aloitusbannerin_kuvio</title>
              <rect className="cls-2" width="100%" height="100%" />
              <g className="cls-3">
                <path
                  className="cls-4"
                  d="M1030.42,1221.86l59.6,9.51s-14.61-132.45-88.49-369.76C1210.67,727.32,1307,635.25,1307,635.25l-51.77-31s-86.2,90.25-288,244.92c55.14,248.18,63.17,372.72,63.17,372.72M83.58,177.77,24,168.26S38.59,300.71,112.46,538C-96.67,672.32-193,764.39-193,764.39l51.77,31s86.2-90.25,288-244.92C91.6,302.32,83.58,177.77,83.58,177.77M237.92,1381.63l52.76-29.32s-35.06-119.78-68.12-371.85c-242.5-76.33-354.37-131.66-354.37-131.66l-38,46.86s122,53.57,364.46,108.25c11.74,248.27,43.31,377.72,43.31,377.72M481.19,269.87C668.54,98,772.39,28.8,772.39,28.8L750.83-27.57s-107.4,78.88-276,261.51C254,120,126.08,82.59,126.08,82.59l-1,60.35s121.26,29.53,356.09,126.93M987.92,1317.05l1-60.35s-121.26-29.53-356.09-126.93c-187.36,171.84-291.21,241.07-291.21,241.07l21.57,56.38s107.4-78.88,276-261.51c220.87,114,348.77,151.35,348.77,151.35M1283.85,504s-122-53.57-364.46-108.25C907.65,147.47,876.08,18,876.08,18L823.32,47.34s35.06,119.78,68.12,371.85c242.5,76.33,354.37,131.66,354.37,131.66Z"
                />
                <path
                  className="cls-4"
                  d="M1180.42,1028.46l59.26-11.45s-59-119.46-209.62-317.19c150.58-197.73,209.62-317.19,209.62-317.19l-59.26-11.45s-50.14,114.29-186.84,328.64c136.7,214.35,186.84,328.64,186.84,328.64M-66.42,371.18l-59.26,11.45s59,119.46,209.61,317.19C-66.65,897.55-125.68,1017-125.68,1017l59.26,11.45s50.14-114.29,186.84-328.64C-16.28,485.47-66.42,371.18-66.42,371.18M490.36,1449.64,529.9,1404S456,1303.47,338.71,1077.91c-254,11.21-378-2.52-378-2.52l-19.72,57s133,8.61,379.51-22.93c95.95,229.27,169.89,340.13,169.89,340.13M338.71,321.73C456,96.17,529.9-4.4,529.9-4.4L490.36-50S416.41,60.85,320.47,290.13C73.93,258.59-59,267.19-59,267.19l19.72,57s124-13.73,378-2.52M1173,1132.44l-19.72-57s-124,13.73-378,2.52C658,1303.47,584.1,1404,584.1,1404l39.55,45.6s73.94-110.85,169.89-340.13c246.53,31.54,379.51,22.93,379.51,22.93m0-865.25s-133-8.61-379.51,22.93C697.59,60.85,623.64-50,623.64-50L584.1-4.4S658,96.17,775.29,321.73c254-11.21,378,2.52,378,2.52Z"
                />
                <path
                  className="cls-4"
                  d="M1255.23,795.42l51.77-31S1210.67,672.32,1001.54,538C1075.41,300.71,1090,168.26,1090,168.26l-59.6,9.51s-8,124.55-63.17,372.73c201.77,154.67,288,244.92,288,244.92M-141.23,604.22l-51.77,31s96.33,92.07,305.46,226.37C38.59,1098.93,24,1231.38,24,1231.38l59.61-9.51s8-124.55,63.17-372.72c-201.77-154.67-288-244.92-288-244.92m892.05,823,21.57-56.37s-103.85-69.22-291.21-241.07c-234.83,97.4-356.09,126.93-356.09,126.93l1,60.35S254,1279.66,474.85,1165.7c168.58,182.63,276,261.51,276,261.51m-528.27-1008c33.07-252.07,68.12-371.85,68.12-371.85L237.92,18s-31.57,129.46-43.31,377.72C-47.84,450.41-169.85,504-169.85,504l38,46.86s111.87-55.33,354.37-131.66m1061.3,476.47-38-46.86s-111.87,55.33-354.37,131.66c-33.07,252.07-68.12,371.85-68.12,371.85l52.76,29.32s31.57-129.46,43.31-377.72c242.45-54.68,364.46-108.25,364.46-108.25M987.92,82.59S860,120,639.15,233.94c-168.58-182.63-276-261.51-276-261.51L341.61,28.8S445.46,98,632.81,269.87c234.83-97.4,356.09-126.93,356.09-126.93Z"
                />
              </g>
            </svg>
          )}
          {bannerplus && (
            <img
              src={bannerpluslogo}
              className="bannerplus"
              alt="Banner accent"
            />
          )}
          {bannersponsor && (
            <div className="bannersponsor">
              <img
                src={bannersponsorlogo}
                alt="Cloetta"
              />
              <div className="text">{t("sponsor.headerTitle")}</div>
            </div>
          )}
          <div id="header" className="textcontainer">
            <div className="text">{pictureText}</div>
          </div>
        </div>
        <div className="children">
          <div className="contentcontainer">{children}</div>
          {showPathNotes && (
            <PathNotes
              auditor={pathname.includes("organization")}
              position={this.state.notesPosition}
              maxHeight={this.state.pathNotesMaxHeight}
            />
          )}
          {showPathNotes && !this.props.isNotesOpen && currentRequest && (
            <button
              className={
                this.state.notesButtonFloat
                  ? "notesbutton floatbutton"
                  : "notesbutton"
              }
              style={
                !this.state.notesButtonFloat
                  ? { top: `${this.getHeaderHeight() + 20}px` }
                  : {}
              }
              onClick={() => this.props.openNotesModal(true)}
              aria-label="open path notes"
            >
              <img src={notesbutton} alt="" />
            </button>
          )}
        </div>

        {footer && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  return {
    serviceNotifications: state.config.serviceNotifications,
    isNotesOpen: state.path.isNotesOpen,
    currentRequest: state.path.currentRequest,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openNotesModal: (value: boolean) => dispatch(SET_NOTES_MODAL(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(MainRouteViewWrapper));
