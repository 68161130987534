import { UserData } from "./modules/account";
import { AuditFormMetaInfo } from "./modules/formMetaInfo";
import { SportsFederationTranslationKeyMapType } from "./modules/config";

export const FORMVERSION = 6;

export interface AccessOrg {
  orgId: number;
  name: string;
  shortName: string;
  type: OrgType;
  federationName: string;
}

export enum OrgType {
  CLUB = "CLUB",
  SPORTS_FEDERATION = "SPORTS_FEDERATION",
  COLLECTION_AREA_ASSOCIATION = "COLLECTION_AREA_ASSOCIATION",
  ADMIN_ORGANIZATION = "ADMIN_ORGANIZATION",
}

export enum PathType {
  YOUTH = "YOUTH",
  ADULTS = "ADULTS",
  TOP_SPORTS = "TOP_SPORTS",
  YOUTH_AND_ADULTS = "YOUTH_AND_ADULTS",
  ALL = "ALL",
}

export enum DevelopmentItemStatus {
  NOT_STARTED = "NOT_STARTED",
  ONGOING = "ONGOING",
  READY = "READY",
}

export enum DevelopmentItemPriority {
  IMPORTANT = 1,
}

export enum PartStatus {
  READY = "READY",
  DRAFT = "DRAFT",
  NOT_STARTED = "NOT_STARTED",
  AWAITING_REVISION = "AWAITING_REVISION",
}

export const AUDITOR_MODIFIABLE_PART_STATES = [
  PartStatus.READY,
  PartStatus.AWAITING_REVISION,
];

export enum PendingAuditState {
  REQUEST_IN_PROGRESS = "REQUEST_IN_PROGRESS",
  RESPONSE_DUE = "RESPONSE_DUE",
  RESPONSE_STARTED = "RESPONSE_STARTED",
  CORRECTIONS_DUE = "CORRECTIONS_DUE",
  CORRECTIONS_STARTED = "CORRECTIONS_STARTED",
}

export enum PendingAuditClubType {
  FIRST_TIMER = "FIRST_TIMER",
  IN_PROCESS = "IN_PROCESS",
  AWAITING_AUDIT = "AWAITING_AUDIT",
  AWAITING_CORRECTIONS = "AWAITING_CORRECTIONS",
}

export interface PathPart {
  id: number;
  type: string;
  status: PartStatus;
  name?: string;
  icon?: any;
}

export interface PathData {
  pathId: number;
  type: PathType;
  parts: PathPart[];
}

export interface PathStartModalData {
  show: boolean;
  path: PathData | undefined;
  orgId: number | undefined;
}

export interface ClubInformation {
  orgId: number;
  name: string;
  suomisportOrgId: number | undefined;
  shortName?: string;
  federationId?: number;
  federationName?: string;
  areaAssociationId?: number;
  areaAssociationName?: string;
  type?: string;
}

export interface ClubEnrollment {
  clubId?: number;
  decisionTimestamp?: string;
  enrollmentTimestamp?: string;
  id?: number;
  qualityPath?: PathType;
  awardGranted?: boolean;
  awardGrantedTimestamp?: string;
  awardRejectedTimestamp?: string;
  active?: boolean;
  enrollerUserId?: number;
  lastModifiedTimestamp?: string;
  modifierUserId?: number;
  nextAuditTimestamp?: string;
  auditRequestCreationAllowed?: boolean;
  correctionsPending?: boolean;
}

export interface ClubEnrollmentUpdate {
  nextAuditTimestamp?: string;
}

export interface AuditRequestPart {
  createdTimestamp: string;
  creatorUserId: number;
  id: number;
  key: string;
  lastModifiedTimestamp: string;
  modifierUserId: number;
  state: string;
  values: { [key: string]: string };
}

export interface AuditRequest {
  clubEnrollmentId: number;
  clubId: number;
  clubName: string;
  createdTimestamp: string;
  creatorUserId: number;
  formVersion: number;
  id: number;
  lastModifiedTimestamp: string;
  modifierUserId: number;
  parts: AuditRequestPart[];
  qualityPath: PathType;
  submitted: boolean;
  modifiableByAuditor: boolean;
  gradeDist: any;
}

export interface AuditRequestAddInfo {
  auditorGradeDist?: any;
  improvementPointsTree?: any;
  allBasicCriteriaAnsweredYes: boolean | null;
}

export interface DescriptionModalState {
  show: boolean;
  form: string | null;
  field: string | null;
  value: string | null;
}

export interface AuditorInfo {
  auditRequestId: number;
  clubId: number;
  clubName: string;
  enrollmentTimestamp: string;
  federationName: string;
  nextAuditTimestamp: string;
  qualityPath: PathType;
  state: string;
  awardGranted: boolean;
  clubTypes: Array<string>;
}

export interface PartFormProps {
  // required, PathForm sets this depending on selected pathType
  form: string;
  commentEdit?: boolean;
  orgId?: string;
  status?: any;
  readOnlyMode?: boolean;
  auditRequest: AuditRequest;
  enrollment: ClubEnrollment;
  clubMode: boolean;
  partKey: string;
  pathType: string;
  toolsKey?: string;
  user: UserData;
  formData: any;
  tools: any;
  formPartValues: any;
  formMetaInfo: AuditFormMetaInfo;
  formVersion: number;
  sportsFederationTranslationKeyMap: SportsFederationTranslationKeyMapType | {};
  handleSubmit(values?: any): void;
  initialize(formData: any): void;
  fetchTools(): void;
  fetchFormPartValues(): void;
  submitThisForm(): void;
  updatePartState(state: PartStatus, quiet?: boolean): void;
  setSubmitType(type: string): void;
  saveCommentsDraft(): void;
  getComments(): void;
}

export interface AuditResponse {
  auditRequestId: number;
  correctionsDueTimestamp: string;
  createdTimestamp: string;
  creatorUserId: number;
  decision: string;
  id: number;
  lastModifiedTimestamp: string;
  modifierUserId: number;
  submitted: true;
  values: {
    [key: string]: string;
  };
}

export interface OrgSummary {
  auditorGradeDist: any;
}
