import {
  SICLIcon,
  SICLIconType,
} from "@suomisport/ui-components/components/icon/SICLIcon";
import { SICLFalafelMenu } from "@suomisport/ui-components/components/SICLFalafelMenu";
import {
  SICLPopupMenuItem,
  SICLPopupMenuPositioningStrategy,
} from "@suomisport/ui-components/components/SICLPopupMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditorInfo } from "../../model";
import { DeleteQualityPathConfirmationModal } from "./DeleteQualityPathConfirmationModal";
import css from "./AuditorInfoActionsFalafelMenu.module.scss";

export interface AuditorInfoActionsFalafelMenu_Props {
  auditorInfo: AuditorInfo;
  onClickEditAudit: () => void;
  onConfirmDeleteQualityPath: () => void;
}

export const AuditorInfoActionsFalafelMenu = (
  props: AuditorInfoActionsFalafelMenu_Props
) => {
  const [
    isDeleteQualityPathConfirmationModalOpen,
    setIsDeleteQualityPathConfirmationModalOpen,
  ] = useState(false);

  const { t } = useTranslation("common");

  const menuItems: SICLPopupMenuItem[] = [
    {
      key: "edit-audit",
      icon: <SICLIcon type={SICLIconType.Pencil} />,
      label: t("AuditorInfoActionsFalafelMenu.editAudit"),
      renderLink: ({ children, onClick }) => (
        <button
          className="sicl"
          type="button"
          onClick={() => {
            props.onClickEditAudit();
            onClick();
          }}
        >
          {children}
        </button>
      ),
    },
    {
      key: "delete-quality-path",
      icon: <SICLIcon type={SICLIconType.Delete} />,
      label: t("AuditorInfoActionsFalafelMenu.deleteQualityPath"),
      renderLink: ({ children, onClick }) => (
        <button
          className="sicl"
          type="button"
          onClick={() => {
            setIsDeleteQualityPathConfirmationModalOpen(true);
            onClick();
          }}
        >
          {children}
        </button>
      ),
    },
  ];

  return (
    <>
      <span className={css.falafelContainer}>
        <SICLFalafelMenu
          ariaLabel={t("AuditorInfoActionsFalafelMenu.falafelMenuAriaLabel")}
          // Need "position: fixed" because otherwise the popup menu is hidden by the parent element's "overflow: hidden" setting.
          positioningStrategy={SICLPopupMenuPositioningStrategy.Fixed}
          // Need z-index because otherwise the next row of SummaryTable draws on top of this popup menu.
          menuZIndex={1}
          items={menuItems}
        />
      </span>
      {isDeleteQualityPathConfirmationModalOpen && (
        <DeleteQualityPathConfirmationModal
          siclModalProps={{
            onRequestClose: () => {
              setIsDeleteQualityPathConfirmationModalOpen(false);
            },
          }}
          clubName={props.auditorInfo.clubName}
          qualityPathName={t(`paths.${props.auditorInfo.qualityPath}`)}
          onConfirm={() => {
            setIsDeleteQualityPathConfirmationModalOpen(false);
            props.onConfirmDeleteQualityPath();
          }}
        />
      )}
    </>
  );
};
