import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { RouteComponentProps } from "react-router-dom";
import { AccessOrg, ClubInformation, OrgType } from "../../model";
import { fetchClubs } from "../../modules/auditor";
import Fuse from "fuse.js";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ClubsTable } from "../../components/Tables/ClubsTable";
import "./Clubs.scss";

const fuseSearchOptions = {
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};

interface OwnProps extends WithTranslation {
  clubs: ClubInformation[];
  currentOrgData: AccessOrg | null | undefined;
  isFetchingClubs: boolean;
  fetchClubs(orgId: number): void;
}

const ClubsComponent: React.FC<OwnProps> = (props) => {
  const [fuzzyInput, setFuzzyInput] = useState<string>("");

  useEffect(() => {
    const { currentOrgData, fetchClubs } = props;
    if (currentOrgData) {
      fetchClubs(currentOrgData.orgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentOrgData]);

  const { clubs, currentOrgData, isFetchingClubs, t } = props;

  if (!currentOrgData) {
    return null;
  }

  if (isFetchingClubs) {
    return <LoadingSpinner />;
  }

  const currentOrgType = currentOrgData.type;

  const isArea = currentOrgType === OrgType.COLLECTION_AREA_ASSOCIATION;
  const isFed = currentOrgType === OrgType.SPORTS_FEDERATION;

  const clubsList = clubs
    ? clubs.filter((club) => {
        return (
          currentOrgType === OrgType.ADMIN_ORGANIZATION ||
          (isArea && club.areaAssociationId != null) ||
          (isFed && club.federationId != null)
        );
      })
    : [];

  const clubsFuse = new Fuse(clubsList, fuseSearchOptions);

  const headingTextBasedOnOrgType = ((currentOrgData: AccessOrg) => {
    switch (currentOrgData.type) {
      case OrgType.SPORTS_FEDERATION:
        return t("clubsPage.federationClubs");
      case OrgType.COLLECTION_AREA_ASSOCIATION:
        return t("clubsPage.areaAssociationClubs");
      case OrgType.ADMIN_ORGANIZATION:
        return t("clubsPage.adminOrgClubs", {
          organizationName: currentOrgData.name,
        });
      default:
        console.warn("Unsupported organization type!");
        return currentOrgData.name;
    }
  })(currentOrgData);

  const helpTextBasedOnOrgType = ((currentOrgData: AccessOrg) => {
    switch (currentOrgData.type) {
      case OrgType.SPORTS_FEDERATION:
        return t("clubsPage.clubsPageInfoTextSportsFederation");
      case OrgType.COLLECTION_AREA_ASSOCIATION:
        return t("clubsPage.clubsPageInfoTextAreaAssociation");
      case OrgType.ADMIN_ORGANIZATION:
        return t("clubsPage.clubsPageInfoTextAdmin");
      default:
        console.warn("Unsupported organization type!");
        return currentOrgData.name;
    }
  })(currentOrgData);

  return (
    <div className="organization-clubs-page-container">
      <div className="clubs-page-heading-container">
        <div className="clubs-page-heading-container-federation-name">
          {currentOrgData.name}
        </div>
        <h2>{headingTextBasedOnOrgType}</h2>
        <div className="clubs-page-help-text">{helpTextBasedOnOrgType}</div>
      </div>

      <div className="search-container">
        <div className="bar searchbar">
          <div>{t("searchName")}</div>
          <input
            type="text"
            name="search"
            value={fuzzyInput}
            onChange={(event) => setFuzzyInput(event.target.value)}
            placeholder={t("searchNamePlaceholder")}
          />
        </div>
      </div>
      <ClubsTable
        showArea={!isArea}
        showFederation={!isFed}
        list={fuzzyInput ? clubsFuse.search(fuzzyInput) : clubsList}
      />
    </div>
  );
};

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{ orgId: string }>
) => {
  return {
    currentOrgData: state.account.user
      ? state.account.user.writeAccessOrgs.find(
          (x) =>
            x.orgId === parseInt(ownProps.match.params.orgId, 10) &&
            (x.type === OrgType.SPORTS_FEDERATION ||
              x.type === OrgType.COLLECTION_AREA_ASSOCIATION ||
              x.type === OrgType.ADMIN_ORGANIZATION)
        )
      : null,
    clubs: state.auditor.auditorOrganizations
      ? state.auditor.auditorOrganizations[ownProps.match.params.orgId]
      : [],
    isFetchingClubs: state.auditor.fetchingClubs,
  };
};

const mapDispatchToProps = {
  fetchClubs: (orgId) => fetchClubs(orgId),
};

export const Clubs = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ClubsComponent));
