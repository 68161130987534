import React from "react";
import { Component } from "react";
import Alert from "react-s-alert";
import { v4 } from "uuid";
import "./alertConfirmationTemplate.scss";

export class AlertTemplateType {
  static CONFIRMATION: string = "CONFIRMATION";
  static FORM_ERRORS: string = "FORM_ERRORS";
}

interface AlertConfirmationTemplateProps {
  classNames: string;
  customFields: any;
  id: string;
  message: string;
  styles: any;
  handleClose(): void;
  handleConfirm(): void;
}

class AlertConfirmationTemplate extends Component<
  AlertConfirmationTemplateProps,
  any
> {
  constructor(props: AlertConfirmationTemplateProps) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleCancel() {
    this.props.customFields.onCancelFn();
    Alert.close(this.props.id);
  }

  handleConfirm() {
    this.props.customFields.onConfirmedFn();
    Alert.close(this.props.id);
  }

  render() {
    const {
      classNames,
      customFields,
      handleClose,
      id,
      message,
      styles,
    } = this.props;

    const templateType = customFields.templateType;
    const showConfirmation = customFields && customFields.showConfirmation;
    let template;

    if (templateType === AlertTemplateType.FORM_ERRORS) {
      const formErrors = customFields.formErrors;

      template = (
        <div className="s-alert-box-inner alertcontainer errortemplate">
          <div className="title">{message}</div>
          <div className="errors">
            {formErrors &&
              formErrors.map((err) => {
                return <span key={v4()}>{err}</span>;
              })}
          </div>
          <div className="buttons">
            <button
              className="btn-secondary-blue"
              style={{ marginRight: "20px", minWidth: "250px" }}
              onClick={this.handleConfirm}
            >
              {customFields.confirmationButtonTxt}
            </button>
          </div>
        </div>
      );
    } else {
      template = (
        <div className="s-alert-box-inner alertcontainer">
          <div className="title">{message}</div>
          {showConfirmation ? (
            <div className="buttons">
              <button
                className="btn-secondary-blue"
                style={{ marginRight: "20px", minWidth: "250px" }}
                onClick={this.handleConfirm}
              >
                {customFields.confirmationButtonTxt}
              </button>
              {customFields && customFields.onCancelFn ? (
                <button
                  className="btn-secondary-blue"
                  style={{ minWidth: "250px" }}
                  onClick={this.handleCancel}
                >
                  {customFields.cancelButtonTxt}
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className={classNames} id={id} style={styles} onClick={handleClose}>
        {template}
      </div>
    );
  }
}

export default AlertConfirmationTemplate;
