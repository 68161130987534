import account from "./account";
import club from "./club";
import auditor from "./auditor";
import config from "./config";
import i18n from "./i18n";
import attachment from "./attachment";
import development from "./development";
import auditMetaInfo from "./auditMetaInfo";
import organization from "./organization";
import formMetaInfo from "./formMetaInfo";

const Api = {
  account,
  club,
  auditor,
  config,
  i18n,
  attachment,
  development,
  auditMetaInfo,
  formMetaInfo,
  organization,
};

export default Api;
