import React from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import SelectField from "../../Fields/SelectField";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class AdultsSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <Field
              options={options}
              className={"pathdropdown"}
              name="sportcontentSharedPath"
              component={SelectField}
              placeholder={t("shared.sportcontent.path.placeholder")}
              label={t("shared.sportcontent.path.label")}
              helpText={t("shared.sportcontent.path.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPath"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.path.label")}
              fieldValue={ratingMap[get(formData, "sportcontentSharedPath")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="sportcontentSharedCoaching"
              component={SelectField}
              placeholder={t("shared.sportcontent.coaching.placeholder")}
              label={t("shared.sportcontent.coaching.label")}
              helpText={t("shared.sportcontent.coaching.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedCoaching"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.coaching.label")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedCoaching")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <Field
              name="sportcontentSharedPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.positive")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedPositive")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <Field
              name="sportcontentSharedNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.negative")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedNegative")]
              }
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.center.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerSuggestions"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.suggestions.placeholder"
              )}
              label={t("adults.sportcontent.center.suggestions.label")}
              helpText={t("adults.sportcontent.center.suggestions.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerSuggestions"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.center.suggestions.label"
              )}
              fieldValue={ratingMap[get(formData, "centerSuggestions")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerLightevents"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.lightEvents.placeholder"
              )}
              label={t("adults.sportcontent.center.lightEvents.label")}
              helpText={t("adults.sportcontent.center.lightEvents.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerLightevents"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.center.lightEvents.label"
              )}
              fieldValue={ratingMap[get(formData, "centerLightevents")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerBeginners"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.beginners.placeholder"
              )}
              label={t("adults.sportcontent.center.beginners.label")}
              helpText={t("adults.sportcontent.center.beginners.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerBeginners"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.beginners.label")}
              fieldValue={ratingMap[get(formData, "centerBeginners")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerEnableCompetitions"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.enableCompetitions.placeholder"
              )}
              label={t("adults.sportcontent.center.enableCompetitions.label")}
              helpText={t("adults.sportcontent.center.enableCompetitions.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerEnableCompetitions"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.center.enableCompetitions.label"
              )}
              fieldValue={ratingMap[get(formData, "centerEnableCompetitions")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerAccessibility"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.accessibility.placeholder"
              )}
              label={t("adults.sportcontent.center.accessibility.label")}
              helpText={t("adults.sportcontent.center.accessibility.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerAccessibility"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.center.accessibility.label"
              )}
              fieldValue={ratingMap[get(formData, "centerAccessibility")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="centerWelcoming"
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.center.welcoming.placeholder"
              )}
              label={t("adults.sportcontent.center.welcoming.label")}
              helpText={t("adults.sportcontent.center.welcoming.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"centerWelcoming"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.welcoming.label")}
              fieldValue={ratingMap[get(formData, "centerWelcoming")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.positive")}
            </div>
            <Field
              name="centerPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.center.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"centerPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.positive")}
              fieldValue={ratingMap[get(formData, "centerPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.negative")}
            </div>
            <Field
              name="centerNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.center.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"centerNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.negative")}
              fieldValue={ratingMap[get(formData, "centerNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "adultsCenter")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.versatility.formHeader")}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <Field
              options={options}
              name="versatilityMethodical"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.methodical.placeholder"
              )}
              label={t("adults.sportcontent.versatility.methodical.label")}
              helpText={t("adults.sportcontent.versatility.methodical.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityMethodical"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.versatility.methodical.label"
              )}
              fieldValue={ratingMap[get(formData, "versatilityMethodical")]}
            />
            <hr />
            <Field
              options={options}
              name="versatilityContent"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.content.placeholder"
              )}
              label={t("adults.sportcontent.versatility.content.label")}
              helpText={t("adults.sportcontent.versatility.content.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityContent"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.versatility.content.label"
              )}
              fieldValue={ratingMap[get(formData, "versatilityContent")]}
            />
            <hr />
            <Field
              options={options}
              name="versatilityWellBeing"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.wellBeing.placeholder"
              )}
              label={t("adults.sportcontent.versatility.wellBeing.label")}
              helpText={t("adults.sportcontent.versatility.wellBeing.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityWellBeing"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.versatility.wellBeing.label"
              )}
              fieldValue={ratingMap[get(formData, "versatilityWellBeing")]}
            />
            <hr />
            <Field
              options={options}
              name="versatilitySafe"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.safe.placeholder"
              )}
              label={t("adults.sportcontent.versatility.safe.label")}
              helpText={t("adults.sportcontent.versatility.safe.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilitySafe"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.versatility.safe.label")}
              fieldValue={ratingMap[get(formData, "versatilitySafe")]}
            />
            <hr />
            <Field
              options={options}
              name="versatilityCustomization"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.customization.placeholder"
              )}
              label={t("adults.sportcontent.versatility.customization.label")}
              helpText={t("adults.sportcontent.versatility.customization.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityCustomization"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.versatility.customization.label"
              )}
              fieldValue={ratingMap[get(formData, "versatilityCustomization")]}
            />
            <hr />
            <Field
              options={options}
              name="versatilitySelfMotivation"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "adults.sportcontent.versatility.selfMotivation.placeholder"
              )}
              label={t("adults.sportcontent.versatility.selfMotivation.label")}
              helpText={t(
                "adults.sportcontent.versatility.selfMotivation.help"
              )}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"versatilitySelfMotivation"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "adults.sportcontent.versatility.selfMotivation.label"
              )}
              fieldValue={ratingMap[get(formData, "versatilitySelfMotivation")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.positive")}
            </div>
            <Field
              name="versatilityPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.versatility.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.versatility.positive")}
              fieldValue={ratingMap[get(formData, "versatilityPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.negative")}
            </div>
            <Field
              name="versatilityNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.versatility.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.versatility.negative")}
              fieldValue={ratingMap[get(formData, "versatilityNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "adultsVersatility"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default AdultsSportContentPart as any;
