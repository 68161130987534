import React from "react";

interface FormFieldDescriptionProps {
  children?: React.ReactNode;
}

const FormFieldDescription: React.StatelessComponent<FormFieldDescriptionProps> = ({
  children,
}) => {
  if (children === undefined) {
    return null;
  }

  return <label className="formfielddescription">{children}</label>;
};

export default FormFieldDescription;
