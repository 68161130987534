import React from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import SelectField from "../../Fields/SelectField";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthResourcesPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.resources.finances.formHeader")}
          open={true}
        >
          <div className="form-section">
            <Field
              options={options}
              name="financesBalance"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.resources.finances.balance.placeholder")}
              label={t("shared.resources.finances.balance.label")}
              helpText={t("shared.resources.finances.balance.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"financesBalance"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.balance.label")}
              fieldValue={ratingMap[get(formData, "financesBalance")]}
            />
            <hr />
            <Field
              options={options}
              name="financesFundraising"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.finances.fundraising.placeholder"
              )}
              label={t("shared.resources.finances.fundraising.label")}
              helpText={t("shared.resources.finances.fundraising.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"financesFundraising"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.fundraising.label")}
              fieldValue={ratingMap[get(formData, "financesFundraising")]}
            />
            <hr />
            <Field
              options={options}
              name="financesModeration"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.finances.moderation.placeholder"
              )}
              label={t("shared.resources.finances.moderation.label")}
              helpText={t("shared.resources.finances.moderation.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"financesModeration"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.moderation.label")}
              fieldValue={ratingMap[get(formData, "financesModeration")]}
            />
            <hr />
            <Field
              options={options}
              name="financesEconomic"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.resources.finances.economic.placeholder")}
              label={t("shared.resources.finances.economic.label")}
              helpText={t("shared.resources.finances.economic.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"financesEconomic"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.economic.label")}
              fieldValue={ratingMap[get(formData, "financesEconomic")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.positive")}
            </div>
            <Field
              name="financesPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.finances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"financesPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.positive")}
              fieldValue={ratingMap[get(formData, "financesPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.negative")}
            </div>
            <Field
              name="financesNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.finances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"financesNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.negative")}
              fieldValue={ratingMap[get(formData, "financesNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthResourcesFinances"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.resources.circumstances.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="circumstancesProper"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.circumstances.proper.placeholder"
              )}
              label={t("shared.resources.circumstances.proper.label")}
              helpText={t("shared.resources.circumstances.proper.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesRules"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.proper.label")}
              fieldValue={ratingMap[get(formData, "circumstancesProper")]}
            />
            <hr />
            <Field
              options={options}
              name="circumstancesEquipment"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.circumstances.equipment.placeholder"
              )}
              label={t("shared.resources.circumstances.equipment.label")}
              helpText={t("shared.resources.circumstances.equipment.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesEquipment"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.resources.circumstances.equipment.label"
              )}
              fieldValue={ratingMap[get(formData, "circumstancesEquipment")]}
            />
            <hr />
            <Field
              options={options}
              name="circumstancesBestuse"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.circumstances.bestuse.placeholder"
              )}
              label={t("shared.resources.circumstances.bestuse.label")}
              helpText={t("shared.resources.circumstances.bestuse.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesBestuse"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.resources.circumstances.bestuse.label"
              )}
              fieldValue={ratingMap[get(formData, "circumstancesBestuse")]}
            />
            <hr />
            <Field
              options={options}
              name="circumstancesInitiative"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.circumstances.initiative.placeholder"
              )}
              label={t("shared.resources.circumstances.initiative.label")}
              helpText={t("shared.resources.circumstances.initiative.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesInitiative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.resources.circumstances.initiative.label"
              )}
              fieldValue={ratingMap[get(formData, "circumstancesInitiative")]}
            />
            <hr />
            <Field
              options={options}
              name="circumstancesSafety"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.resources.circumstances.safety.placeholder"
              )}
              label={t("shared.resources.circumstances.safety.label")}
              helpText={t("shared.resources.circumstances.safety.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesSafety"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.safety.label")}
              fieldValue={ratingMap[get(formData, "circumstancesSafety")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.positive")}
            </div>
            <Field
              name="circumstancesPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.circumstances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.positive")}
              fieldValue={ratingMap[get(formData, "circumstancesPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.negative")}
            </div>
            <Field
              name="circumstancesNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.circumstances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.negative")}
              fieldValue={ratingMap[get(formData, "circumstancesNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthResourcesCircumstances"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default YouthResourcesPart as any;
