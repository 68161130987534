import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./ClubDashboard.scss";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchTools } from "../../modules/contentful";
import { LoadingContainer } from "../../components/LoadingContainer";
import { v4 } from "uuid";

type ToolsPageProps = WithTranslation &
  RouteComponentProps<{ orgId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface ToolsPageState {
  toolsLoading: boolean;
}

/*
  Fetch tool links from contentful using contentful-module. Group by topic and map through the groups
 */
class ToolsPage extends Component<ToolsPageProps, ToolsPageState> {
  constructor(props: ToolsPageProps) {
    super(props);
    this.state = {
      toolsLoading: false,
    };
  }

  async componentDidMount() {
    const { tools } = this.props;
    window.addEventListener("refreshEntries", this.fetchTools);
    if (!tools) {
      this.fetchTools();
    }
  }

  fetchTools = async () => {
    this.setState({ toolsLoading: true });
    const response = await this.props.fetchTools();
    if (response) {
      this.setState({ toolsLoading: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("refreshEntries", this.fetchTools);
  }

  render() {
    const { tools } = this.props;
    const parts = tools
      ? tools.items
          .slice()
          .sort((x) => (x.fields.name.includes("Aikuiset") ? 1 : -1))
      : null;
    return (
      <div className="toolspage">
        <LoadingContainer
          loading={this.state.toolsLoading}
          className="contentful-tools"
        >
          {parts &&
            parts.map((x) => {
              return (
                <div key={v4()} className="parts">
                  <div className="title">{x.fields.name}</div>
                  {x.fields.partThemes.map((y) => {
                    return (
                      <div key={v4()} className="theme">
                        {y.fields.themeTools && (
                          <div className="title">{y.fields.name}</div>
                        )}
                        {y.fields.themeTools &&
                          y.fields.themeTools.map((tool) => {
                            if (!tool.fields) {
                              console.warn("Missing or broken data!");
                              return null;
                            }

                            return (
                              <a
                                key={v4()}
                                href={tool.fields.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {tool.fields.name}
                              </a>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </LoadingContainer>
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{}>
) => {
  return {
    tools: state.contentful.entries.tools,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTools: () => dispatch(fetchTools()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ToolsPage as any));
