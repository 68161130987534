export const translatedOptions = (t) => [
  { value: "0", label: t("options.insufficient") },
  { value: "1", label: t("options.sufficient") },
  { value: "2", label: t("options.good") },
  { value: "3", label: t("options.excellent") },
  { value: "4", label: t("options.notapplicable") },
];

export const translatedRatingMap = (t) => {
  return {
    "0": t("ratingMap.insufficient"),
    "1": t("ratingMap.sufficient"),
    "2": t("ratingMap.good"),
    "3": t("ratingMap.excellent"),
    "4": t("ratingMap.notapplicable"),
  };
};
