// tslint:disable
import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AuditorInfo, OrgType, PathType } from "../../model";
import { connect } from "react-redux";
import { Table, Column, AutoSizer } from "react-virtualized";
import { orderBy, isEqual } from "lodash";
import { RouteComponentProps, withRouter } from "react-router";
import { format, isBefore } from "date-fns";
import "./Tables.scss";
import { getOrganizationDashboardUrl } from "../../routePaths";
import { SET_AUDIT_SETTINGS_MODAL_STATE } from "../../modules/auditSettings";
import pencilIcon from "../../images/pencil-icon.svg";
import { ReduxState } from "../../reducers";
import {
  OrganizationCapability,
  OrganizationCapabilityType,
} from "../../modules/organization";

interface FinalizedTableConnectedProps
  extends RouteComponentProps<{ orgId: string }> {
  list: AuditorInfo[];
  showArea: boolean;
  showFederation: boolean;
  allowEdit: boolean;
}

export interface FinalizedTableProps
  extends FinalizedTableConnectedProps,
    RouteComponentProps<{ orgId: string }>,
    WithTranslation {
  setModalAuditSettingsModal(data: AuditorInfo): void;
  currentOrgType?: OrgType | null;
  superOrgCapabilities: OrganizationCapability[] | [];
}

interface FinalizedTableState {
  disableHeader: boolean;
  headerHeight: number;
  height: number;
  hideIndexRow: boolean;
  overscanRowCount: number;
  rowHeight: number;
  rowCount: number;
  scrollToIndex: any;
  useDynamicRowHeight: boolean;
  sortedList: any;
  correctionsFetched: boolean;
  sortBy: string | null;
  sortDirection: string | null;
}

class FinalizedTable extends Component<
  FinalizedTableProps,
  FinalizedTableState
> {
  private tableRef: any = null;
  constructor(props: FinalizedTableProps) {
    super(props);
    const sortedList = orderBy(
      this.props.list,
      ["nextAuditTimestamp"],
      ["asc"]
    );
    this.state = {
      disableHeader: false,
      headerHeight: 30,
      height: 270,
      hideIndexRow: false,
      overscanRowCount: 10,
      rowHeight: 40,
      rowCount: sortedList.length,
      scrollToIndex: undefined,
      useDynamicRowHeight: false,
      sortedList,
      correctionsFetched: false,
      sortBy: null,
      sortDirection: null,
    };
  }

  componentWillReceiveProps(nextProps: FinalizedTableProps) {
    if (!isEqual(nextProps.list, this.props.list)) {
      const sortBy = this.state.sortBy
        ? this.state.sortBy
        : "nextAuditTimestamp";
      const sortDirection = this.state.sortDirection
        ? this.state.sortDirection.toLowerCase()
        : "asc";
      const sortedList = orderBy(
        nextProps.list,
        [sortBy],
        [sortDirection as any]
      );
      this.setState({ sortedList });
    }
  }

  _noRowsRenderer = () => {
    return <div className="emptyRow">{this.props.t("auditTable.empty")}</div>;
  };

  _rowClassName({ index }: any) {
    if (index < 0) {
      return "auditRow evenRow";
    } else {
      return index % 2 === 0 ? "auditRow evenRow" : "auditRow oddRow";
    }
  }

  _sort = ({ sortBy, sortDirection }: any) => {
    const sortedList = orderBy(
      this.state.sortedList,
      [sortBy],
      [sortDirection.toLowerCase()]
    );

    this.setState({ sortBy, sortDirection, sortedList });
  };

  headerRowRenderer = ({ className, columns, style }) => (
    <div className={className.concat(" headerRow")} role="row" style={style}>
      {columns}
    </div>
  );

  navigateToAuditPath = (rowData) => {
    const { history, match } = this.props;
    history.push(
      `${getOrganizationDashboardUrl(match.params.orgId)}/path/${
        rowData.auditRequestId
      }`
    );
  };

  render() {
    const { scrollToIndex, sortBy, sortDirection, sortedList } = this.state;
    const {
      t,
      showArea,
      showFederation,
      allowEdit,
      currentOrgType,
      superOrgCapabilities,
    } = this.props;
    const rowGetter = ({ index }) => {
      return sortedList[index];
    };

    const currentSuperOrgHasFederationTopSportsCapability =
      currentOrgType === OrgType.ADMIN_ORGANIZATION ||
      superOrgCapabilities?.some((c) => {
        return (
          c.capabilityType ===
            OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
          c.value === "true"
        );
      });

    return (
      <div style={{ width: "100%", flex: "1 1 auto" }}>
        <AutoSizer disableHeight={true} style={{ width: "100%" }}>
          {({ width }) => (
            <Table
              className="auditTable"
              ref={(ref) => (this.tableRef = ref)}
              headerHeight={40}
              height={800}
              headerClassName="auditTableHeader"
              headerRowRenderer={this.headerRowRenderer}
              noRowsRenderer={this._noRowsRenderer}
              rowClassName={this._rowClassName}
              rowHeight={40}
              rowGetter={rowGetter}
              rowCount={sortedList.length}
              scrollToIndex={scrollToIndex}
              sort={this._sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              width={width < 900 ? 900 : width}
            >
              <Column
                label={t("auditTable.clubName")}
                dataKey="clubName"
                width={260}
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.clubName : null
                }
                cellRenderer={({ cellData, rowData }) => (
                  <div
                    className={
                      rowData &&
                      rowData.auditRequestId &&
                      (currentSuperOrgHasFederationTopSportsCapability ||
                        rowData.qualityPath !== PathType.TOP_SPORTS)
                        ? "namecolumn"
                        : "non-link-name-column"
                    }
                    onClick={() =>
                      rowData &&
                      rowData.auditRequestId &&
                      (currentSuperOrgHasFederationTopSportsCapability ||
                        rowData.qualityPath !== PathType.TOP_SPORTS)
                        ? this.navigateToAuditPath(rowData)
                        : null
                    }
                  >
                    <div>{cellData}</div>
                  </div>
                )}
                className={"tablecolumn finalizedname"}
              />
              {showArea && (
                <Column
                  width={270}
                  dataKey="areaAssociationName"
                  cellDataGetter={({ rowData }) =>
                    rowData ? rowData.areaAssociationName : null
                  }
                  label={t("auditTable.area")}
                  className={"tablecolumn"}
                />
              )}
              {showFederation && (
                <Column
                  width={260}
                  dataKey="federationName"
                  cellDataGetter={({ rowData }) =>
                    rowData ? rowData.federationName : null
                  }
                  label={t("auditTable.federation")}
                  className={"tablecolumn"}
                />
              )}
              <Column
                width={140}
                dataKey="enrollmentTimestamp"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.enrollmentTimestamp : null
                }
                cellRenderer={({ cellData }) =>
                  cellData ? format(cellData, "DD.MM.YYYY") : null
                }
                label={t("auditTable.enrollment")}
                className={"tablecolumn"}
              />
              <Column
                width={175}
                dataKey="qualityPath"
                cellDataGetter={({ rowData }) =>
                  rowData ? t("paths." + rowData.qualityPath) : null
                }
                label={t("auditTable.path")}
                className={"tablecolumn"}
              />
              <Column
                width={140}
                dataKey="nextAuditTimestamp"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.nextAuditTimestamp : null
                }
                label={t("auditTable.next")}
                className={"tablecolumn"}
                cellRenderer={({ cellData }) => (
                  <div
                    className={isBefore(cellData, new Date()) ? "reddate" : ""}
                  >
                    {cellData ? format(cellData, "DD.MM.YYYY") : null}
                  </div>
                )}
              />
              <Column
                width={160}
                dataKey="decision"
                cellDataGetter={({ rowData }) =>
                  rowData && rowData.awardGranted
                    ? t("state.granted")
                    : t("state.rejected")
                }
                label={t("auditTable.state")}
                className={"tablecolumn"}
              />
              {allowEdit && (
                <Column
                  width={40}
                  dataKey="dummy"
                  className={"tablecolumn"}
                  cellRenderer={({ rowData }) => (
                    <>
                      {(currentSuperOrgHasFederationTopSportsCapability ||
                        rowData.qualityPath !== PathType.TOP_SPORTS) && (
                        <button
                          className="btn-invisible"
                          title={t("auditTable.changeTimetable")}
                          onClick={() => {
                            this.props.setModalAuditSettingsModal(rowData);
                          }}
                        >
                          <img alt="Edit" src={pencilIcon} />
                        </button>
                      )}
                    </>
                  )}
                />
              )}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

const tableTranslated = withTranslation("common")(FinalizedTable);

const mapStateToProps = (state: ReduxState, props) => {
  const superOrgId = props.match.params.orgId;
  return {
    currentOrgType: state.account.currentOrgType,
    superOrgCapabilities: superOrgId
      ? state.organization.capabilities[superOrgId]
      : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  setModalAuditSettingsModal: (data) =>
    dispatch(
      SET_AUDIT_SETTINGS_MODAL_STATE({
        modalIsOpen: true,
        deadlineOnly: true,
        auditInfo: data,
      })
    ),
});

const FinalizedTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(tableTranslated);

export default withRouter<FinalizedTableConnectedProps, any>(
  FinalizedTableConnected
);
