import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import {
  applyWidgetConfiguration,
  destroyWidget,
  prepareWidgetAPI,
} from "./widgetHelper";
import { UserData } from "../../modules/account";

const supportWidgetId = 44000002363;
const widgetSourceUrl = `https://widget.freshworks.com/widgets/${supportWidgetId}.js`;

interface RetryWorkaround {
  retryDelayMs: number;
  maxActionDurationMs: number;
}

/** Some actions require retrying. These constants define the parameters for retrying. */
const retryWorkaround: RetryWorkaround = {
  retryDelayMs: 500, // delay between retries
  // Maximum duration of an action. I.e. at latest after this amount of time we should stop retrying. 7s should allow
  // successful reconfiguration of a reloaded widget even on the slowest of network connections.
  maxActionDurationMs: 7000,
};

/**
 * Component to display the Freshworks widget.
 *
 * Due to the limited capabilities of the widget code itself (the widget cannot be reconfigured for a different language
 * once its loaded), this component makes up for this by using two workarounds:
 *
 * 1. Certain actions (destroying a previously created widget and configuring the widget) have to be retried in order
 *    to ensure their proper execution.
 * 2. Actions must be run in a work queue, since they cannot be aborted and subsequent actions will fail if started
 *    during the execution of a previous action. E.g. we must wait for the widget script to be loaded before configuring
 *    it.
 *
 * The widget is displayed for logged in users only.
 */
export const SupportWidget: React.FC = () => {
  const userDetails = useSelector((state: ReduxState) => state.account.user);
  const i18nLang = useSelector((state: ReduxState) =>
    state.account.user ? state.account.user.locale : null
  );
  const prevUserId = useRef<number | undefined>();
  const prevI18nLang = useRef<string | null | undefined>();

  const scriptEl = useRef<HTMLElement | null>(null);

  const workQueue = useRef<(() => Promise<any>)[]>([]);
  const working = useRef(false);

  /** Create a promise for adding the widget with the specific language.
   *
   * The promise is resolved when the widget's script element has been loaded. */
  const addWidget = (i18nLang) =>
    new Promise((resolve) => {
      // this has to be done before the script element is added
      prepareWidgetAPI(supportWidgetId, i18nLang);

      const newScriptEl = document.createElement("script");
      newScriptEl.addEventListener("load", resolve);
      newScriptEl.type = "text/javascript";
      newScriptEl.src = widgetSourceUrl;
      newScriptEl.async = true;
      newScriptEl.defer = true;
      document.head.appendChild(newScriptEl);
      scriptEl.current = newScriptEl;
    });

  /** Create a promise for removing the widget.
   *
   * The widget is first destroyed via the Freshdesk API call. If destroying
   * fails (which it does when the widget script code is running but the widget has not been mounted yet), we retry for
   * a maximum of {@const retryWorkaround.maxActionDurationMs}.
   *
   * After the widget has been successfully destroyed, its script element is removed and the promise is resolved.
   */
  const removeWidget = () => {
    const tryRemove = (resolve, prevDelay?: number | undefined) => {
      if (scriptEl.current) {
        if (!destroyWidget()) {
          // retry if widget could not be destroyed
          const newDelay =
            (prevDelay !== undefined ? prevDelay : 0) +
            retryWorkaround.retryDelayMs;
          if (newDelay > retryWorkaround.maxActionDurationMs) {
            resolve(false);
          } else {
            setTimeout(
              () => tryRemove(resolve, newDelay),
              retryWorkaround.retryDelayMs
            );
          }
          return;
        }
        // remove the script element if the widget was destroyed successfully
        scriptEl.current.remove();
        scriptEl.current = null;
      }
      resolve(true);
    };

    return new Promise((resolve) => tryRemove(resolve));
  };

  /** Create a promise for configuring the newly loaded widget, i.e. set custom translations and user information.
   *
   * The configuration is applied multiple times, with a delay of {@const retryWorkaround.retryDelayMs}, for a maximum
   * total of {@const retryWorkaround.maxActionDurationMs} because of the widget's misbehaviour (see below). After this
   * configuration cycle, the promise is resolved.
   *
   * If, during the configuration retrial cycle, it is detected that items have been added to the work queue, we abort
   * the configuration cycle and remove the promise immediately. This is because the items in the work queue will anyway
   * re-add and/or re-configure the widget, so we don't need to waste time applying the already outdated configuration.
   */
  const configureWidget = (userDetails: UserData) => {
    /* Need to use a setTimeout workaround here, because:
      1. reconfiguring the widget on the fly is not possible and
      2. newly set configurations after re-adding the widget will first get eaten by the older widget
         instances' code.

     Therefore, we set the same configuration many times in a row in order to
      1. have the new widget instance use the new configuration immediately when it's shown and
      2. have the new widget instance receive a configuration at least once (in case the old instance
         keeps eating our configurations for a longish time).
    */

    const tryConfigure = (resolve, prevDelay?: number | undefined) => {
      applyWidgetConfiguration(userDetails);
      const newDelay =
        (prevDelay !== undefined ? prevDelay : 0) +
        retryWorkaround.retryDelayMs;
      if (
        newDelay > retryWorkaround.maxActionDurationMs ||
        // Abort configuration if there are new work items in the queue. No need to finish lengthy configuration if the
        // configuration will be changed anyway.
        workQueue.current.length > 0
      ) {
        resolve();
      } else {
        setTimeout(
          () => tryConfigure(resolve, newDelay),
          retryWorkaround.retryDelayMs
        );
      }
    };

    return new Promise((resolve) => tryConfigure(resolve));
  };

  useEffect(() => {
    // simple Promise-based work queue implementation
    const doWork = () => {
      if (working.current) {
        return false; // wait for the current item to finish
      }
      const item = workQueue.current.shift();
      if (!item) {
        return; // no work to be done
      }
      try {
        working.current = true;
        item()
          .then(() => {
            working.current = false;
            doWork();
          })
          .catch(() => {
            working.current = false;
            doWork();
          });
      } catch (err) {
        working.current = false;
        doWork();
      }
    };

    if (
      prevUserId.current === (userDetails ? userDetails.userId : undefined) &&
      prevI18nLang.current === i18nLang
    ) {
      // do nothing if nothing has changed
      return;
    }

    prevUserId.current = userDetails ? userDetails.userId : undefined;
    prevI18nLang.current = i18nLang;

    // always remove the old widget first, since it cannot be reconfigured on the fly
    workQueue.current.push(removeWidget);

    // show the widget only if the user is logged in (i.e. userDetails are set)
    if (userDetails) {
      workQueue.current.push(() => addWidget(i18nLang));
      workQueue.current.push(() => configureWidget(userDetails));
    }
    doWork();
  }, [i18nLang, userDetails]);

  return null;
};
