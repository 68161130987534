import * as React from "react";
import { DonutChart } from "./DonutChart";
import "./AuditGraphs.scss";
import ManagementIcon from "../../images/path/icon_hallinto_summary.svg";
import SportcontentIcon from "../../images/path/icon_urheilutoiminta_summary.svg";
import PeopleIcon from "../../images/path/icon_ihmiset_summary.svg";
import ResourcesIcon from "../../images/path/icon_resurssit_summary.svg";
import SummaryIcon from "../../images/path/icon_yhteenveto_summary.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import { AuditGraphSegment } from "./AuditGraphSegment";
import { LoadingSpinner } from "../LoadingSpinner";

const COLORS = {
  EXCELLENT: "#00a652",
  GOOD: "#f3f448",
  SUFFICIENT: "#faa61a",
  INSUFFICIENT: "#ee334e",
  _EMPTY: "lightgrey",
};

const ORDER: string[] = ["EXCELLENT", "GOOD", "SUFFICIENT", "INSUFFICIENT"];

export interface AuditGraphsProps extends WithTranslation {
  gradeDist: any;
  auditor: boolean;
  enableNavigation?: boolean;
  navigateToPathPart?(part: string): void;
}

export interface AuditGraphsState {}

class AuditGraphsComponent extends React.Component<
  AuditGraphsProps,
  AuditGraphsState
> {
  constructor(props: AuditGraphsProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { t, gradeDist, enableNavigation, navigateToPathPart } = this.props;
    if (!gradeDist) {
      return <LoadingSpinner />;
    }
    return (
      <div className="auditgraphssection">
        <div className="auditgraphs">
          <AuditGraphSegment
            title="management"
            data={gradeDist}
            colors={COLORS}
            order={ORDER}
            icon={ManagementIcon}
            enableNavigation={enableNavigation}
            navigateToPart={navigateToPathPart}
          />
          <AuditGraphSegment
            title="sportcontent"
            data={gradeDist}
            colors={COLORS}
            order={ORDER}
            icon={SportcontentIcon}
            enableNavigation={enableNavigation}
            navigateToPart={navigateToPathPart}
          />
          <AuditGraphSegment
            title="people"
            data={gradeDist}
            colors={COLORS}
            order={ORDER}
            icon={PeopleIcon}
            enableNavigation={enableNavigation}
            navigateToPart={navigateToPathPart}
          />
          <AuditGraphSegment
            title="resources"
            data={gradeDist}
            colors={COLORS}
            order={ORDER}
            icon={ResourcesIcon}
            enableNavigation={enableNavigation}
            navigateToPart={navigateToPathPart}
          />
          <div className="graphsegmentcontainer">
            <div className="graphsegment">
              <DonutChart
                rawData={gradeDist.gradeValues}
                percData={gradeDist.percGradeValues}
                colors={COLORS}
                order={ORDER}
                title={t("summary.title")}
              >
                <img
                  src={SummaryIcon}
                  alt="Summary chart"
                  className="donuticon"
                />
              </DonutChart>
              <div className="summarytext">{t("other.summarytext")}</div>
            </div>
          </div>
        </div>
        <div className="grades">
          <span className="gradetext">{t("auditGraphs.grades")}</span>
          <div className="grade">
            <div
              className="colorball"
              style={{ backgroundColor: COLORS["EXCELLENT"] }}
            />
            <div className="gradetype">{t("auditGraphs.excellent")}</div>
          </div>
          <div className="grade">
            <div
              className="colorball"
              style={{ backgroundColor: COLORS["GOOD"] }}
            />
            <div className="gradetype">{t("auditGraphs.good")}</div>
          </div>
          <div className="grade">
            <div
              className="colorball"
              style={{ backgroundColor: COLORS["SUFFICIENT"] }}
            />
            <div className="gradetype">{t("auditGraphs.sufficient")}</div>
          </div>
          <div className="grade">
            <div
              className="colorball"
              style={{ backgroundColor: COLORS["INSUFFICIENT"] }}
            />
            <div className="gradetype">{t("auditGraphs.insufficient")}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const AuditGraphs = withTranslation("forms")(AuditGraphsComponent);
