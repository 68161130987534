import React from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class AdultsSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedPath"
              tkey="shared.sportcontent.path"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedCoaching"
              tkey="shared.sportcontent.coaching"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <Field
              name="sportcontentSharedPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.positive")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedPositive")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <Field
              name="sportcontentSharedNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.negative")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedNegative")]
              }
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.center.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="centerSuggestions"
              tkey="adults.sportcontent.center.suggestions"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerLightevents"
              tkey="adults.sportcontent.center.lightEvents"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerBeginners"
              tkey="adults.sportcontent.center.beginners"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerEnableCompetitions"
              tkey="adults.sportcontent.center.enableCompetitions"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerAccessibility"
              tkey="adults.sportcontent.center.accessibility"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerWelcoming"
              tkey="adults.sportcontent.center.welcoming"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.positive")}
            </div>
            <Field
              name="centerPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.center.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"centerPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.positive")}
              fieldValue={ratingMap[get(formData, "centerPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.negative")}
            </div>
            <Field
              name="centerNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.center.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"centerNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.center.negative")}
              fieldValue={ratingMap[get(formData, "centerNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "adultsCenter")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.versatility.formHeader")}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityMethodical"
              tkey="adults.sportcontent.versatility.methodical"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityContent"
              tkey="adults.sportcontent.versatility.content"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityWellBeing"
              tkey="adults.sportcontent.versatility.wellBeing"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilitySafe"
              tkey="adults.sportcontent.versatility.safe"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityCustomization"
              tkey="adults.sportcontent.versatility.customization"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilitySelfMotivation"
              tkey="adults.sportcontent.versatility.selfMotivation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.positive")}
            </div>
            <Field
              name="versatilityPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.versatility.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.versatility.positive")}
              fieldValue={ratingMap[get(formData, "versatilityPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.negative")}
            </div>
            <Field
              name="versatilityNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("adults.sportcontent.versatility.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"versatilityNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("adults.sportcontent.versatility.negative")}
              fieldValue={ratingMap[get(formData, "versatilityNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "adultsVersatility"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default AdultsSportContentPart as any;
