import paperClipIcon from "../../images/paperclip-small.svg";
import commentIcon from "../../images/comment.svg";
import { TooltipData } from "./DevelopmentItemTooltip";
import { DevelopmentItemStatus } from "../../model";
import checkMarkIcon from "../../images/checkmark.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { DevelopmentItem } from "../../modules/development";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { identity } from "lodash";

interface DevelopmentItemStatusRowProps {
  item: DevelopmentItem;
  tooltipId: string;
  showPriority?: boolean;
}

const MAX_NUM_ATTACHMENT_NAMES = 3;

export function DevelopmentItemStatusRow(props: DevelopmentItemStatusRowProps) {
  const { t } = useTranslation("clubDevelopment");
  const attachments = useSelector(
    (state: ReduxState) => state.attachment.orgAttachments
  );

  return (
    <div className="statusrow">
      {props.showPriority && props.item.priority && props.item.priority > 0 && (
        <div className="importance">{t("important")}</div>
      )}
      {props.item.attachmentFileIds && props.item.attachmentFileIds.length > 0 && (
        <img
          src={paperClipIcon}
          alt={t("itemCard.paperclip-alt")}
          data-tip={JSON.stringify(
            identity<TooltipData>({
              title: t("itemCard.attachments-tooltip-title"),
              content: props.item.attachmentFileIds
                .slice(0, MAX_NUM_ATTACHMENT_NAMES)
                .map(
                  (id) =>
                    attachments.find((attachment) => attachment.id === id)!.name
                )
                .concat(
                  props.item.attachmentFileIds.length > MAX_NUM_ATTACHMENT_NAMES
                    ? ["..."]
                    : []
                ),
            })
          )}
          data-for={props.tooltipId}
        />
      )}
      {props.item.comment && props.item.comment.trim().length > 0 && (
        <img
          src={commentIcon}
          alt={t("itemCard.comment-alt")}
          data-tip={JSON.stringify(
            identity<TooltipData>({
              title: t("itemCard.comment-tooltip-title"),
              content: props.item.comment,
            })
          )}
          data-for={props.tooltipId}
        />
      )}
      {props.item.status && props.item.status === DevelopmentItemStatus.READY && (
        <img
          src={checkMarkIcon}
          alt={t("itemCard.checkmark-alt")}
          data-tip={
            props.item.results && props.item.results.trim().length > 0
              ? JSON.stringify(
                  identity<TooltipData>({
                    title: t("itemCard.results-tooltip-title"),
                    content: props.item.results,
                  })
                )
              : undefined
          }
          data-for={props.tooltipId}
        />
      )}
    </div>
  );
}
