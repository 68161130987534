import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReduxState } from "./reducers";
import { UserData } from "./modules/account";
import { Redirect } from "react-router";
import { paths } from "./routePaths";
import LoginDisplay from "./views/login/LoginDisplay";

interface IStateProps {
  userData: UserData | null;
  authenticating?: boolean;
}

type IProps = IStateProps;

/**
 * Checks for user authentication before rendering wrapped component.
 */
function withAuthentication(WrappedComponent: any) {
  class UserIsAuthenticated extends React.Component<IProps> {
    public render() {
      const { userData, authenticating } = this.props;
      const redirectUrl = window.location.pathname;
      if (authenticating) {
        return <LoginDisplay />;
      }
      return userData !== null ? (
        <WrappedComponent {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: paths.loginRedirect,
            search: `?redirect=${redirectUrl}`,
          }}
        />
      );
    }
  }
  (UserIsAuthenticated as any).displayName = `UserIsAuthenticated(${getDisplayName(
    WrappedComponent
  )})`;
  return UserIsAuthenticated;
}

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

const mapStateToProps = (state: ReduxState) => ({
  userData: state.account.user,
  authenticating: state.account.authenticating,
});

export default compose<any>(connect(mapStateToProps, null), withAuthentication);
