import * as React from "react";
import { HelpBlock, FormGroup } from "react-bootstrap";
import { Field } from "redux-form";
import { fromJS } from "immutable";
import { v4 } from "uuid";
import { isArray } from "lodash";
import InputField from "./InputField";
import removeIcon from "../../../images/remove.svg";

interface IProps {
  addNewText: string;
  disabled?: boolean;
  fields: any;
  helpText: string;
  meta: {
    invalid: boolean;
    error: boolean;
    touched: boolean;
    dirty: boolean;
  };
  minOnePresent?: boolean;
  newTemplate: object;
  placeholder?: string;
  changeFieldValue(index: number, value: string): void;
}

class DeletableInputFieldArray extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.addNew = this.addNew.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    const { minOnePresent, fields, newTemplate } = this.props;

    if (minOnePresent && fields.length < 1) {
      fields.push(fromJS(newTemplate));
    }
  }

  addNew() {
    const { disabled, fields, newTemplate } = this.props;

    if (!disabled) {
      fields.push(fromJS(newTemplate));
    }
  }

  remove(event, index: number) {
    event.preventDefault();
    const { fields, minOnePresent, changeFieldValue } = this.props;

    if (minOnePresent && fields.length <= 1) {
      changeFieldValue(0, "");
    } else {
      fields.remove(index);
    }
  }

  render() {
    const {
      addNewText,
      disabled,
      fields,
      placeholder,
      meta: { invalid, error, touched, dirty },
    } = this.props;

    return (
      <FormGroup
        controlId={v4()}
        validationState={
          invalid && error && (touched || dirty) ? "error" : "success"
        }
        style={{ marginBottom: "0px" }}
      >
        <div className="formfield">
          {fields.map((name, index) => {
            return (
              <div key={index} className="deletableinputfieldcontainer">
                <div className="deletableinputfieldcontainerrow">
                  <Field
                    disabled={disabled}
                    name={`${name}.name`}
                    component={InputField}
                    className="formfield"
                    placeholder={placeholder}
                  />
                  <div className="deletableinputremoveicon">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      style={{ display: disabled ? "none" : "block" }}
                      src={removeIcon}
                      onClick={(e) => this.remove(e, index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className="addnewrowlink"
            style={{
              paddingBottom: "0px",
              display: disabled ? "none" : "block",
            }}
          >
            <span className="hoverborder" onClick={this.addNew}>
              {addNewText}
            </span>
          </div>
          {invalid && error && (touched || dirty) ? (
            <HelpBlock>
              {isArray(error) &&
                error.length > 0 &&
                error.map((err) => {
                  return <span key={v4()}>{err}</span>;
                })}
            </HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

export default DeletableInputFieldArray;
