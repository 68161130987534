import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./ToolLinks.scss";
import { v4 } from "uuid";
import toolsicon from "../../../images/icon_tyokalut.svg";

export interface ToolLinksProps extends WithTranslation {
  tools: any;
}

class ToolLinks extends Component<ToolLinksProps, any> {
  // gets tools object from a FormPart and maps through the links
  render() {
    const { tools, t } = this.props;
    if (!tools || !tools.fields || !tools.fields.themeTools) {
      return null;
    }
    return (
      <div className="toolLinks">
        <div className="toolsHeader">
          <img src={toolsicon} alt="" />
          <div>
            <b>{t("toolLinks.header")}</b>
          </div>
        </div>
        <div className="links">
          {tools.fields.themeTools.map((x) => {
            return (
              <a
                key={v4()}
                href={x.fields.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x.fields.name}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

const linksTranslated = withTranslation("common")(ToolLinks);

export default linksTranslated;
