import { Component } from "react";
import { ReduxState } from "../../reducers";
import { getDevelopmentItems } from "../../modules/development";
import { connect } from "react-redux";
import { compose } from "redux";
import { RouteComponentProps, withRouter } from "react-router";
import { getAuditMetaInfo } from "../../modules/auditmetainfo";
import { fetchOrgAttachments } from "../../modules/attachment";

export type DevelopmentBaseViewBaseProps = RouteComponentProps<{
  orgId: string;
}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export abstract class DevelopmentBaseView<
  DevelopmentBaseViewProps extends DevelopmentBaseViewBaseProps,
  DevelopmentBaseViewState
> extends Component<DevelopmentBaseViewProps, DevelopmentBaseViewState> {
  protected constructor(props: DevelopmentBaseViewProps) {
    super(props);
  }

  getOrgId(props: DevelopmentBaseViewProps) {
    const { orgId: orgIdStr } = props.match.params;
    return parseInt(orgIdStr, 10);
  }

  getAuditMetaInfo(props: DevelopmentBaseViewProps) {
    const orgId = this.getOrgId(props);
    return props.clubAuditMetaInfo && orgId
      ? props.clubAuditMetaInfo[orgId]
      : null;
  }

  componentDidMount() {
    const orgId = this.getOrgId(this.props);
    if (orgId) {
      this.props.getDevelopmentItems(orgId);
      this.props.fetchAttachments(orgId);

      if (!this.getAuditMetaInfo(this.props)) {
        this.props.getAuditMetaInfo(orgId);
      }
    }
  }

  componentDidUpdate(prevProps: DevelopmentBaseViewProps) {
    const orgId = this.getOrgId(this.props);
    const prevOrgId = this.getOrgId(prevProps);

    if (orgId !== prevOrgId && orgId) {
      this.props.getDevelopmentItems(orgId);
      this.props.fetchAttachments(orgId);

      if (!this.getAuditMetaInfo(this.props)) {
        this.props.getAuditMetaInfo(orgId);
      }
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    developmentItems: state.development.view.filteredDevelopmentItems,
    clubAuditMetaInfo:
      state.clubAuditMetaInfo && state.clubAuditMetaInfo.clubAuditMetaInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDevelopmentItems: (orgId: number) => dispatch(getDevelopmentItems(orgId)),
  getAuditMetaInfo: (orgId: number) => dispatch(getAuditMetaInfo(orgId)),
  fetchAttachments: (orgId: number) =>
    dispatch(fetchOrgAttachments(orgId.toString())),
});

export const enhanceDevelopmentBaseView: any = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
