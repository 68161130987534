import React from "react";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedGradeOptions, translatedGradeRatingMap } from "../model";
import { find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";
import { TahtiseuraListField } from "../../Fields/TahtiseuraListField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class AdultsResourcesPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedGradeOptions(t);
    const ratingMap = translatedGradeRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.resources.finances.formHeader")}
          open={true}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="financesFinancialguide"
              tkey="shared.resources.finances.financialguide"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesFinancial"
              tkey="shared.resources.finances.financial"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesBalance"
              tkey="shared.resources.finances.balance"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesFundraising"
              tkey="shared.resources.finances.fundraising"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesModeration"
              tkey="shared.resources.finances.moderation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesEconomic"
              tkey="shared.resources.finances.economic"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="financesPositive"
              placeholder="shared.resources.finances.placeholder"
              fieldTranslated="shared.resources.finances.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="financesNegative"
              placeholder="shared.resources.finances.placeholder"
              fieldTranslated="shared.resources.finances.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>

          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "adultsResourcesFinances"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.resources.circumstances.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesProper"
              tkey="shared.resources.circumstances.proper"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesEquipment"
              tkey="shared.resources.circumstances.equipment"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesBestuse"
              tkey="shared.resources.circumstances.bestuse"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesInitiative"
              tkey="shared.resources.circumstances.initiative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesSafety"
              tkey="shared.resources.circumstances.safety"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="circumstancesPositive"
              placeholder="shared.resources.circumstances.placeholder"
              fieldTranslated="shared.resources.circumstances.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="circumstancesNegative"
              placeholder="shared.resources.circumstances.placeholder"
              fieldTranslated="shared.resources.circumstances.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "adultsResourcesCircumstances"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default AdultsResourcesPart as any;
