import { handleActions, createAction } from "redux-actions";
import { AuditorInfo } from "../model";
export const SET_AUDIT_SETTINGS_MODAL_STATE = createAction<AuditSettingsState>(
  "SET_AUDIT_SETTINGS_MODAL_STATE"
);
export const CLOSE_AUDIT_SETTINGS_MODAL = createAction(
  "CLOSE_AUDIT_SETTINGS_MODAL"
);
export interface AuditSettingsState {
  modalIsOpen: boolean;
  deadlineOnly: boolean;
  auditInfo?: AuditorInfo;
}

const initialState: AuditSettingsState = {
  modalIsOpen: false,
  deadlineOnly: true,
  auditInfo: undefined,
};

const auditSettingsReducer = handleActions<AuditSettingsState, any>(
  {
    [SET_AUDIT_SETTINGS_MODAL_STATE as any]: (state, action) => action.payload,
    [CLOSE_AUDIT_SETTINGS_MODAL as any]: (state, action) => initialState,
  },
  initialState
);

export default auditSettingsReducer;
