import { combineActions, createAction, handleActions } from "redux-actions";
import Api from "../api";

export const FETCH_ORGANIZATION_CAPABILITIES_SUCCESS = createAction<
  OrganizationCapabilityMapActionType
>("FETCH_ORGANIZATION_CAPABILITIES_SUCCESS");
export const FETCH_ORGANIZATION_CAPABILITIES_FAILURE = createAction(
  "FETCH_ORGANIZATION_CAPABILITIES_FAILURE"
);

export const SET_ORGANIZATION_CAPABILITY_SUCCESS = createAction<
  OrganizationCapabilityMapActionType
>("SET_ORGANIZATION_CAPABILITY_SUCCESS");
export const SET_ORGANIZATION_CAPABILITY_FAILURE = createAction(
  "SET_ORGANIZATION_CAPABILITY_FAILURE"
);

export function fetchOrganizationCapabilities(orgId: number) {
  return async (dispatch): Promise<void> => {
    try {
      const response = await Api.organization.getOrganizationCapabilities(
        orgId
      );

      dispatch(
        FETCH_ORGANIZATION_CAPABILITIES_SUCCESS({
          organizationId: orgId,
          capabilities: response.data,
        })
      );
    } catch (err) {
      dispatch(FETCH_ORGANIZATION_CAPABILITIES_FAILURE());
      return;
    }

    return;
  };
}

export function setOrganizationCapability(
  orgId: number,
  capability: OrganizationCapability
) {
  return async (dispatch): Promise<void> => {
    try {
      await Api.organization.setOrganizationCapability(orgId, capability);

      const capabilitiesResponse = await Api.organization.getOrganizationCapabilities(
        orgId
      );

      dispatch(
        SET_ORGANIZATION_CAPABILITY_SUCCESS({
          organizationId: orgId,
          capabilities: capabilitiesResponse.data,
        })
      );
    } catch (err) {
      dispatch(SET_ORGANIZATION_CAPABILITY_FAILURE());
      return;
    }

    return;
  };
}

export enum OrganizationCapabilityType {
  SPORTS_FEDERATION_TOP_SPORTS_ENABLED = "SPORTS_FEDERATION_TOP_SPORTS_ENABLED",
  CLUB_TOP_SPORTS_ENABLED = "CLUB_TOP_SPORTS_ENABLED",
}

export type OrganizationCapability = {
  organizationId: number;
  capabilityType: OrganizationCapabilityType;
  value: string;
};

export type OrganizationCapabilityMapType = {
  [key: string]: OrganizationCapability[];
};

export type OrganizationCapabilityMapActionType = {
  organizationId: number;
  capabilities: OrganizationCapabilityMapType;
};

export interface OrganizationState {
  capabilities: OrganizationCapabilityMapType;
}

const initialState: OrganizationState = {
  capabilities: {},
};

export const organizationReducer = handleActions<OrganizationState, any>(
  {
    [combineActions(
      FETCH_ORGANIZATION_CAPABILITIES_SUCCESS,
      SET_ORGANIZATION_CAPABILITY_SUCCESS
    ) as any]: (state, action) => {
      return {
        ...state,
        capabilities: {
          ...state.capabilities,
          [action.payload.organizationId]: action.payload.capabilities,
        },
      };
    },
    [combineActions(
      FETCH_ORGANIZATION_CAPABILITIES_FAILURE,
      SET_ORGANIZATION_CAPABILITY_FAILURE
    ) as any]: (state) => ({
      ...state,
    }),
  },
  initialState
);
