import { createAction, handleActions } from "redux-actions";
import {
  AuditResponse,
  AuditorInfo,
  ClubEnrollmentUpdate,
  OrgSummary,
  ClubInformation,
} from "../model";
import * as Logger from "js-logger";
import Api from "../api";
import { format as fnsFormat, parse as fnsParse } from "date-fns";
import DayPicker from "react-day-picker";
import Alert from "react-s-alert";
import i18next from "i18next";
import { CLOSE_AUDIT_SETTINGS_MODAL } from "./auditSettings";
import { SET_CURRENT_ORG } from "./account";

export const RECEIVE_PENDING_AUDITS = createAction<AuditorInfo>(
  "RECEIVE_PENDING_AUDITS"
);
export const SET_FETCHING_PENDING_AUDITS = createAction<boolean>(
  "SET_FETCHING_PENDING_AUDITS"
);
export const RECEIVE_FINALIZED_AUDITS = createAction<AuditorInfo>(
  "RECEIVE_FINALIZED_AUDITS"
);
export const SET_FETCHING_FINALIZED_AUDITS = createAction<boolean>(
  "SET_FETCHING_FINALIZED_AUDITS"
);
export const RECEIVE_SINGLE_RESPONSE = createAction<{
  response: AuditResponse | null;
  id: number | null;
}>("RECEIVE_SINGLE_RESPONSE");
export const SET_RESPONSE_SUBMIT_TYPE = createAction<string>(
  "SET_RESPONSE_SUBMIT_TYPE"
);
export const RECEIVE_PREVIOUS_RESPONSE = createAction<{
  response: AuditResponse | null;
  id: number | null;
}>("RECEIVE_PREVIOUS_RESPONSE");
export const RECEIVE_ORG_SUMMARY = createAction<AuditorInfo>(
  "RECEIVE_ORG_SUMMARY"
);
export const SET_FETCHING_CLUBS = createAction<boolean>("SET_FETCHING_CLUBS");
export const SET_FETCHING_SINGLE_CLUB = createAction<boolean>(
  "SET_FETCHING_SINGLE_CLUB"
);
export const RECEIVE_CLUBS = createAction<{
  response: ClubInformation[] | null;
  id: number;
}>("SET_FETCHING_CLUBS");
export const RECEIVE_SINGLE_CLUB = createAction<{
  response: ClubInformation | null;
  id: number;
}>("RECEIVING_SINGLE_CLUB");
export function fetchPendingAudits(organizationId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      dispatch(SET_FETCHING_PENDING_AUDITS(true));
      const response = await Api.auditor.getPendingAudits(organizationId);
      dispatch(RECEIVE_PENDING_AUDITS(response.data));
      dispatch(SET_FETCHING_PENDING_AUDITS(false));
      return true;
    } catch {
      dispatch(SET_FETCHING_PENDING_AUDITS(false));
      Logger.warn("Fetching pending audits failed");
      return false;
    }
  };
}

export function fetchFinalizedAudits(organizationId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      dispatch(SET_FETCHING_FINALIZED_AUDITS(true));
      const response = await Api.auditor.getFinalizedAudits(organizationId);
      dispatch(RECEIVE_FINALIZED_AUDITS(response.data));
      dispatch(SET_FETCHING_FINALIZED_AUDITS(false));
      return true;
    } catch {
      dispatch(SET_FETCHING_FINALIZED_AUDITS(false));
      Logger.warn("Fetching finalized audits failed");
      return false;
    }
  };
}

export function fetchOrgSummary(organizationId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.auditor.getOrgSummary(organizationId);
      dispatch(RECEIVE_ORG_SUMMARY(response.data));
      return true;
    } catch {
      Logger.warn("Fetching org summary failed");
      return false;
    }
  };
}

export function fetchAudits(organizationId: number) {
  return async (dispatch): Promise<boolean> => {
    dispatch(fetchPendingAudits(organizationId));
    dispatch(fetchFinalizedAudits(organizationId));
    return true;
  };
}

export function fetchClubs(organizationId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      dispatch(SET_FETCHING_CLUBS(true));
      const response = await Api.auditor.getAllClubsUnderOrganization(
        organizationId
      );
      dispatch(RECEIVE_CLUBS({ response: response.data, id: organizationId }));
      dispatch(SET_FETCHING_CLUBS(false));
      return true;
    } catch {
      dispatch(SET_FETCHING_CLUBS(false));
      Logger.warn("Fetching clubs failed");
      return false;
    }
  };
}

export function fetchSingleClub(clubId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      dispatch(SET_FETCHING_SINGLE_CLUB(true));
      const response = await Api.auditor.getSingleClub(clubId);
      dispatch(RECEIVE_SINGLE_CLUB({ response: response.data, id: clubId }));
      dispatch(SET_FETCHING_SINGLE_CLUB(false));
      return true;
    } catch {
      dispatch(SET_FETCHING_SINGLE_CLUB(false));
      Logger.warn("Fetching club failed");
      return false;
    }
  };
}

export function fetchSingleResponse(responseId: string) {
  return async (dispatch): Promise<AuditResponse | null> => {
    try {
      const response = await Api.auditor.getSingleResponse(responseId);
      dispatch(
        RECEIVE_SINGLE_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      return response.data;
    } catch {
      dispatch(RECEIVE_SINGLE_RESPONSE({ response: null, id: null }));
      Logger.warn("Fetching single response failed");
      return null;
    }
  };
}

export function fetchResponseByAuditRequest(requestId: string) {
  return async (dispatch): Promise<AuditResponse | null> => {
    try {
      const response = await Api.auditor.getByAuditRequest(requestId);
      dispatch(
        RECEIVE_SINGLE_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      return response.data;
    } catch {
      dispatch(
        RECEIVE_SINGLE_RESPONSE({ response: null, id: parseInt(requestId, 10) })
      );
      Logger.warn("Fetching response by request failed");
      return null;
    }
  };
}

export function fetchPreviousResponseByAuditRequest(requestId: string) {
  return async (dispatch): Promise<AuditResponse | null> => {
    try {
      const response = await Api.auditor.getByAuditRequestPrevious(requestId);
      dispatch(
        RECEIVE_PREVIOUS_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      return response.data;
    } catch {
      dispatch(
        RECEIVE_PREVIOUS_RESPONSE({
          response: null,
          id: parseInt(requestId, 10),
        })
      );
      Logger.warn("Fetching response by request failed");
      return null;
    }
  };
}

export function createResponse(requestId: string) {
  return async (dispatch): Promise<AuditResponse | null> => {
    try {
      const response = await Api.auditor.createSingleResponse({
        auditRequestId: requestId,
      });
      dispatch(
        RECEIVE_SINGLE_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      return response.data;
    } catch {
      Logger.warn("Creating response failed");
      return null;
    }
  };
}

export function saveResponse(responseId: string, formValues: any) {
  return async (dispatch): Promise<boolean> => {
    try {
      let data: any = {};
      data.values = formValues;
      data.decision = formValues.decision;
      if (
        formValues.decision === "REJECTED_PRELIMINARY" &&
        formValues.agreedCorrectionDate
      ) {
        // date-fns doesn't handle custom date format parsing yet (only in v2), so have to do it manually:
        // split the DD.MM.YYYY string, reverse the direction and put it back together
        // using - as the separator
        const date = formValues.agreedCorrectionDate
          .split(".")
          .reverse()
          .join("-");

        const parsedDate = fnsParse(date);
        if ((DayPicker.DateUtils as any).isDate(parsedDate)) {
          data.correctionsDueTimestamp = fnsFormat(
            parsedDate,
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ).concat("Z");
        }
      }
      const response = await Api.auditor.updateSingleResponse(responseId, data);
      dispatch(
        RECEIVE_SINGLE_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      Alert.info(i18next.t("common:apiAlerts.summarySave"));
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.summarySave"));
      Logger.warn("Saving response failed");
      return false;
    }
  };
}

export function submitResponse(responseId: string) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.auditor.submitSingleResponse(responseId);
      dispatch(
        RECEIVE_SINGLE_RESPONSE({
          response: response.data,
          id: response.data.auditRequestId,
        })
      );
      Alert.info(i18next.t("common:apiAlerts.summarySend"));
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.summarySend"));
      Logger.warn("Saving response failed");
      return false;
    }
  };
}

export function reopenRequest(requestId: number, auditorOrgId?: string) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.auditor.reopenRequest(requestId);

      // only close the modal if re-opening succeeded
      dispatch(CLOSE_AUDIT_SETTINGS_MODAL());

      if (auditorOrgId) {
        const auditorOrgIdNumber = parseInt(auditorOrgId, 10);
        dispatch(fetchAudits(auditorOrgIdNumber));
      }

      Alert.info(i18next.t("common:apiAlerts.reopenRequest"));
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.reopenRequest"));
      Logger.warn("Reopening request failed");
      return false;
    }
  };
}

export function updateEnrollment(
  clubId: number,
  qualityPath: string,
  data: ClubEnrollmentUpdate,
  auditorOrgId?: string
) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.auditor.updateEnrollment(clubId, qualityPath, data);

      if (auditorOrgId) {
        const auditorOrgIdNumber = parseInt(auditorOrgId, 10);
        dispatch(fetchAudits(auditorOrgIdNumber));
      }

      Alert.info(i18next.t("common:apiAlerts.updateEnrollment"));
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.updateEnrollment"));
      Logger.warn("Updating enrollment failed");
      return false;
    }
  };
}

export interface AuditorState {
  pendingAudits: AuditorInfo[] | null;
  fetchingPendingAudits: boolean;
  finalizedAudits: AuditorInfo[] | null;
  fetchingFinalizedAudits: boolean;
  fetchingAudits: boolean;
  requestResponses: { [requestId: number]: AuditResponse };
  currentResponse: AuditResponse | null;
  previousResponse: AuditResponse | null;
  responseReceivedAt: Date | null;
  responseRequestId: number | null;
  submitType: string | null;
  orgSummary: OrgSummary | null;
  fetchingClubs: boolean;
  fetchingSingleClub: boolean;
  auditorOrganizations: {
    [key: number]: ClubInformation[] | null;
  } | null;
  auditorClubs: {
    [key: number]: ClubInformation | null;
  };
}

const initialState: AuditorState = {
  pendingAudits: null,
  fetchingPendingAudits: true,
  finalizedAudits: null,
  fetchingFinalizedAudits: true,
  fetchingAudits: true,
  requestResponses: {},
  currentResponse: null,
  previousResponse: null,
  responseReceivedAt: null,
  responseRequestId: null,
  submitType: null,
  orgSummary: null,
  fetchingClubs: false,
  fetchingSingleClub: false,
  auditorOrganizations: null,
  auditorClubs: [],
};

const auditorReducer = handleActions<AuditorState, any>(
  {
    [RECEIVE_PENDING_AUDITS as any]: (state, action) => {
      return { ...state, pendingAudits: action.payload };
    },
    [SET_FETCHING_PENDING_AUDITS as any]: (state, action) => {
      return {
        ...state,
        fetchingPendingAudits: action.payload,
        fetchingAudits: action.payload || state.fetchingFinalizedAudits,
      };
    },
    [RECEIVE_FINALIZED_AUDITS as any]: (state, action) => {
      return { ...state, finalizedAudits: action.payload };
    },
    [SET_FETCHING_FINALIZED_AUDITS as any]: (state, action) => {
      return {
        ...state,
        fetchingFinalizedAudits: action.payload,
        fetchingAudits: action.payload || state.fetchingPendingAudits,
      };
    },
    [RECEIVE_SINGLE_RESPONSE as any]: (state, action) => {
      return {
        ...state,
        requestResponses: {
          ...state.requestResponses,
          [action.payload.id]: action.payload.response,
        },
        currentResponse: action.payload.response,
        responseReceivedAt: new Date(),
        responseRequestId: action.payload.id,
      };
    },
    [RECEIVE_PREVIOUS_RESPONSE as any]: (state, action) => {
      return {
        ...state,
        requestResponses: {
          ...state.requestResponses,
          [action.payload.id]: action.payload.response,
        },
        previousResponse: action.payload.response,
        responseReceivedAt: new Date(),
        responseRequestId: action.payload.id,
      };
    },
    [SET_RESPONSE_SUBMIT_TYPE as any]: (state, action) => {
      return { ...state, submitType: action.payload };
    },
    [SET_CURRENT_ORG as any]: (state, action) => initialState,
    [RECEIVE_ORG_SUMMARY as any]: (state, action) => {
      return { ...state, orgSummary: action.payload };
    },
    [SET_FETCHING_CLUBS as any]: (state, action) => {
      return {
        ...state,
        fetchingClubs: action.payload,
      };
    },
    [SET_FETCHING_SINGLE_CLUB as any]: (state, action) => {
      return {
        ...state,
        fetchingSingleClub: action.payload,
      };
    },
    [RECEIVE_CLUBS as any]: (state, action) => {
      const { id, response } = action.payload;

      const auditOrgs =
        id && response
          ? {
              ...state.auditorOrganizations,
              [id]: response,
            }
          : { ...state.auditorOrganizations };

      return {
        ...state,
        auditorOrganizations: auditOrgs,
      };
    },
    [RECEIVE_SINGLE_CLUB as any]: (state: AuditorState, action) => {
      const { id, response } = action.payload;

      const auditClubs =
        id && response
          ? {
              ...state.auditorClubs,
              [id]: response,
            }
          : { ...state.auditorClubs };

      return {
        ...state,
        auditorClubs: auditClubs,
      };
    },
  },
  initialState
);

export default auditorReducer;
