import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Field, Fields } from "redux-form";
import { ExpandingContentRowHeader } from "../../Headers/ExpandingContentRowHeader";
import TextAreaField from "../Fields/TextAreaField";
import DateField from "../Fields/DateField";
import RadioButtonGroupField from "../Fields/RadioButtonGroupField";
import {
  AuditRequest,
  AuditRequestAddInfo,
  OrgType,
  PathType,
} from "../../../model";
import { ImprovementInfo } from "../../AuditGraphs/ImprovementInfo";
import "./AuditSummaryV5.scss";
import { AuditGraphs } from "../../AuditGraphs/AuditGraphs";
import { RouteComponentProps, withRouter } from "react-router";
import { SET_NAVIGATE_TO_MODAL } from "../../../modules/path";
import { getPathPart, getPathPartAudit } from "../../../routePaths";
import { connect } from "react-redux";
import { LoadingSpinner } from "../../LoadingSpinner";

type AuditSummaryV5Props = WithTranslation &
  RouteComponentProps<{ orgId: string }> & {
    formData?: any;
    readOnly?: boolean;
    auditor: boolean;
    commentsAdded?: boolean;
    options: any;
    currentOrgType?: OrgType | null;
    auditRequest: AuditRequest;
    auditRequestAddInfo: AuditRequestAddInfo | null;
    dispatch: any;
    handleSubmit?(values?: any): void;
    saveResponseDraft?(): void;
    saveResponseAndSubmit?(): void;
    navigateBack(): void;
  };

class AuditSummaryV5 extends Component<AuditSummaryV5Props, any> {
  getHelpText = (key: string) => {
    const { t, auditor } = this.props;
    return auditor ? t(key + ".helpText") : "";
  };

  navigateToPathPart = (part: string) => {
    const {
      history,
      auditor,
      auditRequest,
      match: {
        params: { orgId },
      },
      dispatch,
    } = this.props;
    dispatch(SET_NAVIGATE_TO_MODAL(true));
    if (auditor) {
      history.push(
        getPathPartAudit(orgId, part, auditRequest.qualityPath, auditRequest.id)
      );
    } else {
      history.push(
        getPathPart(
          auditRequest.clubId.toString(),
          part,
          auditRequest.qualityPath,
          auditRequest.id.toString(),
          auditRequest.clubEnrollmentId.toString()
        )
      );
    }
  };

  render() {
    const {
      t,
      handleSubmit,
      readOnly,
      options,
      commentsAdded,
      currentOrgType,
      auditor,
      formData,
      auditRequest,
      auditRequestAddInfo,
      saveResponseAndSubmit,
      saveResponseDraft,
      navigateBack,
    } = this.props;

    if (!auditRequestAddInfo) {
      return <LoadingSpinner />;
    }

    const isTopSportsPath = auditRequest.qualityPath === PathType.TOP_SPORTS;

    return (
      <div className="partcontainer">
        <div className="summaryinfo">
          <span className="clubname">{auditRequest.clubName}</span>
          <h1>{t("auditSummary.titles.auditSummary")}</h1>
          <h3>{t("auditSummary.titles.auditSummarySubtitle")}</h3>
        </div>
        {isTopSportsPath &&
          auditRequestAddInfo.allBasicCriteriaAnsweredYes !== null && (
            <>
              <hr />
              <div className="top-sports-basiccriteria-section">
                <h1>
                  {t("auditSummary.titles.topSports.basicCriteriaSubtitle")}
                </h1>
                <div className="top-sports-basiccriteria-text">
                  {/**
                   Audit summary originally indicated if the audited organization fullfilled
                   all basic criteria requirements (i.e all parts were audited 'yes').
                   It turned out that many sports federations want to have optional basic criteria
                   requirements, e.g "organization only have to fulfill 2 out of 3 basic criteria
                   requirements". For this reason, this feature is now disabled and a static text is
                   always shown.
                    t(
                    auditRequestAddInfo.allBasicCriteriaAnsweredYes
                      ? "auditSummary.titles.topSports.basicCriteriaFulfilled"
                      : "auditSummary.titles.topSports.basicCriteriaNotFulfilled"
                  )*/}
                  {t(
                    "auditSummary.titles.topSports.basicCriteriaSummaryGenericText"
                  )}
                </div>
              </div>
            </>
          )}
        <hr />
        <h1>{t("auditSummary.titles.generalOutlook")}</h1>
        <AuditGraphs
          gradeDist={auditRequestAddInfo.auditorGradeDist}
          auditor={auditor}
          enableNavigation={true}
          navigateToPathPart={this.navigateToPathPart}
        />
        <hr />
        <h1>{t("auditSummary.titles.improvementPoints")}</h1>
        <ImprovementInfo auditRequestAddInfo={auditRequestAddInfo} />
        <hr />
        <h1>{t("auditSummary.titles.auditorNotes")}</h1>
        <form onSubmit={handleSubmit} className="pathpart">
          <ExpandingContentRowHeader
            title={t("auditSummary.summary")}
            open={true}
            hideHideButton={true}
          >
            <div className="form-section">
              <Field
                label={t("auditSummary.thingsForImprovement.label")}
                helpText={this.getHelpText("auditSummary.thingsForImprovement")}
                name="thingsForImprovement"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.thingsForImprovement.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.criteriaNotMet.label")}
                helpText={this.getHelpText("auditSummary.criteriaNotMet")}
                name="criteriaNotMet"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.criteriaNotMet.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.orgparticipants.label")}
                helpText={this.getHelpText("auditSummary.orgparticipants")}
                name="orgparticipants"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.orgparticipants.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.auditparticipants.label")}
                helpText={this.getHelpText("auditSummary.auditparticipants")}
                name="auditparticipants"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.auditparticipants.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                name="auditDate"
                formCtrlClassName="summaryfield"
                label={t("auditSummary.auditDate.label")}
                placeholder={t("auditSummary.auditDate.placeholder")}
                component={DateField}
                disabled={readOnly}
              />
              <hr />
              <Fields
                helpText={this.getHelpText("auditSummary.decision")}
                className="summaryfield"
                names={["decision"]}
                component={RadioButtonGroupField}
                options={options}
                placeholder={t("auditSummary.decision.label")}
                disabled={readOnly}
                maxlength={4000}
              />
              {auditor &&
                formData &&
                formData.decision === "REJECTED_PRELIMINARY" && (
                  <Field
                    name="agreedCorrectionDate"
                    formCtrlClassName="summaryfield"
                    label={t("auditSummary.rejectDate")}
                    placeholder={t("auditSummary.auditDate.placeholder")}
                    component={DateField}
                    disabled={readOnly}
                  />
                )}
              {auditor && !commentsAdded && !readOnly && (
                <div className="nocommentshelp">
                  {t("auditSummary.NoCommentsHelp")}
                </div>
              )}
            </div>
          </ExpandingContentRowHeader>
          {auditor && readOnly && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "30px 0",
                color: "#00558b",
              }}
            >
              {t("auditSummary.sent")}
            </div>
          )}
          <div className="buttons summarybuttons">
            {auditor && !readOnly && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={saveResponseAndSubmit}
                  disabled={
                    currentOrgType === OrgType.CLUB ||
                    currentOrgType === OrgType.COLLECTION_AREA_ASSOCIATION
                  }
                >
                  {t("auditSummaryButtons.send")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={saveResponseDraft}
                  disabled={currentOrgType === OrgType.CLUB}
                >
                  {t("auditSummaryButtons.draft")}
                </button>
              </React.Fragment>
            )}
            <button
              type="button"
              className="btn btn-secondary btn-secondary-blue"
              onClick={navigateBack}
            >
              {t("auditSummaryButtons.back")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  dispatch,
}))(withRouter<any, any>(withTranslation("formsV5")(AuditSummaryV5)));
