import { createClient } from "contentful";
import { createAction, handleActions } from "redux-actions";
import * as Logger from "js-logger";
import i18nInstance from "../i18n";
const apikey = process.env.REACT_APP_CONTENTFUL_APIKEY;
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const previewKey = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN;

export const RECEIVE_ENTRIES = createAction<{ type: string; response: any }>(
  "RECEIVE_ENTRIES"
);

// create contentful api client
const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: spaceId!,
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: previewKey ? previewKey : apikey!,
  // if in dev-mode, use preview cdn. otherwise use normal cdn and normal accesstoken
  host: previewKey ? "preview.contentful.com" : "cdn.contentful.com",
});

export function fetchTools() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      if (!client) {
        return true;
      }
      // check the content_type id from client.getContentTypes or from the contentful WebUI
      // get the locale for data from i18n
      const response = await client.getEntries({
        content_type: "partTools",
        include: 4,
        locale: i18nInstance.language.replace("_", "-"),
      });
      dispatch(RECEIVE_ENTRIES({ type: "tools", response }));
      return true;
    } catch {
      Logger.warn(`Fetching tools failed`);
      return false;
    }
  };
}

export function fetchNews() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      if (!client) {
        return true;
      }
      // check the content_type id from client.getContentTypes or from the contentful WebUI
      // get the locale for data from i18n
      const response = await client.getEntries({
        content_type: "newsPage",
        "sys.id": "2TKS0z73bO6O2ueaeYEAGI",
        locale: i18nInstance.language.replace("_", "-"),
      });
      dispatch(RECEIVE_ENTRIES({ type: "news", response }));
      return true;
    } catch {
      Logger.warn(`Fetching tools failed`);
      return false;
    }
  };
}

export function fetchHelpLinks() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      if (!client) {
        return true;
      }
      // check the content_type id from client.getContentTypes or from the contentful WebUI
      // get the locale for data from i18n
      const response = await client.getEntries({
        content_type: "helpLink",
        locale: i18nInstance.language.replace("_", "-"),
      });
      dispatch(RECEIVE_ENTRIES({ type: "helpLinks", response }));
      return true;
    } catch {
      Logger.warn(`Fetching helplinks failed`);
      return false;
    }
  };
}

export function fetchKPI() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      if (!client) {
        return true;
      }
      // check the content_type id from client.getContentTypes or from the contentful WebUI
      // get the locale for data from i18n
      const response = await client.getEntries({
        content_type: "kpi",
        locale: i18nInstance.language.replace("_", "-"),
      });
      dispatch(RECEIVE_ENTRIES({ type: "kpi", response }));
      return true;
    } catch {
      Logger.warn(`Fetching kpi failed`);
      return false;
    }
  };
}

export function fetchPartTools(partId: string) {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      if (!client) {
        return true;
      }
      // check the content_type id from client.getContentTypes or from the contentful WebUI
      // get the locale for data from i18n
      const response = await client.getEntries({
        content_type: "partTools",
        "fields.type": partId,
        include: 2,
        locale: i18nInstance.language.replace("_", "-"),
      });
      dispatch(RECEIVE_ENTRIES({ type: "partTools", response }));
      return true;
    } catch {
      Logger.warn(`Fetching part tools failed`);
      return false;
    }
  };
}

export interface ContentfulState {
  entries: {
    tools: any;
    kpi: any;
    news: any;
    partTools: any;
    helpLinks: any;
  };
}

const initialState: ContentfulState = {
  entries: {
    tools: null,
    news: null,
    kpi: null,
    partTools: null,
    helpLinks: null,
  },
};

const contentfulReducer = handleActions<ContentfulState, any>(
  {
    [RECEIVE_ENTRIES as any]: (state, action) => {
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.payload.type]: action.payload.response,
        },
      };
    },
  },
  initialState
);

export default contentfulReducer;
