import React from "react";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedGradeOptions, translatedGradeRatingMap } from "../model";
import { find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";
import { TahtiseuraListField } from "../../Fields/TahtiseuraListField";
import {
  FormSectionWithContents,
  TahtiseuraFormSectionWithContentsProps,
} from "../../FormBuildHelpers";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class TopSportsSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const {
      t,
      commentEdit,
      orgId,
      formData,
      tools,
      readOnlyMode,
      formMetaInfo,
      formVersion,
      sportsFederationTranslationKeyMap,
    } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedGradeOptions(t);
    const ratingMap = translatedGradeRatingMap(t);

    const formSectionProps: Omit<
      TahtiseuraFormSectionWithContentsProps,
      "partKey" | "sectionKey"
    > = {
      orgId,
      formVersion,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
      t,
      formData,
      options,
      ratingMap,
      type,
      partThemes,
      readOnly,
      commentEdit,
    };

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedPath"
              tkey="shared.sportcontent.path"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedCoaching"
              tkey="shared.sportcontent.coaching"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedPositive"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedNegative"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <FormSectionWithContents
          {...formSectionProps}
          partKey="sportcontent"
          sectionKey="topsports.sportcontent.internationalstandards"
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey="sportcontent"
          sectionKey="topsports.sportcontent.athletespath"
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey="sportcontent"
          sectionKey="topsports.sportcontent.coachingpractices"
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey="sportcontent"
          sectionKey="topsports.sportcontent.coachingsupport"
        />
      </React.Fragment>
    );
  }
}

export default TopSportsSportContentPart as any;
