import axios from "axios";
import { ClubEnrollmentUpdate } from "../model";

const baseUrlApiV1 = "/api/v1";

const auditor = {
  getPendingAudits: (organizationId: string | number) =>
    axios.get(`${baseUrlApiV1}/auditor-view/${organizationId}/pending`),
  getFinalizedAudits: (organizationId: string | number) =>
    axios.get(`${baseUrlApiV1}/auditor-view/${organizationId}/finalized`),
  getOrgSummary: (organizationId: string | number) =>
    axios.get(`${baseUrlApiV1}/auditor-view/${organizationId}/org-summary`),
  getSingleResponse: (responseId: string) =>
    axios.get(`${baseUrlApiV1}/audit-response/${responseId}`),
  updateSingleResponse: (responseId: string, data: any) =>
    axios.put(`${baseUrlApiV1}/audit-response/${responseId}`, data),
  createSingleResponse: (data: any) =>
    axios.post(`${baseUrlApiV1}/audit-response`, data),
  submitSingleResponse: (responseId: string) =>
    axios.post(`${baseUrlApiV1}/audit-response/${responseId}/submit`),
  getByAuditRequest: (requestId: string) =>
    axios.get(`${baseUrlApiV1}/audit-response/by-audit-request/${requestId}`),
  getByAuditRequestPrevious: (requestId: string) =>
    axios.get(
      `${baseUrlApiV1}/audit-response/by-audit-request/${requestId}/previous`
    ),
  reopenRequest: (requestId: number) =>
    axios.post(`${baseUrlApiV1}/audit-request/${requestId}/reopen`),
  updateEnrollment: (
    orgId: number,
    qualityPath: string,
    data: ClubEnrollmentUpdate
  ) =>
    axios.put(`${baseUrlApiV1}/club-enrollment/${orgId}/${qualityPath}`, data),
  getRequestNotes: (orgId: string | number, qualityPath: string) =>
    axios.get(`${baseUrlApiV1}/audit-notes/${orgId}/${qualityPath}/AUDITOR`),
  saveRequestNotes: (orgId: string | number, qualityPath: string, data: any) =>
    axios.put(
      `${baseUrlApiV1}/audit-notes/${orgId}/${qualityPath}/AUDITOR`,
      data
    ),
  getAllClubsUnderOrganization: (orgId: number) =>
    axios.get(`${baseUrlApiV1}/auditor-clubs-view/${orgId}/clubs`),
  getSingleClub: (clubId: number) =>
    axios.get(`${baseUrlApiV1}/auditor-clubs-view/club/${clubId}`),
};

export default auditor;
