import { createAction, handleActions } from "redux-actions";
import Api from "../api";
import * as Logger from "js-logger";
import { AuditRequest, ClubEnrollment } from "../model";
import { CancelToken } from "axios";
import { SET_AUDIT_REQUEST_PART } from "./auditRequest";

export const SET_ATTACHMENT_MODAL_DATA = createAction<AttachmentModalData>(
  "SET_ATTACHMENT_MODAL_DATA"
);
export const ADD_ORG_ATTACHMENT = createAction<Attachment>(
  "ADD_ORG_ATTACHMENT"
);
export const GET_ORG_ATTACHMENT = createAction<Attachment[]>(
  "GET_ORG_ATTACHMENT"
);
export const GET_PART_ATTACHMENTS = createAction<{
  [fieldName: string]: Attachment[];
}>("GET_PART_ATTACHMENTS");
export const RECEIVE_PART_ATTACHMENTS = createAction<{
  fieldName: string;
  ids: number[];
}>("RECEIVE_PART_ATTACHMENTS");
export const REMOVE_PART_ATTACHMENT = createAction<{
  fieldName: string;
  id: number;
}>("REMOVE_PART_ATTACHMENT");
export const REMOVE_ORG_ATTACHMENT = createAction<number>(
  "REMOVE_ORG_ATTACHMENT"
);
export const LINK_PART_ATTACHMENTS = createAction<{
  fieldName: string;
  attachments: Attachment[];
}>("LINK_PART_ATTACHMENTS");

export function fetchOrgAttachments(orgId: string) {
  return async (dispatch): Promise<Attachment[]> => {
    try {
      const response = await Api.attachment.getOrgAttachments(orgId);
      dispatch(GET_ORG_ATTACHMENT(response.data));
      return response.data;
    } catch (err) {
      Logger.warn("fetching org attachments failed");
      return [];
    }
  };
}

export function uploadAttachment(
  file: File,
  orgId: string,
  cancelToken: CancelToken
): (dispatch) => Promise<Attachment> {
  return async (dispatch): Promise<Attachment> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("contentType", file.type);
      const response = await Api.attachment.postAttachment(
        orgId,
        formData,
        cancelToken
      );
      dispatch(ADD_ORG_ATTACHMENT(response.data));
      return response.data;
    } catch (err) {
      Logger.warn("uploading attachment failed");
      if (err.response && err.response.status === 409) {
        throw new Error("NAMECONFLICT");
      } else {
        throw new Error();
      }
    }
  };
}

export function fetchPartAttachments(requestId: number, partKey: string) {
  return async (dispatch): Promise<{ [fieldName: string]: Attachment[] }> => {
    try {
      const response = await Api.attachment.getPartAttachments(
        requestId,
        partKey
      );
      dispatch(GET_PART_ATTACHMENTS(response.data));
      return response.data;
    } catch (err) {
      Logger.warn("fetching part attachments failed");
      return {};
    }
  };
}

export function linkAttachments(
  enrollmentId: number,
  requestId: number,
  partKey: string,
  fieldName: string,
  ids: number[]
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.attachment.linkPartAttachments(
        requestId,
        partKey,
        fieldName,
        ids
      );
      dispatch(
        SET_AUDIT_REQUEST_PART({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      dispatch(fetchPartAttachments(requestId, partKey));
      return true;
    } catch (err) {
      Logger.warn("linking attachments to field failed");
      throw new Error();
    }
  };
}

export function removeAttachment(orgId: string, fileId: string | number) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.attachment.deleteAttachment(orgId, fileId);
      dispatch(fetchOrgAttachments(orgId));
      return true;
    } catch (err) {
      Logger.warn("removing attachment from organization failed");
      if (err.response && err.response.status === 409) {
        throw new Error("LOCKED");
      } else {
        throw new Error();
      }
    }
  };
}

export function updateAttachment(
  orgId: string,
  fileId: string | number,
  fileName: string
) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.attachment.putAttachment(orgId, fileId, fileName);
      dispatch(fetchOrgAttachments(orgId));
      return true;
    } catch (err) {
      Logger.warn("renaming org attachment failed");
      return false;
    }
  };
}

export interface Attachment {
  id: number;
  name: string;
  creationTimestamp: string;
  modificationTimestamp: string;
  lastUsedTimestamp: string;
  url: string;
}

export interface AttachmentModalData {
  modalOpen: boolean;
  partKey?: string;
  requestId?: number;
  fieldName?: string;
  orgId?: string;
  showLinkTable: boolean;
  auditRequest?: AuditRequest;
  enrollment?: ClubEnrollment;
}

export interface AttachmentState {
  modalData: AttachmentModalData;
  orgAttachments: Attachment[];
  partAttachments: { [fieldName: string]: Attachment[] };
}

const initialState: AttachmentState = {
  modalData: {
    modalOpen: false,
    fieldName: undefined,
    showLinkTable: false,
  },
  orgAttachments: [],
  partAttachments: {},
};

const attachmentReducer = handleActions<AttachmentState, any>(
  {
    [SET_ATTACHMENT_MODAL_DATA as any]: (state, action) => ({
      ...state,
      modalData: action.payload,
    }),
    [GET_ORG_ATTACHMENT as any]: (state, action) => ({
      ...state,
      orgAttachments: action.payload,
    }),
    [ADD_ORG_ATTACHMENT as any]: (state, action) => ({
      ...state,
      orgAttachments: state.orgAttachments.concat(action.payload),
    }),
    [GET_PART_ATTACHMENTS as any]: (state, action) => ({
      ...state,
      partAttachments: action.payload,
    }),
    [LINK_PART_ATTACHMENTS as any]: (state, action) => ({
      ...state,
      partAttachments: {
        ...state.partAttachments,
        [action.payload.fieldName]: action.payload.attachments,
      },
    }),
    [REMOVE_PART_ATTACHMENT as any]: (state, action) => {
      const attachments = state.partAttachments[action.payload.fieldName];
      const filteredAttachments = attachments.filter(
        (x) => x.id !== action.payload.id
      );
      return {
        ...state,
        partAttachments: {
          ...state.partAttachments,
          [action.payload.fieldName]: filteredAttachments,
        },
      };
    },
    [REMOVE_ORG_ATTACHMENT as any]: (state, action) => {
      return {
        ...state,
        orgAttachments: state.orgAttachments.filter(
          (x) => x.id !== action.payload
        ),
      };
    },
  },
  initialState
);

export default attachmentReducer;
