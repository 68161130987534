import { get } from "lodash";

export const isInvalid = (names, props) => {
  let retVal: boolean = false;

  names &&
    names.forEach((name) => {
      // tslint:disable
      const data = (get(props, name) as any) || props;

      if (
        data &&
        data.meta &&
        data.meta.error &&
        data.meta.invalid &&
        (data.meta.dirty || data.meta.touched)
      ) {
        retVal = true;
      }
    });

  return retVal;
};

export const errorText = (names, props) => {
  return (
    names &&
    names
      .map((name) => {
        const data = get(props, name) as any;
        return get(props, "meta.error") || get(data, "meta.error");
      })
      .filter((val, index, a) => a.indexOf(val) === index) // Take unique errors
      .sort()
  );
};
