import React from "react";
// import { PartStatus } from '../../model';
import * as classNames from "classnames";
import { v4 } from "uuid";
import "./Tables.scss";
import basicCriteriaNotImg from "../../images/path/icon_peruskriteerit_aloittamatta.svg";
import sportcontentNotImg from "../../images/path/icon_urheilutoiminta_aloittamatta.svg";
import peopleNotImg from "../../images/path/icon_ihmiset_aloittamatta.svg";
import managementNotImg from "../../images/path/icon_hallinto_aloittamatta.svg";
import resourcesNotImg from "../../images/path/icon_resurssit_aloittamatta.svg";
import basicCriteriaDraftImg from "../../images/path/icon_peruskriteerit_aloitettu.svg";
import sportcontentDraftImg from "../../images/path/icon_urheilutoiminta_aloitettu.svg";
import peopleDraftImg from "../../images/path/icon_ihmiset_aloitettu.svg";
import managementDraftImg from "../../images/path/icon_hallinto_aloitettu.svg";
import resourcesDraftImg from "../../images/path/icon_resurssit_aloitettu.svg";
import basicCriteriaReadyImg from "../../images/path/icon_peruskriteerit_valmis.svg";
import sportcontentReadyImg from "../../images/path/icon_urheilutoiminta_valmis.svg";
import peopleReadyImg from "../../images/path/icon_ihmiset_valmis.svg";
import managementReadyImg from "../../images/path/icon_hallinto_valmis.svg";
import resourcesReadyImg from "../../images/path/icon_resurssit_valmis.svg";

const iconMapping = {
  basiccriteria: {
    not: basicCriteriaNotImg,
    draft: basicCriteriaDraftImg,
    ready: basicCriteriaReadyImg,
  },
  sportcontent: {
    not: sportcontentNotImg,
    draft: sportcontentDraftImg,
    ready: sportcontentReadyImg,
  },
  management: {
    not: managementNotImg,
    draft: managementDraftImg,
    ready: managementReadyImg,
  },
  people: {
    not: peopleNotImg,
    draft: peopleDraftImg,
    ready: peopleReadyImg,
  },
  resources: {
    not: resourcesNotImg,
    draft: resourcesDraftImg,
    ready: resourcesReadyImg,
  },
};

export const PartStatusCell = (parts: any) => {
  const order = [
    "basiccriteria",
    "sportcontent",
    "management",
    "people",
    "resources",
  ];
  if (!parts) {
    return null;
  }
  const orderedparts = parts.slice().sort((a: any, b: any) => {
    return order.indexOf(a.key) - order.indexOf(b.key);
  });
  return (
    <div className="parts" key={v4()}>
      {orderedparts.map((value) => {
        let partStatus = "not";
        switch (value.state) {
          case "READY":
          case "AWAITING_REVISION":
            partStatus = "ready";
            break;
          case "DRAFT":
            partStatus = "draft";
            break;
          default:
            break;
        }
        const partcircleclasses = classNames("partcircle", {
          revision: value.state === "AWAITING_REVISION",
          ready: value.state === "READY",
          draft: value.state === "DRAFT",
          not: value.state === "NOT_STARTED",
        });
        return (
          <img
            key={v4()}
            className={partcircleclasses}
            src={iconMapping[value.key][partStatus]}
            alt="Part Status"
          />
        );
      })}
    </div>
  );
};
