import { connect } from "react-redux";
import React from "react";
import { Component } from "react";
import "./CommentField.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { DescriptionModalState } from "../../../model";
import { ReduxState } from "../../../reducers";
import pencilsvg from "../../../images/pencil-icon.svg";
import { SET_DESCRIPTION_MODAL_STATE } from "../../../modules/auditRequest";
import { formValueSelector } from "redux-form";
import Linkify from "react-linkify";

interface ComponentProps {
  form: string;
  name: string;
  edit: boolean;
}

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  const selector = formValueSelector(ownProps.form);
  return {
    value: selector(state, ownProps.name),
  };
};

const mapDispatchToProps = (dispatch, ownProps: any) => ({
  openModal: (data: DescriptionModalState) =>
    dispatch(SET_DESCRIPTION_MODAL_STATE(data)),
});

type DescriptionFieldProps = ComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class DescriptionField extends Component<DescriptionFieldProps, any> {
  constructor(props: DescriptionFieldProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleModalOpen = (event: any) => {
    if (event && event.target && event.target.tagName === "A") {
      return;
    }
    const props = this.props;
    this.props.openModal({
      show: true,
      form: props.form,
      field: props.name,
      value: props.value,
    });
  };

  render() {
    const { edit, value, t } = this.props;
    return (
      <div className="descriptionfield">
        {!edit && (
          <div className="commentfield">
            <div className="descriptioncontainer">
              {value && (
                <Linkify
                  properties={{ target: "_blank", rel: "noopener noreferrer" }}
                  className="userdescription"
                >
                  <p>
                    <b>{t("description.text")}</b>
                    {value}
                  </p>
                </Linkify>
              )}
            </div>
          </div>
        )}
        {edit && value && (
          <div className="commentfield" onClick={this.handleModalOpen}>
            <div className="descriptioncontainer hoverborder">
              <img src={pencilsvg} alt="" />
              {value && (
                <Linkify
                  properties={{ target: "_blank", rel: "noopener noreferrer" }}
                  className="userdescription"
                >
                  <p>
                    <b>{t("description.text")}</b>
                    {value}
                  </p>
                </Linkify>
              )}
            </div>
          </div>
        )}
        {edit && !value && (
          <div className="descriptioncontainer">
            <div
              className="addcomment hoverborder"
              onClick={this.handleModalOpen}
            >
              <img src={pencilsvg} alt="" />+ {t("description.add")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(DescriptionField));
