import { createAction, handleActions } from "redux-actions";
import Api from "../api";
import { ReduxState } from "../reducers";
import { AccessOrg, OrgType } from "../model";
import { orderBy } from "lodash";
import i18next from "i18next";
import * as Logger from "js-logger";
import { SET_LOCALE_SUCCESS } from "./i18n";

export const AUTHENTICATE_SUCCESS = createAction<UserData>(
  "AUTHENTICATE_SUCCESS"
);
export const AUTHENTICATE_FAILURE = createAction("AUTHENTICATE_FAILURE");
export const SET_AUTHENTICATING = createAction<boolean>("SET_AUTHENTICATING");
export const SET_CURRENT_ORG = createAction<number>("SET_CURRENT_ORG");
export const SET_USER_PREFERENCES_SUCCESS = createAction<UserPreferences>(
  "SET_USER_PREFERENCES_SUCCESS"
);
export const SET_USER_PREFERENCES_FAILURE = createAction(
  "SET_USER_PREFERENCES_FAILURE"
);

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function authenticateUser() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      let state: ReduxState = getState();
      // Wait for previous account call to finish
      while (state.account.authenticating) {
        await sleep(500);
        state = getState();
      }

      dispatch(SET_AUTHENTICATING(true));
      const response = await Api.account.authenticate();
      const writeAccessOrgs = orderBy(
        response.data.writeAccessOrgs,
        ["type", "orgId"],
        ["desc", "asc"]
      );
      const readAccessOrgs = orderBy(
        response.data.readAccessOrgs,
        ["type", "orgId"],
        ["desc", "asc"]
      );

      // Do not use the locale reported by the backend, since the user might have changed it before logging in.
      const { locale, ...responseDataWithoutLocale } = response.data;
      dispatch(
        AUTHENTICATE_SUCCESS({
          ...responseDataWithoutLocale,
          locale: i18next.language,
          writeAccessOrgs: writeAccessOrgs,
          readAccessOrgs: readAccessOrgs,
        })
      );
      dispatch(SET_AUTHENTICATING(false));

      // Correct the language setting of the backend in case it's not up-to-date. The user might have changed the UI
      // language before logging in.
      try {
        if (i18next.language && response.data.locale !== i18next.language) {
          Api.i18n.setLocale(i18next.language);
        }
      } catch (err) {
        Logger.warn("Failed to set the backend language setting");
      }
    } catch (err) {
      dispatch(AUTHENTICATE_FAILURE());
      dispatch(SET_AUTHENTICATING(false));
    }

    return true;
  };
}

export function login() {
  return Api.account.login(i18next.language);
}

export function loginRedirect(redirect: string) {
  return Api.account.loginRedirect(redirect, i18next.language);
}

export function logout() {
  return Api.account.logout(i18next.language);
}

export function setUserPreferences(userPreferences: UserPreferences) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.account.setUserPreferences(userPreferences);
      dispatch(SET_USER_PREFERENCES_SUCCESS(response.data.userPreferences));
      return true;
    } catch (err) {
      Logger.warn("Failed to set user preferences", err);
      dispatch(SET_USER_PREFERENCES_FAILURE());
      return false;
    }
  };
}

export interface UserPreferences {
  hideClubDevelopmentIntro: boolean;
}

export interface UserData {
  firstName: string;
  lastName: string;
  userId: number;
  email: string;
  phoneNumber: string;
  readAccessOrgs: AccessOrg[];
  writeAccessOrgs: AccessOrg[];
  userPreferences?: UserPreferences;
  locale?: string;
}

export interface UserAccount {
  authenticating?: boolean;
  user: UserData | null;
  currentOrg: number | null;
  currentOrgType: OrgType | null;
}

const initialState: UserAccount = {
  authenticating: false,
  user: null,
  currentOrg: null,
  currentOrgType: null,
};

const authReducer = handleActions<UserAccount, any>(
  {
    [AUTHENTICATE_SUCCESS as any]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [AUTHENTICATE_FAILURE as any]: (state, action) => initialState,
    [SET_AUTHENTICATING as any]: (state, action) => ({
      ...state,
      authenticating: action.payload,
    }),
    [SET_CURRENT_ORG as any]: (state, action) => {
      const foundOrg = state.user
        ? state.user.writeAccessOrgs.find((x) => x.orgId === action.payload)
        : null;
      if (!foundOrg) {
        return { ...state, currentOrg: action.payload };
      } else {
        return {
          ...state,
          currentOrg: action.payload,
          currentOrgType: OrgType[foundOrg.type],
        };
      }
    },
    [SET_USER_PREFERENCES_SUCCESS as any]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        userPreferences: action.payload,
      },
    }),
    [SET_LOCALE_SUCCESS as any]: (state, action) => ({
      ...state,
      // Update the locale in the user details only if we have user details set, i.e. are logged in.
      // Otherwise we'd be setting user details and thus signalling that we'd have a logged in user.
      ...(state.user
        ? {
            user: {
              ...state.user,
              locale: action.payload,
            },
          }
        : {}),
    }),
  },
  initialState
);

export default authReducer;
