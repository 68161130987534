import { RequestPartMetaInfo } from "./auditmetainfo";
import { createAction, handleActions } from "redux-actions";
import Api from "../api";
import * as Logger from "js-logger";
import Alert from "react-s-alert";

export interface AuditFormMetaInfo {
  parts: RequestPartMetaInfo[];
  federationId: number;
}

export const SET_AUDIT_FORM_METAINFO_STATE = createAction<{
  orgId: number;
  auditFormMetaInfo: AuditFormMetaInfo;
}>("SET_AUDIT_FORM_METAINFO_STATE");

export function getOrganizationAuditFormMetaInfo(orgId: number) {
  return async (dispatch): Promise<boolean> => {
    try {
      const auditFormMetaInfo = (
        await Api.formMetaInfo.getAuditFormMetaInfo(orgId)
      ).data;
      dispatch(
        SET_AUDIT_FORM_METAINFO_STATE({
          orgId,
          auditFormMetaInfo,
        })
      );
      return true;
    } catch (err) {
      Logger.warn("fetching audit metainfo failed");
      Alert.error("common:auditMetaInfo.fetchingError");
      return false;
    }
  };
}

export interface AuditFormMetaInfoState {
  organizationAuditFormMetaInfo: Map<number, AuditFormMetaInfo>;
}

const auditFormMetaInfoInitialState: AuditFormMetaInfoState = {
  organizationAuditFormMetaInfo: new Map<number, AuditFormMetaInfo>(),
};

const auditFormMetaInfoReducer = handleActions<AuditFormMetaInfoState, any>(
  {
    [SET_AUDIT_FORM_METAINFO_STATE as any]: (state, action) => {
      return {
        ...state,
        organizationAuditFormMetaInfo: {
          ...state.organizationAuditFormMetaInfo,
          [action.payload.orgId]: action.payload.auditFormMetaInfo,
        },
      };
    },
  },
  auditFormMetaInfoInitialState
);

export default auditFormMetaInfoReducer;
