import { Action } from "redux-actions";
import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import { appReducer } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

/**
 * Create a logger interface for development purposes. Displays state related
 * information in the console.
 */
const createReduxLogger = () => {
  // Log all actions except redux-form actions (there are A LOT of them..)
  return createLogger({
    collapsed: false,
    predicate: (getState, action: Action<any>) =>
      !action.type.includes("redux-form"),
    timestamp: false,
  });
};

/**
 * Configure the redux store instance and add middlewares. Saves the state to
 * localStorage on an interval of 1s aswell.
 */
const configureStore = () => {
  const middlewares: any[] = [thunk as any];

  // If we are in development mode, use logger
  if (process.env.NODE_ENV === "development") {
    middlewares.push(createReduxLogger());
  }

  // if (process.env.NODE_ENV === 'production') {
  //   const sentryDsn = SentryErrorLogger.getSentryDsn();
  //   middlewares.push(
  //     RavenMiddleware(
  //       sentryDsn,
  //       {},
  //       {
  //         stateTransformer: state => state && state.toJS()
  //       }
  //     )
  //   );
  // }

  const store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  return store;
};

export default configureStore;
