/**
 * Sets up js-logger for debugging purposes. In development mode
 * the logger will output debug messages. In production, it will be
 * off.
 *
 * By default chrome hides .DEBUG level messages in the console
 */

import * as Logger from "js-logger";

export function SetupJSLogger() {
  Logger.useDefaults();

  if (process.env.NODE_ENV === "development") {
    Logger.setLevel(Logger.DEBUG);
  } else {
    Logger.setLevel(Logger.WARN);
  }
}
