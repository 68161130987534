import i18next from "i18next";
import { LocaleUtils } from "../../i18n/LocaleUtils";
import { UserData } from "../../modules/account";

/** map the locales supported by the Freshdesk widget to our languages */
const i18nLangToSupportLocale = {
  [LocaleUtils.localeDefaults.fi]: "fi",
  [LocaleUtils.localeDefaults.sv]: "sv-SE",
  [LocaleUtils.localeDefaults.en]: "en",
};

const defaultSupportLocale = "fi";

export function prepareWidgetAPI(supportWidgetId: number, i18nLang: string) {
  const supportLocale =
    (i18nLang && i18nLangToSupportLocale[i18nLang]) || defaultSupportLocale;

  // the remaining function's code is provided by Freshworks
  (window as any).fwSettings = {
    widget_id: supportWidgetId,
    locale: supportLocale,
  };

  !(function (): any /* eslint-disable-line */ {
    if ("function" != typeof (window as any).FreshworksWidget) {
      let n = function () {
        (n as any).q.push(arguments);
      };
      /* eslint-disable-next-line */
      ((n as any).q = []), ((window as any).FreshworksWidget = n);
    }
  })();
}

function translateWidgetLabels(lng: string) {
  const t = (key, lng) => i18next.t(`supportWidget:${key}`, { lng });

  return {
    banner: t("banner", lng),
    launcher: t("launcher", lng),
    contact_form: {
      title: t("contact_form.title", lng),
      submit: t("contact_form.submit", lng),
      confirmation: t("contact_form.confirmation", lng),
    },
  };
}

export function applyWidgetConfiguration(userDetails: UserData) {
  const FreshworksWidget = (window as any).FreshworksWidget;

  const fullName = !userDetails.firstName
    ? userDetails.lastName
    : !userDetails.lastName
    ? userDetails.firstName
    : `${userDetails.firstName} ${userDetails.lastName}`.trim();

  FreshworksWidget("setLabels", {
    // Finnish translations set as default in Freshwork's widget configuration
    en: translateWidgetLabels(LocaleUtils.localeDefaults.en),
    "sv-SE": translateWidgetLabels(LocaleUtils.localeDefaults.sv),
  });

  FreshworksWidget("identify", "ticketForm", {
    ...(fullName ? { name: fullName } : {}),
    ...(userDetails.email ? { email: userDetails.email } : {}),
  });
}

export function destroyWidget() {
  try {
    // The destroy command will throw if the widget code is already loaded (from the previous round), but the
    // newly loaded widget is not yet mounted.
    (window as any).FreshworksWidget("destroy");
    return true;
  } catch (err) {
    return false;
  }
}
