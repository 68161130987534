import React, { useEffect } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { paths, paths as routePaths } from "./routePaths";
import { ClubPageHeader } from "./views/organization/ClubPageHeader";
import { ClubPage } from "./views/organization/ClubPage";
import { ReduxState } from "./reducers";
import { fetchSingleClub } from "./modules/auditor";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import Development from "./views/clubdevelopment/Development";
import { ClubInformation } from "./model";
import { ClubAuditArchive } from "./views/organization/ClubAuditArchive";

interface OwnProps {
  club: ClubInformation;
  fetchSingleClub(orgId: number): any;
}

const OrganizationClubPathRouterComponent: React.FC<
  OwnProps &
    RouteComponentProps<{
      superOrgId: string;
      orgId: string;
    }>
> = (props) => {
  const {
    match: {
      params: { superOrgId, orgId },
    },
    fetchSingleClub,
  } = props;

  useEffect(() => {
    async function fetchSingleClubData() {
      if (orgId && superOrgId) {
        fetchSingleClub(parseInt(orgId));
      }
    }
    fetchSingleClubData();
  }, [superOrgId, orgId, fetchSingleClub]);

  const { club } = props;

  return (
    <>
      <Route
        path={routePaths.organizationClubPath}
        render={(props) => <ClubPageHeader {...props} club={club} />}
      />
      <Route
        exact={true}
        path={routePaths.organizationClubPath}
        render={() => <ClubPage />}
      />
      <Route
        exact={true}
        path={routePaths.organizationClubPath + "/archive"}
        render={(props) =>
          club && <ClubAuditArchive {...props} clubId={club.orgId} />
        }
      />
      <Route
        exact={true}
        path={paths.organizationClubPath + "/development"}
        render={(props) => (
          <Development {...props} readOnly={true} disableHelpLink={true} />
        )}
      />
    </>
  );
};

const mapStateToProps = (
  state: ReduxState,
  props: OwnProps &
    RouteComponentProps<{
      superOrgId: string;
      orgId: string;
    }>
) => {
  const {
    match: {
      params: { orgId },
    },
  } = props;
  return orgId
    ? {
        club: state.auditor.auditorClubs[orgId],
      }
    : {};
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleClub: (orgId) => dispatch(fetchSingleClub(orgId)),
});

export const OrganizationClubPathRouter = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("common")(OrganizationClubPathRouterComponent as any))
);
