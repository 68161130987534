import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import "../dashboard/ClubDashboard.scss";
import {
  CLEAR_AUDIT_REQUEST_ADD_INFO,
  fetchSingleAuditRequestAudit,
} from "../../modules/auditRequest";
import { RouteComponentProps, withRouter } from "react-router";
import PathInfoAudit from "../../components/PathInfo/PathInfoAudit";
import { fetchResponseByAuditRequest } from "../../modules/auditor";
import { LoadingSpinner } from "../../components/LoadingSpinner";

interface PathState {
  loading: boolean;
}

type PathProps = WithTranslation &
  RouteComponentProps<{ orgId: string; requestId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Path extends Component<PathProps, PathState> {
  constructor(props: PathProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.fetchSingleAuditRequestAudit(
        this.props.match.params.requestId
      ),
      this.props.fetchResponseByAuditRequest(),
    ]);
    this.setState({ loading: false });

    this.props.clearAuditRequestAddInfo();
  }

  render() {
    const { auditRequest, paths, currentResponse, t } = this.props;
    const { loading } = this.state;
    if (!auditRequest) {
      return null;
    }

    const path = paths.find((x) => x.type === auditRequest.qualityPath);
    let helpText = t("auditHelpAuditing");
    const responseMatches =
      currentResponse &&
      auditRequest &&
      currentResponse.auditRequestId === auditRequest.id;
    if (responseMatches) {
      if (currentResponse && currentResponse.submitted) {
        if (
          currentResponse.decision === "ACCEPTED" ||
          currentResponse.decision === "REJECTED"
        ) {
          // final decision -> no further modifications possible
          helpText = t("auditHelpFinished");
        } else {
          helpText = t("auditHelpSubmitted");
        }
      }
    }

    return (
      <div className="pathspage">
        <div className="auditpathhelp">{helpText}</div>
        <h3 className="clubname">
          {auditRequest ? auditRequest.clubName : null}
        </h3>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <PathInfoAudit
            key={auditRequest.id}
            data={path}
            auditRequest={auditRequest}
            isArchived={false}
          />
        )}
      </div>
    );
  }
}

const translated = withTranslation("common")(Path);

const mapStateToProps = (state: ReduxState) => ({
  paths: state.path.paths,
  auditRequest: state.auditRequest.auditRequest,
  currentResponse: state.auditor.currentResponse,
});
const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  fetchSingleAuditRequestAudit: (requestId: string) =>
    dispatch(fetchSingleAuditRequestAudit(requestId)),
  fetchResponseByAuditRequest: () =>
    dispatch(fetchResponseByAuditRequest(ownProps.match.params.requestId)),
  clearAuditRequestAddInfo: () => dispatch(CLEAR_AUDIT_REQUEST_ADD_INFO()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translated)
);
