export const translatedOptions = (t) => [
  { value: "INSUFFICIENT", label: t("options.insufficient") },
  { value: "SUFFICIENT", label: t("options.sufficient") },
  { value: "GOOD", label: t("options.good") },
  { value: "EXCELLENT", label: t("options.excellent") },
  { value: "NOTAPPLICABLE", label: t("options.notapplicable") },
];

export const translatedRatingMap = (t) => {
  return {
    INSUFFICIENT: t("ratingMap.insufficient"),
    SUFFICIENT: t("ratingMap.sufficient"),
    GOOD: t("ratingMap.good"),
    EXCELLENT: t("ratingMap.excellent"),
    NOTAPPLICABLE: t("ratingMap.notapplicable"),
  };
};
