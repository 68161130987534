import React from "react";
import { Component } from "react";
import { isEqual } from "lodash";

import "./CommentModal.scss";
import { FormGroup, Modal, FormControl, ControlLabel } from "react-bootstrap";
import { CommentMode } from "../../modules/comment";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";

import { ReduxState } from "../../reducers";
import {
  CLOSE_COMMENT_MODAL,
  CommentModalState,
  UPDATE_COMMENT,
} from "../../modules/comment";

type CommentModalProps = { ratingMap: any; options: any } & WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// mounted in App.tsx
class CommentModal extends Component<CommentModalProps, any> {
  constructor(props: CommentModalProps) {
    super(props);
    this.state = {
      open: false,
      inputValue: this.props.modalData.comment
        ? this.props.modalData.comment
        : "",
      // auditValue stored as numerical value only, need to use ratingMap to get the proper label
      auditValue: this.props.modalData.audit
        ? {
            value: this.props.modalData.audit,
            label: this.props.ratingMap[this.props.modalData.audit],
          }
        : {},
    };
  }

  componentWillReceiveProps(newProps: CommentModalProps) {
    // reset state if multiple comment modals are opened in succession
    if (!isEqual(newProps.modalData.comment, this.props.modalData.comment)) {
      this.setState({
        inputValue: newProps.modalData.comment
          ? newProps.modalData.comment
          : "",
      });
    }
    if (!isEqual(newProps.modalData.audit, this.props.modalData.audit)) {
      this.setState({
        auditValue: newProps.modalData.audit
          ? {
              value: newProps.modalData.audit,
              label: newProps.ratingMap[newProps.modalData.audit],
            }
          : {},
      });
    }
  }

  onAuditChange = (selectedOption: any) => {
    this.setState({ auditValue: selectedOption });
  };

  handleCommentUpdate = () => {
    const { modalData } = this.props;
    // need to set comment and audit value separately to keep the data as simple as possible
    this.props.submitComment({
      ...modalData,
      comment: this.state.inputValue,
      fieldName: modalData.fieldName + "_comment",
    });
    this.props.submitComment({
      ...modalData,
      comment: this.state.auditValue ? this.state.auditValue.value : null,
      fieldName: modalData.fieldName + "_audit",
    });
    // Event for PartFormWrapper, update the field value right away
    const fieldUpdateEvent = new CustomEvent("updateComments", {
      detail: {
        [modalData.fieldName + "_comment"]: this.state.inputValue,
        [modalData.fieldName + "_audit"]: this.state.auditValue
          ? this.state.auditValue.value
          : null,
      },
    });
    window.dispatchEvent(fieldUpdateEvent);
    this.closeModal();
  };

  handleInputValue = (event: any) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  closeModal = () => {
    this.setState(
      { inputValue: "", auditValue: null },
      this.props.onCloseModal
    );
  };

  render() {
    const { modalData, t } = this.props;
    const { commentMode, showAudit, fieldTranslated } = modalData;
    // take modal data from state and show commentbox and/or audit dropdown, depending on options.
    // controlled from AuditField and the props it gets.
    return (
      <div>
        <Modal
          show={modalData.show}
          onHide={this.closeModal}
          dialogClassName="comment-modal"
        >
          <Modal.Body>
            <h1>{fieldTranslated}</h1>

            {commentMode === CommentMode.Field &&
              modalData.fieldValue !== undefined && (
                <div className="fieldinfo">
                  <div className="fieldvalue">
                    <label>{t("modal.value")}</label>
                    <p>
                      {modalData.fieldValue
                        ? modalData.fieldValue
                        : t("modal.empty")}
                    </p>
                  </div>
                </div>
              )}

            {showAudit && (
              <div className="auditdropdown">
                <div className="text">{t("audit.text")}</div>
                <Select
                  name="form-field-name"
                  value={this.state.auditValue}
                  onChange={this.onAuditChange}
                  options={this.props.options}
                  placeholder={t("audit.placeholder")}
                />
              </div>
            )}

            {modalData.showComment && (
              <div className="commentfield">
                <form>
                  <FormGroup>
                    <ControlLabel>
                      {t(`modal.${commentMode}.label`)}
                    </ControlLabel>
                    <FormControl
                      autoFocus={true}
                      componentClass="textarea"
                      value={this.state.inputValue}
                      placeholder={t("modal.placeholder")}
                      onChange={this.handleInputValue}
                      maxLength={2000}
                    />
                  </FormGroup>
                </form>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={this.handleCommentUpdate}
            >
              {t("modal.saveComment")}
            </button>
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={this.props.onCloseModal}
            >
              {t("modal.close")}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const commentTranslated = withTranslation("common")(CommentModal);

const mapStateToProps = (state: ReduxState) => {
  return {
    modalData: state.comment.commentModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => dispatch(CLOSE_COMMENT_MODAL()),
  submitComment: (data: CommentModalState) => dispatch(UPDATE_COMMENT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(commentTranslated);
