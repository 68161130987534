import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import "../dashboard/ClubDashboard.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { fetchSingleAuditRequestAudit } from "../../modules/auditRequest";
import { AUDITOR_MODIFIABLE_PART_STATES, PartStatus } from "../../model";
import PathFormV1 from "../../components/Forms/V1/PathFormV1";
import PathFormV2 from "../../components/Forms/V2/PathFormV2";
import PathFormV3 from "../../components/Forms/V3/PathFormV3";
import PathFormV4 from "../../components/Forms/V4/PathFormV4";
import PathFormV5 from "../../components/Forms/V5/PathFormV5";
import { translateWithMarkdown } from "../../util/translateWithMarkdown";
import { getOrganizationAuditFormMetaInfo } from "../../modules/formMetaInfo";
import {
  GradeOptionsList,
  YesNoOptionsList,
} from "../../components/Forms/Components/FieldGradingOptionList";

type PathFormRouteComponentProps = RouteComponentProps<{
  orgId: string;
  part: string;
  type: string;
  requestId: string;
}>;

type PathFormProps = PathFormRouteComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class PathForm extends Component<PathFormProps, any> {
  constructor(props: PathFormProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      auditRequest,
      fetchSingleAuditRequestAudit,
      getAuditFormMetaInfo,
      match: {
        params: { requestId },
      },
    } = this.props;
    if (!auditRequest) {
      await fetchSingleAuditRequestAudit(requestId);
    }

    // In normal case we could get the federation's/area association's id from url (orgId) and fetch
    // the audit form meta info with that, but when user is using the admin organization this does not
    // work as we cannot connect the admin org's id to correct form data in backend, so we await that
    // audit request data is available and fetch the data using the club's id.
    getAuditFormMetaInfo(auditRequest?.clubId!);
  }

  render() {
    const {
      match,
      auditRequest,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
    } = this.props;
    if (!auditRequest) {
      return null;
    }
    const t = translateWithMarkdown(this.props.t);
    const currentOrg = parseInt(match.params.orgId, 10);
    const readOnlyMode = true;
    const formPart = match.params.part;
    const part = auditRequest.parts.find((x) => x.key === formPart);
    const partState = part ? (PartStatus[part.state] as PartStatus) : null;
    const commentEdit =
      auditRequest.modifiableByAuditor &&
      partState != null &&
      AUDITOR_MODIFIABLE_PART_STATES.includes(partState);

    const partIsBasicCriteria = match.params.part === "basiccriteria";

    return (
      <div className="partcontainer">
        <h1>{t(`${formPart}.title`)}</h1>
        <p className="description">
          {t("part.generalDescriptionOrg", { markdown: { multiline: true } })}
        </p>
        {!partIsBasicCriteria ? <GradeOptionsList /> : <YesNoOptionsList />}
        {auditRequest.formVersion === 1 ? (
          <PathFormV1
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 2 ? (
          <PathFormV2
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 3 ? (
          <PathFormV3
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 4 ? (
          <PathFormV4
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : (
          <PathFormV5
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
            formMetaInfo={formMetaInfo}
            sportsFederationTranslationKeyMap={
              sportsFederationTranslationKeyMap
            }
          />
        )}
      </div>
    );
  }
}

const translated = withTranslation("forms")(PathForm);

const mapStateToProps = (state: ReduxState) => {
  const auditRequest = state.auditRequest.auditRequest;
  return {
    auditRequest: auditRequest,
    formMetaInfo: auditRequest
      ? state.organizationAuditFormMetaInfo?.organizationAuditFormMetaInfo[
          auditRequest.clubId
        ]
      : undefined,
    sportsFederationTranslationKeyMap:
      state.config.topSportsSportsFederationNameMap,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleAuditRequestAudit,
    getAuditFormMetaInfo: (orgId: number) =>
      dispatch(getOrganizationAuditFormMetaInfo(orgId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translated as any)
);
