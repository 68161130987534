import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DevelopmentItemPropsModal } from "../../components/DevelopmentItemPropsModal/DevelopmentItemPropsModal";
import { DevelopmentItem } from "../../modules/development";
import { connect } from "react-redux";
import { compose } from "redux";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Link } from "react-router-dom";
import trophyIcon from "../../images/trophy.svg";
import { DevelopmentItemStatus, OrgType, PathType } from "../../model";
import "./Development.scss";
import { DevelopmentItemSummaryCard } from "../../components/DevelopmentItemCard/DevelopmentItemSummaryCard";
import { ThemeListView } from "./ThemeListView";
import { DevelopmentItemCardFrame } from "../../components/DevelopmentItemCard/DevelopmentItemCardFrame";
import {
  DevelopmentBaseView,
  DevelopmentBaseViewBaseProps,
  enhanceDevelopmentBaseView,
} from "./DevelopmentBaseView";
import { DevelopmentItemDeletionModal } from "../../components/DevelopmentItemDeletionModal/DevelopmentItemDeletionModal";
import { DevelopmentItemFilter } from "./DevelopmentItemFilter";
import { SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE } from "../../modules/developmentModals";
import { DevelopmentItemTooltip } from "../../components/DevelopmentItemCard/DevelopmentItemTooltip";
import {
  fetchOrganizationCapabilities,
  OrganizationCapabilityType,
} from "../../modules/organization";
import { ReduxState } from "../../reducers";

type OwnProps = {
  readOnly?: boolean;
};

type DevelopmentSummaryPageProps = OwnProps &
  DevelopmentBaseViewBaseProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const DEVELOPMENT_ITEM_SUMMARY_TOOLTIP_ID =
  "development-item-summary-tooltip";

class DevelopmentSummaryPage extends DevelopmentBaseView<
  DevelopmentSummaryPageProps,
  any
> {
  componentDidMount() {
    const { currentOrgType, fetchOrganizationCapabilities } = this.props;

    super.componentDidMount();

    if (currentOrgType === OrgType.CLUB) {
      const auditMetaInfo = this.getAuditMetaInfo(this.props);
      const federationId = auditMetaInfo?.federationId;
      federationId && fetchOrganizationCapabilities(federationId);
    }
  }

  componentDidUpdate(prevProps: DevelopmentSummaryPageProps) {
    const { currentOrgType, fetchOrganizationCapabilities } = this.props;

    super.componentDidUpdate(prevProps);

    const currentFederationId = this.getAuditMetaInfo(this.props)?.federationId;
    const previousFederationId = this.getAuditMetaInfo(prevProps)?.federationId;
    if (
      currentFederationId !== previousFederationId &&
      currentOrgType === OrgType.CLUB
    ) {
      const auditMetaInfo = this.getAuditMetaInfo(this.props);
      const federationId = auditMetaInfo?.federationId;
      federationId && fetchOrganizationCapabilities(federationId);
    }
  }

  getNumItemsWithStatus(
    filterFunc: (item: Partial<DevelopmentItem>) => boolean
  ) {
    const { developmentItems } = this.props;
    return developmentItems && developmentItems.filter(filterFunc).length;
  }

  createSummaryNumberCard = (
    title: string,
    itemProps: Partial<DevelopmentItem>,
    summaryNumber: number | null
  ) => (
    <DevelopmentItemCardFrame itemProps={itemProps} className="number-box">
      <div className="number-box-content">
        <div className="number-box-title">{title}</div>
        <div className="vertical-line" />
        <div className="number-box-result">{summaryNumber}</div>
      </div>
    </DevelopmentItemCardFrame>
  );

  createItemListThemeComponent = (
    developmentItems: DevelopmentItem[],
    openNewDevelopmentItemModal: (
      theme: string | null,
      showTopSports: boolean
    ) => void,
    readOnly: boolean
  ) => (theme: string | null, showTopSports: boolean): ReactElement | null => {
    const { t } = this.props;
    const cardsForTheme = developmentItems
      .filter((item) => (!item.theme && !theme) || item.theme === theme)
      .map((item, index) => (
        <DevelopmentItemSummaryCard
          item={item}
          key={item.id || -index} // locked items don't have an id
          readonly={readOnly}
        />
      ));

    return cardsForTheme.length > 0 ? (
      <div className="development-item-list-theme">
        {cardsForTheme}
        <button
          className="btn-transparent newitembtn"
          onClick={() => openNewDevelopmentItemModal(theme, showTopSports)}
          disabled={readOnly}
        >
          + {t("addItem")}
        </button>
      </div>
    ) : null;
  };

  createPartHeader = (partKey: string, partTitle: string) => {
    const { developmentItems, t } = this.props;
    const auditMetaInfo = this.getAuditMetaInfo(this.props);

    if (!developmentItems || !auditMetaInfo) {
      return null;
    }

    const partDef = auditMetaInfo.parts.find((part) => part.key === partKey);
    const partThemes = partDef
      ? partDef.sections.map((section) => section.key)
      : [];
    const numPartItems = developmentItems.filter(
      (item) => item.theme && partThemes.includes(item.theme)
    ).length;

    return (
      <div className="custom-part-title">
        {partTitle}
        <div className="num-part-items">
          {`${numPartItems} ${
            numPartItems === 1 ? t("numItems.singular") : t("numItems.plural")
          }`}
        </div>
      </div>
    );
  };

  render() {
    const {
      developmentItems,
      openNewDevelopmentItemModal,
      t,
      currentOrgType,
      federationCapabilities,
    } = this.props;
    const readOnly = !!this.props.readOnly;
    const auditMetaInfo = this.getAuditMetaInfo(this.props);

    if (!developmentItems || !auditMetaInfo) {
      return <LoadingSpinner />;
    }

    const clubsFederationHasTopSportsCapability =
      currentOrgType === OrgType.CLUB &&
      federationCapabilities?.some((c) => {
        return (
          c.capabilityType ===
            OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
          c.value === "true"
        );
      });

    return (
      <div className="development-summary">
        <Link to="development" className="back-link">
          &lt; {t("summaryView.back")}
        </Link>
        <DevelopmentItemPropsModal
          orgId={this.getOrgId(this.props)}
          auditMetaInfo={auditMetaInfo}
          readOnly={readOnly}
          showTopSports={clubsFederationHasTopSportsCapability}
        />
        <DevelopmentItemDeletionModal />
        <DevelopmentItemTooltip id={DEVELOPMENT_ITEM_SUMMARY_TOOLTIP_ID} />
        <div className="development-header">
          <h1>{t("summaryView.title")}</h1>
          <h3>{t("summaryView.subtitle")}</h3>
          <div className="text">{t("summaryView.description")}</div>
        </div>
        <div className="development-summary-table">
          <DevelopmentItemCardFrame
            className="trophy-box"
            itemProps={{
              year: 2000, // dummy
              status: DevelopmentItemStatus.READY,
            }}
          >
            <div className="trophy-box-content">
              <div className="ready">{t("summaryView.readyItems")}</div>
              <div className="trophy">
                <img src={trophyIcon} alt={t("summaryView.trophy-alt")} />
                {this.getNumItemsWithStatus(
                  (item) => item.status === DevelopmentItemStatus.READY
                )}
              </div>
            </div>
          </DevelopmentItemCardFrame>
          <div className="number-boxes">
            {this.createSummaryNumberCard(
              t("itemStatus.ongoing"),
              {
                year: 2000, // dummy
                status: DevelopmentItemStatus.ONGOING,
              },
              this.getNumItemsWithStatus(
                (item) => item.status === DevelopmentItemStatus.ONGOING
              )
            )}
            {this.createSummaryNumberCard(
              t("itemStatus.notStarted"),
              {
                year: 2000, // dummy
                status: DevelopmentItemStatus.NOT_STARTED,
              },
              this.getNumItemsWithStatus(
                (item) => item.status === DevelopmentItemStatus.NOT_STARTED
              )
            )}
            {this.createSummaryNumberCard(
              t("itemStatus.passive"),
              { year: undefined },
              this.getNumItemsWithStatus(
                (item) => !!item.locked || !item.status
              )
            )}
          </div>
        </div>
        <div className="filter-row">
          <DevelopmentItemFilter
            auditMetaInfo={auditMetaInfo}
            enableYearSelection={true}
            showTopSports={clubsFederationHasTopSportsCapability}
          />
        </div>
        <div className="development-summary-content">
          <ThemeListView
            auditMetaInfo={auditMetaInfo}
            generateContent={this.createItemListThemeComponent(
              developmentItems,
              openNewDevelopmentItemModal,
              readOnly
            )}
            generatePartHeader={this.createPartHeader}
            placeholder={
              <div className="development-item-list-theme">
                <div className="placeholder">{t("placeholder")}</div>
                <button
                  className="btn-transparent newitembtn"
                  onClick={() =>
                    openNewDevelopmentItemModal(
                      null,
                      clubsFederationHasTopSportsCapability
                    )
                  }
                  disabled={readOnly}
                >
                  + {t("addItem")}
                </button>
              </div>
            }
          />
        </div>
        <Link to="development" className="btn-secondary-blue summary-button">
          {t("summaryView.backToMainView")}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, props) => {
  const orgId = parseInt(props.match.params.orgId, 10);

  const auditMetaInfo =
    state.clubAuditMetaInfo && orgId
      ? state.clubAuditMetaInfo.clubAuditMetaInfo[orgId]
      : null;
  const federationId = auditMetaInfo?.federationId;
  return {
    currentOrgType: state.account.currentOrgType,
    federationCapabilities: federationId
      ? state.organization.capabilities[federationId]
      : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewDevelopmentItemModal: (theme: string | null, showTopSports: boolean) =>
    dispatch(
      SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE({
        modalIsOpen: true,
        item: {
          description: "",
          pathType: showTopSports ? PathType.ALL : PathType.YOUTH_AND_ADULTS,
          theme: theme ? theme : undefined,
        },
      })
    ),
  fetchOrganizationCapabilities: (orgId: number) => {
    dispatch(fetchOrganizationCapabilities(orgId));
  },
});

const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("clubDevelopment"),
  enhanceDevelopmentBaseView
);

export default enhance(DevelopmentSummaryPage);
