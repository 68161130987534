import { createAction, handleActions } from "redux-actions";
import Api from "../api";
import i18nInstance from "../i18n";
import * as Logger from "js-logger";

export const FETCH_CONFIG_SUCCESS = createAction<ConfigState>(
  "FETCH_CONFIG_SUCCESS"
);
export const FETCH_CONFIG_FAILURE = createAction("FETCH_CONFIG_FAILURE");

export function fetchConfig() {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      const response = await Api.config.getConfig(i18nInstance.language);

      dispatch(
        FETCH_CONFIG_SUCCESS({
          environment: response.data.environment,
          serviceNotifications: response.data.serviceUserNotifications,
          ...response.data.topSportsSportsFederationNameMap,
        })
      );
    } catch (err) {
      Logger.warn("fetching config failed");
      dispatch(FETCH_CONFIG_FAILURE());
    }

    return true;
  };
}

export type SportsFederationTranslationKeyMapType = { [key: number]: string };

export interface ConfigState {
  environment: string | null;
  serviceNotifications: Array<string> | null;
  topSportsSportsFederationNameMap: SportsFederationTranslationKeyMapType | {};
}

const initialState: ConfigState = {
  environment: null,
  serviceNotifications: null,
  topSportsSportsFederationNameMap: {},
};

const configReducer = handleActions<ConfigState, any>(
  {
    [FETCH_CONFIG_SUCCESS as any]: (state, action) => ({
      ...action.payload,
    }),
    [FETCH_CONFIG_FAILURE as any]: (state, action) => initialState,
  },
  initialState
);

export default configReducer;
