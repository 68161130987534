import React from "react";
import { FormGroup, HelpBlock, Radio } from "react-bootstrap";
import { v4 } from "uuid";
import FormFieldDescription from "./components/FormFieldDescription";
import { errorText, isInvalid } from "../../../util/validationHelper";
import { PathType } from "../../../model";
import { useTranslation } from "react-i18next";

interface QualityPathSelectionFieldProps {
  label?: string;
  labelClassName?: string;
  className?: string;
  disabled: boolean;
  helpText: string;
  names: string[];
  optionsTitleText: string;
  placeholder: string;
  showTopSports: boolean;
  onValueChange(value: any): void;
}

export const QualityPathSelectionField = (
  props: QualityPathSelectionFieldProps
) => {
  const { t } = useTranslation("common");

  const {
    className,
    label,
    labelClassName,
    names,
    placeholder,
    helpText,
    showTopSports,
  } = props;

  const options = showTopSports
    ? [
        { key: PathType.YOUTH, value: t("paths.YOUTH") },
        { key: PathType.ADULTS, value: t("paths.ADULTS") },
        { key: PathType.TOP_SPORTS, value: t("paths.TOP_SPORTS") },
        { key: PathType.ALL, value: t("paths.ALL") },
      ]
    : [
        { key: PathType.YOUTH, value: t("paths.YOUTH") },
        { key: PathType.ADULTS, value: t("paths.ADULTS") },
        { key: PathType.YOUTH_AND_ADULTS, value: t("paths.YOUTH_AND_ADULTS") },
      ];

  const usedLabelClassName = labelClassName
    ? `formlabel ${labelClassName}`
    : "formlabel";

  const onChange = (event: any) => {
    const { names } = props;
    props[names[0]].input.onChange(event.target.value);

    if (props.onValueChange) {
      props.onValueChange(event.target.value);
    }
  };

  const getRandomName = () => Math.random().toString(36).substr(2, 5);

  const getOptions = () => {
    const { className, names, disabled } = props;
    const randomName = getRandomName();
    const input = props[names[0]].input;

    const buttons = () =>
      options.map((option) => {
        const checked = input.value === option.key;
        return (
          <span
            style={{
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
            }}
            key={option.key}
          >
            <Radio
              type="radio"
              id={randomName + option.key}
              name={randomName}
              value={option.key}
              onChange={onChange}
              checked={checked}
              disabled={disabled}
              className={checked ? "checked" : "unchecked"}
            >
              {option.value}
            </Radio>
          </span>
        );
      });

    return <div className={className}>{buttons()}</div>;
  };

  return (
    <FormGroup
      className={
        className
          ? className + " pathtypeselectionfield"
          : "pathtypeselectionfield"
      }
      controlId={placeholder}
      validationState={isInvalid(names, props) ? "error" : "success"}
    >
      {label && (
        <div className="fieldlabel">
          <label className={usedLabelClassName}>{label}</label>
          <FormFieldDescription>{helpText}</FormFieldDescription>
        </div>
      )}
      {placeholder ? (
        <div className="fieldlabel">
          <label className="formlabel">{placeholder}</label>
          <FormFieldDescription>{helpText}</FormFieldDescription>
        </div>
      ) : null}

      <div className="formfield">
        {getOptions()}

        {isInvalid(names, props) ? (
          <HelpBlock>
            {errorText(names, props).map((err) => {
              return <span key={v4()}>{err}</span>;
            })}
          </HelpBlock>
        ) : null}
      </div>
    </FormGroup>
  );
};
