import * as React from "react";
import { ReactComponent as InfoIcon } from "../images/info-icon.svg";
import "./Infobox.scss";

interface InfoBoxProps {
  text: string;
}

export const InfoBox: React.FunctionComponent<InfoBoxProps> = (props) => {
  return (
    <div className="infobox">
      <span>
        <InfoIcon />
      </span>
      <p>{props.text}</p>
    </div>
  );
};
