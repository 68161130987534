import React from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import SelectField from "../../Fields/SelectField";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          className="sharedGroup"
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <Field
              options={options}
              className={"pathdropdown"}
              name="sportcontentSharedPath"
              component={SelectField}
              placeholder={t("shared.sportcontent.path.placeholder")}
              label={t("shared.sportcontent.path.label")}
              helpText={t("shared.sportcontent.path.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPath"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.path.label")}
              fieldValue={ratingMap[get(formData, "sportcontentSharedPath")]}
            />
            <hr />
            <Field
              options={options}
              className={"pathdropdown"}
              name="sportcontentSharedCoaching"
              component={SelectField}
              placeholder={t("shared.sportcontent.coaching.placeholder")}
              label={t("shared.sportcontent.coaching.label")}
              helpText={t("shared.sportcontent.coaching.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedCoaching"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.coaching.label")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedCoaching")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <Field
              name="sportcontentSharedPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.positive")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedPositive")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <Field
              name="sportcontentSharedNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.negative")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedNegative")]
              }
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.passion.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              className={"pathdropdown"}
              name="passionFeedbackKids"
              component={SelectField}
              formfieldDesc={t("youth.sportcontent.passion.feedback.children")}
              placeholder={t("youth.sportcontent.passion.feedback.placeholder")}
              label={t("youth.sportcontent.passion.feedback.label")}
              help={t("youth.sportcontent.passion.feedback.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionFeedbackKids"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.feedback.label")}
              fieldValue={ratingMap[get(formData, "passionFeedbackKids")]}
            />
            <Field
              options={options}
              className={"pathdropdown"}
              name="passionFeedbackYouth"
              component={SelectField}
              formfieldDesc={t("youth.sportcontent.passion.feedback.young")}
              label={" "}
              placeholder={t("youth.sportcontent.passion.feedback.placeholder")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionFeedbackYouth"}
              orgId={orgId}
              fieldTranslated={t("youth.sportcontent.passion.feedback.label")}
              commentMode={CommentMode.Field}
              fieldValue={ratingMap[get(formData, "passionFeedbackYouth")]}
            />
            <Field
              options={options}
              className={"pathdropdown"}
              name="passionFeedbackInstructors"
              component={SelectField}
              label={" "}
              formfieldDesc={t("youth.sportcontent.passion.feedback.coaches")}
              placeholder={t("youth.sportcontent.passion.feedback.placeholder")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionFeedbackInstructors"}
              orgId={orgId}
              fieldTranslated={t("youth.sportcontent.passion.feedback.label")}
              commentMode={CommentMode.Field}
              fieldValue={
                ratingMap[get(formData, "passionFeedbackInstructors")]
              }
            />
            <Field
              options={options}
              className={"pathdropdown"}
              name="passionFeedbackParents"
              label={" "}
              component={SelectField}
              formfieldDesc={t("youth.sportcontent.passion.feedback.parents")}
              placeholder={t("youth.sportcontent.passion.feedback.placeholder")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={"youth"}
              fieldName={"passionFeedbackParents"}
              orgId={orgId}
              fieldTranslated={t("youth.sportcontent.passion.feedback.label")}
              commentMode={CommentMode.Field}
              fieldValue={ratingMap[get(formData, "passionFeedbackParents")]}
            />
            <hr />
            <Field
              options={options}
              name="passionAtmosphere"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.passion.atmosphere.placeholder"
              )}
              label={t("youth.sportcontent.passion.atmosphere.label")}
              helpText={t("youth.sportcontent.passion.atmosphere.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionAtmosphere"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.atmosphere.label")}
              fieldValue={ratingMap[get(formData, "passionAtmosphere")]}
            />
            <hr />
            <Field
              options={options}
              name="passionHappiness"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.passion.happiness.placeholder"
              )}
              label={t("youth.sportcontent.passion.happiness.label")}
              helpText={t("youth.sportcontent.passion.happiness.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionHappiness"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.happiness.label")}
              fieldValue={ratingMap[get(formData, "passionHappiness")]}
            />
            <hr />
            <Field
              options={options}
              name="passionInvolvement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.passion.involvement.placeholder"
              )}
              label={t("youth.sportcontent.passion.involvement.label")}
              helpText={t("youth.sportcontent.passion.involvement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionInvolvement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.passion.involvement.label"
              )}
              fieldValue={ratingMap[get(formData, "passionInvolvement")]}
            />
            <hr />
            <Field
              options={options}
              name="passionFairplay"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("youth.sportcontent.passion.fairplay.placeholder")}
              label={t("youth.sportcontent.passion.fairplay.label")}
              helpText={t("youth.sportcontent.passion.fairplay.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"passionFairplay"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.fairplay.label")}
              fieldValue={ratingMap[get(formData, "passionFairplay")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.positive")}
            </div>
            <Field
              name="passionPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.passion.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"passionPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.positive")}
              fieldValue={ratingMap[get(formData, "passionPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.negative")}
            </div>
            <Field
              name="passionNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.passion.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"passionNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.negative")}
              fieldValue={ratingMap[get(formData, "passionNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPassion")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.practice.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="practiceTotalAmount"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.practice.totalAmount.placeholder"
              )}
              label={t("youth.sportcontent.practice.totalAmount.label")}
              helpText={t("youth.sportcontent.practice.totalAmount.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceTotalAmount"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.practice.totalAmount.label"
              )}
              fieldValue={ratingMap[get(formData, "practiceTotalAmount")]}
            />
            <hr />
            <Field
              options={options}
              name="practiceEncouragement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.practice.encouragement.placeholder"
              )}
              label={t("youth.sportcontent.practice.encouragement.label")}
              helpText={t("youth.sportcontent.practice.encouragement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceEncouragement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.practice.encouragement.label"
              )}
              fieldValue={ratingMap[get(formData, "practiceEncouragement")]}
            />
            <hr />
            <Field
              options={options}
              name="practiceDiverse"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("youth.sportcontent.practice.diverse.placeholder")}
              label={t("youth.sportcontent.practice.diverse.label")}
              helpText={t("youth.sportcontent.practice.diverse.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceDiverse"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.diverse.label")}
              fieldValue={ratingMap[get(formData, "practiceDiverse")]}
            />
            <hr />
            <Field
              options={options}
              name="practiceOrganized"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.practice.organized.placeholder"
              )}
              label={t("youth.sportcontent.practice.organized.label")}
              helpText={t("youth.sportcontent.practice.organized.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceOrganized"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.organized.label")}
              fieldValue={ratingMap[get(formData, "practiceOrganized")]}
            />
            <hr />
            <Field
              options={options}
              name="practiceChoices"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("youth.sportcontent.practice.choices.placeholder")}
              label={t("youth.sportcontent.practice.choices.label")}
              helpText={t("youth.sportcontent.practice.choices.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceChoices"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.choices.label")}
              fieldValue={ratingMap[get(formData, "practiceChoices")]}
            />
            <hr />
            <Field
              options={options}
              name="practiceOwnImprovement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.practice.ownImprovement.placeholder"
              )}
              label={t("youth.sportcontent.practice.ownImprovement.label")}
              helpText={t("youth.sportcontent.practice.ownImprovement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"practiceOwnImprovement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.practice.ownImprovement.label"
              )}
              fieldValue={ratingMap[get(formData, "practiceOwnImprovement")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.positive")}
            </div>
            <Field
              name="practicePositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.practice.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"practicePositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.positive")}
              fieldValue={ratingMap[get(formData, "practicePositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.negative")}
            </div>
            <Field
              name="practiceNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.practice.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"practiceNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.negative")}
              fieldValue={ratingMap[get(formData, "practiceNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPractice")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.lifestyle.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="lifestyleImprovement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.lifestyle.improvement.placeholder"
              )}
              label={t("youth.sportcontent.lifestyle.improvement.label")}
              helpText={t("youth.sportcontent.lifestyle.improvement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleImprovement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.lifestyle.improvement.label"
              )}
              fieldValue={ratingMap[get(formData, "lifestyleImprovement")]}
            />
            <hr />
            <Field
              options={options}
              name="lifestyleCommunication"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.lifestyle.communication.placeholder"
              )}
              label={t("youth.sportcontent.lifestyle.communication.label")}
              helpText={t("youth.sportcontent.lifestyle.communication.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleCommunication"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.lifestyle.communication.label"
              )}
              fieldValue={ratingMap[get(formData, "lifestyleCommunication")]}
            />
            <hr />
            <Field
              options={options}
              name="lifestyleCommunicationParents"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.lifestyle.communicationParents.placeholder"
              )}
              label={t(
                "youth.sportcontent.lifestyle.communicationParents.label"
              )}
              helpText={t(
                "youth.sportcontent.lifestyle.communicationParents.help"
              )}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleCommunicationParents"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.lifestyle.communicationParents.label"
              )}
              fieldValue={
                ratingMap[get(formData, "lifestyleCommunicationParents")]
              }
            />
            <hr />
            <Field
              options={options}
              name="lifestyleExample"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.lifestyle.example.placeholder"
              )}
              label={t("youth.sportcontent.lifestyle.example.label")}
              helpText={t("youth.sportcontent.lifestyle.example.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleExample"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.lifestyle.example.label")}
              fieldValue={ratingMap[get(formData, "lifestyleExample")]}
            />
            <hr />
            <Field
              options={options}
              name="lifestyleAntidoping"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.lifestyle.antidoping.placeholder"
              )}
              label={t("youth.sportcontent.lifestyle.antidoping.label")}
              helpText={t("youth.sportcontent.lifestyle.antidoping.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleAntidoping"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.lifestyle.antidoping.label"
              )}
              fieldValue={ratingMap[get(formData, "lifestyleAntidoping")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.positive")}
            </div>
            <Field
              name="lifestylePositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.lifestyle.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"lifestylePositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.lifestyle.positive")}
              fieldValue={ratingMap[get(formData, "lifestylePositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.negative")}
            </div>
            <Field
              name="lifestyleNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.lifestyle.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.lifestyle.negative")}
              fieldValue={ratingMap[get(formData, "lifestyleNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthLifestyle")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.competition.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="competetitionInvigorating"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.invigorating.placeholder"
              )}
              label={t("youth.sportcontent.competition.invigorating.label")}
              helpText={t("youth.sportcontent.competition.invigorating.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competetitionInvigorating"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.invigorating.label"
              )}
              fieldValue={ratingMap[get(formData, "competetitionInvigorating")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionParticipation"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.participation.placeholder"
              )}
              label={t("youth.sportcontent.competition.participation.label")}
              helpText={t("youth.sportcontent.competition.participation.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionParticipation"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.participation.label"
              )}
              fieldValue={ratingMap[get(formData, "competitionParticipation")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionInclusivity"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.inclusivity.placeholder"
              )}
              label={t("youth.sportcontent.competition.inclusivity.label")}
              helpText={t("youth.sportcontent.competition.inclusivity.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionInclusivity"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.inclusivity.label"
              )}
              fieldValue={ratingMap[get(formData, "competitionInclusivity")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionClass"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.class.placeholder"
              )}
              label={t("youth.sportcontent.competition.class.label")}
              helpText={t("youth.sportcontent.competition.class.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionClass"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.competition.class.label")}
              fieldValue={ratingMap[get(formData, "competitionClass")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionFeedback"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.feedback.placeholder"
              )}
              label={t("youth.sportcontent.competition.feedback.label")}
              helpText={t("youth.sportcontent.competition.feedback.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionFeedback"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.feedback.label"
              )}
              fieldValue={ratingMap[get(formData, "competitionFeedback")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionEvaluation"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.evaluation.placeholder"
              )}
              label={t("youth.sportcontent.competition.evaluation.label")}
              helpText={t("youth.sportcontent.competition.evaluation.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionEvaluation"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.evaluation.label"
              )}
              fieldValue={ratingMap[get(formData, "competitionEvaluation")]}
            />
            <hr />
            <Field
              options={options}
              name="competitionHobbyvalue"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "youth.sportcontent.competition.hobbyvalue.placeholder"
              )}
              label={t("youth.sportcontent.competition.hobbyvalue.label")}
              helpText={t("youth.sportcontent.competition.hobbyvalue.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"competitionHobbyvalue"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "youth.sportcontent.competition.hobbyvalue.label"
              )}
              fieldValue={ratingMap[get(formData, "competitionHobbyvalue")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.positive")}
            </div>
            <Field
              name="competitionPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.competition.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"competitionPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.competition.positive")}
              fieldValue={ratingMap[get(formData, "competitionPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.negative")}
            </div>
            <Field
              name="competitionNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.competition.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"competitionNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.competition.negative")}
              fieldValue={ratingMap[get(formData, "competitionNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthCompetition"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default YouthSportContentPart as any;
