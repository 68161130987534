import React from "react";
import { Component } from "react";
import { AuditRequest, ClubEnrollment, PathType } from "../../../model";
import "./PathFormV5.scss";
import TopSportsBasicCriteriaPart from "./BasicCriteriaPart/TopSportsBasicCriteriaPart";
import YouthSportContentPart from "./SportContentPart/YouthSportContentPart";
import AdultsSportContentPart from "./SportContentPart/AdultsSportContentPart";
import TopSportsSportContentPart from "./SportContentPart/TopSportsSportContentPart";
import YouthManagementPart from "./ManagementPart/YouthManagementPart";
import AdultsManagementPart from "./ManagementPart/AdultsManagementPart";
import TopSportsManagementPart from "./ManagementPart/TopSportsManagementPart";
import YouthResourcesPart from "./ResourcesPart/YouthResourcesPart";
import AdultsResourcesPart from "./ResourcesPart/AdultsResourcesPart";
import TopSportsResourcesPart from "./ResourcesPart/TopSportsResourcesPart";
import YouthPeoplePart from "./PeoplePart/YouthPeoplePart";
import AdultsPeoplePart from "./PeoplePart/AdultsPeoplePart";
import TopSportsPeoplePart from "./PeoplePart/TopSportsPeoplePart";
import PathFormWrapper from "../PartFormWrapper";
import CommentModal from "../../../components/CommentModal/CommentModal";
import {
  translatedGradeOptions,
  translatedGradeRatingMap,
  translatedYesNoMap,
  translatedYesNoOptions,
} from "./model";
import { withTranslation, WithTranslation } from "react-i18next";
import { AttachmentAuditModal } from "../../AttachmentModal/AttachmentAuditModal";
import { AuditFormMetaInfo } from "../../../modules/formMetaInfo";
import { SportsFederationTranslationKeyMapType } from "../../../modules/config";

type PathFormV5Props = {
  currentOrg: number | null;
  enrollment: ClubEnrollment | null | undefined;
  activeRequest: AuditRequest | null | undefined;
  readOnlyMode: boolean;
  commentEdit: boolean;
  clubMode: boolean;
  formPart: string;
  pathType: string;
  formMetaInfo: AuditFormMetaInfo;
  sportsFederationTranslationKeyMap: SportsFederationTranslationKeyMapType | {};
};

class PathFormV5 extends Component<PathFormV5Props & WithTranslation, any> {
  render() {
    const {
      t,
      currentOrg,
      enrollment,
      activeRequest,
      readOnlyMode,
      commentEdit,
      clubMode,
      pathType,
      formPart,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
    } = this.props;
    if (!currentOrg) {
      return null;
    }
    let formContent: any = null;

    const FORM_VERSION = 5;

    const formPartIsTopSportsBasicCriteria =
      formPart === "basiccriteria" && pathType === PathType.TOP_SPORTS;

    const options = formPartIsTopSportsBasicCriteria
      ? translatedYesNoOptions(t)
      : translatedGradeOptions(t);
    const ratingMap = formPartIsTopSportsBasicCriteria
      ? translatedYesNoMap(t)
      : translatedGradeRatingMap(t);

    switch (formPart) {
      case "basiccriteria":
        formContent =
          pathType === PathType.TOP_SPORTS ? (
            <PathFormWrapper
              form={`${pathType}basiccriteria`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="TOP_SPORTS"
              partKey="basiccriteria"
              toolsKey="topSportsBasiccriteria"
              translationNs="formsV5"
              formMetaInfo={formMetaInfo}
              formVersion={FORM_VERSION}
              sportsFederationTranslationKeyMap={
                sportsFederationTranslationKeyMap
              }
            >
              <TopSportsBasicCriteriaPart />
            </PathFormWrapper>
          ) : null;
        break;
      case "sportcontent":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}sportcontent`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="sportcontent"
              toolsKey="youthSportcontent"
              translationNs="formsV5"
            >
              <YouthSportContentPart />
            </PathFormWrapper>
          ) : pathType === PathType.ADULTS ? (
            <PathFormWrapper
              form={`${pathType}sportcontent`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="sportcontent"
              toolsKey="adultsSportcontent"
              translationNs="formsV5"
            >
              <AdultsSportContentPart />
            </PathFormWrapper>
          ) : pathType === PathType.TOP_SPORTS ? (
            <PathFormWrapper
              form={`${pathType}sportcontent`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="TOP_SPORTS"
              partKey="sportcontent"
              toolsKey="topsportsSportcontent"
              translationNs="formsV5"
              formMetaInfo={formMetaInfo}
              formVersion={FORM_VERSION}
              sportsFederationTranslationKeyMap={
                sportsFederationTranslationKeyMap
              }
            >
              <TopSportsSportContentPart />
            </PathFormWrapper>
          ) : null;
        break;
      case "management":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}management`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="management"
              toolsKey="youthManagement"
              translationNs="formsV5"
            >
              <YouthManagementPart />
            </PathFormWrapper>
          ) : pathType === PathType.ADULTS ? (
            <PathFormWrapper
              form={`${pathType}management`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="management"
              toolsKey="adultsManagement"
              translationNs="formsV5"
            >
              <AdultsManagementPart />
            </PathFormWrapper>
          ) : pathType === PathType.TOP_SPORTS ? (
            <PathFormWrapper
              form={`${pathType}management`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="TOP_SPORTS"
              partKey="management"
              toolsKey="topSportsManagement"
              translationNs="formsV5"
              formMetaInfo={formMetaInfo}
              formVersion={FORM_VERSION}
              sportsFederationTranslationKeyMap={
                sportsFederationTranslationKeyMap
              }
            >
              <TopSportsManagementPart />
            </PathFormWrapper>
          ) : null;
        break;
      case "resources":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}resources`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="resources"
              toolsKey="youthResources"
              translationNs="formsV5"
            >
              <YouthResourcesPart />
            </PathFormWrapper>
          ) : pathType === PathType.ADULTS ? (
            <PathFormWrapper
              form={`${pathType}resources`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="resources"
              toolsKey="adultsResources"
              translationNs="formsV5"
            >
              <AdultsResourcesPart />
            </PathFormWrapper>
          ) : pathType === PathType.TOP_SPORTS ? (
            <PathFormWrapper
              form={`${pathType}resources`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="TOP_SPORTS"
              partKey="resources"
              toolsKey="topSportsResources"
              translationNs="formsV5"
              formMetaInfo={formMetaInfo}
              formVersion={FORM_VERSION}
              sportsFederationTranslationKeyMap={
                sportsFederationTranslationKeyMap
              }
            >
              <TopSportsResourcesPart />
            </PathFormWrapper>
          ) : null;
        break;
      case "people":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}people`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="people"
              toolsKey="youthPeople"
              translationNs="formsV5"
            >
              <YouthPeoplePart />
            </PathFormWrapper>
          ) : pathType === PathType.ADULTS ? (
            <PathFormWrapper
              form={`${pathType}people`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="people"
              toolsKey="adultsPeople"
              translationNs="formsV5"
            >
              <AdultsPeoplePart />
            </PathFormWrapper>
          ) : pathType === PathType.TOP_SPORTS ? (
            <PathFormWrapper
              form={`${pathType}people`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="TOP_SPORTS"
              partKey="people"
              toolsKey="topSportsPeople"
              translationNs="formsV5"
              formMetaInfo={formMetaInfo}
              formVersion={FORM_VERSION}
              sportsFederationTranslationKeyMap={
                sportsFederationTranslationKeyMap
              }
            >
              <TopSportsPeoplePart />
            </PathFormWrapper>
          ) : null;
        break;
      default:
        break;
    }
    return (
      <div className="pathformversionwrapper">
        <CommentModal ratingMap={ratingMap} options={options} />
        <AttachmentAuditModal />
        {formContent}
      </div>
    );
  }
}

export default withTranslation("formsV5")(PathFormV5);
