/**
 * TODO: Consider renaming organizationClubPath's superOrgId to orgId, and orgId to clubId.
 * Current naming causes issues in navigation because match.param.orgId can now in some context
 * point to federation/area organizationId or in other cases to clubId. */
export const paths = {
  profile: "/profile",
  login: "/login",
  loginRedirect: "/loginRedirect",
  signout: "/signout",
  clubDashboard: "/dashboard/:orgId?",
  organizationDashboard: "/organization/:orgId?",
  organizationClubPath: "/organization/:superOrgId/club/:orgId",
  clubPath: "/dashboard/:orgId/path",
  clubPathPart: "/dashboard/:orgId/:enrollId/path/:type/:part/:requestId?",
  auditPathPart: "/organization/:orgId/path/:type/:part/:requestId",
  auditSummary: "/organization/:orgId/audit/:requestId/:responseId",
  clubAuditSummary: "/dashboard/:orgId/:enrollId/audit/:requestId/:responseId",
  exportExcel: "/api/v1/auditor-view/:orgId/export?locale=:locale",
};

export const getClubDashboardUrl = (orgId: string) =>
  paths.clubDashboard.replace(":orgId?", orgId);
export const getOrganizationDashboardUrl = (orgId: string) =>
  paths.organizationDashboard.replace(":orgId?", orgId);
export const getOrganizationClubUrl = (superOrgId: string, clubId: string) =>
  paths.organizationClubPath
    .replace(":superOrgId", superOrgId)
    .replace(":orgId", clubId);
export const getPathPart = (
  orgId: string,
  part: string,
  pathType,
  requestId: string,
  enrollId: string
) =>
  paths.clubPathPart
    .replace(":orgId", orgId)
    .replace(":type", pathType)
    .replace(":part", part)
    .replace(":requestId", requestId)
    .replace(":enrollId", enrollId);
export const getPathPartAudit = (
  orgId: string,
  part: string,
  pathType,
  requestId
) =>
  paths.auditPathPart
    .replace(":orgId", orgId)
    .replace(":type", pathType)
    .replace(":part", part)
    .replace(":requestId", requestId);
export const getPathAudit = (
  orgId: string,
  requestId: string,
  responseId?: string
) =>
  paths.auditSummary
    .replace(":orgId", orgId)
    .replace(":requestId", requestId)
    .replace(":responseId", responseId ? responseId : "");
export const getClubPathAudit = (
  orgId: string,
  requestId: string,
  responseId: string,
  enrollId: string
) =>
  paths.clubAuditSummary
    .replace(":orgId", orgId)
    .replace(":requestId", requestId)
    .replace(":responseId", responseId)
    .replace(":enrollId", enrollId);
export const getExcelExport = (orgId: string, locale: string) =>
  paths.exportExcel.replace(":orgId", orgId).replace(":locale", locale);
