import { chain, values } from "lodash";
import Locale from "./Locale";

export class LocaleUtils {
  /**
   * Supported locales in the system.
   */
  static supportedLocales = {
    fi: {
      locales: {
        fi_FI: new Locale("fi", "FI", "Suomi"),
      },
      default: new Locale("fi", "FI", "Suomi"),
    },
    sv: {
      locales: {
        sv_SE: new Locale("sv", "SE", "Svenska"),
      },
      default: new Locale("sv", "SE", "Svenska"),
    },
    en: {
      locales: {
        en_GB: new Locale("en", "GB", "English"),
      },
      default: new Locale("en", "GB", "English"),
    },
  };

  /**
   * Default full locale tags for languages that only have the
   * 2 letter country tag.
   */
  static localeDefaults = {
    fi: LocaleUtils.supportedLocales.fi.default.toString(),
    sv: LocaleUtils.supportedLocales.sv.default.toString(),
    en: LocaleUtils.supportedLocales.en.default.toString(),
  };

  static getAllLocales() {
    return chain(LocaleUtils.supportedLocales)
      .mapValues((l: any) => {
        return values(l.locales);
      })
      .values()
      .flatten()
      .value();
  }

  /**
   * Supported locales as options for select UI elements.
   */
  static getLocaleOptions(): Array<{ key: Locale; value: string }> {
    return LocaleUtils.getAllLocales().map((l: Locale) => ({
      key: l,
      value: l.title,
    }));
  }

  /**
   * Finds default locale for a supported language.
   * @param language language to match
   */
  static findDefaultLocaleByLanguage(language: string) {
    return LocaleUtils.localeDefaults[language];
  }

  static localeExists(locale: string) {
    return (
      LocaleUtils.getAllLocales().find((s) => {
        return s.toString() === locale;
      }) !== undefined
    );
  }
}
