import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./OrganizationDashboard.scss";
import { SET_CURRENT_ORG } from "../../modules/account";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { ReduxState } from "../../reducers";
import MainRouteViewWrapper from "../MainRouteViewWrapper";
import homebg from "../../images/landings/landing2.jpg";
import SubNav from "../../components/Navigation/SubNav";
import { getOrganizationDashboardUrl } from "../../routePaths";
import HighlightNavItem from "../../components/Navigation/HighlightNavItem";
import { OrganizationDashboardRoutes } from "../../router";
import { fetchOrganizationCapabilities } from "../../modules/organization";

type OrganizationDashboardProps = WithTranslation &
  RouteComponentProps<{ orgId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface OrganizationDashboardState {}

class OrganizationDashboard extends Component<
  OrganizationDashboardProps,
  OrganizationDashboardState
> {
  componentDidMount() {
    const {
      currentOrg,
      match: {
        params: { orgId },
      },
    } = this.props;

    const parsedOrgId = parseInt(orgId, 10);

    if (!currentOrg) {
      this.props.setCurrentOrg(parsedOrgId);
    }

    this.props.fetchOrganizationCapabilities(parsedOrgId);
  }

  render() {
    const {
      t,
      match: {
        params: { orgId },
      },
    } = this.props;

    const getCurrentDashboard = getOrganizationDashboardUrl(orgId);

    return (
      <MainRouteViewWrapper
        picture={homebg}
        bannerplus={true}
        bannersponsor={true}
        footer={true}
        pictureText={t("organizationdashboard.title")}
      >
        <div className="orgdashboard">
          <SubNav>
            <HighlightNavItem
              target={getCurrentDashboard}
              text={t("organizationdashboard.news")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/clubs"}
              text={t("organizationdashboard.clubs")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/auditing"}
              text={t("organizationdashboard.audit")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/summary"}
              text={t("organizationdashboard.orgSummary")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/tools"}
              text={t("organizationdashboard.tools")}
              subnav={true}
            />
            <HighlightNavItem
              target={getCurrentDashboard + "/help"}
              text={t("clubdashboard.help")}
              subnav={true}
            />
          </SubNav>
          <OrganizationDashboardRoutes />
        </div>
      </MainRouteViewWrapper>
    );
  }
}

const homeTranslated = withTranslation("common")(OrganizationDashboard);

const mapStateToProps = (state: ReduxState) => {
  return {
    user: state.account.user,
    currentOrg: state.account.currentOrg,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrg: (orgId) => dispatch(SET_CURRENT_ORG(orgId)),
  fetchOrganizationCapabilities: (orgId) =>
    dispatch(fetchOrganizationCapabilities(orgId)),
});

const HomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(homeTranslated);

export default withRouter(HomeConnected);
