import { connect } from "react-redux";
import React from "react";
import { Component } from "react";
import "./CommentField.scss";
import {
  CommentModalState,
  CommentMode,
  SET_COMMENT_MODAL,
  UPDATE_COMMENT,
} from "../../../modules/comment";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReduxState } from "../../../reducers";
import pencilsvg from "../../../images/pencil-icon.svg";
import Linkify from "react-linkify";

interface ComponentProps {
  hideComment?: boolean;
  hideAudit?: boolean;
  edit: boolean;
  fieldName: string;
  type: string;
  orgId: string;
  fieldTranslated?: string;
  commentMode?: CommentMode;
  fieldValue?: any;
  ratingMap: any;
}

type AuditFieldProps = ComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// this field accompanies every field in the form parts but get its data from comment state
// rather than redux-form
class AuditField extends Component<AuditFieldProps, any> {
  constructor(props: AuditFieldProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // opens CommentModal
  handleModalOpen = (event: any) => {
    if (event && event.target && event.target.tagName === "A") {
      return;
    }
    const props = this.props;
    this.props.openModal({
      show: true,
      showAudit: !props.hideAudit,
      showComment: !props.hideComment,
      comment: props.comment
        ? props.comment[props.fieldName + "_comment"]
        : null,
      audit: props.comment ? props.comment[props.fieldName + "_audit"] : null,
      fieldName: props.fieldName,
      orgId: props.orgId,
      type: props.type,
      fieldTranslated: props.fieldTranslated,
      commentMode: props.commentMode,
      fieldValue: props.fieldValue,
    });
  };

  // handles rendering of either the read only value, editable value or a button to create a value
  render() {
    const { edit, comment, t, fieldName, commentMode, ratingMap } = this.props;
    const commentValue = comment ? comment[fieldName + "_comment"] : null;
    const auditValue = comment ? comment[fieldName + "_audit"] : null;
    return (
      <div className="auditfield">
        {!edit && (
          <div className="commentfield">
            <div className="commentcontainer">
              {auditValue && (
                <div>
                  <div className="text">
                    <b>{t("audit.text")}</b> {ratingMap[auditValue]}
                  </div>
                </div>
              )}
              {commentValue && (
                <Linkify
                  properties={{ target: "_blank", rel: "noopener noreferrer" }}
                  className="usercomment"
                >
                  <p>
                    <b>{t(`comment.${commentMode}.revision`)}</b>
                    {commentValue}
                  </p>
                </Linkify>
              )}
            </div>
          </div>
        )}

        {edit && (auditValue || commentValue) && (
          <div className="commentfield" onClick={this.handleModalOpen}>
            <div className="commentcontainer hoverborder">
              <img src={pencilsvg} alt="" />
              {auditValue && (
                <div className="text">
                  <b>{t("audit.text")}</b> {ratingMap[auditValue]}
                </div>
              )}
              {commentValue && (
                <Linkify
                  properties={{ target: "_blank", rel: "noopener noreferrer" }}
                  className="usercomment"
                >
                  <p>
                    <b>{t(`comment.${commentMode}.revision`)}</b>
                    {commentValue}
                  </p>
                </Linkify>
              )}
            </div>
          </div>
        )}
        {edit && !auditValue && !commentValue && (
          <div className="commentcontainer">
            <div
              className="addcomment hoverborder"
              onClick={this.handleModalOpen}
            >
              <img src={pencilsvg} alt="" />+{" "}
              {t(`comment.${commentMode}.comment`)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  return {
    comment: state.comment.comments,
  };
};

const mapDispatchToProps = (dispatch, ownProps: any) => ({
  submitComment: (data: CommentModalState) => dispatch(UPDATE_COMMENT(data)),
  openModal: (data: CommentModalState) => dispatch(SET_COMMENT_MODAL(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(AuditField));
