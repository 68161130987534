import React from "react";
import { useTranslation } from "react-i18next";
import "./FieldGradingOptionList.scss";

export const GradeOptionsList = () => {
  const { t } = useTranslation("forms");
  return (
    <div className="ratinglist">
      <b>{t("part.ratingsScale")}</b>
      <ul>
        <li>{t("part.rating1")}</li>
        <li>{t("part.rating2")}</li>
        <li>{t("part.rating3")}</li>
        <li>{t("part.rating4")}</li>
      </ul>
    </div>
  );
};

export const YesNoOptionsList = () => {
  const { t } = useTranslation("forms");
  return (
    <div className="ratinglist">
      <b>{t("part.basiccriteria.ratingsScale")}</b>
      <ul>
        <li>{t("part.basiccriteria.ratingYes")}</li>
        <li>{t("part.basiccriteria.ratingNo")}</li>
      </ul>
    </div>
  );
};
