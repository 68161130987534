import React from "react";
import { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import { loginRedirect } from "../../modules/account";

type LoginRedirectProps = WithTranslation &
  RouteComponentProps<{}> &
  ReturnType<typeof mapDispatchToProps>;

/*
  Component to get the redirect url parameter, build a login url using it and redirecting to that url.
 */
class LoginRedirect extends React.Component<LoginRedirectProps> {
  redirect(props: LoginRedirectProps) {
    const parsed = queryString.parse(props.location.search);

    if (parsed && parsed.redirect) {
      const redirect =
        parsed.redirect instanceof Array ? parsed.redirect[0] : parsed.redirect;
      props.loginRedirect(redirect);
    }
  }

  componentDidMount() {
    this.redirect(this.props);
  }

  componentWillReceiveProps(
    nextProps: Readonly<LoginRedirectProps>,
    nextContext: any
  ): void {
    this.redirect(nextProps);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginRedirect,
});

export default withRouter(connect(null, mapDispatchToProps)(LoginRedirect));
