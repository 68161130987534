import * as React from "react";
import {
  change,
  Field,
  FieldArray,
  Fields,
  Form,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./DevelopmentItemPropsForm.scss";
import {
  createDevelopmentItem,
  DevelopmentItem,
  MAX_YEARS,
  updateDevelopmentItem,
} from "../../modules/development";
import { compose } from "redux";
import TextAreaField from "../Forms/Fields/TextAreaField";
import SelectField, { Option } from "../Forms/Fields/SelectField";
import DeletableInputFieldArray from "../Forms/Fields/DeletableInputFieldArray";
import { QualityPathSelectionField } from "../Forms/Fields/QualityPathSelectionField";
import statusOngoingIcon from "../../images/status-ongoing.svg";
import statusReadyIcon from "../../images/status-ready.svg";
import statusNotStartedIcon from "../../images/status-notstarted.svg";
import {
  DevelopmentItemPriority,
  DevelopmentItemStatus,
  PathType,
} from "../../model";
import { AuditMetaInfo } from "../../modules/auditmetainfo";
import { AttachmentField } from "../Forms/Fields/AttachmentField";
import { SET_ATTACHMENT_MODAL_DATA } from "../../modules/attachment";
import { AttachmentModal } from "../AttachmentModal/AttachmentModal";
import Alert from "react-s-alert";
import { identity } from "lodash";

const REDUX_FORM_IDENTIFIER = "DevelopmentItemPropsForm";

interface IOwnProps extends WithTranslation {
  orgId: number;
  item: DevelopmentItem;
  auditMetaInfo: AuditMetaInfo;
  readOnly: boolean;
  showTopSports: boolean;
  onCloseForm(): void;
}

interface IReduxFormProps {
  valid: boolean;
  submitting: boolean;
  handleSubmit(values: any): void;
  initialize(formData: any): void;
}

type IProps = IOwnProps &
  IReduxFormProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class DevelopmentItemPropsFormBase extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.themesToOptions = this.themesToOptions.bind(this);
    this.handlePathTypeChange = this.handlePathTypeChange.bind(this);
  }

  initForm(item: DevelopmentItem) {
    // transform assignee array to a map which suits FieldArray
    const assignees = (item.assignees && item.assignees.length > 0
      ? item.assignees
      : [""]
    ).map((assignee) => ({
      name: assignee,
    }));
    this.props.initialize({ ...item, assignees });
  }

  componentDidMount() {
    if (this.props.item) {
      this.initForm(this.props.item);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.item !== prevProps.item && this.props.item) {
      this.initForm(this.props.item);
    }
  }

  themesToOptions(pathType: string) {
    const { auditMetaInfo, t } = this.props;

    return auditMetaInfo.parts.reduce<Option[]>((filtered, part) => {
      const sections = part.sections.filter(
        (section) =>
          !section.qualityPaths ||
          section.qualityPaths.includes(PathType[pathType]) ||
          (pathType === PathType.YOUTH_AND_ADULTS &&
            (section.qualityPaths.includes(PathType.YOUTH) ||
              section.qualityPaths.includes(PathType.ADULTS))) ||
          pathType === PathType.ALL
      );

      // If there are any themes for the given quality path in the current part, add a category item followed
      // by the theme selection items
      if (sections.length > 0) {
        return filtered.concat(
          [identity<Option>({ category: t(`forms:${part.key}.title`) })].concat(
            sections.map((section) => ({
              value: section.key,
              label: this.props.t(`forms:${section.key}.formHeader`),
            }))
          )
        );
      } else {
        return filtered;
      }
    }, []);
  }

  getYearOptions() {
    const thisYear = new Date().getFullYear();
    return Array(MAX_YEARS)
      .fill(0)
      .map((_, i) => ({
        value: thisYear + i,
        label: (thisYear + i).toString(),
      }));
  }

  handlePathTypeChange(value: string) {
    const { auditMetaInfo, changeThemeValue, formTheme } = this.props;

    if (
      !auditMetaInfo.parts.some((part) =>
        part.sections.some(
          (section) =>
            section.key === formTheme &&
            (!section.qualityPaths ||
              section.qualityPaths.includes(PathType[value]) ||
              value === PathType.ALL)
        )
      )
    ) {
      changeThemeValue(null);
    }
  }

  render() {
    const {
      t,
      onCloseForm,
      submitting,
      handleFormSubmit,
      handleSubmit,
      changeAssigneeValue,
      changeAttachmentFileIds,
      formPathType,
      formTheme,
      formStatus,
      formDescription,
      formAttachmentFileIds,
      item,
      orgAttachments,
      openAttachmentModal,
      readOnly,
      showTopSports,
    } = this.props;

    const defaultPathType = showTopSports
      ? PathType.ALL
      : PathType.YOUTH_AND_ADULTS;
    const selectedPathType = formPathType ?? defaultPathType;

    const linkedAttachments =
      formAttachmentFileIds &&
      orgAttachments &&
      formAttachmentFileIds.map((id) =>
        orgAttachments.find((attachment) => attachment.id === id)
      );

    return (
      <Form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="development-item-props-form"
      >
        <AttachmentModal
          formLinkMode={true}
          linkedAttachments={linkedAttachments}
          linkAttachmentsToField={changeAttachmentFileIds}
        />

        <h1>
          {t(
            `itemPropsForm.${
              readOnly ? "info" : item && item.id ? "modify" : "create"
            }`
          )}
        </h1>

        <Field
          label={t("itemPropsForm.description.field")}
          name="description"
          formCtrlClassName="field"
          component={TextAreaField}
          maxlength={4000}
          placeholder={
            !readOnly ? t("itemPropsForm.description.placeholder") : undefined
          }
          disabled={readOnly}
        />

        <Field
          label={t("itemPropsForm.priority")}
          name="priority"
          className="field"
          component={SelectField}
          options={[
            { value: null, label: t("itemPropsForm.undefined") },
            { value: DevelopmentItemPriority.IMPORTANT, label: t("important") },
          ]}
          placeholder={t("itemPropsForm.undefined")}
          disabled={readOnly}
          format={null}
        />

        <Fields
          label={t("itemPropsForm.pathType")}
          names={["pathType"]}
          className="field"
          component={QualityPathSelectionField}
          onValueChange={this.handlePathTypeChange}
          disabled={readOnly}
          format={null}
          showTopSports={showTopSports}
        />

        <Field
          label={t("itemPropsForm.theme")}
          name="theme"
          className="field"
          component={SelectField}
          options={this.themesToOptions(selectedPathType)}
          placeholder={t("itemPropsForm.undefined")}
          disabled={readOnly}
          format={null}
        />

        <Field
          label={t("itemPropsForm.year")}
          name="year"
          className="field"
          component={SelectField}
          options={[
            { value: null, label: t("itemPropsForm.undefined") },
            ...this.getYearOptions(),
          ]}
          placeholder={t("itemPropsForm.undefined")}
          disabled={readOnly}
          format={null}
        />

        <div className="field">
          <label className="formlabel">
            {t("itemPropsForm.assignee.field")}
          </label>
          <FieldArray
            name="assignees"
            formCtrlClassName="wideformfield"
            component={DeletableInputFieldArray}
            disabled={readOnly}
            addNewText={`+ ${t("itemPropsForm.assignee.addAssignee")}`}
            minOnePresent={true}
            changeFieldValue={changeAssigneeValue}
            placeholder={
              !readOnly ? t("itemPropsForm.assignee.placeholder") : undefined
            }
          />
        </div>

        <Field
          label={t("itemPropsForm.status")}
          name="status"
          className="field"
          component={SelectField}
          options={[
            {
              value: null,
              label: t("itemPropsForm.undefined"),
            },
            {
              value: DevelopmentItemStatus.NOT_STARTED,
              label: (
                <div className="statuschoice">
                  <img
                    src={statusNotStartedIcon}
                    alt={t("itemStatus.notStarted")}
                  />
                  {t("itemStatus.notStarted")}
                </div>
              ),
            },
            {
              value: DevelopmentItemStatus.ONGOING,
              label: (
                <div className="statuschoice">
                  <img src={statusOngoingIcon} alt={t("itemStatus.ongoing")} />
                  {t("itemStatus.ongoing")}
                </div>
              ),
            },
            {
              value: DevelopmentItemStatus.READY,
              label: (
                <div className="statuschoice">
                  <img src={statusReadyIcon} alt={t("itemStatus.ready")} />
                  {t("itemStatus.ready")}
                </div>
              ),
            },
          ]}
          placeholder={t("itemPropsForm.undefined")}
          disabled={readOnly}
          format={null}
        />

        {formStatus === DevelopmentItemStatus.READY && (
          <Field
            label={t("itemPropsForm.results.field")}
            name="results"
            formCtrlClassName="field"
            component={TextAreaField}
            maxlength={4000}
            placeholder={
              !readOnly ? t("itemPropsForm.results.placeholder") : undefined
            }
            disabled={readOnly}
          />
        )}

        <hr />

        <Field
          label={t("itemPropsForm.comment.field")}
          name="comment"
          formCtrlClassName="field"
          component={TextAreaField}
          maxlength={4000}
          placeholder={
            !readOnly ? t("itemPropsForm.comment.placeholder") : undefined
          }
          disabled={readOnly}
        />

        <div className="field">
          <label className="formlabel">
            {t("itemPropsForm.attachments.field")}
          </label>
          <Field
            name="attachmentFileIds"
            formCtrlClassName="field"
            component={AttachmentField}
            orgId={1}
            placeholder={t("itemPropsForm.attachments.placeholder")}
            readOnly={readOnly}
            linkedAttachments={linkedAttachments}
            unlinkAttachment={(removedId) => {
              changeAttachmentFileIds(
                formAttachmentFileIds.filter((id) => id !== removedId)
              );
            }}
            openAttachmentModal={openAttachmentModal}
            forceShowContent={true}
          />
        </div>

        {!readOnly ? (
          <div className="button-container">
            <button
              disabled={
                submitting ||
                !formDescription ||
                formDescription.trim().length === 0 ||
                !formPathType ||
                !formTheme
              }
              className="btn-secondary-blue"
              type="submit"
            >
              {t("itemPropsForm.save")}
            </button>
            <button
              className="btn-secondary-blue"
              type="button"
              onClick={onCloseForm}
            >
              {t("forms:buttons.cancel")}
            </button>
          </div>
        ) : (
          <div className="button-container">
            <button
              className="btn-secondary-blue close-button"
              type="button"
              onClick={onCloseForm}
            >
              {t("itemPropsForm.close")}
            </button>
          </div>
        )}
      </Form>
    );
  }
}

const formSelector = formValueSelector(REDUX_FORM_IDENTIFIER);

const mapStateToProps = (state) => ({
  formPathType: formSelector(state, "pathType"),
  formTheme: formSelector(state, "theme"),
  formStatus: formSelector(state, "status"),
  formDescription: formSelector(state, "description"),
  formAttachmentFileIds: formSelector(state, "attachmentFileIds"),
  orgAttachments: state.attachment.orgAttachments,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleFormSubmit: (formValues: any, dispatch, props) => {
    // transform the assignee map gotten from the FieldArray to a string array
    const values = {
      ...formValues,
      assignees: formValues.assignees
        ? formValues.assignees
            .filter(
              (assigneeEntry) =>
                assigneeEntry &&
                assigneeEntry.name &&
                assigneeEntry.name.trim().length > 0
            )
            .map((assigneeEntry) => assigneeEntry.name)
        : null,
    };
    const { t } = ownProps;

    dispatch(
      props.item.id
        ? updateDevelopmentItem(props.item.id, values)
        : createDevelopmentItem(props.orgId, values)
    ).then((success) => {
      if (success) {
        ownProps.onCloseForm();
        Alert.info(
          t(`alerts.${props.item.id ? "itemUpdateInfo" : "itemCreationInfo"}`)
        );
      }
    });
  },
  changeAssigneeValue: (index: number, value: string) =>
    dispatch(change(REDUX_FORM_IDENTIFIER, `assignees[${index}].name`, value)),
  changeThemeValue: (value: string | null) => {
    dispatch(change(REDUX_FORM_IDENTIFIER, "theme", value));
  },
  changeAttachmentFileIds: (value: number[]) => {
    dispatch(change(REDUX_FORM_IDENTIFIER, "attachmentFileIds", value));
  },
  openAttachmentModal: () => {
    dispatch(
      SET_ATTACHMENT_MODAL_DATA({
        modalOpen: true,
        showLinkTable: true,
      })
    );
  },
});

const enhance: any = compose(
  withTranslation("clubDevelopment"),
  reduxForm({
    form: REDUX_FORM_IDENTIFIER,
    touchOnChange: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export const DevelopmentItemPropsForm = enhance(DevelopmentItemPropsFormBase);
