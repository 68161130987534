import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./ClubDashboard.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchNews } from "../../modules/contentful";
import { LoadingContainer } from "../../components/LoadingContainer";
import ReactMarkdown from "react-markdown";
import pathImage from "../../images/path-image.svg";
import { getClubDashboardUrl } from "../../routePaths";
import { fetchEnrollments } from "../../modules/path";

type NewsPageProps = WithTranslation &
  RouteComponentProps<{ orgId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface NewsPageState {
  newsLoading: boolean;
}

/*
  Fetch tool links from contentful using contentful-module. Group by topic and map through the groups
 */
class NewsPage extends Component<NewsPageProps, NewsPageState> {
  constructor(props: NewsPageProps) {
    super(props);
    this.state = {
      newsLoading: false,
    };
  }

  async componentDidMount() {
    const {
      news,
      match: {
        params: { orgId },
      },
    } = this.props;
    window.addEventListener("refreshEntries", this.fetchNews);
    if (!news) {
      await this.fetchNews();
    }
    if (!this.props.enrollments) {
      this.props.fetchEnrollments(parseInt(orgId, 10));
    }
  }

  fetchNews = async () => {
    this.setState({ newsLoading: true });
    const response = await this.props.fetchNews();
    if (response) {
      this.setState({ newsLoading: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("refreshEntries", this.fetchNews);
  }

  navigateToPath = () => {
    const {
      history,
      match: {
        params: { orgId },
      },
    } = this.props;
    history.push(getClubDashboardUrl(orgId) + "/path");
  };

  render() {
    const { news, t, user, enrollments } = this.props;
    const pageContent = news ? news.items[0] : null;
    const showStart = enrollments && enrollments.length < 1;
    return (
      <div className="clubnewspage">
        <div className="startcontainer">
          {user && (
            <h1>
              {t("newsPage.hello")} {user.firstName}!
            </h1>
          )}
          {showStart && (
            <div className="noenrollment">
              <div className="text">
                <div className="startheader">{t("newsPage.start")}</div>

                <div>{t("newsPage.startInfo")}</div>
              </div>
              <img src={pathImage} alt="" />
              <button
                className="btn btn-secondary btn-secondary-blue"
                onClick={this.navigateToPath}
              >
                {t("newsPage.startButton")}
              </button>
            </div>
          )}
        </div>
        <LoadingContainer
          loading={this.state.newsLoading}
          className="contentful-news"
        >
          <div className="news markdownbox">
            <h1>{t("clubnews.news")}</h1>
            <ReactMarkdown
              className="markdown"
              source={pageContent ? pageContent.fields.news : null}
            />
          </div>
          <div className="eventsimpactcontainer">
            <div className="events markdownbox">
              <h1>{t("clubnews.events")}</h1>
              <ReactMarkdown
                className="markdown"
                source={pageContent ? pageContent.fields.events : null}
              />
            </div>
            <div className="impact markdownbox">
              <h1>{t("clubnews.impact")}</h1>
              <ReactMarkdown
                className="markdown"
                source={pageContent ? pageContent.fields.impact : null}
              />
            </div>
          </div>
        </LoadingContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    news: state.contentful.entries.news,
    user: state.account.user,
    enrollments: state.path.clubEnrollments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNews: () => dispatch(fetchNews()),
  fetchEnrollments: (orgId: number) => dispatch(fetchEnrollments(orgId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("common")(NewsPage as any))
);
