import React from "react";
import { Component } from "react";
import { isEqual } from "lodash";

import "./DescriptionModal.scss";
import { FormGroup, Modal, FormControl, ControlLabel } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { change } from "redux-form";

import { ReduxState } from "../../reducers";
import { CLOSE_DESCRIPTION_MODAL } from "../../modules/auditRequest";

type DescriptionModalProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class DescriptionModal extends Component<DescriptionModalProps, any> {
  constructor(props: DescriptionModalProps) {
    super(props);
    this.state = {
      open: false,
      inputValue: this.props.modalData.value ? this.props.modalData.value : "",
    };
  }

  componentWillReceiveProps(newProps: DescriptionModalProps) {
    if (!isEqual(newProps.modalData.value, this.props.modalData.value)) {
      this.setState({
        inputValue: newProps.modalData.value ? newProps.modalData.value : "",
      });
    }
  }

  onAuditChange = (selectedOption: any) => {
    this.setState({ auditValue: selectedOption });
  };

  handleCommentUpdate = () => {
    const { modalData } = this.props;
    if (modalData) {
      this.props.submitDescription(
        modalData.form!,
        modalData.field!,
        this.state.inputValue
      );
      // update field value when saving a description
      const fieldUpdateEvent = new CustomEvent("updateSingleField", {
        detail: { fieldName: modalData.field, value: this.state.inputValue },
      });
      window.dispatchEvent(fieldUpdateEvent);
      this.props.onCloseModal();
    }
  };

  handleInputValue = (event: any) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  render() {
    const { modalData, t } = this.props;
    // simpler version of comment modal, adds a description entry to the redux-form data
    return (
      <div>
        <Modal
          show={modalData.show}
          onHide={this.props.onCloseModal}
          dialogClassName="comment-modal"
        >
          <Modal.Body>
            <h1>{t("modal.description.header")}</h1>

            <div className="commentfield">
              <form>
                <FormGroup>
                  <ControlLabel>{t("modal.description.label")}</ControlLabel>
                  <FormControl
                    autoFocus={true}
                    componentClass="textarea"
                    value={this.state.inputValue}
                    placeholder={t("modal.placeholder")}
                    onChange={this.handleInputValue}
                    maxLength={2000}
                  />
                </FormGroup>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={this.handleCommentUpdate}
            >
              {t("modal.description.save")}
            </button>
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={this.props.onCloseModal}
            >
              {t("modal.close")}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const commentTranslated = withTranslation("common")(DescriptionModal);

const mapStateToProps = (state: ReduxState) => {
  return {
    modalData: state.auditRequest.descriptionModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => dispatch(CLOSE_DESCRIPTION_MODAL()),
  submitDescription: (form: string, valueName: string, value: string) =>
    dispatch(change(form, valueName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(commentTranslated);
