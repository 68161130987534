import * as React from "react";
import { connect } from "react-redux";
import "./PathNotes.scss";
import closebutton from "../../images/close-icon.svg";
import { ReduxState } from "../../reducers";
import { SET_NOTES_MODAL } from "../../modules/path";
import { RouteComponentProps, withRouter } from "react-router";
import {
  fetchRequestNotes,
  saveRequestNotes,
} from "../../modules/auditRequest";
import { withTranslation, WithTranslation } from "react-i18next";
import { LoadingSpinner } from "../LoadingSpinner";

export enum PathNotesPosition {
  Top,
  Floating,
  Bottom,
}

interface OwnProps {
  auditor?: boolean;
  position: PathNotesPosition;
  maxHeight?: number;
}

type PathNotesProps = ReturnType<typeof mapStateToProps> &
  OwnProps &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ orgId: string; type: string; part: string }> &
  WithTranslation;

class PathNotesComponent extends React.Component<
  PathNotesProps,
  { input: string | null; showFailure: boolean; showSuccess: boolean }
> {
  private alertTimeout: any = null;

  private textboxRef = React.createRef<HTMLTextAreaElement>();
  private textboxHeight: number | null = null;

  constructor(props: PathNotesProps) {
    super(props);
    this.state = {
      input: null,
      showFailure: false,
      showSuccess: false,
    };
  }

  async componentDidUpdate(prevProps: PathNotesProps) {
    // dispatch event to reposition the path notes when needed
    const sizeChangeEvent = new CustomEvent("pathNotesSizeChanged");
    window.dispatchEvent(sizeChangeEvent);

    const { type, part, clubId, isNotesOpen } = this.props;
    if (!prevProps.isNotesOpen && isNotesOpen) {
      const response = await this.props.fetchNotes(clubId, type);
      if (response) {
        this.setState({
          input:
            response && response.parts && response.parts[part]
              ? response.parts[part].notes
              : "",
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.alertTimeout);
  }

  saveNotes = async () => {
    const { type, part, clubId } = this.props;
    const response = await this.props.saveNotes(clubId, type, {
      parts: { [part]: { notes: this.state.input } },
    });
    if (response) {
      this.setState({ showSuccess: true, showFailure: false }, () => {
        this.alertTimeout = setTimeout(
          () => this.setState({ showSuccess: false }),
          2000
        );
      });
    } else {
      this.setState({ showSuccess: false, showFailure: true }, () => {
        this.alertTimeout = setTimeout(
          () => this.setState({ showFailure: false }),
          2000
        );
      });
    }
  };

  // Hackish but working way of getting notified about when the user resized the text area. Note that we mustn't handle
  // size changes which are not caused by user actions here, or else we'll risk going into an infinite loop.
  mouseUpListener = (e) => {
    document.removeEventListener("mouseup", this.mouseUpListener, true);

    if (this.textboxRef.current) {
      this.textboxHeight = this.textboxRef.current.clientHeight;
    }

    // dispatch event to reposition the path notes when needed
    const sizeChangeEvent = new CustomEvent("pathNotesSizeChanged");
    window.dispatchEvent(sizeChangeEvent);
  };

  public render() {
    const {
      isNotesOpen,
      position,
      closeModal,
      auditor,
      t,
      part,
      maxHeight,
    } = this.props;
    if (!isNotesOpen) {
      return null;
    }
    return (
      <div
        className={
          position === PathNotesPosition.Bottom
            ? "pathnotes reverse"
            : "pathnotes"
        }
      >
        <div className="shadowdiv" />
        <div
          id="pathnotes"
          className={
            position === PathNotesPosition.Floating
              ? "notecontainer fixedcontainer"
              : "notecontainer relcontainer"
          }
          style={
            maxHeight
              ? {
                  // max-height needs to be calculated programatically because pathNotes is floating (position: fixed) at times.
                  maxHeight: `${maxHeight}px`,
                }
              : {}
          }
        >
          <button className="closebutton" onClick={() => closeModal()}>
            <img src={closebutton} alt="" />
          </button>
          <h2>
            {t("pathNotes.header")} — {t(`forms:${part}.title`)}
          </h2>
          <h3>
            {auditor
              ? t("pathNotes.subtitle-audit")
              : t("pathNotes.subtitle-club")}
          </h3>
          {this.state.input !== null ? (
            <textarea
              ref={this.textboxRef}
              style={
                this.textboxHeight
                  ? {
                      height: `${this.textboxHeight}px`,
                    }
                  : {}
              }
              onChange={(e) => {
                this.setState({ input: e.target.value });
              }}
              onMouseDown={() => {
                document.addEventListener(
                  "mouseup",
                  this.mouseUpListener,
                  true
                );
              }}
              value={this.state.input}
              placeholder={t("pathNotes.placeholder")}
            />
          ) : (
            <LoadingSpinner />
          )}
          <button
            type="button"
            className="btn btn-secondary btn-secondary-blue savebutton"
            onClick={() => this.saveNotes()}
            disabled={this.state.input === null}
          >
            {t("pathNotes.save")}
          </button>
          <div className="alertcontainer">
            {this.state.showFailure && (
              <div className="failure">{t("pathNotes.failure")}</div>
            )}
            {this.state.showSuccess && (
              <div className="success">{t("pathNotes.success")}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    isNotesOpen: state.path.isNotesOpen,
    notes: state.auditRequest.requestNotes,
    type: state.path.currentType,
    part: state.path.currentPart,
    clubId: state.path.currentOrgId,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  return {
    closeModal: () => dispatch(SET_NOTES_MODAL(false)),
    fetchNotes: (orgId: string, type: string) =>
      dispatch(fetchRequestNotes(orgId, type, ownProps.auditor)),
    saveNotes: (orgId: string, type: string, notes: any) =>
      dispatch(saveRequestNotes(orgId, type, notes, ownProps.auditor)),
  };
};

export const PathNotes = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(withTranslation("common")(PathNotesComponent)));
