import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchOrgSummary } from "../../modules/auditor";
import { OrgType } from "../../model";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { AuditGraphs } from "../../components/AuditGraphs/AuditGraphs";

type OrgSummaryViewProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class OrgSummaryView extends Component<OrgSummaryViewProps, any> {
  componentDidMount() {
    const { currentOrgData } = this.props;

    if (currentOrgData) {
      this.props.fetchOrgSummary(currentOrgData.orgId);
    }
  }

  render() {
    const { currentOrgData, orgSummary, t } = this.props;
    if (!currentOrgData || !orgSummary) {
      return <LoadingSpinner />;
    }

    return (
      <div className="partcontainer">
        <div className="summaryinfo">
          <span className="clubname">{currentOrgData.name}</span>
          <h1>{t("orgSummary.titles.orgSummary")}</h1>
          <h3>{t("orgSummary.titles.orgSummarySubtitle")}</h3>
        </div>
        <hr />
        {/* TODO: TOP_SPORTS */}
        <h1>{t("path.type.YOUTH")}</h1>
        <AuditGraphs
          gradeDist={orgSummary.auditorGradeDist["YOUTH"]}
          auditor={true}
          enableNavigation={false}
        />
        <hr />
        <h1>{t("path.type.ADULTS")}</h1>
        <AuditGraphs
          gradeDist={orgSummary.auditorGradeDist["ADULTS"]}
          auditor={true}
          enableNavigation={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{ orgId: string }>
) => {
  return {
    currentOrgData: state.account.user
      ? state.account.user.writeAccessOrgs.find(
          (x) =>
            x.orgId === parseInt(ownProps.match.params.orgId, 10) &&
            (x.type === OrgType.SPORTS_FEDERATION ||
              x.type === OrgType.COLLECTION_AREA_ASSOCIATION ||
              x.type === OrgType.ADMIN_ORGANIZATION)
        )
      : null,
    orgSummary: state.auditor.orgSummary,
  };
};

const mapDispatchToProps = {
  fetchOrgSummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("forms")(OrgSummaryView as any));
