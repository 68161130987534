import React from "react";
import { Field } from "redux-form";
import { Component } from "react";
import InputField from "../Fields/InputField";

export interface ImprovementsListProps {
  baseFieldName: string;
  readOnly?: boolean;
  placeholder?: string;
}

class ImprovementsListFields extends Component<ImprovementsListProps, any> {
  render() {
    const { baseFieldName, readOnly, placeholder } = this.props;
    return (
      <div className="improvementslist">
        <Field
          label="1."
          name={`${baseFieldName}0`}
          formCtrlClassName="wideformfield"
          component={InputField}
          placeholder={placeholder}
          disabled={readOnly}
          autosave={true}
        />
        <Field
          label="2."
          name={`${baseFieldName}1`}
          formCtrlClassName="wideformfield"
          component={InputField}
          placeholder={placeholder}
          disabled={readOnly}
          autosave={true}
        />
        <Field
          label="3."
          name={`${baseFieldName}2`}
          formCtrlClassName="wideformfield"
          component={InputField}
          placeholder={placeholder}
          disabled={readOnly}
          autosave={true}
        />
      </div>
    );
  }
}

export default ImprovementsListFields;
