import axios from "axios";
import { OrganizationCapability } from "../modules/organization";

const organization = {
  getOrganizationCapabilities: (orgId: number) =>
    axios.get(`/api/v1/organization/${orgId}/capability`),
  setOrganizationCapability: (
    orgId: number,
    capability: OrganizationCapability
  ) => {
    return axios.post(`/api/v1/organization/${orgId}/capability`, capability);
  },
};

export default organization;
