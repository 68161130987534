import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { OrgType, PendingAuditClubType } from "../../model";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchAudits } from "../../modules/auditor";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Fuse from "fuse.js";
import FinalizedTable from "../../components/Tables/FinalizedTable";
import "./Auditing.scss";
import Select from "react-select";
import SummaryTable from "../../components/Tables/SummaryTable";
import { Popover, OverlayTrigger } from "react-bootstrap";
import AuditSettingsModal from "../../components/AuditSettingsModal/AuditSettingsModal";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { getExcelExport } from "../../routePaths";
import i18next from "i18next";

const options = {
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["clubName"],
};

type AuditingProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class Auditing extends Component<AuditingProps, any> {
  constructor(props: AuditingProps) {
    super(props);
    this.state = {
      fuzzyInput: "",
      pathFilter: null,
      openTab: 0,
    };
  }

  componentDidMount() {
    const { currentOrgData } = this.props;
    if (currentOrgData) {
      this.props.fetchAudits(currentOrgData.orgId);
    }
  }

  onPathFilterChange = (value) => {
    this.setState({ pathFilter: value });
  };

  render() {
    const { pendingAudits, finalizedAudits, t, currentOrgData } = this.props;
    const dataAvailable = pendingAudits && finalizedAudits;
    const filterOptions = [
      { value: "YOUTH", label: t("auditfilterYouth") },
      { value: "ADULTS", label: t("auditfilterAdults") },
      { value: "TOP_SPORTS", label: t("auditfilterTopSports") },
    ];

    if (!currentOrgData) {
      return null;
    }
    let pending = pendingAudits ? pendingAudits : [];
    let finalized = finalizedAudits ? finalizedAudits : [];
    if (this.state.pathFilter) {
      pending = pending.filter((x) => x.qualityPath === this.state.pathFilter);
      finalized = finalized.filter(
        (x) => x.qualityPath === this.state.pathFilter
      );
    }
    const { fuzzyInput } = this.state;
    const grantedList = finalized.filter((x) => x.awardGranted);
    const grantedFuse = new Fuse(grantedList, options);
    const rejectedList = finalized.filter((x) => !x.awardGranted);
    const rejectedFuse = new Fuse(rejectedList, options);

    const inProcessList = pending.filter((x) =>
      x.clubTypes.includes(PendingAuditClubType.IN_PROCESS)
    );
    const inProcessFuse = new Fuse(inProcessList, options);
    const firstTimerList = pending.filter((x) =>
      x.clubTypes.includes(PendingAuditClubType.FIRST_TIMER)
    );
    const firstTimerFuse = new Fuse(firstTimerList, options);
    const awaitingAuditList = pending.filter((x) =>
      x.clubTypes.includes(PendingAuditClubType.AWAITING_AUDIT)
    );
    const awaitingAuditFuse = new Fuse(awaitingAuditList, options);
    const awaitingCorrectionsList = pending.filter((x) =>
      x.clubTypes.includes(PendingAuditClubType.AWAITING_CORRECTIONS)
    );
    const awaitingCorrectionsFuse = new Fuse(awaitingCorrectionsList, options);

    const popoverGranted = (
      <Popover id="popover-positioned-bottom">{t("popovers.granted")}</Popover>
    );
    const popoverFirst = (
      <Popover id="popover-positioned-bottom">{t("popovers.first")}</Popover>
    );
    const popoverInProcess = (
      <Popover id="popover-positioned-bottom">
        {t("popovers.inProcess")}
      </Popover>
    );
    const popoverAwaitingAudit = (
      <Popover id="popover-positioned-bottom">
        {t("popovers.awaitingAudit")}
      </Popover>
    );
    const popoverAwaitingCorrection = (
      <Popover id="popover-positioned-bottom">
        {t("popovers.pendingCorrect")}
      </Popover>
    );
    const popoverRejected = (
      <Popover id="popover-positioned-bottom">{t("popovers.rejected")}</Popover>
    );

    const isArea = currentOrgData.type === OrgType.COLLECTION_AREA_ASSOCIATION;
    const isFed = currentOrgData.type === OrgType.SPORTS_FEDERATION;

    return (
      <div className="auditingcontainer">
        <AuditSettingsModal />
        <div className="orginfocontainer">
          <div className="auditing-container-federation-name">
            {currentOrgData.name}
          </div>
          <h2>{t("auditTitle")}</h2>
          <div className="auditing-help-text">{t("auditingHelpText")}</div>
        </div>
        {!dataAvailable ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="infocontainer">
              <h2>{`${t("auditSubtitle1")} ${inProcessList.length}, ${t(
                "auditSubtitle2"
              )} ${grantedList ? grantedList.length : 0}`}</h2>
            </div>
            <div className="searchcontainer">
              <div className="bar searchbar">
                <div>{t("searchName")}</div>
                <input
                  type="text"
                  name="search"
                  value={this.state.fuzzyInput}
                  onChange={(event) =>
                    this.setState({ fuzzyInput: event.target.value })
                  }
                  placeholder={t("searchNamePlaceholder")}
                />
              </div>
              <div className="bar filterbar">
                <div>{t("auditfilter")}</div>
                <Select
                  placeholder={t("auditfilterType")}
                  value={this.state.pathFilter}
                  onChange={this.onPathFilterChange}
                  options={filterOptions}
                  simpleValue={true}
                />
              </div>
              <div className="buttonbar">
                <a
                  className="btn btn-secondary btn-secondary-blue"
                  href={getExcelExport(
                    currentOrgData.orgId.toString(),
                    i18next.language
                  )}
                >
                  <div>{t("exportExcel")}</div>
                </a>
              </div>
            </div>

            <Tabs
              defaultIndex={this.state.openTab}
              onSelect={(index) => this.setState({ openTab: index })}
            >
              <TabList>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverGranted}
                  >
                    <div>{t("auditTabs.granted")}</div>
                  </OverlayTrigger>
                </Tab>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverFirst}
                  >
                    <div>{t("auditTabs.firstTimers")}</div>
                  </OverlayTrigger>
                </Tab>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverInProcess}
                  >
                    <div>{t("auditTabs.inProcess")}</div>
                  </OverlayTrigger>
                </Tab>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverAwaitingAudit}
                  >
                    <div>{t("auditTabs.awaitingAudit")}</div>
                  </OverlayTrigger>
                </Tab>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverAwaitingCorrection}
                  >
                    <div>{t("auditTabs.awaitingCorrections")}</div>
                  </OverlayTrigger>
                </Tab>
                <Tab>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverRejected}
                  >
                    <div>{t("auditTabs.rejected")}</div>
                  </OverlayTrigger>
                </Tab>
              </TabList>
              <TabPanel>
                <FinalizedTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  allowEdit={true}
                  list={
                    fuzzyInput ? grantedFuse.search(fuzzyInput) : grantedList
                  }
                />
              </TabPanel>
              <TabPanel>
                <SummaryTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  list={
                    fuzzyInput
                      ? firstTimerFuse.search(fuzzyInput)
                      : firstTimerList
                  }
                />
              </TabPanel>
              <TabPanel>
                <SummaryTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  list={
                    fuzzyInput
                      ? inProcessFuse.search(fuzzyInput)
                      : inProcessList
                  }
                />
              </TabPanel>
              <TabPanel>
                <SummaryTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  list={
                    fuzzyInput
                      ? awaitingAuditFuse.search(fuzzyInput)
                      : awaitingAuditList
                  }
                />
              </TabPanel>
              <TabPanel>
                <SummaryTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  list={
                    fuzzyInput
                      ? awaitingCorrectionsFuse.search(fuzzyInput)
                      : awaitingCorrectionsList
                  }
                />
              </TabPanel>

              <TabPanel>
                <FinalizedTable
                  showArea={!isArea}
                  showFederation={!isFed}
                  allowEdit={false}
                  list={
                    fuzzyInput ? rejectedFuse.search(fuzzyInput) : rejectedList
                  }
                />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{ orgId: string }>
) => {
  return {
    currentOrgData: state.account.user
      ? state.account.user.writeAccessOrgs.find(
          (x) =>
            x.orgId === parseInt(ownProps.match.params.orgId, 10) &&
            (x.type === OrgType.SPORTS_FEDERATION ||
              x.type === OrgType.COLLECTION_AREA_ASSOCIATION ||
              x.type === OrgType.ADMIN_ORGANIZATION)
        )
      : null,
    user: state.account.user,
    pendingAudits: state.auditor.pendingAudits,
    finalizedAudits: state.auditor.finalizedAudits,
    modalIsOpen: state.auditSettings.modalIsOpen,
  };
};

const mapDispatchToProps = {
  fetchAudits: fetchAudits,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Auditing as any));
