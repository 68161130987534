import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as Logger from "js-logger";
import ChainedBackend from "i18next-chained-backend";
import { siclI18nextBackend } from "@suomisport/ui-components/utilities/i18n/siclI18nextBackend";

function getPrefixedPath() {
  const version = process.env.REACT_APP_VERSION;
  const suffix = version ? `?q=${encodeURIComponent(version)}` : "";

  if (window && (window as any).resourcePath) {
    return (window as any).resourcePath + "/i18n/{{lng}}/{{ns}}.json" + suffix;
  }

  return "/i18n/{{lng}}/{{ns}}.json" + suffix;
}

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(
    {
      backend: {
        backends: [siclI18nextBackend, XHR],
        backendOptions: [
          {},
          {
            loadPath: getPrefixedPath,
          },
        ],
      },
      debug: false,
      defaultNS: "common",
      detection: {
        caches: ["cookie"], // Cache user language to co
        cookieMinutes: 40320, // One month
        order: ["querystring", "cookie", "customNavigatorLanguageDetector"],
        lookupQuerystring: "locale",
      },
      fallbackLng: "fi_FI",
      // List the namespaces aka *.json files
      ns: ["common", "menu", "forms", "clubDevelopment", "supportWidget"],
      react: {
        wait: true,
      },
    },
    (err, t) => {
      Logger.debug("i18n translations initialized");
    }
  );

export default i18n;
