import React from "react";
import { PartStatus } from "../../model";
import * as classNames from "classnames";
import { v4 } from "uuid";

// handles the path part status circle rendering, navigate function should send user to the PathForm.tsx
export const PartIcon = (
  name: string,
  icon: any,
  ready: PartStatus,
  connector: boolean,
  connectorReady: boolean,
  type: string,
  orgId: string,
  pathType: string,
  navigateFunc: any,
  navigateArg: any,
  pathSubmitted: boolean,
  showNewComments: boolean,
  enrollId: string,
  isArchived: Boolean
) => {
  // icons inside circles are defined in path reducer, but these classes fill in the background with appropriate color.
  const circleNames = classNames("statecircle", {
    hovershadow: !!navigateFunc,
    circleReady:
      (ready === PartStatus.READY || ready === PartStatus.AWAITING_REVISION) &&
      !pathSubmitted,
    circleDraft: ready === PartStatus.DRAFT,
    circleLocked: pathSubmitted,
  });
  const nameClasses = classNames("name", {
    linkActive: !!navigateFunc,
    newComments: showNewComments,
  });

  const connectorColor = (
    pathSubmitted: Boolean,
    connectorReady: Boolean,
    isArchived: Boolean,
    navigateFunc: any
  ) => {
    if (isArchived) {
      return "#ccd2d2";
    }
    return !pathSubmitted && connectorReady && !!navigateFunc
      ? "forestgreen"
      : undefined;
  };
  return (
    <div className="part" key={v4()}>
      <div
        className="info"
        onClick={() =>
          !!navigateFunc
            ? navigateFunc(navigateArg, orgId, type, pathType, enrollId)
            : null
        }
      >
        <div className={circleNames}>
          <img src={icon} alt="" />
        </div>
        <div className={nameClasses}>{name}</div>
      </div>
      {connector && (
        <div
          className="connector"
          style={{
            color: connectorColor(
              pathSubmitted,
              connectorReady,
              isArchived,
              navigateFunc
            ),
          }}
        >
          •••••••
        </div>
      )}
    </div>
  );
};
