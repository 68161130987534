import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../../reducers";
import { useTranslation } from "react-i18next";
import {
  SET_ATTACHMENT_MODAL_DATA,
  linkAttachments,
} from "../../../modules/attachment";
import "./AttachmentField.scss";
import { useParams } from "react-router";
import Alert from "react-s-alert";
import { PartStatus } from "../../../model";
import { updateAuditRequestPart } from "../../../modules/auditRequest";
import { AttachmentField } from "./AttachmentField";

interface Props {
  name: string;
  orgId: string;
  readOnly?: boolean;
  edit?: boolean;
  placeholder?: string;
}

export function AttachmentAuditField(props: Props) {
  const { name } = props;
  const { t } = useTranslation("forms");
  const linkedAttachments = useSelector(
    (state: ReduxState) => state.attachment.partAttachments[name]
  );
  const dispatch = useDispatch();
  const { requestId, part, orgId, type } = useParams<any>();
  const { auditRequest, enrollment } = useSelector((state: ReduxState) => {
    const enrollment =
      state.path.clubEnrollments &&
      state.path.clubEnrollments.find(
        (x) =>
          (x.clubId ? x.clubId.toString() : null) === orgId &&
          x.qualityPath === type &&
          x.enrollmentTimestamp !== null
      );
    const auditRequests =
      enrollment && enrollment.id
        ? state.auditRequest.requestLists[enrollment.id]
        : null;
    const request =
      auditRequests && requestId
        ? auditRequests.find(
            (x) => x.id === parseInt(requestId, 10) && x.qualityPath === type
          )
        : null;
    return {
      enrollment,
      auditRequest: request,
    };
  });

  const openAttachmentModal = () =>
    dispatch(
      SET_ATTACHMENT_MODAL_DATA({
        modalOpen: true,
        showLinkTable: true,
        fieldName: name,
        auditRequest: auditRequest!,
        enrollment: enrollment!,
      })
    );

  const unlinkAttachment = async (id: number) => {
    const filteredAttachments = linkedAttachments.filter((x) => x.id !== id);
    const fileIds = filteredAttachments.map((x) => x.id);
    if (part) {
      const partObject = auditRequest
        ? auditRequest.parts.find((x) => x.key === part)
        : null;
      // Enrollment and auditRequest are not set when in the auditor view. This prevents the request part state
      // from being reset to DRAFT when the auditor unlinks an attachment.
      if (
        enrollment &&
        auditRequest &&
        partObject &&
        partObject.state !== "DRAFT"
      ) {
        await dispatch(
          updateAuditRequestPart(
            enrollment.id!,
            auditRequest.id.toString(),
            part,
            PartStatus.DRAFT,
            true
          )
        );
      }
      const response = await dispatch(
        linkAttachments(enrollment!.id!, Number(requestId), part, name, fileIds)
      );
      !!response
        ? Alert.info(t("common:apiAlerts.unlinkAttachment"))
        : Alert.error(t("common:apiErrors.unlinkAttachment"));
    }
  };

  return (
    <AttachmentField
      linkedAttachments={linkedAttachments}
      openAttachmentModal={openAttachmentModal}
      unlinkAttachment={unlinkAttachment}
      {...props}
    />
  );
}
