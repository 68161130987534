import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

class ResetScrollPosition extends Component<RouteComponentProps<{}>> {
  componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ResetScrollPosition);
