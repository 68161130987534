import React, { useEffect } from "react";
import {
  DevelopmentItemCard,
  DevelopmentItemCardProps,
} from "./DevelopmentItemCard";
import { DragTypes } from "../../views/clubdevelopment/DragTypes";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export function DraggableDevelopmentItemCard(props: DevelopmentItemCardProps) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragTypes.DEVELOPMENT_ITEM_CARD,
      developmentItem: props.item,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, [preview]);

  return (
    <DevelopmentItemCard
      {...props}
      innerRef={!props.item.locked && !props.readOnly ? drag : undefined}
      opacity={isDragging ? 0.5 : 1}
    />
  );
}
