import React from "react";
import { HelpBlock, FormGroup } from "react-bootstrap";
import FormFieldDescription from "./components/FormFieldDescription";
import { useTranslation } from "react-i18next";

interface TextAreaFieldProps {
  className?: string;
  formCtrlClassName?: string;
  disabled?: boolean;
  displayLabel?: boolean;
  helpBlockPosition?: string;
  input: {
    value: any;
    onChange(): void;
  };
  label?: string;
  labelClassName?: string;
  fieldsAsColumns?: boolean;
  helpText?: string;
  meta: {
    invalid: boolean;
    error: boolean;
    touched: boolean;
  };
  optional?: boolean;
  options: any[];
  placeholder?: string;
  rows?: number;
  maxlength?: number;
  wrapperClassNames?: string;
}

const TextAreaField: React.StatelessComponent<TextAreaFieldProps> = ({
  formCtrlClassName,
  className,
  displayLabel,
  helpBlockPosition,
  label,
  labelClassName,
  wrapperClassNames,
  input,
  optional,
  disabled,
  placeholder,
  helpText,
  rows,
  maxlength,
  meta: { invalid, error, touched },
}) => {
  let usedClassNames = wrapperClassNames
    ? `${wrapperClassNames} formfield`
    : "formfield";
  usedClassNames = disabled ? `${usedClassNames} disabled` : usedClassNames;

  const inputClassNames = className
    ? `${className} form-control`
    : "form-control";

  const usedLabel = label ? label : null;
  const usedLabelClassName = labelClassName
    ? `formlabel ${labelClassName}`
    : "formlabel";
  const [t] = useTranslation();
  let blockTop;
  let blockBottom;
  const labelStyle =
    displayLabel === false ? { display: "none" } : { display: "inline" };

  const optionalText = optional ? (
    <span className="fieldoptionallabel">{t("forms:optional")}</span>
  ) : null;

  if (helpBlockPosition === "top") {
    blockTop =
      invalid && error && touched ? (
        <HelpBlock>{error}</HelpBlock>
      ) : (
        <label className={usedLabelClassName} style={labelStyle}>
          {usedLabel}
          {optionalText}
        </label>
      );
  } else if (usedLabel || optionalText) {
    blockTop = (
      <label className={usedLabelClassName} style={labelStyle}>
        {usedLabel}
        {optionalText}
      </label>
    );
  }

  if (helpBlockPosition === "bottom" || !helpBlockPosition) {
    blockBottom =
      invalid && error && touched ? <HelpBlock>{error}</HelpBlock> : null;
  }

  return (
    <FormGroup
      className={formCtrlClassName}
      controlId={placeholder}
      validationState={invalid && error && touched ? "error" : "success"}
    >
      {blockTop && (
        <div className="fieldlabel">
          {blockTop}
          <FormFieldDescription>{helpText}</FormFieldDescription>
        </div>
      )}
      <div className={usedClassNames}>
        <textarea
          rows={rows}
          className={inputClassNames}
          disabled={disabled}
          placeholder={placeholder}
          {...input}
          maxLength={maxlength}
        />
        {blockBottom}
      </div>
    </FormGroup>
  );
};

export default TextAreaField;
