import React, { useEffect, useState } from "react";
import { AuditorInfo, OrgType, PathData, PathType } from "../../model";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAudits } from "../../modules/auditor";
import { ReduxState } from "../../reducers";
import "./ClubPage.scss";
import {
  fetchOrganizationCapabilities,
  OrganizationCapabilityType,
  setOrganizationCapability,
} from "../../modules/organization";
import { Modal } from "react-bootstrap";

export const ClubPage: React.FC = () => {
  const { t } = useTranslation("common");
  const match = useRouteMatch<any>();
  const { superOrgId, orgId: clubId } = match?.params;

  const dispatch = useDispatch();

  const [
    showTopSportsCapabilityModal,
    setShowTopSportsCapabilityModal,
  ] = useState(false);

  useEffect(() => {
    if (superOrgId) {
      dispatch(fetchAudits(superOrgId));
    }
  }, [superOrgId, dispatch]);

  const currentOrgType = useSelector(
    (state: ReduxState) => state.account.currentOrgType
  );

  const superOrgCapabilities = useSelector((state: ReduxState) =>
    superOrgId ? state.organization.capabilities[superOrgId] : []
  );

  const clubCapabilities = useSelector((state: ReduxState) =>
    clubId ? state.organization.capabilities[clubId] : []
  );

  const paths = useSelector((state: ReduxState) => state.path.paths);

  const pendingAudits = useSelector(
    (state: ReduxState) => state.auditor.pendingAudits
  );
  const finalizedAudits = useSelector(
    (state: ReduxState) => state.auditor.finalizedAudits
  );

  // SuperOrg (federation/area association/adminorg) capabilities are fetched higher in router
  // hierarchy for all "organization" paths, but club capabilites are needed only here
  useEffect(() => {
    dispatch(fetchOrganizationCapabilities(clubId));
  }, [dispatch, clubId]);

  if (!pendingAudits || !finalizedAudits) {
    return <LoadingSpinner />;
  }

  const changeModalState = () => {
    setShowTopSportsCapabilityModal(!showTopSportsCapabilityModal);
  };

  const pendingClubAudits = pendingAudits?.filter((audit) => {
    return audit.clubId === parseInt(clubId, 10);
  });

  const finalizedClubAudits = finalizedAudits?.filter((audit) => {
    return audit.clubId === parseInt(clubId, 10);
  });

  const getActivePathLinks = (
    paths: PathData[],
    pendingAudits: AuditorInfo[],
    finalizedAudits: AuditorInfo[],
    currentSuperOrgHasTopSportsCapability: boolean
  ) => {
    /** Path type is considered 'active' if there is a pending audit or
     * there is a finalized audit but but no pending audits.
     */
    const activePaths = paths.map((path) => {
      const pendingAudit = pendingAudits.find(
        (audit) => audit.qualityPath === path.type
      );

      const orgHasNoCapabilityAndPathIsTopSports =
        !currentSuperOrgHasTopSportsCapability &&
        path.type === PathType.TOP_SPORTS;

      /**
       * If no pending audit on path, let's check if latest finalized audit can be found.
       */
      const latestFinalizedAudit = pendingAudit
        ? null
        : finalizedAudits
            .sort((a, b) => b.auditRequestId - a.auditRequestId)
            .find((audit) => audit.qualityPath === path.type);

      return pendingAudit?.auditRequestId ? (
        <div key={path.pathId}>
          {orgHasNoCapabilityAndPathIsTopSports ? (
            t(`clubPage.pathTypes.${pendingAudit.qualityPath}`)
          ) : (
            <Link
              to={`../path/${pendingAudit.auditRequestId}`}
              className="development-link"
              key={pendingAudit.auditRequestId}
            >
              {t(`clubPage.pathTypes.${pendingAudit.qualityPath}`)}
            </Link>
          )}
        </div>
      ) : latestFinalizedAudit?.auditRequestId ? (
        <div key={path.pathId}>
          {orgHasNoCapabilityAndPathIsTopSports ? (
            t(`clubPage.pathTypes.${latestFinalizedAudit.qualityPath}`)
          ) : (
            <Link
              to={`../path/${latestFinalizedAudit.auditRequestId}`}
              className="development-link"
              key={latestFinalizedAudit.auditRequestId}
            >
              {t(`clubPage.pathTypes.${latestFinalizedAudit.qualityPath}`)}
            </Link>
          )}
        </div>
      ) : null;
    });

    return activePaths.filter((path) => !!path);
  };

  const currentSuperOrgHasFederationTopSportsCapability =
    currentOrgType === OrgType.ADMIN_ORGANIZATION ||
    superOrgCapabilities?.some((capability) => {
      return (
        capability.capabilityType ===
          OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
        capability.value === "true"
      );
    });

  const currentClubHasClubTopSportsCapability = clubCapabilities?.some(
    (capability) => {
      return (
        capability.capabilityType ===
          OrganizationCapabilityType.CLUB_TOP_SPORTS_ENABLED &&
        capability.value === "true"
      );
    }
  );

  const activePathLinks =
    pendingClubAudits &&
    finalizedClubAudits &&
    getActivePathLinks(
      paths,
      pendingClubAudits,
      finalizedClubAudits,
      currentSuperOrgHasFederationTopSportsCapability
    );
  const developmentBoardLink = (
    <Link to={`../club/${clubId}/development`} className="development-link">
      {t("clubPage.developmentBoard")}
    </Link>
  );
  const auditArchiveLink = (
    <Link to={`../club/${clubId}/archive`}>
      {t("clubPage.previousAuditsArchive")}
    </Link>
  );

  const toggleClubTopSportsPermission = () => {
    dispatch(
      setOrganizationCapability(clubId, {
        organizationId: clubId,
        capabilityType: OrganizationCapabilityType.CLUB_TOP_SPORTS_ENABLED,
        value: (!currentClubHasClubTopSportsCapability).toString(),
      })
    );

    changeModalState();
  };

  return (
    <div className="clubpagecontainer">
      <h3>{t("clubPage.activePathsHeading")}</h3>
      {activePathLinks && activePathLinks.length > 0
        ? activePathLinks
        : t("clubPage.noActivePaths")}
      <h3>{t("clubPage.clubOtherPagesHeading")}</h3>
      <div>{developmentBoardLink && developmentBoardLink}</div>
      <div>{auditArchiveLink && auditArchiveLink}</div>
      {currentSuperOrgHasFederationTopSportsCapability && (
        <>
          <h3>{t("clubPage.topSportsHeading")}</h3>
          {currentClubHasClubTopSportsCapability ? (
            <div className="top-sports-revoke-club-capability">
              <div>{t("clubPage.clubTopSportsCapabilityGranted")}</div>
              &nbsp;
              <div
                className="top-sports-capability-modal-button"
                onClick={changeModalState}
              >
                {t("clubPage.clubTopSportsCapabilityGrantedRevokeLinkText")}
              </div>
            </div>
          ) : (
            <div
              className="top-sports-capability-modal-button"
              onClick={changeModalState}
            >
              {t("clubPage.grantClubTopSportsCapability")}
            </div>
          )}

          <Modal
            show={showTopSportsCapabilityModal}
            onHide={changeModalState}
            dialogClassName="top-sports-club-capability-modal"
          >
            <div className="top-sports-club-permission-modal-container">
              <div className="top-sports-club-permission-modal-headline">
                {t(
                  currentClubHasClubTopSportsCapability
                    ? "clubPage.clubTopSportsCapabilityGrantModal.grantedHeading"
                    : "clubPage.clubTopSportsCapabilityGrantModal.notGrantedHeading"
                )}
              </div>
              <p className="top-sports-club-permission-modal-info-text">
                {t(
                  currentClubHasClubTopSportsCapability
                    ? "clubPage.clubTopSportsCapabilityGrantModal.grantedDescription"
                    : "clubPage.clubTopSportsCapabilityGrantModal.notGrantedDescription"
                )}
              </p>
              <div className="top-sports-club-permission-modal-button-container">
                <button
                  className="btn btn-secondary-blue btn-full top-sports-club-permission-modal-button"
                  type="button"
                  onClick={toggleClubTopSportsPermission}
                >
                  {t(
                    currentClubHasClubTopSportsCapability
                      ? "clubPage.clubTopSportsCapabilityGrantModal.revokeClubTopSportsCapabilityButtonText"
                      : "clubPage.clubTopSportsCapabilityGrantModal.grantClubTopSportsCapabilityButtonText"
                  )}
                </button>
                <button
                  className="btn btn-secondary-blue btn-full top-sports-club-permission-modal-button"
                  type="button"
                  onClick={changeModalState}
                >
                  {t(
                    "clubPage.clubTopSportsCapabilityGrantModal.cancelButtonText"
                  )}
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};
