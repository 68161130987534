import { createAction, handleActions } from "redux-actions";
import {
  AuditRequest,
  AuditRequestAddInfo,
  AuditResponse,
  DescriptionModalState,
  FORMVERSION,
  PartStatus,
  PathType,
} from "../model";
import * as Logger from "js-logger";
import Api from "../api";
import Alert from "react-s-alert";
import i18next from "i18next";
import { ReduxState } from "../reducers";
import { fetchEnrollments } from "./path";
import { fetchPartAttachments } from "./attachment";
export const GET_AUDIT_REQUESTS = createAction("GET_AUDIT_REQUESTS");
export const GET_AUDIT_REQUESTS_SUCCESS = createAction<{
  enrollmentId: number;
  response: any;
}>("GET_AUDIT_REQUESTS_SUCCESS");
export const GET_AUDIT_REQUESTS_FAILURE = createAction(
  "GET_AUDIT_REQUESTS_FAILURE"
);
export const ADD_SINGLE_AUDIT_REQUEST = createAction<{
  enrollmentId: number;
  response: AuditRequest;
}>("ADD_SINGLE_AUDIT_REQUEST");
export const RESET_LIST_RECEIVED_AT = createAction("RESET_LIST_RECEIVED_AT");
export const SET_AUDIT_REQUEST_PART = createAction<{
  enrollmentId: number;
  response: any;
}>("SET_AUDIT_REQUEST_PART");
export const SET_SUBMIT_TYPE = createAction<string>("SET_SUBMIT_TYPE");

export const RECEIVE_FORM_PART_VALUES = createAction<{}>(
  "RECEIVE_FORM_PART_VALUES"
);
export const SET_DESCRIPTION_MODAL_STATE = createAction<DescriptionModalState>(
  "SET_DESCRIPTION_MODAL_STATE"
);
export const CLOSE_DESCRIPTION_MODAL = createAction("CLOSE_DESCRIPTION_MODAL");
export const RECEIVE_SINGLE_REQUEST_AUDIT = createAction<AuditRequest>(
  "RECEIVE_SINGLE_REQUEST_AUDIT"
);
export const RECEIVE_AUDIT_REQUEST_ADD_INFO = createAction<AuditRequestAddInfo>(
  "RECEIVE_AUDIT_REQUEST_ADD_INFO"
);
export const CLEAR_AUDIT_REQUEST_ADD_INFO = createAction(
  "CLEAR_AUDIT_REQUEST_ADD_INFO"
);
export const RECEIVE_SINGLE_REQUEST_RESPONSE = createAction<{
  requestId: string;
  response: AuditResponse | null;
}>("RECEIVE_SINGLE_REQUEST_RESPONSE");
export const RECEIVE_REQUEST_NOTES = createAction<string>(
  "RECEIVE_REQUEST_NOTES"
);

export function fetchAuditRequestsByEnrollment(enrollmentId: number) {
  return async (
    dispatch
  ): Promise<{ enrollmentId: number; response: any } | null> => {
    try {
      dispatch(GET_AUDIT_REQUESTS);
      const response = await Api.club.getAuditRequestsForEnrollment(
        enrollmentId
      );
      dispatch(
        GET_AUDIT_REQUESTS_SUCCESS({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      return { enrollmentId: enrollmentId, response: response.data };
    } catch {
      dispatch(GET_AUDIT_REQUESTS_FAILURE);
      Logger.warn("Fetch Audit requests failed");
      return null;
    }
  };
}

export function fetchSingleAuditRequest(
  requestId: string,
  enrollmentId: number
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.getSingleAuditRequest(requestId);
      dispatch(
        ADD_SINGLE_AUDIT_REQUEST({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      return true;
    } catch {
      Logger.warn("Fetch Audit requests failed");
      return false;
    }
  };
}

export function fetchSingleAuditRequestAudit(requestId: string) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.getSingleAuditRequest(requestId);
      dispatch(RECEIVE_SINGLE_REQUEST_AUDIT(response.data));
      return true;
    } catch {
      Logger.warn("Fetch Audit requests failed");
      return false;
    }
  };
}

export function fetchAuditRequestAddInfo(requestId: string) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.getAuditRequestAddInfo(requestId);
      dispatch(RECEIVE_AUDIT_REQUEST_ADD_INFO(response.data));
      return true;
    } catch {
      Logger.warn("Fetch Audit request add info failed");
      return false;
    }
  };
}

export function createAuditRequest(
  enrollmentId: number,
  orgId: number,
  pathType: string
) {
  return async (dispatch): Promise<AuditRequest | null> => {
    try {
      const data: AuditRequest | any = {};
      data.clubId = orgId;
      data.qualityPath = PathType[pathType];
      data.formVersion = FORMVERSION;
      const response = await Api.club.createAuditRequest(data);
      dispatch(
        ADD_SINGLE_AUDIT_REQUEST({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      // need to update the enrollments as well
      dispatch(fetchEnrollments(orgId));
      return response.data;
    } catch (error) {
      Logger.warn("Create Audit request failed");
      return null;
    }
  };
}

export function updateAuditRequestPartValues(
  enrollmentId: number,
  requestId: string,
  partKey: string,
  formData: any
) {
  return async (dispatch, getState): Promise<boolean> => {
    try {
      const state: ReduxState = getState();
      const response = await Api.club.setAuditRequestPartValues(
        requestId,
        partKey,
        formData
      );
      dispatch(
        SET_AUDIT_REQUEST_PART({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      await dispatch(getAuditRequestPartValues(requestId, partKey));
      // If DRAFT, display toast message,
      // otherwise continue to other function that updates part state and handles toasting
      if (state.auditRequest.submitType === "DRAFT") {
        Alert.info(i18next.t("common:apiAlerts.draft"));
      }
      if (state.auditRequest.submitType === "READY") {
        await dispatch(
          updateAuditRequestPart(
            enrollmentId,
            requestId,
            partKey,
            PartStatus.READY
          )
        );
      }
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.draft"));
      Logger.warn("Update Audit request part values failed");
      return false;
    }
  };
}

export function updateAuditRequestPartSingleValue(
  enrollmentId: number,
  requestId: string,
  partKey: string,
  formData: any
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.setAuditRequestPartValues(
        requestId,
        partKey,
        formData
      );
      dispatch(
        SET_AUDIT_REQUEST_PART({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      await dispatch(getAuditRequestPartValues(requestId, partKey));
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.draft"));
      Logger.warn("Update Audit request part values failed");
      return false;
    }
  };
}

export function getAuditRequestPartValues(
  requestId: number | string,
  partKey: string
) {
  return async (dispatch): Promise<{} | null> => {
    try {
      const response = await Api.club.getAuditRequestPartValues(
        requestId.toString(),
        partKey
      );
      dispatch(RECEIVE_FORM_PART_VALUES(response.data));
      return response.data;
    } catch {
      Logger.warn("Update Audit request part values failed");
      return null;
    }
  };
}

export function submitAuditRequest(requestId: number, enrollmentId: number) {
  return async (dispatch): Promise<{} | null> => {
    try {
      const response = await Api.club.submitAuditRequest(requestId.toString());
      if (response.status === 200) {
        Alert.info(i18next.t("common:apiAlerts.submitRequest"));
        dispatch(GET_AUDIT_REQUESTS);
        const response2 = await Api.club.getAuditRequestsForEnrollment(
          enrollmentId
        );
        dispatch(
          GET_AUDIT_REQUESTS_SUCCESS({
            enrollmentId: enrollmentId,
            response: response2.data,
          })
        );
        dispatch(fetchSingleResponseByAuditRequest(requestId.toString()));
      }
      return response.data;
    } catch {
      dispatch(GET_AUDIT_REQUESTS_FAILURE);
      Logger.warn("Update Audit request part values failed");
      return null;
    }
  };
}

export function updateAuditRequestPart(
  enrollmentId: number,
  requestId: string,
  partKey: string,
  state: any,
  quiet?: boolean
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.setAuditRequestPart(
        requestId,
        partKey,
        state
      );
      dispatch(
        SET_AUDIT_REQUEST_PART({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );
      if (!quiet) {
        Alert.info(i18next.t("common:apiAlerts.partSetReady"));
      }
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.partSetReady"));
      Logger.warn("Update Audit request part values failed");
      return false;
    }
  };
}

export function updateAuditRequestPartAudit(
  requestId: string,
  partKey: string,
  state: any,
  quiet?: boolean
) {
  return async (dispatch): Promise<boolean> => {
    try {
      await Api.club.setAuditRequestPart(requestId, partKey, state);
      await fetchSingleAuditRequestAudit(requestId);
      if (!quiet) {
        Alert.info(i18next.t("common:apiAlerts.commentSend"));
      }
      return true;
    } catch {
      Alert.error(i18next.t("common:apiErrors.commentSend"));
      Logger.warn("Update Audit request part values failed");
      return false;
    }
  };
}

export function copyClubContentFromOtherQualityPath(
  enrollmentId: number,
  requestId: number,
  partKey: string,
  otherQualityPath: string
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = await Api.club.copyClubContentFromOtherQualityPath(
        requestId.toString(),
        partKey,
        otherQualityPath
      );
      dispatch(
        SET_AUDIT_REQUEST_PART({
          enrollmentId: enrollmentId,
          response: response.data,
        })
      );

      dispatch(getAuditRequestPartValues(requestId, partKey));
      dispatch(fetchPartAttachments(requestId, partKey));

      Alert.info(i18next.t("common:formCopy.success"));
      return true;
    } catch (err) {
      Logger.warn("Copying club content from other quality path failed");
      Alert.error(i18next.t("common:formCopy.failure"));
      return false;
    }
  };
}

export function fetchSingleResponseByAuditRequest(requestId: string) {
  return async (dispatch): Promise<AuditResponse | null> => {
    try {
      const response = await Api.auditor.getByAuditRequest(requestId);
      dispatch(
        RECEIVE_SINGLE_REQUEST_RESPONSE({
          requestId: requestId,
          response: response.data,
        })
      );
      return response.data;
    } catch {
      dispatch(
        RECEIVE_SINGLE_REQUEST_RESPONSE({
          requestId: requestId,
          response: null,
        })
      );
      Logger.warn("No audit response found");
      return null;
    }
  };
}

export function fetchRequestNotes(
  orgId: string,
  qualityPath: string,
  auditor?: boolean
) {
  return async (dispatch): Promise<string | null> => {
    try {
      const response = auditor
        ? await Api.auditor.getRequestNotes(orgId, qualityPath)
        : await Api.club.getRequestNotes(orgId, qualityPath);
      dispatch(RECEIVE_REQUEST_NOTES(response.data));
      return response.data;
    } catch {
      dispatch(RECEIVE_REQUEST_NOTES(""));
      Logger.warn("No request notes found");
      return null;
    }
  };
}

export function saveRequestNotes(
  orgId: string,
  qualityPath: string,
  notes: any,
  auditor?: boolean
) {
  return async (dispatch): Promise<boolean> => {
    try {
      const response = auditor
        ? await Api.auditor.saveRequestNotes(orgId, qualityPath, notes)
        : await Api.club.saveRequestNotes(orgId, qualityPath, notes);
      dispatch(RECEIVE_REQUEST_NOTES(response.data));
      return true;
    } catch {
      dispatch(RECEIVE_REQUEST_NOTES(""));
      Logger.warn("No request notes found");
      return false;
    }
  };
}

// TODO: typing when the content has been figured out
export interface AuditRequestState {
  requestLists: { [enrollmentId: number]: AuditRequest[] };
  listsReceivedAt: Date | null;
  formPartValues: any;
  submitType: "DRAFT" | "READY";
  descriptionModal: DescriptionModalState;
  auditRequest: AuditRequest | null;
  auditRequestAddInfo: AuditRequestAddInfo | null;
  auditResponses: { [requestId: number]: AuditResponse };
  numOfActiveAuditRequestFetches: number;
  requestNotes?: {
    auditParty: string;
    clubEnrollmentId: number;
    id: number;
    lastModifiedTimestamp: string;
    modifierUserId: number;
    parts: {
      [part: string]: {
        auditNotesId: number;
        id: number;
        key: string;
        lastModifiedTimestamp: string;
        modifierUserId: number;
        notes: string;
      };
    };
  };
}

const initialState: AuditRequestState = {
  requestLists: {},
  listsReceivedAt: null,
  formPartValues: null,
  submitType: "DRAFT",
  descriptionModal: {
    show: false,
    form: null,
    field: null,
    value: null,
  },
  auditRequest: null,
  auditRequestAddInfo: null,
  auditResponses: {},
  requestNotes: undefined,
  numOfActiveAuditRequestFetches: 0,
};

const auditRequestReducer = handleActions<AuditRequestState, any>(
  {
    [GET_AUDIT_REQUESTS as any]: (state) => {
      return {
        ...state,
        numOfActiveAuditRequestFetches:
          state.numOfActiveAuditRequestFetches + 1,
      };
    },
    [GET_AUDIT_REQUESTS_SUCCESS as any]: (state, action) => {
      return {
        ...state,
        requestLists: {
          ...state.requestLists,
          [action.payload.enrollmentId]: action.payload.response,
        },
        listsReceivedAt: new Date(),
        numOfActiveAuditRequestFetches:
          state.numOfActiveAuditRequestFetches > 0
            ? state.numOfActiveAuditRequestFetches - 1
            : 0,
      };
    },
    [GET_AUDIT_REQUESTS_FAILURE as any]: (state) => {
      return {
        ...state,
        numOfActiveAuditRequestFetches:
          state.numOfActiveAuditRequestFetches > 0
            ? state.numOfActiveAuditRequestFetches - 1
            : 0,
      };
    },
    [ADD_SINGLE_AUDIT_REQUEST as any]: (state, action) => {
      const oldList = state.requestLists[action.payload.enrollmentId]
        ? state.requestLists[action.payload.enrollmentId]
        : [];
      return {
        ...state,
        requestLists: {
          ...state.requestLists,
          [action.payload.enrollmentId]: oldList.concat(
            action.payload.response
          ),
        },
      };
    },
    [RESET_LIST_RECEIVED_AT as any]: (state) => {
      return {
        ...state,
        listsReceivedAt: null,
      };
    },
    [SET_AUDIT_REQUEST_PART as any]: (state, action) => {
      const oldList = state.requestLists[action.payload.enrollmentId]
        ? state.requestLists[action.payload.enrollmentId]
        : [];
      const oldAuditRequest = oldList.find(
        (x) => x.id === action.payload.response.auditRequestId
      );
      if (oldAuditRequest) {
        const oldPart = oldAuditRequest.parts.find(
          (x) => x.id === action.payload.response.id
        );
        let updatedParts: any = null;
        if (oldPart) {
          updatedParts = oldAuditRequest.parts.map((x) => {
            return x.key === action.payload.response.key
              ? action.payload.response
              : x;
          });
        } else {
          updatedParts = oldAuditRequest.parts
            ? oldAuditRequest.parts.concat(action.payload.response)
            : [action.payload.response];
        }
        const updatedRequest = { ...oldAuditRequest, parts: updatedParts };
        const updatedList = oldList.map((x) => {
          return x.id === updatedRequest.id ? updatedRequest : x;
        });
        return {
          ...state,
          requestLists: {
            ...state.requestLists,
            [action.payload.enrollmentId]: updatedList,
          },
        };
      }
      return {
        ...state,
        requestLists: {
          ...state.requestLists,
          [action.payload.enrollmentId]: oldList,
        },
      };
    },
    [RECEIVE_FORM_PART_VALUES as any]: (state, action) => {
      return {
        ...state,
        formPartValues: action.payload,
      };
    },
    [SET_SUBMIT_TYPE as any]: (state, action) => {
      return {
        ...state,
        submitType: action.payload,
      };
    },
    [SET_DESCRIPTION_MODAL_STATE as any]: (state, action) => {
      return {
        ...state,
        descriptionModal: action.payload,
      };
    },
    [CLOSE_DESCRIPTION_MODAL as any]: (state, action) => {
      return {
        ...state,
        descriptionModal: initialState.descriptionModal,
      };
    },
    [RECEIVE_SINGLE_REQUEST_AUDIT as any]: (state, action) => {
      return {
        ...state,
        auditRequest: action.payload,
      };
    },
    [RECEIVE_AUDIT_REQUEST_ADD_INFO as any]: (state, action) => {
      return {
        ...state,
        auditRequestAddInfo: action.payload,
      };
    },
    [CLEAR_AUDIT_REQUEST_ADD_INFO as any]: (state, action) => {
      return {
        ...state,
        auditRequestAddInfo: null,
      };
    },
    [RECEIVE_SINGLE_REQUEST_RESPONSE as any]: (state, action) => {
      return {
        ...state,
        auditResponses: {
          ...state.auditResponses,
          [action.payload.requestId]: action.payload.response,
        },
      };
    },
    [RECEIVE_REQUEST_NOTES as any]: (state, action) => {
      return {
        ...state,
        requestNotes: action.payload,
      };
    },
  },
  initialState
);

export default auditRequestReducer;
