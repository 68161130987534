import { createAction, handleActions } from "redux-actions";
import * as Logger from "js-logger";
import {
  ClubEnrollment,
  PathData,
  PathStartModalData,
  PartStatus,
  PathType,
} from "../model";
import Api from "../api";
import Alert from "react-s-alert";
import i18next from "i18next";
import basicCriteriaNotImg from "../images/path/icon_peruskriteerit_aloittamatta.svg";
import sportcontentNotImg from "../images/path/icon_urheilutoiminta_aloittamatta.svg";
import peopleNotImg from "../images/path/icon_ihmiset_aloittamatta.svg";
import leadershipNotImg from "../images/path/icon_hallinto_aloittamatta.svg";
import resourcesNotImg from "../images/path/icon_resurssit_aloittamatta.svg";
import basicCriteriaDraftImg from "../images/path/icon_peruskriteerit_aloitettu.svg";
import sportcontentDraftImg from "../images/path/icon_urheilutoiminta_aloitettu.svg";
import peopleDraftImg from "../images/path/icon_ihmiset_aloitettu.svg";
import leadershipDraftImg from "../images/path/icon_hallinto_aloitettu.svg";
import resourcesDraftImg from "../images/path/icon_resurssit_aloitettu.svg";
import basicCriteriaReadyImg from "../images/path/icon_peruskriteerit_valmis.svg";
import sportcontentReadyImg from "../images/path/icon_urheilutoiminta_valmis.svg";
import peopleReadyImg from "../images/path/icon_ihmiset_valmis.svg";
import leadershipReadyImg from "../images/path/icon_hallinto_valmis.svg";
import resourcesReadyImg from "../images/path/icon_resurssit_valmis.svg";
import basicCriteriaArchivedImg from "../images/path/icon_peruskriteerit_lukittu.svg";
import sportcontentArchivedImg from "../images/path/icon_urheilutoiminta_lukittu.svg";
import peopleArchivedImg from "../images/path/icon_ihmiset_lukittu.svg";
import leadershipArchivedImg from "../images/path/icon_hallinto_lukittu.svg";
import resourcesArchivedImg from "../images/path/icon_resurssit_lukittu.svg";
import { createAuditRequest } from "./auditRequest";
import { fetchAudits } from "./auditor";
import { ReduxState } from "../reducers";
import { ThunkAction } from "redux-thunk";

export const RECEIVE_ORG_PATHS = createAction<PathData[]>("RECEIVE_ORG_PATHS");
export const SET_PATH_START_MODAL_DATA = createAction<PathStartModalData>(
  "SET_PATH_START_MODAL_DATA"
);
export const SET_PATH_DATA = createAction<PathData>("SET_PATH_DATA");
export const HAS_FETCHED_ENROLLMENTS = createAction<{
  id: number;
  data: boolean;
}>("HAS_FETCHED_ENROLLMENTS");
export const RECEIVE_ENROLLMENTS = createAction<ClubEnrollment[]>(
  "RECEIVE_ENROLLMENTS"
);
export const UPSERT_ENROLLMENT = createAction<ClubEnrollment>(
  "UPSERT_ENROLLMENT"
);
export const SET_NOTES_MODAL = createAction<boolean>("SET_NOTES_MODAL");
export const SET_CURRENT_FORM = createAction<{
  currentOrgId: string;
  currentType: string;
  currentPart: string;
  currentRequest?: number;
}>("SET_CURRENT_FORM");
export const SET_NAVIGATE_TO_MODAL = createAction<boolean>(
  "SET_NAVIGATE_TO_MODAL"
);

/**
 TODO: fetchEnrollments only fetches ACTIVE enrollments, not previous enrollments.
 This means that audit archive won't show audits from previous enrollments.
 When club loses its tähtiseura status, that enrollment is deactivated and if they
 ever resume to the same path, a completely new enrollment is created and all audits
 are tied to that.*/
export function fetchEnrollments(orgId: number | string) {
  return async (dispatch): Promise<Array<ClubEnrollment> | null> => {
    try {
      dispatch(
        HAS_FETCHED_ENROLLMENTS({
          id: typeof orgId === "string" ? parseInt(orgId) : orgId,
          data: false,
        })
      );
      const response = await Api.club.getAllEnrollments(orgId);
      dispatch(RECEIVE_ENROLLMENTS(response.data));
      dispatch(
        HAS_FETCHED_ENROLLMENTS({
          id: typeof orgId === "string" ? parseInt(orgId) : orgId,
          data: true,
        })
      );
      return response.data;
    } catch {
      dispatch(
        HAS_FETCHED_ENROLLMENTS({
          id: typeof orgId === "string" ? parseInt(orgId) : orgId,
          data: false,
        })
      );
      Logger.warn("Fetching enrollments has failed");
      return null;
    }
  };
}

export function fetchSingleEnrollment(orgId: number | string, type: string) {
  return async (dispatch): Promise<ClubEnrollment | null> => {
    try {
      const response = await Api.club.getOneEnrollment(orgId, type);
      dispatch(UPSERT_ENROLLMENT(response.data));
      return response.data;
    } catch {
      Logger.warn("Fetching enrollment has failed");
      return null;
    }
  };
}

export function submitEnrollment(data: ClubEnrollment) {
  return async (dispatch, getState): Promise<number | null> => {
    try {
      if (data && data.clubId && data.qualityPath) {
        const response = await Api.club.createEnrollment(
          data,
          data.clubId,
          data.qualityPath
        );
        if (response.status === 201) {
          dispatch(UPSERT_ENROLLMENT(response.data));
          const requestResponse = await dispatch(
            createAuditRequest(response.data.id, data.clubId, data.qualityPath)
          );
          if (requestResponse) {
            Alert.info(i18next.t("common:apiAlerts.enrollment"));
          }
        }
        return response.data.id;
      }
      return null;
    } catch {
      Alert.error(i18next.t("common:apiErrors.enrollment"));
      Logger.warn("Error creating clubenrollment");
      return null;
    }
  };
}

export const deleteEnrollment = (params: {
  orgId: string | number;
  qualityPath: string;
}): ThunkAction<void, ReduxState, unknown> => async (dispatch, getState) => {
  try {
    await Api.club.deleteEnrollment(params.orgId, params.qualityPath);

    // Re-fetch audits so that the related UI tables (SummaryTable and friends) are updated after deletion. Note that it's possible that deletion succeeds, but this re-fetch then fails. We will show this to the user as an error, but hopefully it's rare in practice.
    const state = getState();
    if (!state.account.currentOrg) {
      throw new Error("Invalid currentOrg id in Redux state");
    }
    await dispatch(fetchAudits(state.account.currentOrg));

    Alert.info(i18next.t("common:apiAlerts.deleteEnrollment"));
  } catch (err) {
    Logger.error("Error deleting club enrollment", err);
    Alert.error(i18next.t("common:apiErrors.deleteEnrollment"));
  }
};

export interface PathState {
  paths: PathData[];
  pathStartModalData: PathStartModalData;
  fetchedClubEnrollments: { [clubId: number]: boolean };
  clubEnrollments: ClubEnrollment[] | null;
  isNotesOpen: boolean;
  currentType: string;
  currentPart: string;
  currentOrgId: string;
  navigateToSummary: boolean;
  currentRequest?: number;
}

const initialState: PathState = {
  paths: [
    {
      pathId: 1,
      type: PathType.YOUTH,
      parts: [
        {
          id: 1,
          status: PartStatus.NOT_STARTED,
          type: "management",
          icon: {
            not: leadershipNotImg,
            draft: leadershipDraftImg,
            ready: leadershipReadyImg,
            archived: leadershipArchivedImg,
          },
        },
        {
          id: 2,
          status: PartStatus.NOT_STARTED,
          type: "sportcontent",
          icon: {
            not: sportcontentNotImg,
            draft: sportcontentDraftImg,
            ready: sportcontentReadyImg,
            archived: sportcontentArchivedImg,
          },
        },
        {
          id: 3,
          status: PartStatus.NOT_STARTED,
          type: "people",
          icon: {
            not: peopleNotImg,
            draft: peopleDraftImg,
            ready: peopleReadyImg,
            archived: peopleArchivedImg,
          },
        },
        {
          id: 4,
          status: PartStatus.NOT_STARTED,
          type: "resources",
          icon: {
            not: resourcesNotImg,
            draft: resourcesDraftImg,
            ready: resourcesReadyImg,
            archived: resourcesArchivedImg,
          },
        },
      ],
    },
    {
      pathId: 2,
      type: PathType.ADULTS,
      parts: [
        {
          id: 1,
          status: PartStatus.NOT_STARTED,
          type: "management",
          icon: {
            not: leadershipNotImg,
            draft: leadershipDraftImg,
            ready: leadershipReadyImg,
            archived: leadershipArchivedImg,
          },
        },
        {
          id: 2,
          status: PartStatus.NOT_STARTED,
          type: "sportcontent",
          icon: {
            not: sportcontentNotImg,
            draft: sportcontentDraftImg,
            ready: sportcontentReadyImg,
            archived: sportcontentArchivedImg,
          },
        },
        {
          id: 3,
          status: PartStatus.NOT_STARTED,
          type: "people",
          icon: {
            not: peopleNotImg,
            draft: peopleDraftImg,
            ready: peopleReadyImg,
            archived: peopleArchivedImg,
          },
        },
        {
          id: 4,
          status: PartStatus.NOT_STARTED,
          type: "resources",
          icon: {
            not: resourcesNotImg,
            draft: resourcesDraftImg,
            ready: resourcesReadyImg,
            archived: resourcesArchivedImg,
          },
        },
      ],
    },
    {
      pathId: 3,
      type: PathType.TOP_SPORTS,
      parts: [
        {
          id: 0,
          status: PartStatus.NOT_STARTED,
          type: "basiccriteria",
          icon: {
            not: basicCriteriaNotImg,
            draft: basicCriteriaDraftImg,
            ready: basicCriteriaReadyImg,
            archived: basicCriteriaArchivedImg,
          },
        },
        {
          id: 1,
          status: PartStatus.NOT_STARTED,
          type: "management",
          icon: {
            not: leadershipNotImg,
            draft: leadershipDraftImg,
            ready: leadershipReadyImg,
            archived: leadershipArchivedImg,
          },
        },
        {
          id: 2,
          status: PartStatus.NOT_STARTED,
          type: "sportcontent",
          icon: {
            not: sportcontentNotImg,
            draft: sportcontentDraftImg,
            ready: sportcontentReadyImg,
            archived: sportcontentArchivedImg,
          },
        },
        {
          id: 3,
          status: PartStatus.NOT_STARTED,
          type: "people",
          icon: {
            not: peopleNotImg,
            draft: peopleDraftImg,
            ready: peopleReadyImg,
            archived: peopleArchivedImg,
          },
        },
        {
          id: 4,
          status: PartStatus.NOT_STARTED,
          type: "resources",
          icon: {
            not: resourcesNotImg,
            draft: resourcesDraftImg,
            ready: resourcesReadyImg,
            archived: resourcesArchivedImg,
          },
        },
      ],
    },
  ],
  pathStartModalData: {
    show: false,
    path: undefined,
    orgId: undefined,
  },
  fetchedClubEnrollments: {},
  clubEnrollments: null,
  isNotesOpen: false,
  currentPart: "",
  currentType: "",
  currentOrgId: "",
  currentRequest: undefined,
  navigateToSummary: false,
};

const pathReducer = handleActions<PathState, any>(
  {
    [RECEIVE_ORG_PATHS as any]: (state, action) => ({
      ...state,
      paths: action.payload,
    }),
    [HAS_FETCHED_ENROLLMENTS as any]: (state, action) => ({
      ...state,
      fetchedClubEnrollments: {
        ...state.fetchedClubEnrollments,
        [action.payload.id]: action.payload.data,
      },
    }),
    [RECEIVE_ENROLLMENTS as any]: (state, action) => ({
      ...state,
      clubEnrollments: action.payload,
    }),
    [SET_PATH_START_MODAL_DATA as any]: (state, action) => ({
      ...state,
      pathStartModalData: action.payload,
    }),
    [SET_PATH_DATA as any]: (state, action) => ({
      ...state,
      paths: state.paths.map((x) => {
        return x.pathId === action.payload.id ? action.payload : x;
      }),
    }),
    [UPSERT_ENROLLMENT as any]: (state, action) => {
      if (state && state.clubEnrollments) {
        const existing = state.clubEnrollments.find(
          (x) => x.id === action.payload.id
        );
        if (existing) {
          return {
            ...state,
            clubEnrollments: state.clubEnrollments.map((x) => {
              return x.id === action.payload.id ? action.payload : x;
            }),
          };
        } else {
          return {
            ...state,
            clubEnrollments: state.clubEnrollments.concat(action.payload),
          };
        }
      } else {
        return { ...state, clubEnrollments: [action.payload] };
      }
    },
    [SET_NOTES_MODAL as any]: (state, action) => {
      return {
        ...state,
        isNotesOpen: action.payload,
      };
    },
    [SET_CURRENT_FORM as any]: (state, action) => {
      return {
        ...state,
        currentOrgId: action.payload.currentOrgId,
        currentPart: action.payload.currentPart,
        currentType: action.payload.currentType,
        currentRequest: action.payload.currentRequest,
      };
    },
    [SET_NAVIGATE_TO_MODAL as any]: (state, action) => {
      return {
        ...state,
        navigateToSummary: action.payload,
      };
    },
  },
  initialState
);

export default pathReducer;
