import React from "react";

import "./DevelopmentItemDeletionModal.scss";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { ReduxState } from "../../reducers";
import { deleteDevelopmentItem } from "../../modules/development";
import { useTranslation } from "react-i18next";
import { CLOSE_DEVELOPMENT_ITEM_DELETION_MODAL } from "../../modules/developmentModals";

export function DevelopmentItemDeletionModal() {
  const modalData = useSelector(
    (state: ReduxState) => state.development.itemDeletionModal
  );
  const dispatch = useDispatch();
  const { t } = useTranslation("clubDevelopment");

  const hideModal = () => dispatch(CLOSE_DEVELOPMENT_ITEM_DELETION_MODAL());

  return (
    <div>
      <Modal
        show={modalData.modalIsOpen}
        onHide={hideModal}
        className="centered-modal"
        dialogClassName="development-item-deletion-modal"
      >
        <Modal.Body>
          <h1>{t("confirmation.question")}</h1>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalbuttons">
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={() => {
                dispatch(deleteDevelopmentItem(modalData.item!.id!));
                hideModal();
                if (modalData.onDelete) {
                  modalData.onDelete();
                }
              }}
            >
              {t("confirmation.delete")}
            </button>
            <button
              className="btn btn-lg btn-secondary-blue"
              onClick={hideModal}
            >
              {t("common:modal.cancel")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
