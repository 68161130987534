import * as React from "react";
import { AuditRequestAddInfo } from "../../model";
import { CollapsibleImprovementSection } from "../CollapsibleImprovementSection";
import { withTranslation, WithTranslation } from "react-i18next";
import { v4 } from "uuid";
import { LoadingSpinner } from "../LoadingSpinner";

export interface SummaryInfoProps extends WithTranslation {
  auditRequestAddInfo: AuditRequestAddInfo | null;
}

function createSectionImprovementInfo(
  sectiondata: any,
  props: SummaryInfoProps & WithTranslation
) {
  if (!sectiondata || !sectiondata.values) {
    return null;
  }

  let components: any = [];
  sectiondata.values.forEach((value) => {
    components.push(
      <div className="improvementline" key={String(Object.values(value)[0])}>
        <div className="improvementcontent">
          {Object.values(value)[0] as React.ReactElement}
        </div>
      </div>
    );
  });
  return components;
}

function createPartImprovementInfo(
  partdata: any,
  props: SummaryInfoProps & WithTranslation
) {
  const { t } = props;

  if (!partdata || !partdata.sections) {
    return null;
  }

  let components: any = [];
  partdata.sections.forEach((section) => {
    components.push(
      <CollapsibleImprovementSection
        title={t(`${section.key}.formHeader`)}
        numItems={section.numItems}
        key={v4()}
      >
        <div className="improvementsectioncontent">
          {createSectionImprovementInfo(section, props)}
        </div>
      </CollapsibleImprovementSection>
    );
  });
  return components;
}

function createAuditImprovementInfo(
  auditdata: any,
  props: SummaryInfoProps & WithTranslation
) {
  const { t } = props;

  if (!auditdata || !auditdata.parts) {
    return null;
  }

  let components: any = [];
  auditdata.parts.forEach((part) => {
    components.push(
      <CollapsibleImprovementSection
        title={t(`${part.key}.title`)}
        numItems={part.numItems}
        key={v4()}
      >
        {createPartImprovementInfo(part, props)}
      </CollapsibleImprovementSection>
    );
  });
  return components;
}

function ImprovementInfoComponent(props: SummaryInfoProps) {
  const { auditRequestAddInfo, t } = props;
  if (!auditRequestAddInfo || !auditRequestAddInfo.improvementPointsTree) {
    return <LoadingSpinner />;
  }
  const auditData = auditRequestAddInfo.improvementPointsTree;

  return (
    <React.Fragment>
      <div className="improvements">
        {auditData && auditData.parts && auditData.parts.length > 0 ? (
          createAuditImprovementInfo(auditData, props)
        ) : (
          <div>{t("auditSummary.noImprovementPoints")}</div>
        )}
      </div>
    </React.Fragment>
  );
}

export const ImprovementInfo = withTranslation("forms")(
  ImprovementInfoComponent
);
