import React from "react";
import "./ExpandingContentRowHeader.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import arrowDown from "../../images/nav-arrow-down.svg";
import arrowUp from "../../images/nav-arrow-up.svg";
import * as classNames from "classnames";

interface ExpandingContentRowHeaderProps extends WithTranslation {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  subTitle?: string;
  open?: boolean;
  hideHideButton?: boolean;
  info?: string;
  isTopSports?: boolean;
  onRemove?(): void;
}

interface ExpandingContentRowHeaderState {
  showContent: boolean;
}

class ExpandingContentRowHeader extends React.Component<
  ExpandingContentRowHeaderProps,
  ExpandingContentRowHeaderState
> {
  constructor(props: ExpandingContentRowHeaderProps) {
    super(props);
    this.state = {
      showContent: !!props.open,
    };
  }

  toggleHide = async () => {
    await this.setState({ showContent: !this.state.showContent });
    const scrollEvent = new Event("scroll");
    // Adjust the AuditFormButtons visibility and position
    window.dispatchEvent(scrollEvent);
  };

  render() {
    const {
      children,
      className,
      title,
      subTitle,
      hideHideButton,
      info,
      isTopSports,
    } = this.props;

    const expandingContentRowHeaderClasses = classNames(
      "expandingContentRowHeader",
      { topsports: isTopSports },
      className
    );

    return (
      <div className="expanding-content-row-header-container">
        <div className={expandingContentRowHeaderClasses}>
          {title ? <h4 className="titletext">{title}</h4> : null}
          {subTitle ? <div className="subtitletext">{subTitle}</div> : null}
          <div className="rightgroup">
            {info && <span>{info}</span>}
            {!hideHideButton && (
              <div className="hidebutton" onClick={this.toggleHide}>
                {this.state.showContent ? (
                  <img src={arrowUp} alt="" />
                ) : (
                  <img src={arrowDown} alt="" />
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.showContent && children}
      </div>
    );
  }
}

const component = withTranslation("forms")(ExpandingContentRowHeader);

export { component as ExpandingContentRowHeader };
