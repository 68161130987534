export interface VSpacer_Props {
  /**
   * Spacer size in pixels.
   */
  gap: number;
}

export const VSpacer = (props: VSpacer_Props) => (
  <div style={{ height: props.gap }} />
);
