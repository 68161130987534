import { WithTranslation, withTranslation } from "react-i18next";
import { DonutChart } from "./DonutChart";
import * as React from "react";
import { HorizontalBar } from "./HorizontalBar";
import { v4 } from "uuid";

export interface IAuditGraphSegmentProps {
  title: string;
  data: any;
  colors: object;
  order: string[];
  icon: any;
  enableNavigation?: boolean;
  navigateToPart?(part: string): void;
}

function createBarGraphs(
  bardata: any,
  props: IAuditGraphSegmentProps & WithTranslation
) {
  const { colors, order, t } = props;
  let components: any = [];
  bardata.forEach((section) => {
    components.push(
      <HorizontalBar
        title={t(`${section.key}.formHeader`)}
        rawData={section.gradeValues}
        percData={section.percGradeValues}
        colors={colors}
        order={order}
        key={v4()}
      />
    );
  });
  return components;
}

function AuditGraphSegmentComponent(
  props: IAuditGraphSegmentProps & WithTranslation
) {
  const {
    title,
    data,
    colors,
    order,
    icon,
    enableNavigation,
    navigateToPart,
    t,
  } = props;
  const part = data.parts.find((part) => part.key === title);
  return (
    <div className="graphsegmentcontainer">
      <div className="graphsegment">
        <DonutChart
          rawData={part.gradeValues}
          percData={part.percGradeValues}
          colors={colors}
          order={order}
          title={t(`${title}.title`)}
          onTitleClick={
            enableNavigation ? () => navigateToPart!(title) : undefined
          }
        >
          <img
            alt={t(`${title}.title`)}
            src={icon}
            onClick={
              enableNavigation ? () => navigateToPart!(title) : undefined
            }
            className={
              enableNavigation ? "donuticon donuticonlink" : "donuticon"
            }
          />
        </DonutChart>

        {createBarGraphs(part.sections, props)}
      </div>
    </div>
  );
}

export const AuditGraphSegment = withTranslation("forms")(
  AuditGraphSegmentComponent
);
