import React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserData } from "../../modules/account";
import {
  PathData,
  PartStatus,
  ClubEnrollment,
  AuditRequest,
  AuditResponse,
} from "../../model";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { RouteComponentProps, withRouter } from "react-router";
import { SET_PATH_START_MODAL_DATA } from "../../modules/path";
import * as classNames from "classnames";
import { format, differenceInMinutes } from "date-fns";
import { PartIcon } from "./PartIcon";
import {
  createAuditRequest,
  fetchSingleResponseByAuditRequest,
  submitAuditRequest,
} from "../../modules/auditRequest";
import { getClubPathAudit, getPathPart } from "../../routePaths";
import { Modal } from "react-bootstrap";
import Alert from "react-s-alert";
import lockIcon from "../../images/lock.png";
import { LoadingSpinner } from "../LoadingSpinner";
import "./PathInfo.scss";

interface PathInfoConnectedProps
  extends RouteComponentProps<{ orgId: string }> {
  data?: PathData;
  enrollment: ClubEnrollment;
  request: AuditRequest;
  isArchived: Boolean;
}

export interface PathInfoProps extends PathInfoConnectedProps, WithTranslation {
  user: UserData;
  listsReceivedAt: Date;
  auditResponses: {};
  activeResponse: AuditResponse;
  openPathStartModal(): void;
  createAuditRequest(
    enrollmentId: number,
    orgId: number,
    pathType: string
  ): Promise<AuditRequest>;
  submitAuditRequest(
    requestId: number,
    enrollmentId: number
  ): Promise<AuditRequest>;
  fetchSingleResponseByAuditRequest(
    requestId: number
  ): Promise<AuditResponse | null>;
}

interface PathInfoState {
  responseFetched: boolean;
  loading: boolean;
  modalShow: boolean;
}

class PathInfo extends Component<PathInfoProps, PathInfoState> {
  constructor(props: PathInfoProps) {
    super(props);
    this.state = {
      responseFetched: false,
      loading: true,
      modalShow: false,
    };
  }

  async componentDidMount() {
    const { enrollment } = this.props;
    if (enrollment) {
      const { enrollment, request, listsReceivedAt } = this.props;
      if (
        enrollment ||
        (enrollment && differenceInMinutes(new Date(), listsReceivedAt) > 5)
      ) {
        await this.fetchResponseToRequest(request);
      } else {
        this.setState({ loading: false });
      }
    }
    this.setState({ loading: false });
  }

  private async fetchResponseToRequest(request: AuditRequest) {
    if (request && !this.state.responseFetched) {
      await this.props.fetchSingleResponseByAuditRequest(request.id);
      this.setState({
        responseFetched: true,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps: PathInfoConnectedProps) {
    const { request } = this.props;
    if (prevProps.request !== request) {
      await this.fetchResponseToRequest(request);
    }
  }

  navigateToPathPart = async (requestId, orgId, type, pathType, enrollment) => {
    this.props.history.push(
      getPathPart(orgId, type, pathType, requestId ? requestId : "", enrollment)
    );
  };

  createAuditRequest = async () => {
    const {
      data,
      enrollment,
      t,
      match: {
        params: { orgId },
      },
    } = this.props;
    if (enrollment && enrollment.id && data) {
      const response = await this.props.createAuditRequest(
        enrollment.id,
        parseInt(orgId, 10),
        data.type
      );
      if (response) {
        Alert.info(t("path.requestCreateSuccess"));
      } else {
        Alert.error(t("path.requestCreateFailure"));
      }
    }
  };

  submitAndCloseModal = async (activeRequest: any, enrollment: any) => {
    const response = await this.props.submitAuditRequest(
      activeRequest.id,
      enrollment.id
    );
    if (response) {
      this.setState({ modalShow: false });
    } else {
      this.setState({ modalShow: false });
    }
  };

  navigateToSummary = () => {
    const { match, enrollment, history, request, activeResponse } = this.props;
    if (request && enrollment) {
      history.push(
        getClubPathAudit(
          match.params.orgId,
          request.id.toString(),
          activeResponse.id.toString(),
          enrollment.id!.toString()
        )
      );
    }
  };

  createPathParts = (decisionMade, pathReady, showNewComments) => {
    const { t, data, enrollment, request, isArchived } = this.props;
    // the parts that form a path are defined in the frontend but we need to get the saved parts from the backend
    // to display path progress
    if (!data || !data.parts) {
      return null;
    }
    let partsComponents: any = [];
    const partsLength = data.parts.length;
    data.parts.forEach((value, index) => {
      let icon = value.icon.not;
      // display dots connecting PartIcons if not last part
      let connector = index < partsLength - 1;
      let requestPartStatus = PartStatus.NOT_STARTED;
      let showOrangeCircleText = false;
      // use activeRequest to show real path status if the user has already saved a path before.
      if (request) {
        const requestPart = request.parts
          ? request.parts.find((x) => x.key === value.type)
          : null;
        requestPartStatus = requestPart
          ? PartStatus[requestPart.state]
          : PartStatus.NOT_STARTED;
        switch (requestPartStatus) {
          case PartStatus.DRAFT:
            icon = value.icon.draft;
            break;
          case PartStatus.READY:
          case PartStatus.AWAITING_REVISION:
            icon = isArchived ? value.icon.archived : value.icon.ready;
            break;
          default:
            icon = value.icon.not;
        }
      }
      showOrangeCircleText =
        requestPartStatus === "AWAITING_REVISION" && showNewComments;

      // finally give data to PartIcon constructor and push into an array
      partsComponents.push(
        PartIcon(
          t(`${value.type}.title`),
          icon,
          requestPartStatus as PartStatus,
          connector,
          pathReady,
          value.type,
          this.props.match.params.orgId,
          data.type,
          this.navigateToPathPart,
          request ? request.id : null,
          request && !decisionMade ? request.submitted : false,
          showOrangeCircleText,
          enrollment ? enrollment.id!.toString() : "preview",
          isArchived
        )
      );
    });
    return partsComponents;
  };

  render() {
    const {
      data,
      t,
      enrollment,
      request,
      activeResponse,
      isArchived,
    } = this.props;
    const { loading } = this.state;
    if (loading) {
      return (
        <div className="pathstatus pathloading">
          <LoadingSpinner />
        </div>
      );
    }
    const decisionMade = activeResponse && activeResponse.decision;
    const enrolled = !!enrollment && enrollment.active;
    const awardGranted =
      enrollment && enrollment.awardGranted && enrollment.active;

    const sidestatusClassnames = classNames("sidestatus", {
      pathStarted: enrolled,
      pathAccepted: awardGranted,
      pathArchived: isArchived,
    });

    // check if all parts are READY in the active auditRequest
    // and display a button to submit the request.
    const pathReady =
      request && request.parts && data
        ? request.parts.filter((x) => x.state === PartStatus.READY).length ===
          data.parts.length
        : null;

    // if any parts have fresh comments, display a message above PartIcons
    const newComments = request
      ? request.parts.filter((x) => x.state === PartStatus.AWAITING_REVISION)
          .length
      : 0;

    const showBoardDecision = enrollment && enrollment.decisionTimestamp;

    const enrolledContent = !enrolled ? (
      <button
        className="btn btn-secondary btn-secondary-blue"
        onClick={this.props.openPathStartModal}
      >
        {t("path.start")}
      </button>
    ) : (
      <div>
        {t("path.started")}{" "}
        <b>
          {request && request.createdTimestamp
            ? format(request.createdTimestamp, "DD.MM.YYYY")
            : null}
        </b>
      </div>
    );

    // various booleans to decide what to show to the user
    const submitted = request && request.submitted && !decisionMade;

    const nextAudit = enrollment && enrollment.nextAuditTimestamp;

    const showSubmit = pathReady && request && !request.submitted;

    const showLockIcon = request && request.submitted && !decisionMade;

    const correctionsPending = enrollment && enrollment.correctionsPending;

    const showNewCommentsText =
      newComments > 0 &&
      (!activeResponse ||
        !activeResponse.submitted ||
        activeResponse.decision !== "ACCEPTED");

    const partsComponents = this.createPathParts(
      decisionMade,
      pathReady,
      showNewCommentsText
    );

    // club has to create a new request to begin filling in the forms
    // request can be created 6 months before the next audit date
    /** TODO: enrollment.auditRequestCreationAllowed is supposed to come from backend,
     * it is implemented there but never set to the DTO (it is/was a calculated value, not
     * stored in db).
     */
    const showNewAudit =
      enrolled && enrollment.auditRequestCreationAllowed && !isArchived;

    return (
      <div className="pathstatus">
        {!isArchived && <div className={sidestatusClassnames} />}
        {!isArchived && data && (
          <div className="statustitle">{t(`path.type.${data.type}`)}</div>
        )}

        {enrolledContent}
        {showBoardDecision && (
          <div>
            {t("path.boardDecision")}{" "}
            <b>{format(enrollment!.decisionTimestamp!, "DD.MM.YYYY")}</b>
          </div>
        )}
        {submitted && <div>{t("path.submitted")}</div>}
        {awardGranted && (
          <div>
            {t("path.awardGranted")}{" "}
            <b>{format(enrollment!.awardGrantedTimestamp!, "DD.MM.YYYY")}!</b>
          </div>
        )}
        {!isArchived && nextAudit && (
          <div>
            {t(`path.${correctionsPending ? "corrections1" : "nextAudit"}`)}{" "}
            <b>{format(enrollment!.nextAuditTimestamp!, "DD.MM.YYYY")}</b>
            {t(`path.${correctionsPending ? "corrections2" : "nextAudit2"}`)}
          </div>
        )}
        {showNewCommentsText && (
          <div style={{ color: "#faa61a", textAlign: "center" }}>
            {t("path.newComments")}
          </div>
        )}
        {showNewAudit && (
          <>
            <button
              className="btn btn-secondary btn-secondary-blue"
              onClick={this.createAuditRequest}
            >
              {awardGranted ? t("path.newExistingAudit") : t("path.newAudit")}
            </button>
          </>
        )}
        <div className="partscontainer">
          {showLockIcon && (
            <img className="lockIcon" src={lockIcon} alt="Path locked" />
          )}
          {partsComponents}
        </div>
        {showSubmit && (
          <button
            className="btn btn-secondary btn-secondary-blue"
            onClick={() => this.setState({ modalShow: true })}
          >
            {t("path.submit")}
          </button>
        )}
        {activeResponse && (
          <div
            className="auditsummarycontainer"
            onClick={this.navigateToSummary}
          >
            <div
              className={
                activeResponse.decision === "ACCEPTED"
                  ? "summaryicon greenicon"
                  : "summaryicon yellowicon"
              }
            >
              !
            </div>
            <div
              className={
                isArchived
                  ? "summarytext bluetext"
                  : activeResponse.decision === "ACCEPTED"
                  ? "summarytext greentext"
                  : "summarytext yellowtext"
              }
            >
              {t("path.summary")}
            </div>
          </div>
        )}
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          dialogClassName="submit-modal"
        >
          <Modal.Body>
            <h1>{t("path.modalTitle")}</h1>
          </Modal.Body>
          <Modal.Footer>
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => this.submitAndCloseModal(request, enrollment)}
              >
                {t("path.modalSubmit")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => this.setState({ modalShow: false })}
              >
                {t("path.modalCancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const PathInfoTranslated = withTranslation("forms")(PathInfo);

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  const activeResponse = ownProps.request
    ? state.auditRequest.auditResponses[ownProps.request.id]
    : null;
  // Display the response on the club user's side only after it has been submitted
  const submittedActiveResponse =
    activeResponse && activeResponse.submitted ? activeResponse : null;
  return {
    user: state.account.user,
    listsReceivedAt: state.auditRequest.listsReceivedAt,
    auditResponses: state.auditRequest.auditResponses,
    activeResponse: submittedActiveResponse,
  };
};

const mapDispatchToProps = (dispatch, ownProps: PathInfoConnectedProps) => ({
  openPathStartModal: () =>
    dispatch(
      SET_PATH_START_MODAL_DATA({
        show: true,
        path: ownProps.data!,
        orgId: parseInt(ownProps.match.params.orgId, 10),
      })
    ),
  createAuditRequest: (enrollmentId: number, orgId: number, pathType: string) =>
    dispatch(createAuditRequest(enrollmentId, orgId, pathType)),
  submitAuditRequest: (requestId: number, enrollmentId: number) =>
    dispatch(submitAuditRequest(requestId, enrollmentId)),
  fetchSingleResponseByAuditRequest: (requestId: number) =>
    dispatch(fetchSingleResponseByAuditRequest(requestId.toString())),
});

const PathInfoConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PathInfoTranslated as any);

export default withRouter<PathInfoConnectedProps, any>(PathInfoConnected);
