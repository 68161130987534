import React from "react";
import "./ServiceNotification.scss";
import { Component } from "react";
import { v4 } from "uuid";

interface ServiceNotificationProps {
  notifications: Array<string>;
}

export class ServiceNotification extends Component<
  ServiceNotificationProps,
  any
> {
  createNotificationComponents = (notifications) => {
    let components: any = [];
    notifications.forEach((notification) =>
      components.push(
        <div className="notificationcontent" key={v4()}>
          {notification}
        </div>
      )
    );
    return components;
  };

  render() {
    return (
      <div className="servicenotification" id="notifications">
        {this.createNotificationComponents(this.props.notifications)}
      </div>
    );
  }
}
