import { Menu } from "react-bootstrap/lib/Dropdown";
import { MenuItem } from "react-bootstrap";
import pencilIcon from "../../images/pencil-icon.svg";
import { DevelopmentItem } from "../../modules/development";
import trashCanIcon from "../../images/trashcan.svg";
import React, { useState } from "react";
import falafelIcon from "../../images/falafel.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import lockIcon from "../../images/lock.svg";
import "./DevelopmentItemMenu.scss";
import {
  SET_DEVELOPMENT_ITEM_DELETION_MODAL_STATE,
  SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE,
} from "../../modules/developmentModals";

interface DevelopmentItemMenuProps {
  item: DevelopmentItem;
  itemListElement?: HTMLElement;
  readOnly: boolean;
  tooltipId: string;
}

export function DevelopmentItemMenu(props: DevelopmentItemMenuProps) {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("clubDevelopment");

  const openDevelopmentItemPropsModal = () =>
    dispatch(
      SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE({
        modalIsOpen: true,
        item: props.item,
      })
    );

  const tooltipYOffset =
    props.itemListElement && -props.itemListElement.scrollTop;

  return props.item.locked ? (
    <img
      className="lockedicon"
      src={lockIcon}
      alt={t("itemCard.locked-alt")}
      data-tip={t("itemCard.locked-tooltip")}
      data-for={props.tooltipId}
    />
  ) : (
    <div className="development-item-menu">
      <button
        className="btn-transparent menu-btn"
        onClick={() => setShowMenu(true)}
      >
        <img src={falafelIcon} alt={t("itemCard.menu-alt")} />
      </button>
      <div
        style={{
          position: "absolute",
          zIndex: 10,
          visibility: !showMenu ? "hidden" : undefined,
          transform:
            (tooltipYOffset && `translate(0, ${tooltipYOffset}px`) || undefined,
        }}
      >
        {props.readOnly ? (
          <Menu open={showMenu} onClose={() => setShowMenu(false)}>
            <MenuItem
              onClick={() => {
                setShowMenu(false);
                openDevelopmentItemPropsModal();
              }}
            >
              <div className="menutext">{t("details")}</div>
            </MenuItem>
          </Menu>
        ) : (
          <Menu open={showMenu} onClose={() => setShowMenu(false)}>
            <MenuItem
              onClick={() => {
                setShowMenu(false);
                openDevelopmentItemPropsModal();
              }}
            >
              <img src={pencilIcon} alt={t("itemCard.pencil-alt")} />
              <div className="menutext">{t("modify")}</div>
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                setShowMenu(false);
                dispatch(
                  SET_DEVELOPMENT_ITEM_DELETION_MODAL_STATE({
                    modalIsOpen: true,
                    item: props.item,
                  })
                );
              }}
            >
              <img src={trashCanIcon} alt={t("itemCard.trashcan-alt")} />
              <div className="menutext">{t("delete")}</div>
            </MenuItem>
          </Menu>
        )}
      </div>
    </div>
  );
}
