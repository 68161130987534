import { format, isBefore } from "date-fns";
import { isEqual, orderBy } from "lodash";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AutoSizer, Column, Table } from "react-virtualized";
import pencilIcon from "../../images/pencil-icon.svg";
import { AuditorInfo, OrgType, PathType } from "../../model";
import { SET_AUDIT_SETTINGS_MODAL_STATE } from "../../modules/auditSettings";
import {
  OrganizationCapability,
  OrganizationCapabilityType,
} from "../../modules/organization";
import { deleteEnrollment } from "../../modules/path";
import { ReduxState } from "../../reducers";
import { getOrganizationDashboardUrl } from "../../routePaths";
import { AuditorInfoActionsFalafelMenu } from "./AuditorInfoActionsFalafelMenu";
import { PartStatusCell } from "./PartStatusCell";
import "./Tables.scss";

interface SummaryTableConnectedProps
  extends RouteComponentProps<{ orgId: string }> {
  list: AuditorInfo[];
  showArea: boolean;
  showFederation: boolean;
}

interface SummaryTable_DispatchProps {
  setModalAuditSettingsModal(data: AuditorInfo): void;
  onConfirmDeleteQualityPath: (auditorInfo: AuditorInfo) => void;
}

export interface SummaryTableProps
  extends SummaryTableConnectedProps,
    SummaryTable_DispatchProps,
    RouteComponentProps<{ orgId: string }>,
    WithTranslation {
  currentOrgType?: OrgType | null;
  superOrgCapabilities: OrganizationCapability[] | [];
}

interface SummaryTableState {
  disableHeader: boolean;
  headerHeight: number;
  height: number;
  hideIndexRow: boolean;
  overscanRowCount: number;
  rowHeight: number;
  rowCount: number;
  scrollToIndex: any;
  useDynamicRowHeight: boolean;
  sortedList: any;
  correctionsFetched: boolean;
  sortBy: string | null;
  sortDirection: string | null;
}

class SummaryTable extends Component<SummaryTableProps, SummaryTableState> {
  private tableRef: any = null;

  constructor(props: SummaryTableProps) {
    super(props);
    const sortedList = orderBy(
      this.props.list,
      ["nextAuditTimestamp"],
      ["asc"]
    );
    this.state = {
      disableHeader: false,
      headerHeight: 30,
      height: 270,
      hideIndexRow: false,
      overscanRowCount: 10,
      rowHeight: 40,
      rowCount: sortedList.length,
      scrollToIndex: undefined,
      useDynamicRowHeight: false,
      sortedList,
      correctionsFetched: false,
      sortBy: null,
      sortDirection: null,
    };
  }

  componentWillReceiveProps(nextProps: SummaryTableProps) {
    if (!isEqual(nextProps.list, this.props.list)) {
      const sortBy = this.state.sortBy
        ? this.state.sortBy
        : "nextAuditTimestamp";
      const sortDirection = this.state.sortDirection
        ? this.state.sortDirection.toLowerCase()
        : "asc";
      const sortedList = orderBy(
        nextProps.list,
        [sortBy],
        [sortDirection as any]
      );
      this.setState({ sortedList });
    }
  }

  _noRowsRenderer = () => {
    return <div className="emptyRow">{this.props.t("auditTable.empty")}</div>;
  };

  _rowClassName({ index }: any) {
    if (index < 0) {
      return "auditRow evenRow";
    } else {
      return index % 2 === 0 ? "auditRow evenRow" : "auditRow oddRow";
    }
  }

  _sort = ({ sortBy, sortDirection }: any) => {
    const sortedList = orderBy(
      this.state.sortedList,
      [sortBy],
      [sortDirection.toLowerCase()]
    );

    this.setState({ sortBy, sortDirection, sortedList });
  };

  headerRowRenderer = ({ className, columns, style }) => (
    <div className={className.concat(" headerRow")} role="row" style={style}>
      {columns}
    </div>
  );

  navigateToAuditPath = (rowData) => {
    const { history, match } = this.props;
    history.push(
      `${getOrganizationDashboardUrl(match.params.orgId)}/path/${
        rowData.auditRequestId
      }`
    );
  };

  render() {
    const { scrollToIndex, sortBy, sortDirection, sortedList } = this.state;
    const {
      t,
      showArea,
      showFederation,
      currentOrgType,
      superOrgCapabilities,
    } = this.props;
    const rowGetter = ({ index }) => {
      return sortedList[index];
    };

    const currentSuperOrgHasFederationTopSportsCapability =
      currentOrgType === OrgType.ADMIN_ORGANIZATION ||
      superOrgCapabilities?.some((c) => {
        return (
          c.capabilityType ===
            OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
          c.value === "true"
        );
      });

    const openAuditSettingsModal = (auditorInfo: AuditorInfo) => {
      this.props.setModalAuditSettingsModal(auditorInfo);
    };

    const renderAuditorInfoActions_normalOrg = (auditorInfo: AuditorInfo) => {
      // If it's a TOP_SPORTS quality path but the current organization doesn't have the top sports capability, don't show the action button.
      if (
        auditorInfo.qualityPath === PathType.TOP_SPORTS &&
        !currentSuperOrgHasFederationTopSportsCapability
      ) {
        return null;
      }

      return (
        <button
          className="btn-invisible"
          title={t("auditTable.changeSettings")}
          onClick={() => {
            openAuditSettingsModal(auditorInfo);
          }}
        >
          <img alt={t("auditTable.changeSettings")} src={pencilIcon} />
        </button>
      );
    };

    const renderAuditorInfoActions_adminOrg = (auditorInfo: AuditorInfo) => (
      <AuditorInfoActionsFalafelMenu
        auditorInfo={auditorInfo}
        onClickEditAudit={() => {
          openAuditSettingsModal(auditorInfo);
        }}
        onConfirmDeleteQualityPath={() => {
          this.props.onConfirmDeleteQualityPath(auditorInfo);
        }}
      />
    );

    const renderAuditorInfoActions = (auditorInfo: AuditorInfo) => {
      if (currentOrgType === OrgType.ADMIN_ORGANIZATION) {
        return renderAuditorInfoActions_adminOrg(auditorInfo);
      } else {
        return renderAuditorInfoActions_normalOrg(auditorInfo);
      }
    };

    return (
      <div style={{ width: "100%", flex: "1 1 auto" }}>
        <AutoSizer disableHeight={true} style={{ width: "100%" }}>
          {({ width }) => (
            <Table
              className="auditTable"
              ref={(ref) => (this.tableRef = ref)}
              headerHeight={40}
              height={800}
              headerClassName="auditTableHeader"
              headerRowRenderer={this.headerRowRenderer}
              noRowsRenderer={this._noRowsRenderer}
              rowClassName={this._rowClassName}
              rowHeight={40}
              rowGetter={rowGetter}
              rowCount={sortedList.length}
              scrollToIndex={scrollToIndex}
              sort={this._sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              width={width < 900 ? 900 : width}
            >
              <Column
                label={t("auditTable.clubName")}
                dataKey="clubName"
                width={270}
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.clubName : null
                }
                cellRenderer={({ cellData, rowData }) => (
                  <div
                    onClick={() =>
                      !currentSuperOrgHasFederationTopSportsCapability &&
                      rowData.qualityPath === PathType.TOP_SPORTS
                        ? null
                        : this.navigateToAuditPath(rowData)
                    }
                    className={
                      !currentSuperOrgHasFederationTopSportsCapability &&
                      rowData.qualityPath === PathType.TOP_SPORTS
                        ? "non-link-name-column"
                        : "namecolumn"
                    }
                  >
                    {cellData}
                  </div>
                )}
                className={"tablecolumn"}
              />
              {showArea && (
                <Column
                  width={210}
                  dataKey="areaAssociationName"
                  cellDataGetter={({ rowData }) =>
                    rowData ? rowData.areaAssociationName : null
                  }
                  label={t("auditTable.area")}
                  className={"tablecolumn finalizedname"}
                />
              )}
              {showFederation && (
                <Column
                  width={190}
                  dataKey="federationName"
                  cellDataGetter={({ rowData }) =>
                    rowData ? rowData.federationName : null
                  }
                  label={t("auditTable.federation")}
                  className={"tablecolumn"}
                />
              )}
              <Column
                width={110}
                dataKey="enrollmentTimestamp"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.enrollmentTimestamp : null
                }
                cellRenderer={({ cellData }) =>
                  cellData ? format(cellData, "DD.MM.YYYY") : null
                }
                label={t("auditTable.enrollment")}
                className={"tablecolumn"}
              />
              <Column
                width={140}
                dataKey="qualityPath"
                cellDataGetter={({ rowData }) =>
                  rowData ? t("paths." + rowData.qualityPath) : null
                }
                label={t("auditTable.path")}
                className={"tablecolumn"}
              />
              <Column
                width={110}
                dataKey="nextAuditTimestamp"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.nextAuditTimestamp : null
                }
                label={t("auditTable.next")}
                className={"tablecolumn"}
                cellRenderer={({ cellData }) => (
                  <div
                    className={isBefore(cellData, new Date()) ? "reddate" : ""}
                  >
                    {cellData ? format(cellData, "DD.MM.YYYY") : null}
                  </div>
                )}
              />
              <Column
                width={210}
                dataKey="state"
                cellDataGetter={({ rowData }) =>
                  rowData ? t("state." + rowData.state) : null
                }
                label={t("auditTable.state")}
                className={"tablecolumn"}
              />
              <Column
                width={200}
                dataKey="parts"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.parts : null
                }
                cellRenderer={({ cellData }) => PartStatusCell(cellData)}
                label={t("auditTable.parts")}
                className={"tablecolumn"}
              />
              <Column
                width={50}
                dataKey="dummy"
                className={"tablecolumn"}
                cellRenderer={({ rowData }) =>
                  renderAuditorInfoActions(rowData)
                }
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

const tableTranslated = withTranslation("common")(SummaryTable);

const mapStateToProps = (state: ReduxState, props) => {
  const superOrgId = props.match.params.orgId;
  return {
    currentOrgType: state.account.currentOrgType,
    superOrgCapabilities: superOrgId
      ? state.organization.capabilities[superOrgId]
      : [],
  };
};

const mapDispatchToProps = (dispatch): SummaryTable_DispatchProps => ({
  setModalAuditSettingsModal: (data) =>
    dispatch(
      SET_AUDIT_SETTINGS_MODAL_STATE({
        modalIsOpen: true,
        deadlineOnly: false,
        auditInfo: data,
      })
    ),

  onConfirmDeleteQualityPath: (auditorInfo) =>
    dispatch(
      deleteEnrollment({
        orgId: auditorInfo.clubId,
        qualityPath: auditorInfo.qualityPath,
      })
    ),
});

const SummaryTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(tableTranslated);

export default withRouter<SummaryTableConnectedProps, any>(
  SummaryTableConnected
);
