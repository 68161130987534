import React from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import SelectField from "../../Fields/SelectField";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthPeoplePart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.people.resources.formHeader")}
          open={true}
        >
          <div className="form-section">
            <Field
              options={options}
              name="resourcesRoles"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.roles.placeholder")}
              label={t("shared.people.resources.roles.label")}
              helpText={t("shared.people.resources.roles.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesRoles"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.roles.label")}
              fieldValue={ratingMap[get(formData, "resourcesRoles")]}
            />
            <hr />
            <Field
              options={options}
              name="resourcesNewpeole"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.newpeople.placeholder")}
              label={t("shared.people.resources.newpeople.label")}
              helpText={t("shared.people.resources.newpeople.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesNewpeole"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.newpeople.label")}
              fieldValue={ratingMap[get(formData, "resourcesNewpeole")]}
            />
            <hr />
            <Field
              options={options}
              name="resourcesIntro"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.intro.placeholder")}
              label={t("shared.people.resources.intro.label")}
              helpText={t("shared.people.resources.intro.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesIntro"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.intro.label")}
              fieldValue={ratingMap[get(formData, "resourcesIntro")]}
            />
            <hr />
            <Field
              options={options}
              name="resourcesInvolvement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.involvement.placeholder")}
              label={t("shared.people.resources.involvement.label")}
              helpText={t("shared.people.resources.involvement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesInvolvement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.involvement.label")}
              fieldValue={ratingMap[get(formData, "resourcesInvolvement")]}
            />
            <hr />
            <Field
              options={options}
              name="resourcesPrize"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.prize.placeholder")}
              label={t("shared.people.resources.prize.label")}
              helpText={t("shared.people.resources.prize.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesPrize"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.prize.label")}
              fieldValue={ratingMap[get(formData, "resourcesPrize")]}
            />
            <hr />
            <Field
              options={options}
              name="resourcesEducation"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.resources.education.placeholder")}
              label={t("shared.people.resources.education.label")}
              helpText={t("shared.people.resources.education.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesEducation"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.education.label")}
              fieldValue={ratingMap[get(formData, "resourcesEducation")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.people.resources.positive")}
            </div>
            <Field
              name="resourcesPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.people.resources.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.positive")}
              fieldValue={ratingMap[get(formData, "resourcesPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.people.resources.negative")}
            </div>
            <Field
              name="resourcesNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.people.resources.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"resourcesNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.resources.negative")}
              fieldValue={ratingMap[get(formData, "resourcesNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthPeopleResources"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.people.community.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="communityEffort"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.community.effort.placeholder")}
              label={t("shared.people.community.effort.label")}
              helpText={t("shared.people.community.effort.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communityEffort"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.effort.label")}
              fieldValue={ratingMap[get(formData, "communityEffort")]}
            />
            <hr />
            <Field
              options={options}
              name="communityAtmosphere"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.community.atmosphere.placeholder")}
              label={t("shared.people.community.atmosphere.label")}
              helpText={t("shared.people.community.atmosphere.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communityAtmosphere"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.atmosphere.label")}
              fieldValue={ratingMap[get(formData, "communityAtmosphere")]}
            />
            <hr />
            <Field
              options={options}
              name="communityOther"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.community.other.placeholder")}
              label={t("shared.people.community.other.label")}
              helpText={t("shared.people.community.other.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communityOther"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.other.label")}
              fieldValue={ratingMap[get(formData, "communityOther")]}
            />
            <hr />
            <Field
              options={options}
              name="communityContinuity"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.people.community.continuity.placeholder")}
              label={t("shared.people.community.continuity.label")}
              helpText={t("shared.people.community.continuity.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communityContinuity"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.continuity.label")}
              fieldValue={ratingMap[get(formData, "communityContinuity")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.people.community.positive")}
            </div>
            <Field
              name="communityPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.people.community.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"communityPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.positive")}
              fieldValue={ratingMap[get(formData, "communityPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.people.community.negative")}
            </div>
            <Field
              name="communityNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.people.community.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"communityNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.people.community.negative")}
              fieldValue={ratingMap[get(formData, "communityNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthPeopleCommunity"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default YouthPeoplePart as any;
