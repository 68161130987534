// tslint:disable
import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AuditorInfo, ClubInformation } from "../../model";
import { Table, Column, AutoSizer } from "react-virtualized";
import { orderBy } from "lodash";
import { RouteComponentProps, withRouter } from "react-router";
import "./Tables.scss";
import { getOrganizationClubUrl } from "../../routePaths";

interface ClubsTableTranslatedProps
  extends RouteComponentProps<{ orgId: string }> {
  list: ClubInformation[];
  showArea: boolean;
  showFederation: boolean;
}

export interface ClubsTableProps
  extends ClubsTableTranslatedProps,
    RouteComponentProps<{ orgId: string }>,
    WithTranslation {
  setModalAuditSettingsModal(data: AuditorInfo): void;
}

const ClubsTableComponent = (props: ClubsTableProps) => {
  const sortedListInitial = orderBy(props.list, ["name"], ["asc"]);

  const [sortedList, setSortedList] = useState(sortedListInitial);
  const [sortBy, setSortBy] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    const newSortedList = orderBy(
      props.list,
      [sortBy],
      [sortDirection.toLowerCase() as any]
    );

    setSortedList(newSortedList);
  }, [props.list, sortBy, sortDirection]);

  const { t, showArea, showFederation } = props;

  const rowGetter = ({ index }) => {
    return sortedList[index];
  };

  const noRowsRenderer = () => {
    return <div className="emptyRow">{props.t("auditTable.empty")}</div>;
  };

  const rowClassName = ({ index }: any) => {
    if (index < 0) {
      return "auditRow evenRow";
    } else {
      return index % 2 === 0 ? "auditRow evenRow" : "auditRow oddRow";
    }
  };

  const sort = ({ sortBy, sortDirection }: any) => {
    const orderedSortedList = orderBy(
      sortedList,
      [sortBy],
      [sortDirection.toLowerCase()]
    );

    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedList(orderedSortedList);
  };

  const headerRowRenderer = ({ className, columns, style }) => (
    <div className={className.concat(" headerRow")} role="row" style={style}>
      {columns}
    </div>
  );

  const navigateToClubPath = (rowData) => {
    const { history, match } = props;
    history.push(
      `${getOrganizationClubUrl(match.params.orgId, rowData.orgId)}`
    );
  };

  return (
    <div style={{ width: "100%", flex: "1 1 auto" }}>
      <AutoSizer disableHeight={true} style={{ width: "100%" }}>
        {({ width }) => (
          <Table
            className="auditTable"
            headerHeight={40}
            height={800}
            headerClassName="auditTableHeader"
            headerRowRenderer={headerRowRenderer}
            noRowsRenderer={noRowsRenderer}
            rowClassName={rowClassName}
            rowHeight={40}
            rowGetter={rowGetter}
            rowCount={sortedList.length}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection?.toUpperCase()}
            width={width}
          >
            <Column
              label={t("auditTable.clubName")}
              dataKey="name"
              width={width / 2}
              cellDataGetter={({ rowData }) => (rowData ? rowData.name : null)}
              cellRenderer={({ cellData, rowData }) => (
                <div
                  className={
                    rowData?.orgId && rowData.suomisportOrgId
                      ? "name-column-no-icon"
                      : "non-link-name-column"
                  }
                  onClick={() =>
                    rowData?.orgId && rowData.suomisportOrgId
                      ? navigateToClubPath(rowData)
                      : null
                  }
                >
                  <div>{cellData}</div>
                </div>
              )}
              className={"tablecolumn finalizedname"}
            />
            {showArea && (
              <Column
                width={width / 2}
                dataKey="areaAssociationName"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.areaAssociationName : null
                }
                label={t("auditTable.area")}
                className={"tablecolumn"}
              />
            )}
            {showFederation && (
              <Column
                width={width / 2}
                dataKey="federationName"
                cellDataGetter={({ rowData }) =>
                  rowData ? rowData.federationName : null
                }
                label={t("auditTable.federation")}
                className={"tablecolumn"}
              />
            )}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

const tableTranslated = withTranslation("common")(ClubsTableComponent);

export const ClubsTable = withRouter<ClubsTableTranslatedProps, any>(
  tableTranslated
);
