export default class Locale {
  country: string;
  region: string;
  title: string;

  constructor(country: string, region: string, title: string) {
    this.country = country;
    this.region = region;
    this.title = title;
  }

  toString() {
    return `${this.country}_${this.region}`;
  }
}
