import { handleActions, createAction } from "redux-actions";
import { DevelopmentItem } from "./development";

export const SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE = createAction<
  DevelopmentItemPropsModalState
>("SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE");
export const CLOSE_DEVELOPMENT_ITEM_PROPS_MODAL = createAction(
  "CLOSE_DEVELOPMENT_ITEM_PROPS_MODAL"
);

export interface DevelopmentItemPropsModalState {
  modalIsOpen: boolean;
  item?: DevelopmentItem;
}

const developmentItemPropsModalInitialState: DevelopmentItemPropsModalState = {
  modalIsOpen: false,
};

export const developmentItemPropsModalReducer = handleActions<
  DevelopmentItemPropsModalState,
  any
>(
  {
    [SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE as any]: (state, action) =>
      action.payload,
    [CLOSE_DEVELOPMENT_ITEM_PROPS_MODAL as any]: (state, action) =>
      developmentItemPropsModalInitialState,
  },
  developmentItemPropsModalInitialState
);

export const SET_DEVELOPMENT_ITEM_DELETION_MODAL_STATE = createAction<
  DevelopmentItemDeletionModalState
>("SET_DEVELOPMENT_ITEM_DELETION_MODAL_STATE");
export const CLOSE_DEVELOPMENT_ITEM_DELETION_MODAL = createAction(
  "CLOSE_DEVELOPMENT_ITEM_DELETION_MODAL"
);

export interface DevelopmentItemDeletionModalState {
  modalIsOpen: boolean;
  item?: DevelopmentItem;
  onDelete?(): void;
}

const developmentItemDeletionModalInitialState: DevelopmentItemDeletionModalState = {
  modalIsOpen: false,
};

export const developmentItemDeletionModalReducer = handleActions<
  DevelopmentItemDeletionModalState,
  any
>(
  {
    [SET_DEVELOPMENT_ITEM_DELETION_MODAL_STATE as any]: (state, action) =>
      action.payload,
    [CLOSE_DEVELOPMENT_ITEM_DELETION_MODAL as any]: (state, action) =>
      developmentItemDeletionModalInitialState,
  },
  developmentItemDeletionModalInitialState
);

export const SET_CLUB_DEVELOPMENT_INTRO_MODAL_STATE = createAction<
  ClubDevelopmentIntroModalState
>("SET_CLUB_DEVELOPMENT_INTRO_MODAL_STATE");
export const CLOSE_CLUB_DEVELOPMENT_INTRO_MODAL = createAction(
  "CLOSE_CLUB_DEVELOPMENT_INTRO_MODAL"
);

export interface ClubDevelopmentIntroModalState {
  modalIsOpen: boolean;
  firstTimeUser: boolean;
}

const clubDevelopmentIntroModalInitialState: ClubDevelopmentIntroModalState = {
  modalIsOpen: false,
  firstTimeUser: false,
};

export const clubDevelopmentIntroModalReducer = handleActions<
  ClubDevelopmentIntroModalState,
  any
>(
  {
    [SET_CLUB_DEVELOPMENT_INTRO_MODAL_STATE as any]: (state, action) =>
      action.payload,
    [CLOSE_CLUB_DEVELOPMENT_INTRO_MODAL as any]: (state, action) =>
      clubDevelopmentIntroModalInitialState,
  },
  clubDevelopmentIntroModalInitialState
);
