import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./Development.scss";
import { DevelopmentItemPropsModal } from "../../components/DevelopmentItemPropsModal/DevelopmentItemPropsModal";
import arrowLeftIcon from "../../images/arrow-left.svg";
import arrowRightIcon from "../../images/arrow-right.svg";
import {
  SET_DEVELOPMENT_ITEM_FILTER_STATE,
  updateDevelopmentItem,
} from "../../modules/development";
import { connect } from "react-redux";
import { DevelopmentItemCardDragLayer } from "./DevelopmentItemCardDragLayer";
import { YearTab } from "./YearTab";
import { DevelopmentItemListContainer } from "./DevelopmentItemListContainer";
import { YearContentContainer } from "./YearContentContainer";
import { compose } from "redux";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Link } from "react-router-dom";
import {
  DevelopmentBaseView,
  DevelopmentBaseViewBaseProps,
  enhanceDevelopmentBaseView,
} from "./DevelopmentBaseView";
import { DevelopmentItemDeletionModal } from "../../components/DevelopmentItemDeletionModal/DevelopmentItemDeletionModal";
import stepDirectionIcon from "../../images/step-direction.svg";
import stepActionIcon from "../../images/step-action.svg";
import stepResultsIcon from "../../images/step-results.svg";
import { translateWithMarkdown } from "../../util/translateWithMarkdown";
import { DevelopmentItemFilter } from "./DevelopmentItemFilter";
import { DevelopmentItemTooltip } from "../../components/DevelopmentItemCard/DevelopmentItemTooltip";
import { ReduxState } from "../../reducers";
import { setUserPreferences, UserPreferences } from "../../modules/account";
import { ClubDevelopmentIntroModal } from "../../components/ClubDevelopmentIntroModal/ClubDevelopmentIntroModal";
import { SET_CLUB_DEVELOPMENT_INTRO_MODAL_STATE } from "../../modules/developmentModals";
import {
  fetchOrganizationCapabilities,
  OrganizationCapabilityType,
} from "../../modules/organization";

type OwnProps = {
  readOnly?: boolean;
  disableHelpLink?: boolean;
};

type DevelopmentPageProps = OwnProps &
  DevelopmentBaseViewBaseProps &
  WithTranslation &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface DevelopmentPageState {
  startingYear: number;
  selectedYear: number;
  canDropOnYearContainer: boolean;
  isDragOverYearContainer: boolean;
}

export const DEVELOPMENT_ITEM_TOOLTIP_ID = "development-item-tooltip";

const MIN_STARTING_YEAR = 2020;
const MAX_STARTING_YEAR = new Date().getFullYear() + 2;

class DevelopmentPage extends DevelopmentBaseView<
  DevelopmentPageProps,
  DevelopmentPageState
> {
  private yearContentRef = React.createRef<HTMLElement>();

  constructor(props: DevelopmentPageProps) {
    super(props);
    this.handleYearSelected = this.handleYearSelected.bind(this);

    const currentYear = new Date().getFullYear();
    this.state = {
      startingYear: currentYear,
      selectedYear: currentYear,
      canDropOnYearContainer: false,
      isDragOverYearContainer: false,
    };
  }

  componentDidMount() {
    const {
      userPreferences,
      openClubDevelopmentIntroModal,
      fetchOrganizationCapabilities,
    } = this.props;

    super.componentDidMount();

    this.props.resetYearFilter();

    if (userPreferences && !userPreferences.hideClubDevelopmentIntro) {
      openClubDevelopmentIntroModal(true);
    }

    const federationId = this.getAuditMetaInfo(this.props)?.federationId;
    federationId && fetchOrganizationCapabilities(federationId);
  }

  componentDidUpdate(prevProps: DevelopmentPageProps) {
    const {
      userPreferences,
      openClubDevelopmentIntroModal,
      fetchOrganizationCapabilities,
    } = this.props;

    super.componentDidUpdate(prevProps);

    if (
      userPreferences &&
      !prevProps.userPreferences &&
      !userPreferences.hideClubDevelopmentIntro
    ) {
      openClubDevelopmentIntroModal(true);
    }

    const currentFederationId = this.getAuditMetaInfo(this.props)?.federationId;
    const previousFederationId = this.getAuditMetaInfo(prevProps)?.federationId;
    if (currentFederationId !== previousFederationId) {
      currentFederationId && fetchOrganizationCapabilities(currentFederationId);
    }
  }

  handleYearSelected(year: number) {
    if (year !== this.state.selectedYear) {
      this.setState(
        {
          selectedYear: year,
        },
        () => {
          if (this.yearContentRef && this.yearContentRef.current) {
            this.yearContentRef.current.scrollTop = 0;
          }
        }
      );
    }
  }

  createYearTabComponent(year: number) {
    const developmentItems = this.props.developmentItems;
    const selected = this.state.selectedYear === year;

    if (!developmentItems) {
      return null;
    }

    return (
      <YearTab
        year={year}
        numItems={developmentItems.filter((item) => item.year === year).length}
        selected={selected}
        forceCanDrop={selected && this.state.canDropOnYearContainer}
        forceDragIsOver={selected && this.state.isDragOverYearContainer}
        onYearSelected={this.handleYearSelected}
        onSetItemYear={this.props.setItemYear}
      />
    );
  }

  render() {
    const {
      developmentItems,
      t,
      setItemYear,
      setUserPreferences,
      openClubDevelopmentIntroModal,
      userPreferences,
      federationCapabilities,
    } = this.props;
    const readOnly = !!this.props.readOnly;
    const auditMetaInfo = this.getAuditMetaInfo(this.props);
    const { startingYear } = this.state;
    const T = translateWithMarkdown(t);

    if (!developmentItems || !auditMetaInfo) {
      return <LoadingSpinner />;
    }

    const clubsFederationHasTopSportsCapability = federationCapabilities?.some(
      (c) => {
        return (
          c.capabilityType ===
            OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
          c.value === "true"
        );
      }
    );

    return (
      <div className="development">
        <DevelopmentItemPropsModal
          orgId={this.getOrgId(this.props)}
          auditMetaInfo={auditMetaInfo}
          readOnly={readOnly}
          showTopSports={clubsFederationHasTopSportsCapability}
        />
        <DevelopmentItemDeletionModal />
        <ClubDevelopmentIntroModal
          onClose={() => {
            if (!userPreferences || !userPreferences.hideClubDevelopmentIntro) {
              setUserPreferences({ hideClubDevelopmentIntro: true });
            }
          }}
        />
        <DevelopmentItemTooltip
          id={DEVELOPMENT_ITEM_TOOLTIP_ID}
          itemVisibilityKey={this.state.selectedYear.toString()}
        />
        <div className="development-header">
          <h1>{T("mainView.title")}</h1>
          <h3>{T("mainView.subtitle")}</h3>
          <div className="text">
            <div className="three-steps">
              <div className="step">
                <img src={stepDirectionIcon} alt={t("mainView.step1-alt")} />
                {T("mainView.step1")}
              </div>
              <div className="step">
                <img src={stepActionIcon} alt={t("mainView.step2-alt")} />
                {T("mainView.step2")}
              </div>
              <div className="step">
                <img src={stepResultsIcon} alt={t("mainView.step3-alt")} />
                {T("mainView.step3")}
              </div>
            </div>
            <div className="vertical-divider">
              <hr />
            </div>
            <div className="description">
              {T("mainView.description", {
                markdown: {
                  callbacks: {
                    openClubDevelopmentIntroModal: () =>
                      openClubDevelopmentIntroModal(false),
                  },
                  ...(this.props.disableHelpLink
                    ? {
                        disabledLinks: ["help"],
                      }
                    : {}),
                },
              } as any)}
            </div>
          </div>
        </div>
        <div className="filter-row">
          <DevelopmentItemFilter
            auditMetaInfo={auditMetaInfo}
            showTopSports={clubsFederationHasTopSportsCapability}
          />
        </div>
        <div className="development-body">
          <DevelopmentItemListContainer
            developmentItems={developmentItems}
            auditMetaInfo={auditMetaInfo}
            onSetItemYear={setItemYear}
            readOnly={readOnly}
          />
          <div className="year-view">
            <div className="year-tab-row">
              <button
                className="btn-transparent arrow"
                onClick={() =>
                  this.setState({ startingYear: startingYear - 1 }, () => {
                    if (this.state.selectedYear > this.state.startingYear + 2) {
                      this.handleYearSelected(this.state.startingYear + 2);
                    }
                  })
                }
                disabled={this.state.startingYear <= MIN_STARTING_YEAR}
              >
                <img src={arrowLeftIcon} alt={t("mainView.arrowLeft-alt")} />
              </button>
              <div className="tabs">
                {this.createYearTabComponent(startingYear)}
                {this.createYearTabComponent(startingYear + 1)}
                {this.createYearTabComponent(startingYear + 2)}
              </div>
              <button
                className="btn-transparent arrow"
                onClick={() =>
                  this.setState({ startingYear: startingYear + 1 }, () => {
                    if (this.state.selectedYear < this.state.startingYear) {
                      this.handleYearSelected(this.state.startingYear);
                    }
                  })
                }
                disabled={this.state.startingYear >= MAX_STARTING_YEAR}
              >
                <img src={arrowRightIcon} alt={t("mainView.arrowRight-alt")} />
              </button>
            </div>
            <YearContentContainer
              developmentItems={developmentItems}
              auditMetaInfo={auditMetaInfo}
              year={this.state.selectedYear}
              innerRef={this.yearContentRef}
              onCanDropChanged={(canDrop) =>
                this.setState({ canDropOnYearContainer: canDrop })
              }
              onDragOverChanged={(isOver) =>
                this.setState({ isDragOverYearContainer: isOver })
              }
              onSetItemYear={setItemYear}
              readOnly={readOnly}
            />
          </div>
        </div>
        <DevelopmentItemCardDragLayer />
        <div className="vertical-divider">
          <hr />
        </div>
        <Link
          to="development-summary"
          className="btn-secondary-blue summary-button"
        >
          {t("mainView.showSummary")}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, props) => {
  const orgId = parseInt(props.match.params.orgId, 10);

  const auditMetaInfo =
    state.clubAuditMetaInfo && orgId
      ? state.clubAuditMetaInfo.clubAuditMetaInfo[orgId]
      : null;

  const federationId = auditMetaInfo?.federationId;
  return {
    userPreferences: state.account.user
      ? state.account.user.userPreferences
      : null,
    federationCapabilities: federationId
      ? state.organization.capabilities[federationId]
      : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  setItemYear: (id: number, year: number) =>
    dispatch(updateDevelopmentItem(id, { year })),
  resetYearFilter: () =>
    dispatch(
      SET_DEVELOPMENT_ITEM_FILTER_STATE({
        itemProperty: "year",
        value: null,
      })
    ),
  openClubDevelopmentIntroModal: (firstTimeUser: boolean) =>
    dispatch(
      SET_CLUB_DEVELOPMENT_INTRO_MODAL_STATE({
        modalIsOpen: true,
        firstTimeUser,
      })
    ),
  setUserPreferences: (userPreferences: UserPreferences) =>
    dispatch(setUserPreferences(userPreferences)),
  fetchOrganizationCapabilities: (orgId: number) => {
    dispatch(fetchOrganizationCapabilities(orgId));
  },
});

const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("clubDevelopment"),
  enhanceDevelopmentBaseView
);

export default enhance(DevelopmentPage);
