import React from "react";
import AuditField from "./AuditField";
import { get } from "lodash";
import { CommentMode } from "../../../modules/comment";
import ImprovementsListFields from "../Components/ImprovementsListFields";
import { TFunction } from "i18next";

export function TahtiseuraListField(props: {
  name: string;
  placeholder: string;
  fieldTranslated: string;
  orgId: string | undefined;
  readOnly?: boolean;
  commentEdit?: boolean;
  type: string;
  ratingMap: any;
  formData: any;
  t: TFunction;
}) {
  const {
    name,
    placeholder,
    orgId,
    readOnly,
    commentEdit,
    type,
    formData,
    ratingMap,
    fieldTranslated,
    t,
  } = props;
  return (
    <>
      <ImprovementsListFields
        baseFieldName={name}
        placeholder={t(placeholder)}
        readOnly={readOnly}
      />
      <AuditField
        ratingMap={ratingMap}
        edit={commentEdit}
        type={type}
        fieldName={name}
        orgId={orgId}
        commentMode={CommentMode.Field}
        fieldTranslated={t(fieldTranslated)}
        fieldValue={ratingMap[get(formData, name)]}
      />
    </>
  );
}
