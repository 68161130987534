import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { default as authReducer, UserAccount } from "./modules/account";
import { default as commentReducer, CommentState } from "./modules/comment";
import contentfulReducer, { ContentfulState } from "./modules/contentful";
import { default as pathReducer, PathState } from "./modules/path";
import {
  default as auditRequestReducer,
  AuditRequestState,
} from "./modules/auditRequest";
import { default as auditorReducer, AuditorState } from "./modules/auditor";
import {
  default as attachmentReducer,
  AttachmentState,
} from "./modules/attachment";
import auditSettingsReducer, {
  AuditSettingsState,
} from "./modules/auditSettings";
import configReducer, { ConfigState } from "./modules/config";
import developmentReducer, { DevelopmentState } from "./modules/development";
import auditMetaInfoReducer, {
  AuditMetaInfoState,
} from "./modules/auditmetainfo";
import { organizationReducer, OrganizationState } from "./modules/organization";
import auditFormMetaInfoReducer, {
  AuditFormMetaInfoState,
} from "./modules/formMetaInfo";

export interface ReduxState {
  form: formReducer;
  account: UserAccount;
  comment: CommentState;
  contentful: ContentfulState;
  path: PathState;
  auditRequest: AuditRequestState;
  auditor: AuditorState;
  auditSettings: AuditSettingsState;
  config: ConfigState;
  attachment: AttachmentState;
  development: DevelopmentState;
  clubAuditMetaInfo: AuditMetaInfoState;
  organizationAuditFormMetaInfo: AuditFormMetaInfoState;
  organization: OrganizationState;
}

export const appReducer = combineReducers<ReduxState>({
  form: formReducer,
  account: authReducer,
  comment: commentReducer,
  contentful: contentfulReducer,
  path: pathReducer,
  auditRequest: auditRequestReducer,
  auditor: auditorReducer,
  auditSettings: auditSettingsReducer,
  config: configReducer,
  attachment: attachmentReducer,
  development: developmentReducer,
  clubAuditMetaInfo: auditMetaInfoReducer,
  organizationAuditFormMetaInfo: auditFormMetaInfoReducer,
  organization: organizationReducer,
});

export const rootReducer = (state: ReduxState, action: any) => {
  return appReducer(state, action);
};
