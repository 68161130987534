import axios from "axios";
import { AuditRequest, ClubEnrollment } from "../model";

const club = {
  createEnrollment: (
    data: ClubEnrollment,
    orgId: string | number,
    qualityPath: string
  ) => axios.post(`/api/v1/club-enrollment/${orgId}/${qualityPath}`, data),
  deleteEnrollment: (orgId: string | number, qualityPath: string) =>
    axios.delete(`/api/v1/club-enrollment/${orgId}/${qualityPath}`),
  getAllEnrollments: (orgId: string | number) =>
    axios.get(`/api/v1/club-enrollment/${orgId}`),
  getOneEnrollment: (orgId: string | number, qualityPath: string) =>
    axios.get(`/api/v1/club-enrollment/${orgId}/${qualityPath}`),
  getAuditRequestsForEnrollment: (enrollmentId) =>
    axios.get(`/api/v1/audit-request/by-enrollment/${enrollmentId}`),
  createAuditRequest: (data: AuditRequest) =>
    axios.post(`/api/v1/audit-request`, data),
  getSingleAuditRequest: (requestId: string) =>
    axios.get(`/api/v1/audit-request/${requestId}`),
  getAuditRequestAddInfo: (requestId: string) =>
    axios.get(`/api/v1/audit-request/${requestId}/additionalinfo`),
  getSingleRequestPart: (requestId: string, partKey: string) =>
    axios.get(`/api/v1/audit-request/${requestId}/part/${partKey}`),
  setAuditRequestPartValues: (requestId: string, partKey: string, data: any) =>
    axios.put(
      `/api/v1/audit-request/${requestId}/part/${partKey}/values`,
      data
    ),
  getAuditRequestPartValues: (requestId: string, partKey: string) =>
    axios.get(`/api/v1/audit-request/${requestId}/part/${partKey}/values`),
  copyClubContentFromOtherQualityPath: (
    requestId: string,
    partKey: string,
    otherQualityPath: string
  ) =>
    axios.post(
      `/api/v1/audit-request/${requestId}/part/${partKey}/copyClubContentFrom/${otherQualityPath}`
    ),
  setAuditRequestPart: (requestId: string, partKey: string, state: string) =>
    axios.put(`/api/v1/audit-request/${requestId}/part/${partKey}`, {
      state: state,
    }),
  submitAuditRequest: (requestId: string) =>
    axios.post(`/api/v1/audit-request/${requestId}/submit`),
  setAuditRequestPartComments: (
    requestId: string,
    partKey: string,
    data: any
  ) =>
    axios.put(
      `/api/v1/audit-request/${requestId}/part/${partKey}/comments`,
      data
    ),
  getAuditRequestPartComments: (requestId: string, partKey: string) =>
    axios.get(`/api/v1/audit-request/${requestId}/part/${partKey}/comments`),
  getRequestNotes: (orgId: string | number, qualityPath: string) =>
    axios.get(`/api/v1/audit-notes/${orgId}/${qualityPath}/CLUB`),
  saveRequestNotes: (orgId: string | number, qualityPath: string, data: any) =>
    axios.put(`/api/v1/audit-notes/${orgId}/${qualityPath}/CLUB`, data),
};

export default club;
