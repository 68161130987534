import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";

/*
 * Use this in place of a wrapper div if you need to show the user a loading spinner when fetching data
 */
export class LoadingContainer extends React.PureComponent<{
  className?: string;
  loading: boolean;
  children: any;
  height?: number;
}> {
  render() {
    const { loading, children, className, height } = this.props;
    return (
      <div
        style={{ height: height ? height : "inherit" }}
        className={className}
      >
        {loading ? <LoadingSpinner /> : children}
      </div>
    );
  }
}
