import React from "react";
import { Checkbox } from "react-bootstrap";

export interface ArraySelectionCheckboxOption {
  label: string;
  value: any;
}

interface ArraySelectionCheckboxGroupProps {
  className?: string;
  disabled: boolean;
  options: ArraySelectionCheckboxOption[];
  values: any[];
  onValueChange?(values: any[]): void;
}

export function ArraySelectionCheckboxGroup(
  props: ArraySelectionCheckboxGroupProps
) {
  const { options, disabled, values, onValueChange } = props;
  const randomName = Math.random().toString(36).substr(2, 5);
  const className = props.className
    ? props.className + " arrayselectioncheckboxgroup"
    : "arrayselectioncheckboxgroup";

  const onChange = (event: any) => {
    if (event.target.checked) {
      if (onValueChange) {
        onValueChange([...values, event.target.value]);
      }
    } else {
      if (onValueChange) {
        onValueChange(values.filter((value) => value !== event.target.value));
      }
    }
  };

  return (
    <div className={className}>
      {options.map((item) => {
        const checked = values.some((value) => value === item.value);

        return (
          <span key={item.value}>
            <Checkbox
              id={randomName + item.value}
              name={randomName}
              value={item.value}
              onChange={onChange}
              disabled={disabled}
              className={checked ? "checked" : "unchecked"}
              checked={checked}
            >
              <div className="labelcontainer">{item.label}</div>
            </Checkbox>
          </span>
        );
      })}
    </div>
  );
}
