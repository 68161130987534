import React from "react";
import { OrgType } from "../../model";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { first } from "lodash";
import {
  getClubDashboardUrl,
  getOrganizationDashboardUrl,
} from "../../routePaths";

type LoginProps = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>;

/*
  Component to redirect the logged in user to her own profile page. This is usually the page
  of a club or federation.
 */
class Login extends React.Component<LoginProps> {
  navigateToDashboard(props: LoginProps) {
    const { user, authenticating } = props;

    if (authenticating) {
      // Do nothing as long as the logging in process is not finished
      return;
    }

    let foundPath = false;

    if (user && user.writeAccessOrgs) {
      const firstOrg = first(user.writeAccessOrgs);
      if (firstOrg && firstOrg.type === OrgType.CLUB) {
        this.props.history.push(getClubDashboardUrl(firstOrg.orgId.toString()));
        foundPath = true;
      } else if (
        firstOrg &&
        (firstOrg.type === OrgType.SPORTS_FEDERATION ||
          firstOrg.type === OrgType.COLLECTION_AREA_ASSOCIATION ||
          firstOrg.type === OrgType.ADMIN_ORGANIZATION)
      ) {
        this.props.history.push(
          getOrganizationDashboardUrl(firstOrg.orgId.toString())
        );
        foundPath = true;
      }
    }

    if (!foundPath) {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.navigateToDashboard(this.props);
  }

  componentWillReceiveProps(nextProps: LoginProps) {
    this.navigateToDashboard(nextProps);
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state: ReduxState) => ({
  authenticating: state.account.authenticating,
  user: state.account.user,
});

export default withRouter(connect(mapStateToProps)(Login));
